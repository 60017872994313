import React from 'react';

class CBSortableTableHeader extends React.Component {
  getOrderingSymbol = (columnName) => {
    const { ordering } = this.props;
    if (ordering === columnName) {
      return '/img/down_arrow_icon.svg';
    } else if (ordering === `-${columnName}`) {
      return '/img/up_arrow_icon.svg';
    }
    return undefined;
  }

  render() {
    const {
      style,
      text,
      fieldName,
      onClick,
    } = this.props;

    return (
      <th onClick={() => onClick(fieldName)} style={style}>
        { text }
        {
          this.getOrderingSymbol(fieldName) && (
            <img
              alt=''
              src={this.getOrderingSymbol(fieldName)}
            />
          )
        }
      </th>
    );
  }
}

export default CBSortableTableHeader;
