import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import '../../../App.css';
import { getStyle, getModalStyle } from '../../../utils/utils';
import {
  createSMSPackage as _createSMSPackage,
  fetchSMSCounts as _fetchSMSCounts,
} from '../../../actions/workshopActions';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBButtonSubmit from '../../shared/CBButtonSubmit';

const PACKAGES = [
  { size: 100, price: 25 },
  { size: 500, price: 99 },
  { size: 1000, price: 190 },
  { size: 2000, price: 370 },
];

// TODO: put Box and BoxRow in their own files

const Box = ({ labelText, invertColors, onClick, flex }) => {
  let containerStyle = { ...boxStyles.boxContainer, flex };
  if (invertColors) {
    containerStyle = {
      ...containerStyle,
      ...boxStyles.invertColors,
    };
  }
  if (onClick) {
    containerStyle = {
      ...containerStyle,
      cursor: 'pointer',
    };
  }
  return (
    <div onClick={onClick} style={containerStyle}>
      <p style={boxStyles.buttonText}>{labelText}</p>
    </div>
  );
};

const BoxRow = ({ leftLabel, onClick, selected }) => (
  <div style={boxStyles.row}>
    <Box flex={3} labelText={leftLabel} invertColors />
    <Box
      flex={1}
      labelText='Valitse'
      invertColors={selected}
      onClick={onClick}
    />
  </div>
);

const boxStyles = {
  boxContainer: {
    height: 50,
    border: '2px solid #0095da',
    color: '#0095da',
    borderRadius: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 5,
  },
  invertColors: {
    backgroundColor: '#0095da',
    color: 'white',
  },
  buttonText: {
    fontSize: 14,
    fontWeight: 700,
    textDecoration: 'none solid rgb(0, 246, 0)',
    color: 'inherit',
    padding: 16,
    marginBottom: 0,
  },
  row: {
    display: 'flex',
    width: '100%',
  },
};

class BuySMSModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPackageId: null,
    };
  }

  getIsValid = () => this.state.selectedPackageId !== null;

  handlePackageClick = id => {
    this.setState(prevState => ({
      ...prevState,
      selectedPackageId: id === prevState.selectedPackageId ? null : id,
    }));
  };

  submitForm = () => {
    const { selectedPackageId } = this.state;
    const { user, createSMSPackage, closeModal, selectedWorkshopId } = this.props;
    const size = PACKAGES[selectedPackageId].size;
    const data = {
      workshop: selectedWorkshopId,
      orderer: user.id,
      size,
    };
    createSMSPackage(data);
    closeModal(true);
  };

  render() {
    const { selectedPackageId } = this.state;
    const { modalIsOpen, closeModal, t } = this.props;
    const getPackageLabel = id =>
      `${PACKAGES[id].size} ${t('smsAmount')} - ${PACKAGES[id].price} €`;

    return (
      <CBMediaQuery>
        {screenSize => (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => closeModal(false)}
            style={getModalStyle(screenSize, styles.modal)}
          >
            <div style={getStyle(screenSize, styles, 'container')}>
              <p style={getStyle(screenSize, styles, 'title')}>
                {t('buySMSTitle')}
              </p>
              <p style={getStyle(screenSize, styles, 'info')}>
                {t('buySMSInfo')}
              </p>
              <p style={getStyle(screenSize, styles, 'subTitle')}>
                {t('buySMSTitle')}
              </p>
              <div style={getStyle(screenSize, styles, 'selectionContainer')}>
                <BoxRow
                  leftLabel={getPackageLabel(0)}
                  selected={selectedPackageId === 0}
                  onClick={() => this.handlePackageClick(0)}
                />
                <BoxRow
                  leftLabel={getPackageLabel(1)}
                  selected={selectedPackageId === 1}
                  onClick={() => this.handlePackageClick(1)}
                />
                <BoxRow
                  leftLabel={getPackageLabel(2)}
                  selected={selectedPackageId === 2}
                  onClick={() => this.handlePackageClick(2)}
                />
                <BoxRow
                  leftLabel={getPackageLabel(3)}
                  selected={selectedPackageId === 3}
                  onClick={() => this.handlePackageClick(3)}
                />
              </div>
              <div style={getStyle(screenSize, styles, 'buttonContainer')}>
                <CBButtonSubmit
                  onClick={() => closeModal(false)}
                  text={t('default:promptCancelButton')}
                />
                <CBButtonSubmit
                  disabled={!this.getIsValid()}
                  onClick={this.submitForm}
                  text={t('buySMSSubmitButton')}
                />
              </div>
            </div>
          </Modal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
    title: {
      fontSize: 32,
      color: '#0095da',
      textAlign: 'center',
    },
    subTitle: {
      color: 'black',
      textAlign: 'center',
      fontWeight: 800,
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 180,
      resize: 'none',
      color: 'black',
    },
    info: {
      width: '100%',
      fontSize: 14,
      color: 'black',
      paddingHorizontal: 30,
      textAlign: 'center',
      borderStyle: 'solid',
      borderWidth: 0,
      borderBottomWidth: 1,
      borderBottomColor: 'rgb(102, 102, 102, 0.3)',
      paddingBottom: 16,
    },
    selectionContainer: {
      width: '100%',
      paddingBottom: 16,
      borderStyle: 'solid',
      borderWidth: 0,
      borderBottomWidth: 1,
      borderBottomColor: 'rgb(102, 102, 102, 0.3)',
    },
    buttonContainer: {
      marginTop: 16,
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },
  },
  modal: {
    default: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    medium: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    small: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '90%',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    smsPackageStatus: state.workshop.smsPackageStatus,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  { createSMSPackage: _createSMSPackage, fetchSMSCounts: _fetchSMSCounts },
)(translate('VehicleDetailsView')(BuySMSModal));
