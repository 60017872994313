import React from 'react';

class CBLabelAndValue extends React.Component {
  render() {
    const { label, value } = this.props;
    return (
      <div style={styles.vehicleInfoPieceContainer}>
        <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
          {label}
        </p>
        <p className='font-titillium-web-light' style={styles.infoValue}>
          {value}
        </p>
      </div>
    );
  }
}

const styles = {
  vehicleInfoPieceContainer: {},
  infoLabel: {
    color: '#FFF',
    fontSize: 14,
    fontWeight: 600,
  },
  infoValue: {
    color: '#FFF',
    fontSize: 14,
    fontWeight: 200,
  },
};

export default CBLabelAndValue;
