import React from 'react';
import { translate } from 'react-i18next';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle } from '../../../utils/utils';
import NavbarButton from '../../shared/NavbarButton';

class WorkshopNavbar extends React.Component {
  getActiveTab = () => {
    const url = window.location.pathname;
    if (url.includes('/vehicles')) {
      return 'Vehicles and maintenances';
    } else if (url.includes('/messages')) {
      return 'Messages';
    } else if (url.includes('/tasks')) {
      return 'Tasks';
    } else {
      return 'Care Service';
    }
  };

  render() {
    const { t, workshop } = this.props;
    const activeTab = this.getActiveTab();
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'navbarContainer')}>
            <div style={getStyle(screenSize, styles, 'buttonGroup')}>
              <NavbarButton
                to={`/workshop/${workshop.id}/care-service`}
                iconSrc='/img/icon_care_service.svg'
                alt='Care Service'
                buttonText={t('navCareVehicles')}
                setActiveTab={tabName => this.setState({ activeTab: tabName })}
                activeTab={activeTab}
              />
              <NavbarButton
                to={`/workshop/${workshop.id}/vehicles`}
                iconSrc='/img/icon_car_basic_info.svg'
                alt='Vehicles and maintenances'
                buttonText={t('navVehiclesAndMaintenances')}
                setActiveTab={tabName => this.setState({ activeTab: tabName })}
                activeTab={activeTab}
              />
              {/*
              <NavbarButton
                to={`#`}
                iconSrc='/img/icon_vehicles_and_maintenances.svg'
                alt='Maintenances'
                buttonText={t('navMaintenances')}
                setActiveTab={tabName => this.setState({ activeTab: tabName })}
                activeTab={activeTab}
                disabled
              />
              */}
              <NavbarButton
                to={`/workshop/${workshop.id}/messages`}
                iconSrc='/img/icon_messages.svg'
                alt='Messages'
                buttonText={t('navMessages')}
                setActiveTab={tabName => this.setState({ activeTab: tabName })}
                activeTab={activeTab}
              />
              <NavbarButton
                to={`/workshop/${workshop.id}/tasks`}
                iconSrc='/img/icon_task_list.svg'
                alt='Tasks'
                buttonText={t('navTasks')}
                setActiveTab={tabName => this.setState({ activeTab: tabName })}
                activeTab={activeTab}
              />
              {/*
                <NavbarButton
                to={`/workshop/${workshop.id}/marketing`}
                iconSrc='/img/icon_marketing.svg'
                alt='Marketing'
                buttonText={t('navMarketing')}
                setActiveTab={tabName => this.setState({ activeTab: tabName })}
                activeTab={activeTab}
                disabled
              />
              */}

            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    navbarContainer: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
    },
    buttonGroup: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
    },
    navbarButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRight: '1px solid #FFF',
      padding: 16,
      cursor: 'pointer',
    },
    buttonIcon: {
      display: 'inline-block',
      width: 64,
      height: 64,
    },
    buttonText: {
      display: 'inline',
      color: '#FFF',
      fontSize: 18,
    },
  },
};

export default translate('WSHomeView')(WorkshopNavbar);
