import React, { Component } from 'react';
import Modal from 'react-modal';
import moment from 'moment';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getStyle, getModalStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBButtonSubmit from '../../shared/CBButtonSubmit';
import {
  requestVehiclePermission as _requestVehiclePermission,
} from '../../../actions/vehiclePermissionActions';

// import { browserIsIE } from '../../../ie-support';


Modal.setAppElement('#root');

class NotificationModal extends Component {
  state = {
  };

  render() {
    const { formData } = this.state;
    const {
      modalIsOpen,
      closeModal,
      afterOpenModal,
      vehicle,
      requestVehiclePermission,
    } = this.props;
    const lastTraficomSync = new Date(String(vehicle.traficom_sync_date));
    const deletionDate = moment(lastTraficomSync.setDate(lastTraficomSync.getDate() + 365)).format('DD.MM.YYYY');
    return (
      <CBMediaQuery>
        {screenSize => (
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={getModalStyle(screenSize, styles.modal)}
            contentLabel='Notification modal'
          >
            <div style={getStyle(screenSize, styles, 'container')}>
              <p style={getStyle(screenSize, styles, 'title')}>
                Ajoneuvosi tiedot vanhenevat
              </p>
              <div>
                <p style={getStyle(screenSize, styles, 'subTitle')}>
                  Rekisterinumero:
                </p>
                <p style={getStyle(screenSize, styles, 'title')}>
                  {vehicle.registration_number}
                </p>
              </div>
              <p style={getStyle(screenSize, styles, 'info')}>
                Ajoneuvon tiedot poistuvat {deletionDate}
              </p>
              <p style={getStyle(screenSize, styles, 'info')}>
                Päivitä ajoneuvon tiedot estääksesi Traficomista tulleiden tietojen poistamisen.
              </p>
              <CBButtonSubmit
                onClick={() => {
                  requestVehiclePermission(vehicle.registration_number);
                  closeModal();
                }}
                text={'Päivitä tiedot'}
              />
            </div>
          </Modal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      height: '100%',
    },
    title: {
      fontSize: 32,
      color: '#0095da',
      textAlign: 'center',
    },
    subTitle: {
      fontSize: 14,
      color: 'black',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    largeButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 50,
      border: '2px solid #00f600',
      cursor: 'pointer',
      borderRadius: 50,
      backgroundColor: '#00f600',
      width: '50%',
    },
    info: {
      width: '100%',
      fontSize: 14,
      color: 'black',
      paddingTop: 16,
      paddingHorizontal: 30,
      textAlign: 'center',
    },
  },
  small: {
    largeButton: {
      width: '90%',
    },
  },
  modal: {
    default: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 370,
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    small: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
};

export default connect(
  null,
  {
    requestVehiclePermission: _requestVehiclePermission,
  },
)(translate('NotificationModal')(NotificationModal));
