import React from 'react';

class CBButtonTransparent extends React.Component {
  render() {
    const {
      to, text, imgSrc, textStyle,
    } = this.props;
    let className = 'hover-green-border';
    if (!textStyle) {
      className += ' hover-green-text';
    }
    return (
      <a href={to}>
        <div className={className} style={styles.largeButton}>
          { imgSrc && <img src={imgSrc} alt='' />}
          <p
            style={textStyle ? { ...styles.buttonText, ...textStyle } : styles.buttonText}
          >
            {text}
          </p>
        </div>
      </a>
    );
  }
}

export default CBButtonTransparent;

const styles = {
  buttonText: {
    fontFamily: 'TitilliumWeb-Bold',
    fontSize: 14,
    fontWeight: 700,
    color: '#0095da',
    textDecoration: 'text-decoration: none solid rgb(0, 149, 218)',
    padding: 16,
    marginBottom: 0,
  },
  largeButton: {
    height: 50,
    width: '100%',
    border: '2px solid #0095da',
    color: '#0095da',
    borderRadius: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
