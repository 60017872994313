import React, { Component } from 'react';
import Modal from 'react-modal';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../../shared/CBMediaQuery';
import '../../../App.css';
import { getStyle, getModalStyle, SERVICE_WORKSHOP } from '../../../utils/utils';
import CBButtonSubmit from '../../shared/CBButtonSubmit';
import CBInputField from '../../shared/form_components/CBInputField';
import CBTextAreaField from '../../shared/form_components/CBTextAreaField';
import CBSelectField from '../../shared/form_components/CBSelectField';
import {
  createVehicleMessageResponse as _createVehicleMessageResponse,
  updateVehicleMessageResponse as _updateVehicleMessageResponse,
} from '../../../actions/vehicleActions';
import {
  createMessageResponse as _createMessageResponse,
} from '../../../actions/messageActions';
import {
  getNumberValidator,
  getLengthValidator,
} from '../../../utils/validators';
import CBDatePickerInput from '../../shared/CBDatePickerInput';
import MessageCard from './MessageCard';
import CBModal from '../../shared/CBModal';
import CBDropzone from '../../shared/CBDropzone';
import ImagePreview from '../../shared/ImagePreview';


class AddMessageResponseModal extends Component {
  constructor(props) {
    super(props);

    const { response } = props;

    this.state = {
      showAllResponses: false,
      formData: {
        user: response ? response.user : undefined,
        text: response ? response.text : '',
        message: '',
        images: response ? response.images : [],
        image_actions: [],
      },
      formValid: {
        text: !!response,
      },
    };
  }

  componentDidMount = () => {
    const { user, message, response } = this.props;
    const { formData } = this.state;
    this.setState({
      formData: {
        ...formData,
        user: response
          ? response.user
          : user.id,
        message: message.id,
      },
    });
  };

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
      [name]: isValid,
    };

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const { formData } = this.state;
    const {
      createVehicleMessageResponse,
      updateVehicleMessageResponse,
      createMessageResponse,
      response,
      closeModal,
      user,
      message,
      service,
      selectedWorkshopId,
      isListRequest
    } = this.props;
    if (response) {
      updateVehicleMessageResponse(response.id, formData, service === SERVICE_WORKSHOP && selectedWorkshopId);
    } else {
      isListRequest
        ? createMessageResponse(formData, service === SERVICE_WORKSHOP && selectedWorkshopId)
        : createVehicleMessageResponse(formData, service === SERVICE_WORKSHOP && selectedWorkshopId);
    }
    this.setState({
      formData: {
        user: response
          ? response.name
          : `${user.profile.first_name} ${user.profile.last_name}`,
        text: response ? response.text : '',
        message: message.id,
      },
      formValid: {
        description: !!response,
      },
    });
    closeModal();
  };

  onDropImage = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageChanged(reader.result, file);
      };
    });
  };

  imageChanged = (image, file) => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData.image_actions.push(
      {
        action: 'create',
        data: image,
        preview: file.preview,
      },
    );
    this.setState({ formData: newFormData });
  };

  deleteImage = (image) => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    // Is newly added image, not yet saved to backend
    if (image.preview) {
      newFormData.image_actions = newFormData.image_actions.filter(
        (img) => img.preview !== image.preview,
      );
    } else {
      newFormData.image_actions.push(
        {
          action: 'delete',
          id: image.id,
        },
      );
      newFormData.images = newFormData.images.filter(
        (img) => img.id !== image.id,
      );
    }
    this.setState({ formData: newFormData });
  };

  render() {
    const { formData, showAllResponses } = this.state;
    const {
      modalIsOpen,
      closeModal,
      afterOpenModal,
      t,
      response,
      user,
      message,
    } = this.props;

    const newImages = formData.image_actions.filter((ia) => ia.action === 'create');

    return (
      <CBMediaQuery>
        {screenSize => (
          <CBModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={closeModal}
            contentLabel='Add response modal'
            isValid={this.getIsValid()}
            submitForm={this.submitForm}
            submitText={t('addMessageSaveButton')}
            cancelText={t('cancel')}
            title={response ? t('updateMessageResponseLabel') : t('addNewResponse')}
            descriptionText='Lähetä viesti ajoneuvolle'
            vehicleText={message.vehicle_registration_number}
          >
            <div style={getStyle(screenSize, styles, 'container')}>
              <div style={getStyle(screenSize, styles, 'previousMessagesContainer')}>
                {
                  !showAllResponses && message.responses.length > 1 && (
                    <button
                      className='opacity-on-hover'
                      style={getStyle(screenSize, styles, 'showAllButton')}
                      onClick={() => {this.setState({showAllResponses: true})}}
                    >
                      Näytä koko viestiketju ({message.responses.length + 1})
                    </button>
                  )
                }

                <div style={getStyle(screenSize, styles, 'previousMessageContainer')}>
                  <MessageCard message={message} />
                </div>

                {
                  showAllResponses && (
                    message.responses.map((res, i, responses) => (
                      <MessageCard key={res.id} message={res} isLastResponse={i + 1 === responses.length} />
                    ))
                  )
                }
                {
                  !showAllResponses && message.responses.length > 0 && (
                    <MessageCard
                      message={message.responses[message.responses.length - 1]}
                      isLastResponse
                    />
                  )
                }
              </div>
              <div style={getStyle(screenSize, styles, 'bodyContainer')}>
                <div style={getStyle(screenSize, styles, 'inputContainer')}>
                  <CBTextAreaField
                    name='text'
                    value={formData.text}
                    onChange={this.updateFormState}
                    labelText={t('addMessageTextLabel')}
                    labelTextStyle={getStyle(screenSize, styles, 'subTitle')}
                    validators={[getLengthValidator(1, 5000)]}
                    isValidCallback={this.setIsValid}
                    validateOnInput
                  />
                </div>
                <div style={getStyle(screenSize, styles, 'dropZoneContainer')}>
                  <CBDropzone
                    className='opacity-on-hover'
                    onDropAccepted={this.onDropImage}
                  >
                    <p style={getStyle(screenSize, styles, 'dropZoneText')}>
                      Lisää kuvia...
                    </p>
                  </CBDropzone>
                </div>
                <div style={getStyle(screenSize, styles, 'imagesContainer')}>
                  {
                    !!formData.images.length && (
                      formData.images.map((image) => (
                        <ImagePreview
                          src={image.thumbnail}
                          onDelete={() => this.deleteImage(image)}
                        />
                      ))
                    )
                  }
                  {
                    !!newImages.length && (
                      newImages.map((image) => (
                        <ImagePreview
                          src={image.preview}
                          onDelete={() => this.deleteImage(image)}
                        />
                      ))
                    )
                  }
                </div>
              </div>
            </div>
          </CBModal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    bodyContainer: {
      padding: 32,
      width: '100%',
    },
    inputContainer: {
      marginBottom: 32,
    },
    imagesContainer: {
      marginTop: 16,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    dropZoneContainer: {
    },
    dropZoneText: {
      color: 'rgb(102, 102, 102)',
      fontSize: 24,
    },
    showAllButton: {
      height: 48,
      backgroundColor: '#FFFFFF',
      borderRadius: 8,
      boxShadow: 0,
      marginBottom: 16,
      opacity: '70%',
      border: '1px solid rgba(0, 0, 0, 0.5)',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
    },
    previousMessagesContainer: {
      minHeight: 200,
      backgroundColor: 'rgba(243, 243, 253, 1)',
      display: 'flex',
      flexDirection: 'column',
      padding: 32,
    },
    previousMessageContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    subTitle: {
      fontSize: 14,
      color: 'black',
      textAlign: 'center',
      fontWeight: 600,
    },
  },
  small: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 16px'
    },
    previousMessagesContainer: {
      minHeight: 200,
      backgroundColor: 'rgba(243, 243, 253, 1)',
      display: 'flex',
      flexDirection: 'column',
      padding: 16,
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    createVehicleMessageResponse: _createVehicleMessageResponse,
    updateVehicleMessageResponse: _updateVehicleMessageResponse,
    createMessageResponse: _createMessageResponse,
  },
)(translate('VehicleDetailsView')(AddMessageResponseModal));
