import React from 'react';
import { browserIsIE } from '../../ie-support';

class LicensePlate extends React.Component {
  render() {
    const { size, plateNumber } = this.props;
    return (
      <div
        style={
          size === 'large' ?
          (browserIsIE() ? styles.licensePlateContainerLargeIE : styles.licensePlateContainerLarge) :
          (browserIsIE() ? styles.licensePlateContainerSmall : styles.licensePlateContainerSmall)
        }
      >
        <span
          className='font-roboto-bold'
          style={size === 'large' ? styles.licensePlateTextLarge : styles.licensePlateTextSmall}
        >
          {plateNumber}
        </span>
      </div>
    );
  }
}

const styles = {
  licensePlateContainerLarge: {
    backgroundImage: 'url(/img/vector_plates_1.svg)',
    width: 209,
    height: 54,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 22,
  },
  licensePlateContainerLargeIE: {
    backgroundImage: 'url(/img/vector_plates_1.svg)',
    width: 139,
    height: 54,
    backgroundSize: 'cover',
    display: 'flex',
    paddingLeft: 22,
  },
  licensePlateContainerSmall: {
    backgroundImage: 'url(/img/vector_plates_1.svg)',
    width: 139,
    height: 36,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 20,
    boxSizing: 'border-box',
    paddingLeft: 15,
  },
  licensePlateTextLarge: {
    color: '#FFFFFF',
    fontSize: 27,
  },
  licensePlateTextSmall: {
    color: '#FFFFFF',
    fontSize: 20,
  },
};

export default LicensePlate;
