import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import VehicleDetailsLabelAndValue from '../VehicleDetailsLabelAndValue';
import { translate } from 'react-i18next';
import { styleMerge } from '../../../../utils/utils';

class MessageListItem extends React.Component {
  getContainerStyle = () => {
    const { isSelected } = this.props;
    let style = styles.listItemContainer;

    if (isSelected) {
      style = styleMerge(style, styles.selected);
    }

    return style;
  }

  render() {
    const { message, vehicle, t } = this.props;

    return (
      <Link to={`/vehicle/${vehicle.id}/messages/${message.id}`}>
        <div style={this.getContainerStyle()}>
          <div style={styleMerge(styles.innerContainer, message.is_read ? styles.isRead : {})}>
            <VehicleDetailsLabelAndValue
              label={moment(message.created_at).format('D.M.Y')}
              value={message.subject}
            />
            <VehicleDetailsLabelAndValue
              label={message.vehicle_registration_number}
              value={message.is_read ? t('isRead') : t('unread')}
            />
          </div>
        </div>
      </Link>
    );
  }
}

const styles = {
  listItemContainer: {
    padding: 10,
    borderBottom: '1px solid rgba(255,255,255,.3)',
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selected: {
    borderLeft: '4px solid #27bbff',
  },
  isRead: {
    opacity: '60%',
  },
};

export default translate('MessageListItem')(MessageListItem);
