import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import VehicleDetailsLabelAndValue from '../VehicleDetailsLabelAndValue';
import { getTreatmentTypeTranslationKey, styleMerge } from '../../../../utils/utils';

class TreatmentListItem extends React.Component {
  getContainerStyle = () => {
    const { isSelected } = this.props;

    let style = styles.listItemContainer;

    if (isSelected) {
      style = styleMerge(style, styles.selected);
    }

    return style;
  };

  render() {
    const { treatment, vehicle, history, t } = this.props;
    return (
      <Link
        to={
          history.location.pathname.includes('workshop-vehicle')
            ? `/workshop-vehicle/${vehicle.id}/treatments/${treatment.id}`
            : `/vehicle/${vehicle.id}/treatments/${treatment.id}`
        }
      >
        <div style={this.getContainerStyle()}>
          <VehicleDetailsLabelAndValue
            label={`${moment(treatment.date).format('D.M.Y')} | ${t(
              getTreatmentTypeTranslationKey(treatment.treatment_type),
            )}`}
            value={treatment.name}
          />
        </div>
      </Link>
    );
  }
}

const styles = {
  listItemContainer: {
    padding: 10,
    borderBottom: '1px solid rgba(255,255,255,.3)',
  },
  selected: {
    borderLeft: '4px solid #27bbff',
  },
};

export default translate('VehicleDetailsView')(TreatmentListItem);
