import { API, GET } from '../middleware/api';
import { API_URL } from '../config';

export function searchMaintenances(params) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/search_maintenance/`,
      types: [
        'SEARCH_VEHICLE_MAINTENANCES_REQUEST',
        'SEARCH_VEHICLE_MAINTENANCES_SUCCESS',
        'SEARCH_VEHICLE_MAINTENANCES_FAIL',
      ],
      params,
      additionalData: {
        search: params.search,
      },
    },
  };
}

export function searchTreatments(params) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/search_treatment/`,
      types: [
        'SEARCH_VEHICLE_TREATMENTS_REQUEST',
        'SEARCH_VEHICLE_TREATMENTS_SUCCESS',
        'SEARCH_VEHICLE_TREATMENTS_FAIL',
      ],
      params,
      additionalData: {
        search: params.search,
        treatment_type: params.treatment_type,
      },
    },
  };
}

export function searchMessages(params) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/search_message/`,
      types: [
        'SEARCH_VEHICLE_MESSAGES_REQUEST',
        'SEARCH_VEHICLE_MESSAGES_SUCCESS',
        'SEARCH_VEHICLE_MESSAGES_FAIL',
      ],
      params,
      additionalData: {
        search: params.search,
      },
    },
  };
}

export function searchTasks(params) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/search_task/`,
      types: [
        'SEARCH_VEHICLE_TASKS_REQUEST',
        'SEARCH_VEHICLE_TASKS_SUCCESS',
        'SEARCH_VEHICLE_TASKS_FAIL',
      ],
      params,
      additionalData: {
        search: params.search,
      },
    },
  };
}
