import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import VehicleGridItem from './VehicleGridItem';
import VehicleCategoryHeader from './VehicleCategoryHeader';
import SubHeaderBar from '../../shared/SubHeaderBar';
import CBButtonAddCar from '../../shared/CBButtonAddCar';
import CBShadedBar from '../../shared/CBShadedBar';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle } from '../../../utils/utils';
import AddCarModal from './AddCarModal';
import CBSpinnerFloating from '../../shared/CBSpinnerFloating';
import CBErrorModal from '../../shared/CBErrorModal';
import { clearVehiclePermissionErrors as _clearVehiclePermissionErrors } from '../../../actions/vehiclePermissionActions';

class VehicleGridView extends React.Component {
  state = {
    modalIsOpen: false,
    addOrganizationCarMode: false,
  };

  getVehiclesByCategory = (vehiclePermissions, vehicleCategories) => {
    const userCategories = [];
    vehicleCategories.forEach(category => {
      userCategories.push({ name: category.name, id: category.id, vehicles: [] });
    });
    const categories = [
      {
        id: 'owned',
        name: 'Omistetut ajoneuvot',
        vehicles: [],
      },
      ...userCategories,
      {
        id: 'other',
        name: 'Muut ajoneuvot',
        vehicles: [],
      },
    ];

    vehiclePermissions.forEach(permission => {
      if (permission.category) {
        let categoryPushed = false;
        categories.forEach(category => {
          if (category.id == permission.category.id) {
            category.vehicles.push(permission.vehicle);
            categoryPushed = true;
          }
        });
        if (!categoryPushed) {
          categories.forEach(category => {
            if (category.id === 'other') {
              category.vehicles.push(permission.vehicle);
            }
          });
        }
      } else if (permission.permission_type === 'owner' && permission.category === null) {
        categories.forEach(category => {
          if (category.id === 'owned') {
            category.vehicles.push(permission.vehicle);
          }
        });
      } else {
        categories.forEach(category => {
          if (category.id === 'other') {
            category.vehicles.push(permission.vehicle);
          }
        });
      }
    });
    return categories;
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  afterOpenModal = () => {
  };

  addCar = () => {
    this.setState({ modalIsOpen: true, addOrganizationCarMode: false });
  };

  addOrganizationCar = () => {
    this.setState({ modalIsOpen: true, addOrganizationCarMode: true });
  };

  render() {
    const {
      vehiclePermission,
      vehicleCategories,
      clearVehiclePermissionErrors,
      t,
      user,
    } = this.props;
    const { vehiclePermissions } = vehiclePermission;
    const { modalIsOpen, addOrganizationCarMode } = this.state;
    const categories = this.getVehiclesByCategory(vehiclePermissions, vehicleCategories);
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            {vehiclePermission.loading && (
              <CBSpinnerFloating color='white' />
            )}
            {vehiclePermission.error && (
              <CBErrorModal
                modalIsOpen
                closeModal={clearVehiclePermissionErrors}
              >
                <p style={getStyle(screenSize, styles, 'errorRegNumLabel')}>
                  {t('registrationNumber')}
                </p>
                <p style={getStyle(screenSize, styles, 'errorRegNumValue')}>
                  {vehiclePermission.error.registration_number}
                </p>
                <p style={getStyle(screenSize, styles, 'errorTitle')}>
                  {t('addingVehicleFailed')}
                </p>
                <p style={getStyle(screenSize, styles, 'errorDescription')}>
                  {t(`traficomErrors:${vehiclePermission.error.error_code}`)}
                </p>
              </CBErrorModal>
            )}
            <SubHeaderBar
              backTarget='/'
              label='Autotalli'
              height={110}
              rightBlock={(
                <React.Fragment>
                  <CBButtonAddCar text={t('addOrganizationCar')} onClick={this.addOrganizationCar} />
                  <CBButtonAddCar onClick={this.addCar} />
                  {/* <Link to='/'>
                    <CBButtonHistory />
                  </Link> */}
                </React.Fragment>
              )}
            />
            <div style={getStyle(screenSize, styles, 'rowContainer')}>
              <div style={getStyle(screenSize, styles, 'gridContainer')}>
                {categories.map(category => {
                  return (
                    <div
                      key={`category_${category.id}`}
                      style={getStyle(screenSize, styles, 'categoryContainer')}
                    >
                      <VehicleCategoryHeader category={category} user={user} />
                      <div style={getStyle(screenSize, styles, 'vehicleCategoryGrid')}>
                        {category.vehicles.map(vehicle => (
                          <div
                            style={getStyle(screenSize, styles, 'gridItemContainer')}
                            key={`vehicle-${vehicle.id}`}
                          >
                            <VehicleGridItem vehicle={vehicle} />
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
              <CBShadedBar label='Lisää kategoria' />
            </div>
            {modalIsOpen && (
              <AddCarModal
                modalIsOpen={modalIsOpen}
                closeModal={this.closeModal}
                afterOpenModal={this.afterOpenModal}
                user={user}
                isOrganization={addOrganizationCarMode}
              />
            )}
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    vehicleCategoryGrid: {
      display: 'flex',
      flexWrap: 'wrap',
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      borderBottomColor: 'rgba(255,255,255,.3)',
      paddingTop: 15,
      paddingBottom: 30,
      justifyContent: 'space-between',
    },
    gridItemContainer: {
      padding: 0,
      fontSize: 22,
      width: 'calc(50% - 15px)',
      minWidth: 300,
    },
    categoryContainer: {
      paddingTop: 30,
      paddingRight: 16,
    },
    gridContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '70%',
      paddingLeft: 0,
    },
    rowContainer: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      marginRight: 16,
    },
    errorRegNumLabel: {
      color: 'black',
      fontWeight: 'bold',
    },
    errorRegNumValue: {
      fontSize: 50,
      fontFamily: 'TitilliumWeb-Light',
      color: '#0095da',
    },
    errorTitle: {
      fontSize: 18,
      fontFamily: 'TitilliumWeb-SemiBold',
      color: '#0095da',
    },
    errorDescription: {
      fontSize: 16,
      fontFamily: 'TitilliumWeb-Light',
      color: 'black',
    },
  },
  medium: {
    vehicleCategoryGrid: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      borderBottomColor: 'rgba(255,255,255,.3)',
      paddingTop: 15,
      paddingBottom: 30,
      justifyContent: 'space-between',
      width: 'auto',
    },
    categoryContainer: {
      paddingTop: 30,
    },
    gridContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      paddingLeft: 16,
      paddingRight: 16,
    },
    rowContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginRight: 0,
    },
    gridItemContainer: {
      padding: 0,
    },
  },
  small: {
    categoryContainer: {
      paddingRight: 0,
    },
    rowContainer: {
      flexDirection: 'column',
      marginRight: 0,
    },
    vehicleCategoryGrid: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    container: {
      alignItems: 'center',
      paddingLeft: 0,
      paddingRight: 0,
    },
    gridItemContainer: {
      paddingLeft: 20,
      paddingRight: 20,
      width: 'initial',
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    vehiclePermission: state.vehiclePermission,
  };
}

export default connect(
  mapStateToProps,
  {
    clearVehiclePermissionErrors: _clearVehiclePermissionErrors,
  },
)(translate('GarageView')(VehicleGridView));
