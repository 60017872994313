import React, { Component } from 'react';
import CBMediaQuery from './CBMediaQuery';
import { getStyle } from '../../utils/utils';
import AddCategoryModal from '../GarageView/GarageViewComponents/AddCategoryModal';

export default class CBShadedBar extends Component {
  state = {
    modalIsOpen: false,
  };

  addCategory = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  afterOpenModal = () => {};

  render() {
    const { modalIsOpen } = this.state;
    const { label } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <React.Fragment>
            <div style={getStyle(screenSize, styles, 'shade')} onClick={this.addCategory}>
              <div style={getStyle(screenSize, styles, 'contentContainer')}>
                <img
                  src='/img/icon_heading_marker.svg'
                  alt='Add category'
                  style={getStyle(screenSize, styles, 'headingIcon')}
                />
                <img
                  src='/img/icon_plus.svg'
                  alt='Add category'
                  style={getStyle(screenSize, styles, 'plusIcon')}
                />
                <div style={getStyle(screenSize, styles, 'label')}>
                  {label}
                </div>
              </div>
            </div>
            <AddCategoryModal
              modalIsOpen={modalIsOpen}
              closeModal={this.closeModal}
              afterOpenModal={this.afterOpenModal}
            />
          </React.Fragment>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    shade: {
      flex: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: 100,
      backgroundImage: 'url("/img/blue_lines-f30477-transparent.png")',
      backgroundPosition: 'center center',
      marginTop: 0,
      cursor: 'pointer',
    },
    plusIcon: {
      width: 24,
      height: 24,
      opacity: 0.5,
      marginTop: 10,
      marginBottom: 10,
    },
    headingIcon: {
      opacity: 0.5,
      height: 32,
      width: 32,
    },
    label: {
      fontSize: 16,
      textAlign: 'center',
      color: 'white',
      opacity: 0.5,
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 20,
    },
  },
  medium: {
    shade: {
      marginRight: -16,
    }
  },
  small: {
    contentContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingLeft: 16,
      paddingTop: 18,
      paddingBottom: 18,
    },
    plusIcon: {
      width: 24,
      height: 24,
      opacity: 0.5,
      marginLeft: 10,
      marginRight: 6,
    },
    shade: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      marginRight: 0,
      alignSelf: 'center',
    },
  },
};
