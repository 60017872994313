import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import moment from 'moment';

import SubHeaderBar from '../shared/SubHeaderBar';
import CBMediaQuery from '../shared/CBMediaQuery';
import UserSettingsMenu from '../SettingsView/SettingsViewComponents/UserSettingsMenu';
import CBHeaderTextWithHelp from '../shared/CBHeaderTextWithHelp';
import { getStyle } from '../../utils/utils';
import BackButton from '../shared/BackButton';
import CBInputField from '../shared/form_components/CBInputField';
import CBDatePicker from '../shared/CBDatePicker';
import { getLengthValidator, getNumberValidator } from '../../utils/validators';
import CBSubmitButton from '../shared/form_components/CBSubmitButton';
import CBDropzone from '../shared/CBDropzone';
import AddImageModal from '../shared/AddImageModal';
import CBLabelAndCheckbox from '../shared/CBLabelAndCheckbox';
import {
  fetchAdvertisement as _fetchAdvertisement,
  updateAdvertisement as _updateAdvertisement,
  deleteAdvertisement as _deleteAdvertisement,
  updateAdvertisementPicture as _updateAdvertisementPicture,
  createAdvertisement as _createAdvertisement,
} from '../../actions/advertisementActions';
import CBButtonWithIcon from '../shared/CBButtonWithIcon';
import CBPromptModal from '../shared/CBPromptModal';


const advertAppLocationOptions = ['garage', 'front_page', 'vehicle_page'];

class AdvertisementEditorView extends React.Component {
  state = {
    formData: {

    },
    formValid: {},
  }

  componentDidMount() {
    const { match, fetchAdvertisement, selectedAdvertisement } = this.props;
    const advertId = match.params.advertId;

    if (advertId === 'new') {
      this.setFormData({
        destination_url: '',
        customer_name: '',
        customer_contact_name: '',
        price: 0.00,
        starting_date: null,
        expiry_date: null,
        locations: [],
      });
    } else {
      fetchAdvertisement(advertId);
      if (selectedAdvertisement) {
        this.setState({ formData: { ...selectedAdvertisement } });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectedAdvertisement } = this.props;

    const adChanged = selectedAdvertisement && (selectedAdvertisement.id !== nextProps.selectedAdvertisement.id);

    if ((!selectedAdvertisement && nextProps.selectedAdvertisement) || adChanged) {
      this.setState({ formData: { ...nextProps.selectedAdvertisement } });
    }
  }

  setFormData(newFormData) {
    this.setState({ formData: { ...newFormData } });
  }

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  handleChangeDate = (dateValue, formField) => {
    const { formData } = this.state;
    const newFormData = { ...formData };
    newFormData[formField] = dateValue;
    this.setFormData(newFormData);
  };

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
    };
    newFormValid[name] = isValid;

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  checkIfChecked = (value, formField) => {
    const { formData } = this.state;
    // Return true if passed value is found in formData's [formField] field.
    return formData && formData[formField] && formData[formField].some((fieldVal) => fieldVal === value);
  };

  handleCheckboxChange = (value, newState, formField) => {
    // Toggles checkboxes
    // When toggling is set to true push the true field's value to formData[formField].
    // When toggling is set to false filter out the false field's value from formData[formField].
    const { formData } = this.state;
    const newFormData = { ...formData };
    if (!newState) {
      newFormData[formField] = newFormData[formField].filter((v) => v !== value);
    } else {
      newFormData[formField].push(value);
    }
    this.setFormData(newFormData);
  };

  getLocationTranslation = (location) => {
    const { t } = this.props;

    switch (location) {
      case 'front_page':
        return t('frontPageView');
      case 'garage':
        return t('garageView');
      case 'vehicle_page':
        return t('singleCarView');
      default:
        return '';
    }
  };
  /*
  handlePostalCodeAreaToggle = (area) => {
    const toggledState = !area.enabled;
    const newFormData = { ...formData };

    newFormData.viewers_postal_code_areas = newFormData.viewers_postal_code_areas.map((vpca) =>
      vpca.id === area.id ? { ...vpca, enabled: toggledState } : { ...vpca }
    );

    setFormData(newFormData);
  };

  toggleAllPostalCodeAreas = () => {
    const allToggled = viewers_postal_code_areas.every((vpca) => vpca.enabled);
    const newFormData = { ...formData };
    newFormData.viewers_postal_code_areas = newFormData.viewers_postal_code_areas.map(
      (vpca) => {
        return { ...vpca, enabled: allToggled ? false : true };
      }
    );

    setFormData(newFormData);
  };
  */

  onDropImage = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageChanged(reader.result);
      };
    });
  };

  imageChanged = (imageData) => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData.image_data = imageData;
    this.setState({ formData: newFormData });
  };

  handleSubmit = () => {
    const { formData } = this.state;
    const { createAdvertisement, updateAdvertisement } = this.props;
    const dateObject = {
      starting_date: formData.starting_date ? moment(formData.starting_date).format('YYYY-MM-DD') : null,
      expiry_date: formData.expiry_date ? moment(formData.expiry_date).format('YYYY-MM-DD') : null,
    };
    const newFormData = Object.assign({}, formData, dateObject);
    if (formData.id) {
      updateAdvertisement(newFormData);
    } else {
      createAdvertisement(newFormData);
    }
  };

  openDeleteModal = () => {
    this.setDeleteModalIsOpen(true);
  };

  setDeleteModalIsOpen = (isOpen) => {
    this.setState({
      deleteModalIsOpen: isOpen,
    });
  };

  handleDeletion = () => {
    const { formData } = this.state;
    const { history, deleteAdvertisement } = this.props;
    if (formData.id) {
      deleteAdvertisement(formData.id);
      history.push('/staff_settings/advertisements');
    }
  };

  render() {
    const {
      t, advertisementsState,
    } = this.props;

    const {
      formData, deleteModalIsOpen,
    } = this.state;

    const {
      id,
      locations,
      destination_url,
      image_url,
      customer_name,
      customer_contact_name,
      price,
      expiry_date,
      starting_date,
      image_data,
    } = formData || {};

    const isNew = !formData.id;

    return (
      <CBMediaQuery>
        {(screenSize) => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'innerContainer')}>
              <div style={getStyle(screenSize, styles, 'menuAndContentContainer')}>
                <div style={getStyle(screenSize, styles, 'contentContainersWrapper')}>
                  <div style={getStyle(screenSize, styles, 'contentHeaderContainer')}>
                    <CBHeaderTextWithHelp text={isNew ? t('createAdvertisement') : t('updateAdvertisement')} />
                  </div>

                  <div style={getStyle(screenSize, styles, 'contentContainer')}>
                    <div style={styles.formContainer}>
                      {/* Advert URL */}
                      <CBInputField
                        labelText={t('destinationUrlLabel')}
                        name="destination_url"
                        onChange={(e) => this.updateFormState(e)}
                        value={destination_url || ''}
                        // serverErrors={workshopRequest.workshopStatus.error}
                        isValidCallback={this.setIsValid}
                        validators={[getLengthValidator(2, 50)]}
                        labelTextStyle={styles.default.fieldContainerLeftAlign}
                      />
                      {/* End Advert URL */}

                      {/* Advert Customer name */}
                      <CBInputField
                        labelText={t('customerNameLabel')}
                        name="customer_name"
                        onChange={(e) => this.updateFormState(e)}
                        value={customer_name || ''}
                        // serverErrors={workshopRequest.workshopStatus.error}
                        isValidCallback={this.setIsValid}
                        validators={[getLengthValidator(2, 50)]}
                        labelTextStyle={styles.default.fieldContainerLeftAlign}
                      />
                      {/* End Advert Customer name */}

                      {/* Advert Customer contact name */}
                      <CBInputField
                        labelText={t('customerContactNameLabel')}
                        name="customer_contact_name"
                        onChange={(e) => this.updateFormState(e)}
                        value={customer_contact_name || ''}
                        // serverErrors={workshopRequest.workshopStatus.error}
                        isValidCallback={this.setIsValid}
                        validators={[getLengthValidator(2, 50)]}
                        labelTextStyle={styles.default.fieldContainerLeftAlign}
                      />
                      {/* End Advert Customer contact name */}
                      {/* Advert price */}
                      <CBInputField
                        labelText={t('advertPriceLabel')}
                        name="price"
                        onChange={(e) => this.updateFormState(e)}
                        value={price || ''}
                        // serverErrors={workshopRequest.workshopStatus.error}
                        isValidCallback={this.setIsValid}
                        validators={[
                          getNumberValidator(),
                          getLengthValidator(1, 8),
                        ]}
                        labelTextStyle={styles.default.fieldContainerLeftAlign}
                      />
                      {/* End Advert price */}

                      {/* Advert Starting/Ending dates */}
                      <p
                        className="font-titillium-web-semi-bold"
                        style={styles.default.fieldContainerLeftAlign}
                      >
                        {t('validityPeriodLabel')}
                      </p>

                      <div style={styles.default.datepickerContainer}>
                        <CBDatePicker
                          date={starting_date}
                          changeDate={(e) =>
                            this.handleChangeDate(e, 'starting_date')
                          }
                        />
                        <img
                          src="/img/icon_arrow_calendar.svg"
                          alt="close"
                          style={styles.arrowIcon}
                        />
                        <CBDatePicker
                          date={expiry_date}
                          changeDate={(e) =>
                            this.handleChangeDate(e, 'expiry_date')
                          }
                        />
                      </div>
                      {/* End Advert Starting/Ending dates */}

                      {/* Advert App locations */}
                      <p
                        className="font-titillium-web-semi-bold"
                        style={styles.default.pLabel}
                      >
                        {t('advertAppLocationsLabel')}
                      </p>

                      <div
                        style={getStyle(
                          screenSize,
                          styles,
                          'checkboxesContainer'
                        )}
                      >
                        {advertAppLocationOptions &&
                          advertAppLocationOptions.map((location, i) => (
                            <div
                              style={getStyle(
                                screenSize,
                                styles,
                                'checkboxContainer',
                              )}
                              key={`checkbox-${location + i}`}
                            >
                              <CBLabelAndCheckbox
                                labelText={this.getLocationTranslation(location)}
                                checked={locations && locations.includes(location)}
                                onChange={() =>
                                  this.handleCheckboxChange(location, !locations.includes(location), 'locations')
                                }
                              />
                            </div>
                          ))}
                      </div>

                      {/* End Advert App locations */}

                      {
                      /* Advert viewers postal code areas
                      <p
                        className="font-titillium-web-semi-bold"
                        style={styles.default.pLabel}
                      >
                        {t('advertViewerPostalCodeAreasLavel')}
                      </p>

                      <div
                        style={getStyle(
                          screenSize,
                          styles,
                          'checkboxesContainer'
                        )}
                      >
                        {viewers_postal_code_areas && (
                          <>
                            <div
                              style={getStyle(
                                screenSize,
                                styles,
                                'checkboxContainer'
                              )}
                              key="checkbox-location-all"
                            >
                              <CBLabelAndCheckbox
                                labelText={'Kaikki'}
                                checked={viewers_postal_code_areas.every(
                                  (vpca) => vpca.enabled
                                )}
                                onChange={() =>
                                  this.toggleAllPostalCodeAreas()
                                }
                              />
                            </div>

                            {viewers_postal_code_areas.map((area, i) => (
                              <div
                                style={getStyle(
                                  screenSize,
                                  styles,
                                  'checkboxContainer'
                                )}
                                key={`checkbox-location-${area.id}`}
                              >
                                <CBLabelAndCheckbox
                                  labelText={area.name}
                                  checked={area.enabled}
                                  onChange={() =>
                                    this.handlePostalCodeAreaToggle(area)
                                  }
                                />
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                      End Advert viewers postal code areas */
                      }

                      <div
                        style={getStyle(
                          screenSize,
                          styles,
                          'formActionsContainer'
                        )}
                      >
                        <CBSubmitButton
                          disabled={
                            advertisementsState.advertisementsStatus
                              .loading || !this.getIsValid()
                          }
                          onClick={this.handleSubmit}
                        />
                        {
                          !isNew && (
                            <CBButtonWithIcon
                              icon="/img/remove.svg"
                              text={t('deleteAdvertisementButtonText')}
                              onClick={this.openDeleteModal}
                            />
                          )
                        }
                      </div>
                    </div>
                  </div>

                  <div style={getStyle(screenSize, styles, 'contentImageContainer')}>

                    {
                      (image_url || image_data) && (
                        <CBDropzone
                          style={getStyle(screenSize, styles, 'dropZone')}
                          onDropAccepted={this.onDropImage}
                        >
                          <img
                            style={getStyle(
                              screenSize,
                              styles,
                              'profilePicture'
                            )}
                            alt="Ad pic"
                            src={(image_data || image_url) }
                          />
                        </CBDropzone>
                      )
                    }

                    {!image_url && !image_data && (
                      <CBDropzone
                        style={getStyle(screenSize, styles, 'dropZone')}
                        onDropAccepted={this.onDropImage}
                      >
                        <div
                          style={getStyle(screenSize, styles,'profileImageUnset')}
                        >
                          <img
                            src="/img/icon_plus.svg"
                            alt="Add"
                            style={getStyle(
                              screenSize,
                              styles,
                              'plusIcon',
                            )}
                          />
                        </div>
                      </CBDropzone>
                    )}
                  </div>

                  <CBPromptModal
                    modalIsOpen={deleteModalIsOpen}
                    closeModal={() => this.setDeleteModalIsOpen(false)}
                    performAction={() => this.handleDeletion()}
                    title={t('deleteAdvertisementPromptTitle')}
                    text={t('deleteAdvertisement')}
                    buttonText={t('remove')}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }

};

const styles = {
  default: {
    contentContainersWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '1rem 1rem 6rem',
      fontSize: '16px',
    },
    formActionsContainer: {
      margin: '3rem 0',
      display: 'flex',
      width: '100%',
    },
    contentHeaderContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    checkboxesContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      rowGap: '1rem',
    },
    checkboxContainer: {
      display: 'InlineBlock',
      width: '25%',
    },
    profilePicture: {
      borderRadius: 4,
      marginTop: 8,
      marginBottom: 8,
    },
    dropZone: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      color: 'white',
      fontFamily: 'TitilliumWeb-Regular',
      overflow: 'hidden',
      width: '100%',
    },
    removePictureButton: {
      width: '100%',
      backgroundColor: 'transparent',
      border: 0,
      color: 'white',
      fontFamily: 'TitilliumWeb-Regular',
      marginTop: 8,
    },
    profileImageUnset: {
      border: '1px solid #FFFFFF',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 200,
      width: 200,
      marginBottom: 8,
      marginTop: 8,
    },
    plusIcon: {
      width: 45,
      height: 45,
    },
    menuAndContentContainer: {
      backgroundColor: '#0095da',
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid rgba(255, 255, 255, 0.4)',
    },
    contentContainer: {
      order: 2,
      width: '100%',
    },
    contentImageContainer: {
      order: 1,
      padding: '0 1rem',
    },
    formContainer: {
      position: 'relative',
    },
    datepickerContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 20,
      marginTop: 24,
    },
    arrowIcon: {
      margin: '0px 8px',
      width: 32,
      height: 32,
      fill: '#ffffff',
    },
    fieldContainerLeftAlign: {
      width: '100%',
    },
    pLabel: {
      margin: '10px 0',
    },
    option: {
      backgroundColor: 'rgba(0, 149, 218, 1)',
    },
  },
  medium: {
    menuAndContentContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    checkboxContainer: {
      width: '50%',
    },
    contentContainer: {
      order: 1,
      width: '50%',
    },
    contentContainersWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 0,
      fontSize: '16px',
    },
    contentImageContainer: {
      order: 2,
      padding: '1rem',
      width: '50%',
    },
  },
  small: {
    contentContainer: {
      order: 2,
      width: '100%',
      padding: 0,
    },
    contentImageContainer: {
      padding: '0 1rem',
      width: 'min(350px, 100%)',
      order: 1,
    },
    contentContainersWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      padding: 0,
    },
  },
};

function mapStateToProps(state) {
  return {
    advertisements: state.advertisement.advertisements,
    selectedAdvertisement: state.advertisement.selectedAdvertisement,
    advertisementsState: state.advertisement,
    user: state.authUser.user,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchAdvertisement: _fetchAdvertisement,
    updateAdvertisement: _updateAdvertisement,
    deleteAdvertisement: _deleteAdvertisement,
    createAdvertisement: _createAdvertisement,
    updateAdvertisementPicture: _updateAdvertisementPicture,
  },
)(translate('AdvertisementsView')(withRouter(AdvertisementEditorView)));
