import React from 'react';

class CBButtonSubmit extends React.Component {
  render() {
    const { onClick, text, disabled, style, textStyle } = this.props;
    const buttonStyle = style || styles.largeButton;
    return (
      <div
        className={!disabled ? 'hover-green-border hover-green-text' : undefined}
        style={
          disabled ? { ...buttonStyle, ...styles.disabled } : buttonStyle
        }
        onClick={disabled ? undefined : onClick}
      >
        <p style={textStyle || styles.buttonText}>
          {text}
        </p>
      </div>
    );
  }
}

export default CBButtonSubmit;

const styles = {
  buttonText: {
    fontSize: 14,
    fontWeight: 700,
    textDecoration: 'none solid rgb(0, 246, 0)',
    color: '#0095da',
    padding: 16,
    marginBottom: 0,
  },
  largeButton: {
    height: 50,
    width: '50%',
    border: '2px solid #0095da',
    color: '#0095da',
    borderRadius: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  disabled: {
    opacity: 0.5,
    cursor: 'normal',
  },
};
