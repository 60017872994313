import { invisibilityPeriodState } from '../initialState';
import {
  storeInsertItem,
  storeRemoveItem,
  storeUpdateObjectInArray,
} from '../utils/utils';

export default function (state = invisibilityPeriodState, action) {
  switch (action.type) {
    // FETCH
    case 'FETCH_INVISIBILITYPERIODS_REQUEST': {
      return {
        ...state,
        invisibilityPeriodsStatus: Object.assign({}, state.invisibilityPeriodsStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_INVISIBILITYPERIODS_SUCCESS': {
      return {
        ...state,
        invisibilityPeriods: action.payload,
        invisibilityPeriodsStatus: Object.assign({}, state.invisibilityPeriodsStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_INVISIBILITYPERIODS_FAIL': {
      return {
        ...state,
        invisibilityPeriods: [],
        invisibilityPeriodsStatus: Object.assign({}, state.invisibilityPeriodsStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    // CREATE
    case 'CREATE_INVISIBILITYPERIOD_REQUEST': {
      return {
        ...state,
        invisibilityPeriodsStatus: Object.assign({}, state.invisibilityPeriodsStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_INVISIBILITYPERIOD_SUCCESS': {
      return {
        ...state,
        invisibilityPeriods: storeInsertItem(
          state.invisibilityPeriods,
          action.payload,
        ),
        invisibilityPeriodsStatus: Object.assign({}, state.invisibilityPeriodsStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_INVISIBILITYPERIOD_FAIL': {
      return {
        ...state,
        invisibilityPeriodsStatus: Object.assign({}, state.invisibilityPeriodsStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    // UPDATE
    case 'UPDATE_INVISIBILITYPERIOD_REQUEST': {
      return {
        ...state,
        invisibilityPeriodsStatus: Object.assign({}, state.invisibilityPeriodsStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_INVISIBILITYPERIOD_SUCCESS': {
      return {
        ...state,
        invisibilityPeriods: storeUpdateObjectInArray(
          state.invisibilityPeriods,
          action.payload,
        ),
        invisibilityPeriodsStatus: Object.assign({}, state.invisibilityPeriodsStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_INVISIBILITYPERIOD_FAIL': {
      return {
        ...state,
        invisibilityPeriodsStatus: Object.assign({}, state.invisibilityPeriodsStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    // DELETE
    case 'DELETE_INVISIBILITYPERIOD_REQUEST': {
      return {
        ...state,
        invisibilityPeriodsStatus: Object.assign({}, state.invisibilityPeriodsStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DELETE_INVISIBILITYPERIOD_SUCCESS': {
      return {
        ...state,
        invisibilityPeriods: storeRemoveItem(state.invisibilityPeriods, action.payload.id),
        invisibilityPeriodsStatus: Object.assign({}, state.invisibilityPeriodsStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DELETE_INVISIBILITYPERIOD_FAIL': {
      return {
        ...state,
        invisibilityPeriodsStatus: Object.assign({}, state.invisibilityPeriodsStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    default:
      break;
  }
  return state;
}
