import {
  API, GET, POST, PATCH, DELETE,
} from '../middleware/api';
import { API_URL } from '../config';

export function fetchVehiclePermissions() {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/vehicle_permission/`,
      types: [
        'FETCH_VEHICLE_PERMISSIONS_REQUEST',
        'FETCH_VEHICLE_PERMISSIONS_SUCCESS',
        'FETCH_VEHICLE_PERMISSIONS_FAIL',
      ],
    },
  };
}

export function changeVehicleCategory(permissionId, categoryId) {
  return {
    [API]: {
      method: PATCH,
      url: `${API_URL}/uiapi/1/vehicle_permission/${permissionId}/`,
      types: [
        'UPDATE_VEHICLE_PERMISSION_REQUEST',
        'UPDATE_VEHICLE_PERMISSION_SUCCESS',
        'UPDATE_VEHICLE_PERMISSION_FAIL',
      ],
      data: {
        category: categoryId,
      },
    },
  };
}

export function createVehiclePermission(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/vehicle_permission/`,
      types: [
        'CREATE_VEHICLE_PERMISSION_REQUEST',
        'CREATE_VEHICLE_PERMISSION_SUCCESS',
        'CREATE_VEHICLE_PERMISSION_FAIL',
      ],
      data,
    },
  };
}

export function requestVehiclePermission(registrationNumber, hetu, garageId) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/request_vehicle_permission/`,
      types: [
        'REQUEST_VEHICLE_PERMISSION_REQUEST',
        'REQUEST_VEHICLE_PERMISSION_SUCCESS',
        'REQUEST_VEHICLE_PERMISSION_FAIL',
      ],
      data: {
        registrationNumber,
        hetu,
        garageId,
      },
      additionalData: {
        isGarageRequest: !!garageId,
      },
    },
  };
}

export function updateVehiclePermission(permissionId, data) {
  return {
    [API]: {
      method: PATCH,
      url: `${API_URL}/uiapi/1/vehicle_permission/${permissionId}/`,
      types: [
        'UPDATE_VEHICLE_PERMISSION_REQUEST',
        'UPDATE_VEHICLE_PERMISSION_SUCCESS',
        'UPDATE_VEHICLE_PERMISSION_FAIL',
      ],
      data,
    },
  };
}

export function deleteVehiclePermission(permissionId) {
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/vehicle_permission/${permissionId}/`,
      types: [
        'DELETE_VEHICLE_PERMISSION_REQUEST',
        'DELETE_VEHICLE_PERMISSION_SUCCESS',
        'DELETE_VEHICLE_PERMISSION_FAIL',
      ],
    },
  };
}

export function clearVehiclePermissionErrors() {
  return {
    type: 'CLEAR_VEHICLE_PERMISSION_ERRORS',
  };
}
