import { invoiceReportState } from '../initialState';
import { storeInsertItem, storeRemoveItem, storeUpdateObjectInArray } from '../utils/utils';

export default function (state = invoiceReportState, action) {
  switch (action.type) {
    case 'FETCH_INVOICE_REPORTS_REQUEST': {
      return {
        ...state,
        invoiceReports: [],
        invoiceReportStatus: Object.assign({}, state.invoiceReportStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_INVOICE_REPORTS_SUCCESS': {
      return {
        ...state,
        invoiceReports: action.payload,
        invoiceReportStatus: Object.assign({}, state.invoiceReportStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_INVOICE_REPORTS_FAIL': {
      return {
        ...state,
        invoiceReports: [],
        invoiceReportStatus: Object.assign({}, state.invoiceReportStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'FETCH_INVOICE_REPORT_REQUEST': {
      return {
        ...state,
        invoiceReport: null,
        invoiceReportStatus: Object.assign({}, state.invoiceReportStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_INVOICE_REPORT_SUCCESS': {
      return {
        ...state,
        invoiceReport: action.payload,
        invoiceReportStatus: Object.assign({}, state.invoiceReportStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_INVOICE_REPORT_FAIL': {
      return {
        ...state,
        invoiceReport: null,
        invoiceReportStatus: Object.assign({}, state.invoiceReportStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    default:
      break;
  }
  return state;
}
