import React, { Component } from 'react';

export default class CBAdvertisement extends Component {
  getUrl = (url) => {
    if (!url.includes('https://')) {
      return `https://${url}`;
    }
  };

  render() {
    const { obj, location } = this.props;
    return (
      <div style={styles.advertisementContainer}>
        <a href={obj.internal_destination_url} target='_blank'>
          <img src={obj.image_url} alt='Advertisement' style={styles.advertisementImage} />
        </a>
      </div>
    );
  }
}

const styles = {
  advertisementContainer: {
    height: 280,
    border: '1px solid #464646',
    marginBottom: 16,
  },
  advertisementImage: {
    width: 320,
    maxWidth: '100%',
    height: 280,
  },
};
