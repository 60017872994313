import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaintenanceListItem from './ListItems/MaintenanceListItem';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle, getUrlId, formatKm } from '../../../utils/utils';
import CBSelect from '../../shared/CBSelect';
import AddMaintenanceButton from './ListItems/AddMaintenanceButton';
import { searchMaintenances as _searchMaintenances } from '../../../actions/searchActions';

class VehicleDetailsMaintenanceList extends React.Component {
  componentWillMount() {
    const { searchMaintenances, searchRequest, vehicle } = this.props;
    searchMaintenances({ vehicle_id: vehicle.id, search: searchRequest.maintenanceFilters.search });
  }

  // Do not remove. For unknown reasons the view won't re-render without this.
  // static getDerivedStateFromProps(nextProps, prevState) {}

  getFilteredMaintenances = maintenances => {
    const { searchRequest } = this.props;
    const { maintenanceIDs } = searchRequest;
    if (!maintenanceIDs) {
      return maintenances;
    }

    return maintenances.filter(maintenance => maintenanceIDs.includes(parseInt(maintenance.id)));
  };

  isSelected = (maintenance, idx) => {
    const { match } = this.props;
    const urlParts = window.location.pathname.split('/');
    const index = urlParts.indexOf('maintenances') + 1;

    if (index && urlParts[index]) {
      return maintenance.id == urlParts[index];
    }

    return idx == 0;
  };

  maintenanceSelected = e => {
    const { history, vehicle } = this.props;
    history.push(`/vehicle/${vehicle.id}/maintenances/${e.target.value}`);
  };

  render() {
    const { vehicle, location, user } = this.props;
    const { maintenances } = vehicle;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div
            style={getStyle(
              screenSize,
              styles,
              screenSize !== 'small' ? 'listContainer' : 'container',
            )}
          >
            {vehicle.can_edit && false && <AddMaintenanceButton />}
            {screenSize !== 'small' && (
              <div>
                {this.getFilteredMaintenances(maintenances).map((maintenance, idx) => (
                  <MaintenanceListItem
                    key={maintenance.id}
                    vehicle={vehicle}
                    maintenance={maintenance}
                    isSelected={this.isSelected(maintenance, idx)}
                  />
                ))}
              </div>
            )}
            {screenSize === 'small' && (
              <div style={getStyle(screenSize, styles, 'selectContainer')}>
                <CBSelect value={getUrlId(location.pathname)} onChange={this.maintenanceSelected}>
                  {this.getFilteredMaintenances(maintenances).map(maintenance => (
                    <option key={maintenance.id} value={maintenance.id}>
                      {`${moment(maintenance.pickup_date).format('D.M.Y')} | ${formatKm(maintenance.km)}`}
                    </option>
                  ))}
                </CBSelect>
              </div>
            )}
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
      width: '25%',
    },
    selectContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 40,
      marginRight: 15,
      marginLeft: 15,
      marginTop: 40,
    },
  },
  small: {
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
      width: '100%',
      maxHeight: 300,
      overflow: 'auto',
    },
  },
};

function mapStateToProps(state) {
  return {
    searchRequest: state.search,
    user: state.authUser.user,
  };
}

export default connect(
  mapStateToProps,
  {
    searchMaintenances: _searchMaintenances,
  },
)(withRouter(VehicleDetailsMaintenanceList));
