import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getStyle, getModalStyle } from '../../../../utils/utils';
import {
  createVehicleDescription as _createVehicleDescription,
  updateVehicleDescription as _updateVehicleDescription,
} from '../../../../actions/vehicleActions';
import CBMediaQuery from '../../../shared/CBMediaQuery';
import CBButtonSubmit from '../../../shared/CBButtonSubmit';
import CBTextAreaField from '../../../shared/form_components/CBTextAreaField';

Modal.setAppElement('#root');

class EditVehicleDescriptionModal extends Component {
  state = {
    descriptionText: '',
  };

  componentDidMount = () => {
    const { description } = this.props;
    if (description.description) {
      this.setState({
        descriptionText: description.description,
      });
    }
  };

  onSubmit = () => {
    const {
      descriptionId,
      vehicle,
      updateVehicleDescription,
      createVehicleDescription,
      closeModal,
    } = this.props;
    const { descriptionText } = this.state;

    if (descriptionId) {
      updateVehicleDescription(descriptionId, {
        description: descriptionText,
        vehicle: vehicle.id,
      });
    } else {
      createVehicleDescription({ vehicle: vehicle.id, description: descriptionText });
    }
    closeModal();
  };

  changeText = target => {
    this.setState({
      descriptionText: target.value,
    });
  };

  render() {
    const { descriptionText } = this.state;
    const {
      modalIsOpen, closeModal, afterOpenModal, t,
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={getModalStyle(screenSize, styles.modal)}
            contentLabel='Description modal'
          >
            <div style={getStyle(screenSize, styles, 'container')}>
              <p style={getStyle(screenSize, styles, 'title')}>
                {t('descriptionHeading')}
              </p>

              <CBTextAreaField
                inputStyle={getStyle(screenSize, styles, 'input')}
                value={descriptionText}
                placeholder={t('descriptionHeading')}
                onChange={this.changeText}
              />
              <CBButtonSubmit text={t('save')} onClick={this.onSubmit} />
            </div>
          </Modal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },
    title: {
      fontSize: 32,
      color: '#0095da',
      textAlign: 'center',
    },
    subTitle: {
      fontSize: 14,
      color: 'black',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    input: {
      backgroundColor: 'ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 400,
      height: 250,
      resize: 'none',
    },
    info: {
      width: '100%',
      fontSize: 14,
      color: 'black',
      paddingHorizontal: 30,
      textAlign: 'center',
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    input: {
      backgroundColor: 'ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: '100%',
      height: 250,
      resize: 'none',
    },
  },
  modal: {
    default: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 500,
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    medium: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '90%',
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    small: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '80%',
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
};

function mapStateToProps(state) {
  return {
    vehiclePermissionRequest: state.vehiclePermission,
    user: state.authUser.user,
    vehicle: state.vehicle.vehicle,
  };
}

export default connect(
  mapStateToProps,
  {
    createVehicleDescription: _createVehicleDescription,
    updateVehicleDescription: _updateVehicleDescription,
  },
)(translate('VehicleDetailsView')(EditVehicleDescriptionModal));
