import React from 'react';
import { Panel } from 'react-bootstrap';

class VehicleDetailsCollapse extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: props.open };
  }

  render() {
    const { open } = this.state;
    const { title, children } = this.props;
    return (
      <div style={styles.collapseContainer}>
        <button
          type='button'
          className='font-titillium-web'
          style={styles.collapseButton}
          onClick={() => {
            this.setState({ open: !open });
          }}
        >
          {title}
          <img
            src={open ? '/img/up_arrow_icon.svg' : '/img/down_arrow_icon.svg'}
            alt='Add'
            style={styles.collapseButtonIcon}
          />
        </button>
        <Panel style={styles.panel} expanded={open} onToggle={() => {}}>
          <Panel.Collapse>
            <Panel.Body style={styles.panelBody}>
              {children}
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      </div>
    );
  }
}

const styles = {
  panel: {
    border: 0,
    boxShadow: 'none',
    borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
    padding: '16px 16px 0',
    margin: 0,
    backgroundColor: 'rgb(0, 149, 218)',
  },
  panelBody: {
    border: '0px',
    padding: 0,
    lineHeight: 1,
  },
  collapseButton: {
    background: 'none',
    border: 'none',
    padding: '16px 16px 0',
    cursor: 'pointer',
    outline: 'inherit',
    textAlign: 'left',
    color: '#FFF',
    fontSize: 22,
    fontWeight: 200,
    width: '100%',
  },
  collapseButtonIcon: {
    float: 'right',
    width: 32,
    height: 32,
    opacity: 0.5,
  },
};

export default VehicleDetailsCollapse;
