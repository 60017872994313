import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSearchInput from '../../shared/CBSearchInput';
import CBSelectFilters from '../../shared/CBSelectFilters';
import AddMessageButton from '../../VehicleDetailsView/VehicleDetailsComponents/ListItems/AddMessageButton';
import CBButtonGarageAction from '../../shared/CBButtonGarageAction';


class MessageFilterBar extends Component {
  render() {
    const {
      t,
      changeSearchTerms,
      refetchMessages,
      sendMessages,
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'containerFilters')}>
              <CBSearchInput
                containerStyle={{ height: 40, marginLeft: 0 }}
                changeSearchTerms={changeSearchTerms}
                onBlur={refetchMessages}
              />
            </div>
            <div style={getStyle(screenSize, styles, 'containerActions')}>
              <CBButtonGarageAction
                  onClick={sendMessages}
                  mainIcon='/img/icon_message_solid.svg'
                  additionalIcon='/img/icon_plus.svg'
                  text={t('headingNewMessage')}
                  additionalStylesMainIcon={{ height: 25, width: 25 }}
                />
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    containerFilters: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    containerActions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  },
};

function mapStateToProps(state) {
  return {
    searchRequest: state.search,
  };
}

export default connect(
  mapStateToProps,
  {
  },
)(translate('MessageListView')(MessageFilterBar));
