import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import i18n from 'i18next';
import { styleMerge, getStyle, SERVICE_WORKSHOP, SERVICE_FLEET, TYPE_CARE_SERVICE, TYPE_WORKSHOP_ALL } from '../../utils/utils';
import CBMediaQuery from './CBMediaQuery';
import {
  searchMaintenances as _searchMaintenances,
  searchTreatments as _searchTreatments,
} from '../../actions/searchActions';
import CBSelectField from './form_components/CBSelectField';
import CBButtonAddCar from './CBButtonAddCar';
import CBSearchInput from './CBSearchInput';
import CBSelect from './CBSelect';
import CBSelectFilters from './CBSelectFilters';
import CBButtonGarageAction from './CBButtonGarageAction';
import { fetchOrganizations as _fetchOrganizations } from '../../actions/organizationActions';

class VehicleFilterBar extends Component {
  componentWillMount() {
    const {
      fetchOrganizations,
      service,
      selectedWorkshopId,
    } = this.props;

    if (service === SERVICE_WORKSHOP) {
      fetchOrganizations({ workshop_id: selectedWorkshopId });
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedWorkshopId, fetchOrganizations } = this.props;

    if (selectedWorkshopId != prevProps.selectedWorkshopId) {
      fetchOrganizations({ workshop_id: selectedWorkshopId });
    }
  };

  getOrganizationOptions = () => {
    const { organizations } = this.props;

    const options = organizations.map((org) => {
      return {
        value: org.id,
        label: org.name,
      }
    });

    options.unshift({
      label: 'Kaikki yritykset',
      value: '',
    });

    return options;
  }

  render() {
    const {
      addCar,
      inviteToCareService,
      addWSVehicle,
      t,
      changeSearchTerms,
      refetchVehicles,
      service,
      user,
      selectedOrganizationId,
      filters,
      changeFilterTerms,
      listType
    } = this.props;

    const currentOrganization = user.organizations.find((org) => selectedOrganizationId == org.id);

    const addCarDisabled = service === SERVICE_FLEET && currentOrganization && !currentOrganization.paid_subscription;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'containerFilters')}>
              <CBSearchInput
                containerStyle={{ height: 40, marginLeft: 0 }}
                changeSearchTerms={changeSearchTerms}
                onBlur={refetchVehicles}
              />
              {
                service === SERVICE_WORKSHOP && listType === TYPE_CARE_SERVICE && (
                  <CBSelectFilters
                    name='organization'
                    options={this.getOrganizationOptions()}
                    onChange={changeFilterTerms}
                    value={filters.organization}
                  />
                )
              }

            </div>
            <div style={getStyle(screenSize, styles, 'containerActions')}>
              {
                service === SERVICE_WORKSHOP && listType === TYPE_CARE_SERVICE && (
                  <CBButtonGarageAction
                    onClick={inviteToCareService}
                    text={t('inviteToCareService')}
                    mainIcon='/img/icon_care_service.svg'
                  />
                )
              }
              {
                listType === TYPE_WORKSHOP_ALL && (
                  <CBButtonGarageAction
                    onClick={addWSVehicle}
                    text={t('addWSVehicle')}
                    additionalIcon='/img/icon_plus.svg'
                    mainIcon='/img/icon_car_basic_info.svg'
                  />
                )
              }
              {
                listType !== TYPE_WORKSHOP_ALL && (
                  <div style={getStyle(screenSize, styles, 'addCarContainer')}>
                    <CBButtonAddCar
                      onClick={addCar}
                      text={t('addCarOrganization')}
                      disabled={addCarDisabled}
                    />
                    {
                      addCarDisabled && (
                        <div  style={getStyle(screenSize, styles, 'addCarInfoTextContainer')}>
                          <p>Voit lisätä ajoneuvoja vain jos sinulla on maksullinen tilaus.</p>
                          <p>Ota yhteyttä <a href="mailto:info@ovio.fi">info@ovio.fi</a> aloittaaksesi tilauksen.</p>
                        </div>
                      )
                    }
                  </div>
                )
              }
            </div>

          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    containerFilters: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    addCarContainer: {
      fontSize: 12,
    },
    addCarInfoTextContainer: {
      marginTop: 8,
    },
    containerActions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  },
};

function mapStateToProps(state) {
  return {
    searchRequest: state.search,
    service: state.app.service,
    user: state.authUser.user,
    selectedOrganizationId: state.organization.selectedOrganization,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    organizations: state.organization.organizations
  };
}

export default connect(
  mapStateToProps,
  {
    fetchOrganizations: _fetchOrganizations
  },
)(translate('VehicleListView')(VehicleFilterBar));
