import React from 'react';

class CBSelect extends React.Component {
  render() {
    const { children, onChange, value } = this.props;
    return (
      <select value={value} style={styles.select} onChange={onChange}>
        {children}
      </select>
    );
  }
}

const styles = {
  select: {
    width: '100%',
    height: 30,
    borderRadius: 50,
    border: 0,
    paddingLeft: 7,
    backgroundColor: '#0095da',
    color: '#FFFFFF',
    // fontsize: 14,
  },
};

export default CBSelect;
