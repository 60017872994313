import { API, GET, POST, PUT, DELETE } from '../middleware/api';
import { API_URL } from '../config';

export function fetchOrganizations(params) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/organization/`,
      types: [
        'FETCH_ORGANIZATIONS_REQUEST',
        'FETCH_ORGANIZATIONS_SUCCESS',
        'FETCH_ORGANIZATIONS_FAIL',
      ],
      params,
    },
  };
}

export function createOrganization(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/organization/`,
      types: [
        'CREATE_ORGANIZATION_REQUEST',
        'CREATE_ORGANIZATION_SUCCESS',
        'CREATE_ORGANIZATION_FAIL',
      ],
      data,
    },
  };
}

export function updateOrganization(organizationId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/organization/${organizationId}/`,
      types: [
        'UPDATE_ORGANIZATION_REQUEST',
        'UPDATE_ORGANIZATION_SUCCESS',
        'UPDATE_ORGANIZATION_FAIL',
      ],
      data,
    },
  };
}

export function deleteOrganization(organizationId) {
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/organization/${organizationId}/`,
      types: [
        'UPDATE_ORGANIZATION_REQUEST',
        'UPDATE_ORGANIZATION_SUCCESS',
        'UPDATE_ORGANIZATION_FAIL',
      ],
    },
  };
}

export function confirmOrganization(organizationId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/organization/${organizationId}/confirm/`,
      types: [
        'UPDATE_ORGANIZATION_REQUEST',
        'UPDATE_ORGANIZATION_SUCCESS',
        'UPDATE_ORGANIZATION_FAIL',
      ],
      data,
    },
  };
}

export function selectOrganization(organizationId) {
  return {
    type: 'SELECT_ORGANIZATION',
    payload: organizationId,
  };
}

export function createOrganizationPermission(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/organization_permission/`,
      types: [
        'CREATE_ORGANIZATION_PERMISSION_REQUEST',
        'CREATE_ORGANIZATION_PERMISSION_SUCCESS',
        'CREATE_ORGANIZATION_PERMISSION_FAIL',
      ],
      data,
    },
  };
}

export function updateOrganizationPermission(permissionId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/organization_permission/${permissionId}/`,
      types: [
        'UPDATE_ORGANIZATION_PERMISSION_REQUEST',
        'UPDATE_ORGANIZATION_PERMISSION_SUCCESS',
        'UPDATE_ORGANIZATION_PERMISSION_FAIL',
      ],
      data,
    },
  };
}

export function deleteOrganizationPermission(permissionId, baseObjId) {
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/organization_permission/${permissionId}/`,
      types: [
        'DELETE_ORGANIZATION_PERMISSION_REQUEST',
        'DELETE_ORGANIZATION_PERMISSION_SUCCESS',
        'DELETE_ORGANIZATION_PERMISSION_FAIL',
      ],
      additionalData: {
        organizationId: baseObjId,
        permissionId,
      },
    },
  };
}

export function toggleOrganizationMessageSubscription(organizationId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/organization/${organizationId}/toggle_message_subscription/`,
      types: [
        'TOGGLE_ORGANIZATION_MESSAGE_SUBSCRIPTION_REQUEST',
        'TOGGLE_ORGANIZATION_MESSAGE_SUBSCRIPTION_SUCCESS',
        'TOGGLE_ORGANIZATION_MESSAGE_SUBSCRIPTION_FAIL',
      ],
      data,
    },
  };
}