import axios from 'axios';
import moment from 'moment';

import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../shared/CBMediaQuery';
import { getStyle } from '../../utils/utils';
import CBSpinner from '../shared/CBSpinner';
import CBLabelAndValue from '../shared/CBLabelAndValue';
import CBSubHeading from '../shared/CBSubHeading';
import { Link } from 'react-router-dom';
import CBButtonGeneric from '../shared/CBButtonGeneric';

class PaymentResultView extends React.Component {
  state = {
    isLoading: true,
    order: undefined,
    error: undefined,
  }

  errorCodes = [
    'errorVehicleExists',
    'errorVehicleNotFound'
  ];

  componentWillMount() {
    const { match } = this.props;
    const orderId = match.params.orderId;
    if (!orderId || this.errorCodes.includes(orderId)) {
      this.setState({ order: null, isLoading: false });
      if (orderId) {
        this.setState({ error: orderId });
      }
    } else {
      axios({
        url: `/api/1/payments/result/${match.params.orderId}/`,
        method: 'get',
        headers: {
          Authorization:
            process.env.REACT_APP_IS_PRODUCTION == 'false' &&
            (process.env.REACT_APP_IS_WORKSHOP_USER == 'true'
              ? `Token ${process.env.REACT_APP_SERVICE_TOKEN}`
              : `Token ${process.env.REACT_APP_USER_TOKEN}`),
        },
      })
        .then((response) => response)
        .then((response) => {
          this.setState({ order: response.data, isLoading: false });
        });
    }
  }

  getGarageLink = () => {
    const { order } = this.state;
    return '/';
  }

  render() {
    const { t, user, orderId } = this.props;
    const { isLoading, order, error } = this.state;

    if (isLoading) {
      return <CBSpinner />;
    }

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'headingContainer')}>
              <CBSubHeading>
                {
                  order && order.status === 'success' && (
                    t('paymentSuccess')
                  )
                }
                {
                  (!order || order.status === 'failed') && (
                    t('paymentFail')
                  )
                }

              </CBSubHeading>
            </div>
            {
              order && order.product_name && (
                <CBLabelAndValue label={t('labelProductName')} value={order.product_name} />
              )
            }
            {
              order && order.price && (
                <CBLabelAndValue label={t('labelPrice')} value={order.price} />
              )
            }
            {
              order && order.next_payment && (
                <CBLabelAndValue label={t('labelNextCharge')} value={moment(order.next_payment).format('D.M.Y')} />
              )
            }
            {
              !isLoading && !order && (
                <CBLabelAndValue label={t('labelFailReason')} value={error ? t(error) : t('failReasonNoVehicleOrPermission')} />
              )
            }
            <div style={getStyle(screenSize, styles, 'buttonContainer')}>
              <Link to={'/'}>
                <CBButtonGeneric textStyle={getStyle(screenSize, styles, 'buttonText')} text={t('goToGarage')} />
              </Link>
              {
                user.profile.has_subscriptions && (
                  <div>
                    <CBButtonGeneric textStyle={getStyle(screenSize, styles, 'buttonText')} onClick={() => { window.location.replace('/api/1/payments/stripe-create-portal-session/'); }} text={t('subscriptionManagement')} />
                  </div>
                )
              }
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      padding: 16,
    },
    headingContainer: {
      marginBottom: 16,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 280,
      marginTop: 32,
    },
    buttonText: {
      fontSize: 14,
      fontWeight: 500,
      textDecoration: 'none solid rgb(0, 246, 0)',
      color: '#FFFFFF',
      padding: 16,
      marginBottom: 0,
    },
  },
  medium: {
  },
  small: {
  },
};

function mapStateToProps(state) {
  return {
    garages: state.garage.garages,
    user: state.authUser.user,
  };
}

export default connect(
  mapStateToProps,
  {
  },
)(translate('PaymentResultView')(PaymentResultView));
