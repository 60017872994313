import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { history } from '../../../../index';
import { formatKm, styleMerge } from '../../../../utils/utils';
import VehicleDetailsLabelAndValue from '../VehicleDetailsLabelAndValue';

class MaintenanceListItem extends React.Component {
  getContainerStyle = () => {
    const { isSelected } = this.props;

    let style = styles.listItemContainer;

    if (isSelected) {
      style = styleMerge(style, styles.selected);
    }

    return style;
  };

  render() {
    const { maintenance, vehicle } = this.props;

    return (
      <Link
        to={
          history.location.pathname.includes('workshop-vehicle')
            ? `/workshop-vehicle/${vehicle.id}/maintenances/${maintenance.id}`
            : `/vehicle/${vehicle.id}/maintenances/${maintenance.id}`
        }
      >
        <div style={this.getContainerStyle()}>
          <VehicleDetailsLabelAndValue
            label={`${moment(maintenance.pickup_date).format(
              'D.M.Y',
            )} | ${formatKm(maintenance.km)}`}
            value={maintenance.workshop.name}
          />
        </div>
      </Link>
    );
  }
}

const styles = {
  listItemContainer: {
    padding: 10,
    borderBottom: '1px solid rgba(255,255,255,.3)',
  },
  selected: {
    borderLeft: '4px solid #27bbff',
  }
};

export default MaintenanceListItem;
