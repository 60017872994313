import React, { createRef } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  fetchVehicleTask as _fetchVehicleTask,
  deleteVehicleTask as _deleteVehicleTask,
  setVehicleTaskStatus as _setVehicleTaskStatus,
} from '../../../actions/vehicleActions';
import { formatDate, formatDateTime, getStyle, SERVICE_WORKSHOP } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSpinner from '../../shared/CBSpinner';
import TextWithLinebreaks from '../../shared/TextWithLinebreaks';
import TaskEditRow from './TaskEditRow';
import ImageCarouselModal from './ImageCarouselModal';

import CBPromptModal from '../../shared/CBPromptModal';
import AddTaskModal from '../../TasksView/components/AddTaskModal';
import InfoboxWithIcon from './ListItems/InfoboxWithIcon';

const STATUS_CREATED = 'created';
const STATUS_DONE = 'done';

class VehicleDetailsTaskContent extends React.Component {
  state = {
    imageIndex: 0,
    imageModalIsOpen: false,
    deleteModalIsOpen: false,
    modalIsOpen: false,
    statusModalIsOpen: false,
  }

  constructor(props) {
    super(props);
    this.gallery = createRef('gallery');
  }

  componentDidMount = () => {
    const {
      match,
      fetchVehicleTask,
      vehicle,
      service,
      selectedWorkshopId,
    } = this.props;
    if (match) {
      fetchVehicleTask(match.params.taskId, service === SERVICE_WORKSHOP && selectedWorkshopId);
    }
    else if (vehicle && vehicle.tasks.length) {
      fetchVehicleTask(vehicle.tasks[0].id, service === SERVICE_WORKSHOP && selectedWorkshopId);
    }
  };

  componentDidUpdate(prevProps) {
    const {
      match,
      fetchVehicleTask,
      service,
      selectedWorkshopId,
    } = this.props;

    if (match && match.params.taskId !== prevProps.match.params.taskId) {
      fetchVehicleTask(match.params.taskId, service === SERVICE_WORKSHOP && selectedWorkshopId);
    }
  }

  openImageModal = index => {
    this.setState({ imageModalIsOpen: true, imageIndex: index });
  }

  hideImageModal = () => {
    this.setState({ imageModalIsOpen: false });
  }

  openStatusModal = () => {
    this.setState({
      statusModalIsOpen: true,
    });
  };

  closeStatusModal = () => {
    this.setState({
      statusModalIsOpen: false,
    });
  };

  deleteVehicleTask = () => {
    const { deleteVehicleTask, match, vehicle, service, selectedWorkshopId } = this.props;
    this.setState({
      deleteModalIsOpen: false,
    });
    let taskId = null;
    if (match) {
      taskId = match.params.taskId;
    } else if (vehicle && vehicle.tasks.length) {
      taskId = vehicle.tasks[0].id
    }
    if (service === SERVICE_WORKSHOP) {
      deleteVehicleTask(taskId, selectedWorkshopId);
    } else {
      deleteVehicleTask(taskId);
    }
  };

  setTaskStatus = () => {
    const {
      setVehicleTaskStatus,
      service,
      selectedWorkshopId,
    } = this.props;

    const task = this.getTask();

    const status = task.status === STATUS_DONE ? STATUS_CREATED : STATUS_DONE;

    setVehicleTaskStatus(task.id, status, service === SERVICE_WORKSHOP && selectedWorkshopId);

    this.closeStatusModal();
  };

  getTask = () => {
    const { vehicle, match, searchRequest } = this.props;
    const { taskIDs } = searchRequest;
    let task = match
      ? vehicle.tasks.find(x => x.id == match.params.taskId)
      : undefined;
    if (!task && vehicle && vehicle.tasks.length) {
      if (taskIDs) {
        if (taskIDs.length) {
          task = vehicle.tasks.find(
            x => x.id === taskIDs[0],
          );
        }
      } else {
        task = vehicle.tasks[0];
      }
    }
    return task;
  }

  openDeleteTaskModal = () => {
    this.setState({
      deleteModalIsOpen: true,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      deleteModalIsOpen: false,
    });
  };

  afterOpenDeleteModal = () => {
  };

  editTask = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  openResponseModal = () => {
    this.setState({
      responseModalIsOpen: true,
    });
  };

  closeResponseModal = () => {
    this.setState({
      responseModalIsOpen: false,
    });
  };

  getPriorityText = (priority) => {
    const {
      t,
    } = this.props;

    switch(priority) {
      case 'high':
        return t('taskPriorityHigh');
      case 'medium':
        return t('taskPriorityMedium');
      case 'low':
        return t('taskPriorityLow');
      default:
        return '';
    }
  };


  render() {
    const {
      vehicleRequest, t, match, vehicle,
    } = this.props;

    const {
      modalIsOpen,
      deleteModalIsOpen,
      imageIndex,
      imageModalIsOpen,
      statusModalIsOpen
    } = this.state;

    const task = this.getTask();

    if (vehicleRequest.taskStatus.loading) {
      return (
        <CBMediaQuery>
          {screenSize => (
            <div style={getStyle(screenSize, styles, 'taskContentContainer')}>
              <div>
                <CBSpinner />
              </div>
            </div>
          )}
        </CBMediaQuery>
      );
    } else if (vehicleRequest.taskStatus.error) {
      return (
        <CBMediaQuery>
          {screenSize => (
            <div style={getStyle(screenSize, styles, 'taskContentContainer')}>
              <div
                className='font-titillium-web-extra-light'
                style={getStyle(screenSize, styles, 'sectionHeading')}
              >
                <div style={getStyle(screenSize, styles, 'sectionHeadingText')}>
                  Tehtävää ei löydy.
                </div>
              </div>
            </div>
          )}
        </CBMediaQuery>
      );
    } else {
      return (
        <CBMediaQuery>
          {screenSize => (
            <div style={getStyle(screenSize, styles, 'taskContentContainer')}>
              {task && (
                <React.Fragment>
                  <div>
                    <div style={getStyle(screenSize, styles, 'topInfoContainer')}>
                      <InfoboxWithIcon
                        icon='/img/icon_calendar.svg'
                        text={formatDate(task.done_at ? task.done_at : task.due_at)}
                        labelText={task.done_at ? t('taskDoneAt') : t('taskDueDate')}
                      />
                      <InfoboxWithIcon
                        icon='/img/icon_priority.svg'
                        text={this.getPriorityText(task.priority)}
                        labelText={t('taskPriority')}
                        hasBorders
                      />
                      <InfoboxWithIcon
                        icon='/img/icon_task_origin.svg'
                        text={t(task.origin)}
                        labelText={t('taskOrigin')}
                      />
                    </div>
                    <div
                      className='font-titillium-web-extra-light'
                      style={getStyle(screenSize, styles, 'sectionHeading')}
                    >
                      <div style={getStyle(screenSize, styles, 'sectionHeadingText')}>
                        {task.title}
                      </div>
                      <div style={getStyle(screenSize, styles, 'additionalTaskInfo')}>
                        <div style={getStyle(screenSize, styles, 'creatorContainer')}>
                          {`${t('taskUserLabel')}: ${task.user_display}`}
                        </div>
                        <div>
                          {`${t('taskCreatedLabel')}: ${moment(task.created_at).format('D.M.YYYY HH:mm')}`}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div style={getStyle(screenSize, styles, 'taskTextContainer')}>
                        <p style={getStyle(screenSize, styles, 'treatmentDescriptionText')}>
                          <TextWithLinebreaks text={task.text} />
                        </p>
                      </div>
                      <div style={getStyle(screenSize, styles, 'galleryContainer')}>
                        <ImageCarouselModal
                          show={imageModalIsOpen}
                          handleClose={this.hideImageModal}
                          imageIndex={imageIndex}
                          images={task.images || []}
                          showNextImage={this.showNextImage}
                          showPreviousImage={this.showPreviousImage}
                          getBackroundImageUrl={this.getBackroundImageUrl}
                        />
                        <div style={getStyle(screenSize, styles, 'imageContainer')}>
                          {
                            task.images && !!task.images.length && task.images.map((image, index) => (
                              <div
                                style={{
                                  ...getStyle(screenSize, styles, 'squareImage'),
                                  backgroundImage: `url(${image.image})`,
                                }}
                                key={image.id}
                                onClick={() => this.openImageModal(index)}
                              />
                            ))
                          }
                        </div>
                      </div>
                      <TaskEditRow
                        task={task}
                        openDeleteTaskModal={this.openDeleteTaskModal}
                        editTask={this.editTask}
                        openStatusModal={this.openStatusModal}
                        isDone={task.status === STATUS_DONE}
                      />
                    </div>
                  </div>
                  {
                    modalIsOpen && (
                      <AddTaskModal
                        modalIsOpen={modalIsOpen}
                        closeModal={this.closeModal}
                        afterOpenModal={this.afterOpenModal}
                        task={task}
                      />
                    )
                  }
                  <CBPromptModal
                    modalIsOpen={deleteModalIsOpen}
                    closeModal={this.closeDeleteModal}
                    afterOpenModal={this.afterOpenDeleteModal}
                    title={t('deleteTaskPromptTitle')}
                    text={t('deleteTaskPromptText')}
                    buttonText={t('deleteTaskPromptButton')}
                    performAction={this.deleteVehicleTask}
                  />
                  <CBPromptModal
                    modalIsOpen={statusModalIsOpen}
                    closeModal={this.closeStatusModal}
                    afterOpenModal={undefined}
                    title={task.status === STATUS_CREATED ? t('taskStatusPromptTitle') : t('taskUnmarkDoneStatusPromptTitle')}
                    text={task.status === STATUS_CREATED ? t('taskStatusPromptText') : t('taskUnmarkDoneStatusPromptText')}
                    buttonText={t('taskPromptButton')}
                    performAction={this.setTaskStatus}
                  />
                </React.Fragment>
              )}
            </div>
          )}
        </CBMediaQuery>
      );
    }
  }
}

const styles = {
  default: {
    topInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    taskNameText: {
      fontSize: 32,
    },
    imageContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 32,
    },
    squareImage: {
      width: 100,
      height: 100,
      margin: '0 10px 10px 0',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
      cursor: 'pointer',
    },
    galleryContainer: {
      marginBottom: 16,
    },
    responseButtonContainer: {
      width: 200,
      marginBottom: 32,
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    taskDescriptionText: {
      fontSize: 14,
    },
    galleryThumbnailsContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    thumbnail: {
      maxWidth: 120,
      cursor: 'pointer',
    },
    thumbnailContainer: {
      marginRight: 8,
    },
    responseContainer: {
      marginTop: 32,
    },
    sectionHeadingText: {
      fontSize: 24,
    },
    responseHeading: {
      fontSize: 20,
      color: 'white',
    },
    taskTextContainer: {
      marginBottom: 16,
    },
    sectionHeading: {
      display: 'flex',
      fontSize: 20,
      justifyContent: 'space-between',
      alignItems: 'center',
      color: 'white',
      flexWrap: 'wrap',
      marginBottom: 16,
      marginTop: 16,
    },
    additionalTaskInfo: {
      display: 'flex',
      fontSize: 12,
      color: 'white',
    },
    treatmentDescriptionText: {
      fontSize: 14,
      minWidth: '100%',
      width: 0,
      overflowWrap: 'break-word',
    },
    creatorContainer: {
      marginRight: 16,
    },
    taskContentContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 1)',
      minHeight: 500,
      width: '75%',
      padding: 16,
    },
    topInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginLeft: -16,
      marginRight: -16,
    },
  },
  small: {
    taskContentContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 1)',
      minHeight: 500,
      width: '100%',
      padding: 16,
    },
    topInfoContainer: {
      display: 'flex',
      justifyContent: 'normal',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
    },
  },
};

function mapStateToProps(state) {
  return {
    vehicleRequest: state.vehicle,
    searchRequest: state.search,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchVehicleTask: _fetchVehicleTask,
    deleteVehicleTask: _deleteVehicleTask,
    setVehicleTaskStatus: _setVehicleTaskStatus,
  },
)(translate('VehicleDetailsView')(VehicleDetailsTaskContent));
