import React from 'react';

class MaintenancePartListItem extends React.Component {
  render() {
    const part = this.props.part;
    return (
      <div style={styles.partContainer}>
        <div style={styles.upperSectionContainer}>
          <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
            {part.name}
            {' '}
            {part.amount}
            {' '}
            {part.unit}
          </p>
        </div>
      </div>
    );
  }
}

const styles = {
  partContainer: {
    borderBottom: '1px solid rgba(255, 255, 255, .4)',
    height: 46,
  },
  upperSectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 16,
    paddingBottom: 8,
    paddingTop: 8,
    paddingRight: 16,
  },
  infoLabel: {
    color: '#FFF',
    fontSize: 14,
    fontWeight: 600,
  },
  infoValue: {
    color: '#FFF',
    fontSize: 14,
    fontWeight: 200,
  },
};

export default MaintenancePartListItem;
