import React, { Component } from 'react';
import Modal from 'react-modal';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../../../shared/CBMediaQuery';
import { getStyle, getModalStyle } from '../../../../utils/utils';
import CBButtonSubmit from '../../../shared/CBButtonSubmit';
import CBInputField from '../../../shared/form_components/CBInputField';
import { createVehicleReminder as _createVehicleReminder } from '../../../../actions/vehicleActions';
import { getLengthValidator } from '../../../../utils/validators';
import CBDatePickerInput from '../../../shared/CBDatePickerInput';

class AddReminderModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        name: '',
        due_date: moment()
          .add(1, 'days')
          .format('YYYY-MM-DD HH:mm'),
        vehicle: '',
      },
      formValid: {
        name: false,
      },
      displayedDate: moment().add(1, 'days'),
    };
  }

  componentDidMount = () => {
    const { vehicle } = this.props;
    const { formData } = this.state;
    this.setState({
      formData: {
        ...formData,
        vehicle: vehicle.id,
      },
    });
  };

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
      [name]: isValid,
    };

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const { formData } = this.state;
    const { createVehicleReminder, closeModal, vehicle } = this.props;
    createVehicleReminder(formData);
    this.setState({
      formData: {
        name: '',
        due_date: moment()
          .add(1, 'days')
          .format('YYYY-MM-DD HH:mm'),
        vehicle: vehicle.id,
      },
      formValid: {
        name: false,
      },
      displayedDate: moment().add(1, 'days'),
    });
    closeModal();
  };

  changeDate = value => {
    const { formData } = this.state;
    this.setState({
      formData: {
        ...formData,
        due_date: moment(value).format('YYYY-MM-DD HH:mm'),
      },
      displayedDate: value,
    });
  };

  render() {
    const { formData, displayedDate } = this.state;
    const {
      modalIsOpen, closeModal, afterOpenModal, t,
    } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={getModalStyle(screenSize, styles.modal)}
            contentLabel='Add reminder modal'
          >
            <div style={getStyle(screenSize, styles, 'container')}>
              <p style={getStyle(screenSize, styles, 'title')}>
                {t('addReminderLabel')}
              </p>

              <div>
                <CBInputField
                  name='name'
                  inputStyle={getStyle(screenSize, styles, 'input')}
                  value={formData.name}
                  placeholder={t('title')}
                  onChange={this.updateFormState}
                  validators={[getLengthValidator(4, 30)]}
                  isValidCallback={this.setIsValid}
                  labelText={t('addReminderNameLabel')}
                  labelTextStyle={getStyle(screenSize, styles, 'subTitle')}
                />
              </div>
              <div style={getStyle(screenSize, styles, 'datePickerContainer')}>
                <p
                  className='font-titillium-web-semi-bold'
                  style={getStyle(screenSize, styles, 'subTitle')}
                >
                  {t('addReminderDateLabel')}
                </p>
                <CBDatePickerInput
                  name='due_date'
                  dateFormat='dd.MM.yyyy HH:mm'
                  type='datetime'
                  displayedDate={displayedDate}
                  changeDate={this.changeDate}
                />
              </div>
              <CBButtonSubmit
                disabled={!this.getIsValid()}
                onClick={this.submitForm}
                text={t('save')}
              />
            </div>
          </Modal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 'auto',
    },
    title: {
      fontSize: 32,
      color: '#0095da',
      textAlign: 'center',
    },
    subTitle: {
      fontSize: 14,
      color: 'black',
      textAlign: 'center',
      fontWeight: 600,
      marginTop: 30,
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 400,
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      marginBottom: 30,
    },
    datePickerContainer: {
      marginBottom: 60,
    },
    info: {
      width: '100%',
      fontSize: 14,
      color: 'black',
      paddingHorizontal: 30,
      textAlign: 'center',
    },
    fieldsRow: {
      display: 'flex',
      flexDirection: 'row',
      width: 400,
      justifyContent: 'space-between',
      alignItems: 'space-between',
    },
    inputCol: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  small: {
    input: {
      width: 200,
    },
  },
  modal: {
    default: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 'auto',
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    small: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: 'auto',
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
};

function mapStateToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  {
    createVehicleReminder: _createVehicleReminder,
  },
)(translate('VehicleDetailsView')(AddReminderModal));
