import React from 'react';
import { Route } from 'react-router';
import { translate } from 'react-i18next';
import VehicleDetailsMessageContent from './VehicleDetailsMessageContent';
import VehicleDetailsMessageList from './VehicleDetailsMessageList';
import VehicleDetailsSectionHeader from './VehicleDetailsSectionHeader';
import CBMediaQuery from '../../shared/CBMediaQuery';

class VehicleDetailsMainMessage extends React.Component {
  render() {
    const { t, vehicle } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div>
            <VehicleDetailsSectionHeader type='message' text={t('messagesHeading')} />
            {screenSize === 'small' && <VehicleDetailsMessageList vehicle={vehicle} />}
            <div style={styles.mainContentContainer}>
              {screenSize !== 'small' && <VehicleDetailsMessageList vehicle={vehicle} />}
              <Route
                exact
                path='/vehicle/:vehicleId/messages/'
                render={() => <VehicleDetailsMessageContent vehicle={vehicle} />}
              />
              <Route
                path='/vehicle/:vehicleId/messages/:messageId'
                render={props => <VehicleDetailsMessageContent {...props} vehicle={vehicle} />}
              />
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  mainContentContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
};

export default translate('VehicleDetailsView')(VehicleDetailsMainMessage);
