import React from 'react'

class CBSubHeading extends React.Component {
  render() {
    return(
      <div>
        <p style={styles.headingText}>
          { this.props.children }
        </p>
      </div>
    )
  }
}

const styles = {
  headingText: {
    color: '#FFF',
    fontSize: 20,
    margin: 0
  }
}

export default CBSubHeading
