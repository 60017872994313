import { globalStatsState } from '../initialState';

export default function (state = globalStatsState, action) {
  switch (action.type) {
   
    case 'FETCH_GLOBAL_STATS_REQUEST': {
      return {
        ...state,
        globalStatsStatus: Object.assign({}, state.globalStatsStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_GLOBAL_STATS_SUCCESS': {
      return {
        ...state,
        ...action.payload,
        globalStatsStatus: Object.assign({}, state.globalStatsStatus, {
          loading: null,
          error: false,
        }),
      };
    }
    case 'FETCH_GLOBAL_STATS_FAIL': {
      return {
        ...state,
        globalStats: null,
        globalStatsStatus: Object.assign({}, state.globalStatsStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    default:
      break;
  }
  return state;
}
