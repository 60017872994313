import React, { Component } from 'react';
import BackButton from './BackButton';
import CBMediaQuery from './CBMediaQuery';
import { getStyle, styleMerge } from '../../utils/utils';
import { browserIsIE } from '../../ie-support';

export default class SubHeaderBar extends Component {
  render() {
    const {
      backTarget,
      label,
      rightBlock,
      goBack,
      searchBlock,
      filterBlock,
      height,
    } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={styleMerge(getStyle(screenSize, styles, 'container'), height ? { height } : {})}>
            <div style={styleMerge(browserIsIE() ? getStyle(screenSize, styles, 'headerBackgroundIE') : getStyle(screenSize, styles, 'headerBackground'), height ? { height } : {})} />
            {/* This is style hack to get content aligned to center in end user site while not braking the workshop side */}
            <div style={styleMerge(getStyle(screenSize, styles, 'contentContainer'), { height: height ? '' : 'inherit' })}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: searchBlock ? '' : 'space-between' }}>
                <div style={getStyle(screenSize, styles, 'leftBlock')}>
                  {backTarget ? (<BackButton to={backTarget} goBack={goBack} onClick={() => goBack()} />) : (<div />)}
                  <p className='font-titillium-web-extra-light' style={getStyle(screenSize, styles, 'label')}>
                    {label}
                  </p>
                </div>
                <div style={getStyle(screenSize, styles, 'rightBlock')}>
                  {rightBlock}
                </div>
              </div>
              {searchBlock && (
                <div style={getStyle(screenSize, styles, 'searchBlock')}>
                  {searchBlock}
                </div>
              )}
              {filterBlock && (
                <div style={getStyle(screenSize, styles, 'filterBlock')}>
                  {filterBlock}
                </div>
              )}
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      height: 150,
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    contentContainer: {
      width: '100%',
      marginTop: 10,
    },
    label: {
      fontSize: 32,
      color: '#ffffff',
      fontWeight: 200,
      marginBottom: 0,
      paddingLeft: 20,
      paddingRight: 10,
    },
    leftBlock: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    rightBlock: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: 16,
    },
    searchBlock: {
      display: 'inline-flex',
      width: '100%',
      flexDirection: 'row',
    },
    headerBackground: {
      position: 'absolute',
      left: 0,
      right: 0,
      height: 155,
      background: 'linear-gradient(0deg, #0095da 3%, #0069ab 100% )',
      zIndex: -1,
    },
    headerBackgroundIE: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: 155,
      background: 'linear-gradient(0deg, #0095da 3%, #0069ab 100% )',
      zIndex: -1,
    },
  },
  medium: {
    headerBackground: {
      height: 200,
      width: '100%',
    },
    contentContainer: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    searchBlock: {
      marginBottom: 6,
    },
  },
  small: {
    container: {
      alignItems: 'flex-start',
    },
    searchBlock: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    headerBackground: {
      height: 180,
      left: 0,
      right: 0,
    },
    contentContainer: {
      alignSelf: 'center',
      height: 80,
      top: 0,
      marginTop: 0,
      paddingTop: 0,
    },
    label: {
      fontSize: 32,
      color: '#ffffff',
      fontWeight: 200,
      marginBottom: 0,
      paddingLeft: 4,
      paddingRight: 4,
    },
    leftBlock: {
      marginLeft: 16,
    },
  },
};
