import { garageState } from '../initialState';
import { storeInsertItem, storeUpdateObjectInArray, storeRemoveItem } from '../utils/utils';

export default function (state = garageState, action) {
  switch (action.type) {
    case 'FETCH_GARAGES_REQUEST': {
      return {
        ...state,
        garageListStatus: Object.assign({}, state.garageListStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_GARAGES_SUCCESS': {
      return {
        ...state,
        garages: action.payload,
        garageListStatus: Object.assign({}, state.garageListStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_GARAGES_FAIL': {
      return {
        ...state,
        garageListStatus: Object.assign({}, state.garageListStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_GARAGE_REQUEST': {
      return {
        ...state,
        garageStatus: Object.assign({}, state.garageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_GARAGE_SUCCESS': {
      return {
        ...state,
        garages: storeInsertItem(state.garages, action.payload),
        garageStatus: Object.assign({}, state.garageStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_GARAGE_FAIL': {
      return {
        ...state,
        garageStatus: Object.assign({}, state.garageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_GARAGE_REQUEST': {
      return {
        ...state,
        garageStatus: Object.assign({}, state.garageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_GARAGE_SUCCESS': {
      return {
        ...state,
        garages: storeUpdateObjectInArray(state.garages, action.payload),
        garageStatus: Object.assign({}, state.garageStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_GARAGE_FAIL': {
      return {
        ...state,
        garageStatus: Object.assign({}, state.garageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_GARAGE_PERMISSION_REQUEST': {
      return {
        ...state,
        garageStatus: Object.assign({}, state.garageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_GARAGE_PERMISSION_SUCCESS': {
      const garageId = action.payload.garage;
      const garage = state.garages.find((x) => x.id === garageId);
      const newGarage = { ...garage };
      newGarage.permissions = storeUpdateObjectInArray(newGarage.permissions, action.payload);
      return {
        ...state,
        garages: storeUpdateObjectInArray(state.garages, newGarage),
        garageStatus: Object.assign({}, state.garageStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_GARAGE_PERMISSION_FAIL': {
      return {
        ...state,
        garageStatus: Object.assign({}, state.garageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'DELETE_GARAGE_PERMISSION_REQUEST': {
      return {
        ...state,
        garageStatus: Object.assign({}, state.garageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DELETE_GARAGE_PERMISSION_SUCCESS': {
      const garageId = action.additionalData.garageId;
      const garage = state.garages.find((x) => x.id === garageId);
      const newGarage = { ...garage };
      newGarage.permissions = storeRemoveItem(newGarage.permissions, action.additionalData.permissionId);
      return {
        ...state,
        garages: storeUpdateObjectInArray(state.garages, newGarage),
        garageStatus: Object.assign({}, state.garageStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DELETE_GARAGE_PERMISSION_FAIL': {
      return {
        ...state,
        garageStatus: Object.assign({}, state.garageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_GARAGE_PERMISSION_REQUEST': {
      return {
        ...state,
        garageStatus: Object.assign({}, state.garageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_GARAGE_PERMISSION_SUCCESS': {
      const garageId = action.payload.garage;
      const garage = state.garages.find((x) => x.id === garageId);
      const newGarage = { ...garage };
      newGarage.permissions = storeInsertItem(newGarage.permissions, action.payload);
      return {
        ...state,
        garages: storeUpdateObjectInArray(state.garages, newGarage),
        garageStatus: Object.assign({}, state.garageStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_GARAGE_PERMISSION_FAIL': {
      return {
        ...state,
        garageStatus: Object.assign({}, state.garageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'TOGGLE_GARAGE_ORGANIZATION_SHARING_REQUEST': {
      return {
        ...state,
        garageStatus: Object.assign({}, state.garageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'TOGGLE_GARAGE_ORGANIZATION_SHARING_SUCCESS': {
      return {
        ...state,
        garages: storeUpdateObjectInArray(state.garages, action.payload),
        garageStatus: Object.assign({}, state.garageStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'TOGGLE_GARAGE_ORGANIZATION_SHARING_FAIL': {
      return {
        ...state,
        garageStatus: Object.assign({}, state.garageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'TOGGLE_GARAGE_MESSAGE_SUBSCRIPTION_REQUEST': {
      return {
        ...state,
        garageStatus: Object.assign({}, state.garageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'TOGGLE_GARAGE_MESSAGE_SUBSCRIPTION_SUCCESS': {
      return {
        ...state,
        garages: storeUpdateObjectInArray(state.garages, action.payload),
        garageStatus: Object.assign({}, state.garageStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'TOGGLE_GARAGE_MESSAGE_SUBSCRIPTION_FAIL': {
      return {
        ...state,
        garageStatus: Object.assign({}, state.garageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'SELECT_GARAGE': {
      return {
        ...state,
        selectedGarage: action.payload,
      };
    }
    default:
      break;
  }
  return state;
}
