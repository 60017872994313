import React from 'react';

class CBButtonGeneric extends React.Component {
  render() {
    const { onClick, text, disabled, textStyle, containerStyle } = this.props;
    return (
      <div
        className={!disabled ? 'hover-green-border hover-green-text' : undefined}
        style={
          disabled ? { ...styles.largeButton, ...styles.largeButtonDisabled } : containerStyle || styles.largeButton
        }
        onClick={disabled ? undefined : onClick}
      >
        <p style={textStyle || styles.buttonText}>
          {text}
        </p>
      </div>
    );
  }
}

export default CBButtonGeneric;

const styles = {
  buttonText: {
    fontSize: 14,
    fontWeight: 700,
    textDecoration: 'none solid rgb(0, 246, 0)',
    color: '#0095da',
    padding: 16,
    marginBottom: 0,
  },
  largeButton: {
    height: 50,
    width: '100%',
    border: '2px solid #0095da',
    color: '#0095da',
    borderRadius: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  largeButtonDisabled: {
    opacity: 0.5,
    cursor: 'normal',
  },
};
