import React, { Component } from 'react';
import Modal from 'react-modal';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { getStyle, getModalStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBButtonSubmit from '../../shared/CBButtonSubmit';
import {
  createVehicleCategory as _createVehicleCategory,
  updateVehicleCategory as _updateVehicleCategory,
} from '../../../actions/vehicleCategoryActions';
import CBInputField from '../../shared/form_components/CBInputField';
import { getLengthValidator } from '../../../utils/validators';

Modal.setAppElement('#root');

class AddCategoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        name: props.category ? props.category.name : '',
      },
      formValid: {
        name: false,
      },
    };
  }

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
      [name]: isValid,
    };

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const { formData } = this.state;
    const {
      createVehicleCategory, updateVehicleCategory, closeModal, category,
    } = this.props;
    if (category) {
      updateVehicleCategory(category.id, formData);
    } else {
      createVehicleCategory(formData);
    }
    this.setState({
      formData: {
        name: category ? category.name : '',
      },
      formValid: {
        name: false,
      },
    });
    closeModal();
  };

  render() {
    const { formData } = this.state;
    const {
      modalIsOpen, closeModal, afterOpenModal, t, category,
    } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={getModalStyle(screenSize, styles.modal)}
            contentLabel={category ? t('updateCategoryHeading') : t('addCategoryHeading')}
            shouldCloseOnOverlayClick
          >
            <div style={getStyle(screenSize, styles, 'container')}>
              <p style={getStyle(screenSize, styles, 'title')}>
                {category ? t('updateCategoryHeading') : t('addCategoryHeading')}
              </p>

              <CBInputField
                name='name'
                inputStyle={getStyle(screenSize, styles, 'input')}
                value={formData.name}
                placeholder={t('addCategoryInputPlaceholder')}
                onChange={this.updateFormState}
                validators={[getLengthValidator(4, 40)]}
                isValidCallback={this.setIsValid}
                labelText={t('addCategoryNameLabel')}
                labelTextStyle={getStyle(screenSize, styles, 'subTitle')}
              />
              <CBButtonSubmit
                disabled={!this.getIsValid()}
                onClick={this.submitForm}
                text={category ? t('updateCategorySubmitButton') : t('addCategorySubmitButton')}
              />
              {!category && (
                <p style={getStyle(screenSize, styles, 'info')}>
                  {t('addCategoryInfoText')}
                </p>
              )}
            </div>
          </Modal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      fontSize: 32,
      color: '#0095da',
      textAlign: 'center',
    },
    subTitle: {
      fontSize: 14,
      color: 'black',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 400,
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      marginBottom: 30,
    },
    info: {
      width: '100%',
      fontSize: 14,
      color: 'black',
      paddingHorizontal: 30,
      textAlign: 'center',
    },
  },
  small: {
    input: {
      width: 200,
    },
  },
  modal: {
    default: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 'auto',
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    small: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: 'unset',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
};

function mapStateToProps(state) {
  return {
    vehicleCategoryRequest: state.vehicleCategory,
  };
}

export default connect(
  mapStateToProps,
  {
    createVehicleCategory: _createVehicleCategory,
    updateVehicleCategory: _updateVehicleCategory,
  },
)(translate('GarageView')(AddCategoryModal));
