import React, { Component } from 'react';
import { getStyle } from '../../../../utils/utils';
import OptionsList from './OptionsList';

export default class OptionsButton extends Component {
  state = {
    displayOptions: false,
  };

  toggleOptions = e => {
    e.stopPropagation();
    const { displayOptions } = this.state;
    this.setState({
      displayOptions: !displayOptions,
    });
  };

  render() {
    const { screenSize, options, icon, type } = this.props;
    const { displayOptions } = this.state;
    return (
      <div
        style={getStyle(screenSize, styles, 'container')}
        onClick={this.toggleOptions}
      >
        <img
          alt='Options'
          className="opacity-and-border-on-hover"
          style={getStyle(screenSize, styles, 'optionsIcon')}
          src={icon}
        />
        <OptionsList
          onMouseLeave={() => this.setState({
            displayOptions: false,
          })}
          displayOptions={displayOptions}
          options={options}
          type={type}
        />
      </div>
    );
  }
}

const styles = {
  default: {
    container: {
      height: 25,
      width: 25,
      zIndex: 99,
      cursor: 'pointer',
    },
    optionsIcon: {
      width: 25,
      height: 25,
      opacity: 0.5,
      border: '1px solid transparent',
    },
  },
};
