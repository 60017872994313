import React, { Component } from 'react';
import Modal from 'react-modal';
import { translate } from 'react-i18next';
import moment from 'moment';
import { getStyle, getModalStyle } from '../../utils/utils';
import CBMediaQuery from './CBMediaQuery';

Modal.setAppElement('#root');

class WorkshopNotificationModal extends Component {
  render() {
    const { announcement, t } = this.props;
    const { modalIsOpen, closeModal, afterOpenModal } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={getModalStyle(screenSize, styles.modal)}
            contentLabel={announcement.workshop.name}
            shouldCloseOnOverlayClick
          >
            <div style={getStyle(screenSize, styles, 'container')} onClick={closeModal}>
              <div style={getStyle(screenSize, styles, 'header')}>
                <p style={getStyle(screenSize, styles, 'title')}>
                  {announcement.workshop.name}
                </p>
                <p style={getStyle(screenSize, styles, 'subTitle')}>
                  {announcement.workshop.street_address}
                </p>
              </div>
              <p style={getStyle(screenSize, styles, 'info')}>
                {announcement.description}
              </p>
              <p style={getStyle(screenSize, styles, 'footerText')}>
                {t('validUntil', { date: moment(announcement.expire_date).format('D.M.Y') })}
              </p>
            </div>
          </Modal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    title: {
      fontSize: 32,
      color: '#ffff',
      textAlign: 'center',
    },
    subTitle: {
      fontSize: 14,
      color: '#ffff',
      textAlign: 'center',
      fontWeight: 'bold',
      opacity: 0.8,
    },
    info: {
      width: '100%',
      fontSize: 14,
      color: '#ffff',
      paddingHorizontal: 30,
      textAlign: 'center',
    },
    footerText: {
      width: '100%',
      fontSize: 14,
      color: '#ffff',
      paddingHorizontal: 30,
      textAlign: 'center',
      opacity: 0.6,
    },
  },
  modal: {
    default: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 400,
        backgroundColor: 'rgba(0, 149, 218, 1)',
        border: 'none',
        borderRadius: 1,
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    small: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
};

export default translate()(WorkshopNotificationModal);
