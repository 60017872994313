import React from 'react';
import 'draft-js/dist/Draft.css';

class StyleButton extends React.Component {
  onToggle = e => {
    const { onToggle, style } = this.props;
    e.preventDefault();
    onToggle(style);
  }

  render() {
    const { icon, active, activeIcon } = this.props;
    let style = styles.button;
    if (active) {
      style = { ...style, ...styles.active };
    }

    return (
      <span style={style} onMouseDown={this.onToggle}>
        <img src={active ? activeIcon : icon} alt='' />
      </span>
    );
  }
}

const styles = {
  button: {
    cursor: 'pointer',
    padding: 5,
  },
  active: {
    backgroundColor: 'black',
  },
};

export default StyleButton;
