import React from 'react';
import { translate } from 'react-i18next';
import LicensePlate from '../../../shared/LicensePlate';
import { getStyle } from '../../../../utils/utils';
import { browserIsIE } from '../../../../ie-support';

class VehicleDetailsTopInfo extends React.Component {
  render() {
    const { t, vehicle, screenSize } = this.props;

    return (
      <div style={getStyle(screenSize, styles, 'infoContainer')}>
        <div style={browserIsIE() ? getStyle(screenSize, styles, 'licensePlateContainerIE') : getStyle(screenSize, styles, 'licensePlateContainer')}>
          <LicensePlate size='large' plateNumber={vehicle.registration_number} />
        </div>
        <div>
          <p className='font-titillium-web-light' style={getStyle(screenSize, styles, 'mainLabel')}>
            {`${vehicle.manufacturer} ${vehicle.model_name}`}
          </p>
        </div>
        {false && (
          <p style={getStyle(screenSize, styles, 'smallInfoText')}>
            <span className='font-titillium-web-light'>
              {`${t('nextMOTTest')} `}
            </span>
            <span className='font-titillium-web-semi-bold'>
              {'missing backend support'}
            </span>
          </p>
        )}
      </div>
    );
  }
}

const styles = {
  default: {
    detailsText: {
      fontSize: 12,
      color: '#FFF',
      borderRight: '0',
      marginRight: 8,
      paddingRight: 0,
    },
    smallInfoText: {
      color: '#FFF',
      fontSize: 12,
    },
    mainLabel: {
      fontSize: 24,
      color: '#ffffff',
      fontWeight: 200,
      marginBottom: 0,
      marginLeft: 20,
      lineHeight: 1,
      paddingTop: 'auto',
      paddingBottom: 'auto',
      paddingLeft: 0,
    },
    licensePlateContainer: {
      marginBottom: 0,
    },
    licensePlateContainerIE: {
      marginTop: 24,
      marginBottom: 0,
    },
    subLabel: {
      fontSize: 20,
      color: '#ffffff',
      marginBottom: 0,
      fontWeight: 200,
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'row',
      padding: 16,
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  medium: {
    infoContainer: {
      padding: 0,
      marginTop: 10,
    },
    mainLabel: {
      marginTop: 10,
    },
    licensePlateContainer: {
      marginTop: 10,
    },
  },
  small: {
    detailsText: {
      fontSize: 12,
      color: '#FFF',
      borderRight: '1px solid rgba(255, 255, 255, .5)',
      paddingRight: 10,
      marginRight: 10,
    },
    infoContainer: {
      flexDirection: 'column',
      alignItems: 'left',
    },
    licensePlateContainer: {
      marginLeft: 0,
      paddingLeft: 0,
    },
    mainLabel: {
      paddingLeft: 0,
      marginLeft: 0,
      paddingTop: 8,
    },
  },
};

export default translate('VehicleDetailsView')(VehicleDetailsTopInfo);
