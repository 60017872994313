import React from 'react';

class MaintenanceTaskListItem extends React.Component {
  render() {
    const task = this.props.task;
    return (
      <div style={styles.taskContainer}>
        <div style={styles.upperSectionContainer}>
          <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
            {task.name}
          </p>
          <p className='font-titillium-web-light' style={styles.infoValue}>
            {task.amount}
            {' '}
kappaletta
          </p>
        </div>
        {/* <p className="font-titillium-web-light" style={styles.infoValue}>Tekijä: {task.technician_name}</p> */}
      </div>
    );
  }
}

const styles = {
  taskContainer: {
    height: 46,
    borderBottom: '1px solid rgba(255, 255, 255, .4)',
  },
  upperSectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 16,
    paddingBottom: 8,
    paddingTop: 8,
    paddingRight: 16,
  },
  infoLabel: {
    color: '#FFF',
    fontSize: 14,
    fontWeight: 600,
  },
  infoValue: {
    color: '#FFF',
    fontSize: 14,
    fontWeight: 200,
  },
};

export default MaintenanceTaskListItem;
