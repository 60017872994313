import { organizationState } from '../initialState';
import { storeInsertItem, storeRemoveItem, storeUpdateObjectInArray } from '../utils/utils';

export default function (state = organizationState, action) {
  switch (action.type) {
    case 'FETCH_ORGANIZATIONS_REQUEST': {
      return {
        ...state,
        organizationListStatus: Object.assign({}, state.organizationListStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_ORGANIZATIONS_SUCCESS': {
      return {
        ...state,
        organizations: action.payload,
        organizationListStatus: Object.assign({}, state.organizationListStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_ORGANIZATIONS_FAIL': {
      return {
        ...state,
        organizationListStatus: Object.assign({}, state.organizationListStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_ORGANIZATION_REQUEST': {
      return {
        ...state,
        organizationStatus: Object.assign({}, state.organizationStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_ORGANIZATION_SUCCESS': {
      return {
        ...state,
        organizations: storeInsertItem(state.organizations, action.payload),
        organizationStatus: Object.assign({}, state.organizationStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_ORGANIZATION_FAIL': {
      return {
        ...state,
        organizationStatus: Object.assign({}, state.organizationStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_ORGANIZATION_REQUEST': {
      return {
        ...state,
        organizationStatus: Object.assign({}, state.organizationStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_ORGANIZATION_SUCCESS': {
      return {
        ...state,
        organizations: storeUpdateObjectInArray(state.organizations, action.payload),
        organizationStatus: Object.assign({}, state.organizationStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_ORGANIZATION_FAIL': {
      return {
        ...state,
        organizationStatus: Object.assign({}, state.organizationStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'SELECT_ORGANIZATION': {
      return {
        ...state,
        selectedOrganization: action.payload,
      };
    }
    case 'UPDATE_ORGANIZATION_PERMISSION_REQUEST': {
      return {
        ...state,
        organizationStatus: Object.assign({}, state.organizationStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_ORGANIZATION_PERMISSION_SUCCESS': {
      const organizationId = action.payload.organization;
      const organization = state.organizations.find((x) => x.id === organizationId);
      const newOrganization = { ...organization };
      newOrganization.permissions = storeUpdateObjectInArray(newOrganization.permissions, action.payload);
      return {
        ...state,
        organizations: storeUpdateObjectInArray(state.organizations, newOrganization),
        organizationStatus: Object.assign({}, state.organizationStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_ORGANIZATION_PERMISSION_FAIL': {
      return {
        ...state,
        organizationStatus: Object.assign({}, state.organizationStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'DELETE_ORGANIZATION_PERMISSION_REQUEST': {
      return {
        ...state,
        organizationStatus: Object.assign({}, state.organizationStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DELETE_ORGANIZATION_PERMISSION_SUCCESS': {
      const organizationId = action.additionalData.organizationId;
      const organization = state.organizations.find((x) => x.id === organizationId);
      const newOrganization = { ...organization };

      newOrganization.permissions = storeRemoveItem(newOrganization.permissions, action.additionalData.permissionId);
      return {
        ...state,
        organizations: storeUpdateObjectInArray(state.organizations, newOrganization),
        organizationStatus: Object.assign({}, state.organizationStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DELETE_ORGANIZATION_PERMISSION_FAIL': {
      return {
        ...state,
        organizationStatus: Object.assign({}, state.organizationStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_ORGANIZATION_PERMISSION_REQUEST': {
      return {
        ...state,
        organizationStatus: Object.assign({}, state.organizationStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_ORGANIZATION_PERMISSION_SUCCESS': {
      const organizationId = action.payload.organization;
      const organization = state.organizations.find((x) => x.id === organizationId);
      const newOrganization = { ...organization };
      newOrganization.permissions = storeInsertItem(newOrganization.permissions, action.payload);
      return {
        ...state,
        organizations: storeUpdateObjectInArray(state.organizations, newOrganization),
        organizationStatus: Object.assign({}, state.organizationStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_ORGANIZATION_PERMISSION_FAIL': {
      return {
        ...state,
        organizationStatus: Object.assign({}, state.organizationStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'TOGGLE_ORGANIZATION_MESSAGE_SUBSCRIPTION_REQUEST': {
      return {
        ...state,
        organizationStatus: Object.assign({}, state.organizationStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'TOGGLE_ORGANIZATION_MESSAGE_SUBSCRIPTION_SUCCESS': {
      return {
        ...state,
        organizations: storeUpdateObjectInArray(state.organizations, action.payload),
        organizationStatus: Object.assign({}, state.organizationStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'TOGGLE_ORGANIZATION_MESSAGE_SUBSCRIPTION_FAIL': {
      return {
        ...state,
        organizationStatus: Object.assign({}, state.organizationStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    default:
      break;
  }
  return state;
}
