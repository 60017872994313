import {
    API, GET, POST, PUT, DELETE,
  } from '../middleware/api';
  import { API_URL } from '../config';

  export function fetchCheckLists(params) {
    return {
      [API]: {
        method: GET,
        url: `${API_URL}/uiapi/1/check_list/`,
        types: [
          'FETCH_CHECK_LISTS_REQUEST',
          'FETCH_CHECK_LISTS_SUCCESS',
          'FETCH_CHECK_LISTS_FAIL',
        ],
        params,
      },
    };
  }

  export function fetchVehicleCheckListItems(params) {
    return {
      [API]: {
        method: GET,
        url: `${API_URL}/uiapi/1/vehicle_check_list_item/`,
        types: [
          'FETCH_VEHICLE_CHECK_LIST_ITEM_REQUEST',
          'FETCH_VEHICLE_CHECK_LIST_ITEM_SUCCESS',
          'FETCH_VEHICLE_CHECK_LIST_ITEM_FAIL',
        ],
        params,
      },
    };
  }

  export function createCheckList(data) {
    return {
      [API]: {
        method: POST,
        url: `${API_URL}/uiapi/1/check_list/`,
        types: [
          'CREATE_CHECK_LIST_REQUEST',
          'CREATE_CHECK_LIST_SUCCESS',
          'CREATE_CHECK_LIST_FAIL',
        ],
        data,
      },
    };
  }

  export function updateCheckList(careServiceId, data) {
    return {
      [API]: {
        method: PUT,
        url: `${API_URL}/uiapi/1/check_list/${careServiceId}/`,
        types: [
          'UPDATE_CHECK_LIST_REQUEST',
          'UPDATE_CHECK_LIST_SUCCESS',
          'UPDATE_CHECK_LIST_FAIL',
        ],
        data,
      },
    };
  }

  export function deleteCheckList(careServiceId) {
    return {
      [API]: {
        method: DELETE,
        url: `${API_URL}/uiapi/1/check_list/${careServiceId}/`,
        types: [
          'DELETE_CHECK_LIST_REQUEST',
          'DELETE_CHECK_LIST_SUCCESS',
          'DELETE_CHECK_LIST_FAIL',
        ],
      },
    };
  }

  export function createCheckListItem(data) {
    return {
      [API]: {
        method: POST,
        url: `${API_URL}/uiapi/1/check_list_item/`,
        types: [
          'CREATE_CHECK_LIST_ITEM_REQUEST',
          'CREATE_CHECK_LIST_ITEM_SUCCESS',
          'CREATE_CHECK_LIST_ITEM_FAIL',
        ],
        data,
      },
    };
  }

  export function updateCheckListItem(checkListItemId, data, params={}) {
    return {
      [API]: {
        method: PUT,
        url: `${API_URL}/uiapi/1/check_list_item/${checkListItemId}/`,
        types: [
          'UPDATE_CHECK_LIST_ITEM_REQUEST',
          'UPDATE_CHECK_LIST_ITEM_SUCCESS',
          'UPDATE_CHECK_LIST_ITEM_FAIL',
        ],
        data,
        params,
      },
    };
  }

  export function deleteCheckListItem(checkListItemId, checkListId, params={}) {
    return {
      [API]: {
        method: DELETE,
        url: `${API_URL}/uiapi/1/check_list_item/${checkListItemId}/`,
        types: [
          'DELETE_CHECK_LIST_ITEM_REQUEST',
          'DELETE_CHECK_LIST_ITEM_SUCCESS',
          'DELETE_CHECK_LIST_ITEM_FAIL',
        ],
        additionalData: {
          itemId: checkListItemId,
          checkListId: checkListId
        },
        params
      },
    };
  }

  export function toggleVehicleChecks(data) {
    return {
      [API]: {
        method: POST,
        url: `${API_URL}/uiapi/1/vehicle_check_list_item/toggle_checks/`,
        types: [
          'FETCH_VEHICLE_CHECK_LIST_ITEM_REQUEST',
          'FETCH_VEHICLE_CHECK_LIST_ITEM_SUCCESS',
          'FETCH_VEHICLE_CHECK_LIST_ITEM_FAIL',
        ],
        data,
      },
    };
  }