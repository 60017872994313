export const browserIsIE = () => {
  var ua = window.navigator.userAgent;

  var msie = ua.indexOf("MSIE ");
  var trident = ua.indexOf("Trident/");

  if (msie > 0 || trident > 0) {
    return true;
  }
  return false;
};
