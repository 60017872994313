import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../../shared/CBMediaQuery';
import '../../../App.css';
import {finnishDateFormat, getStyle, SERVICE_FLEET, SERVICE_WORKSHOP, storeRemoveItem, storeInsertItem, dbDateFormat } from '../../../utils/utils';
import CBModal from '../../shared/CBModal';
import {
    fetchCheckLists as _fetchCheckLists,
    fetchVehicleCheckListItems as _fetchVehicleCheckListItems,
    toggleVehicleChecks as _toggleVehicleChecks
  } from '../../../actions/checkListActions';
import CBDatePickerInput from '../../shared/CBDatePickerInput';
import moment from 'moment';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';


class SelectCheckListItemsModal extends Component {
  constructor(props) {
    super(props);

    const { vehicle } = props;

    this.state = {
      vehicleCheckListItemsSet: false,
      formData: {
        check_list_id: null,
        vehicle_id: vehicle.id,
        items: []
      },
    };
  }

  componentDidMount = () => {
    const {
      fetchVehicleCheckListItems,
      vehicle,
      selectedOrganizationId,
      selectedWorkshopId
    } = this.props;

    const params = {
      vehicle_id: vehicle.id
    };

    if (selectedOrganizationId) {
      params.organization_id = selectedOrganizationId;
    } else if (selectedWorkshopId) {
      params.workshop_id = selectedWorkshopId;
    }

    this.setState({
      vehicleCheckListItemsSet: false,
    });

    fetchVehicleCheckListItems(params);
    this.fetchCheckLists();
  };

  componentDidUpdate = (prevProps) => {
    const {
      vehicleCheckListItems,
      checkLists,
      vehicle
    } = this.props;

    const { formData, vehicleCheckListItemsSet } = this.state;

    if (vehicleCheckListItems.length && !formData.items.length && !vehicleCheckListItemsSet) {
      this.setState({
        vehicleCheckListItemsSet: true,
        formData: {
          ...formData,
          items: vehicleCheckListItems.map((item) => {
            return {
              'check_list_item': item.check_list_item,
              'last_check_date': item.last_check_date,
              'check_list_id': item.check_list_id,
              'enabled': item.enabled
            }
          })
        }
      })
    } else if (checkLists.length && !formData.check_list_id) {
      this.setState({
        formData: {
          ...formData,
          check_list_id: this.getCheckList(checkLists).id
        }
      });
    }

  };

  fetchCheckLists = () => {
    const {
      fetchCheckLists,
      selectedOrganizationId,
      selectedWorkshopId
    } = this.props;

    const params = {};

    if (selectedOrganizationId) {
      params.organization_id = selectedOrganizationId;
    } else if (selectedWorkshopId) {
      params.workshop_id = selectedWorkshopId;
    }

    fetchCheckLists(params);
  };

  getIsValid = () => {
    const { formData } = this.state;
    let isValid = true;
    formData.items.forEach(item => {
      if (!item.check_list_item || !item.last_check_date) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const { formData } = this.state;
    const {
      service,
      selectedWorkshopId,
      selectedOrganizationId,
      closeModal,
      toggleVehicleChecks,
    } = this.props;

    const newFormData = Object.assign({}, formData);

    newFormData.items = formData.items.filter((item) => item.check_list_id == formData.check_list_id)

    if (service === SERVICE_WORKSHOP && selectedWorkshopId) {
      newFormData.workshop = selectedWorkshopId;
    }
    else if (service === SERVICE_FLEET && selectedOrganizationId) {
      newFormData.organization = selectedOrganizationId;
    }

    toggleVehicleChecks(newFormData);

    closeModal();
  };

  getCheckList = (checkLists) => {
    return checkLists.find((cl) => cl.list_type == 'default');
  };

  getVehicleCheckListItem = (item) => {
    const { vehicleCheckListItems } = this.props;
    const selectedItem = vehicleCheckListItems.find((vItem) => vItem.check_list_item == item.id);
    return selectedItem;
  };

  getIsItemSelected = (item) => {
    const { formData } = this.state;

    return !!formData.items.find((fItem) => fItem.check_list_item == item.id && fItem.enabled)
  };

  getLastCheckDate = (item) => {
    const { formData } = this.state;
    const checkedItem = formData.items.find((fItem) => fItem.check_list_item == item.id)

    return checkedItem ? checkedItem.last_check_date : null;
  };

  itemToggled = (item) => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);

    if (this.getIsItemSelected(item)) {
      newFormData.items = formData.items.map((vItem) => {
        if (vItem.check_list_item != item.id) {
          return vItem;
        }
        return {
          ...vItem,
          enabled: false,
        };
      });
    } else {
      const vItem = formData.items.find((fItem) => fItem.check_list_item == item.id);
      if (vItem) {
        newFormData.items = formData.items.map((vItem) => {
          if (vItem.check_list_item != item.id) {
            return vItem;
          }
          return {
            ...vItem,
            enabled: true,
          };
        });
      } else {
        newFormData.items = storeInsertItem(formData.items, {check_list_item: item.id, check_list_id: formData.check_list_id, enabled: true})
      }
    }

    this.setState({
      formData: newFormData
    });

  };

  setLastCheckDate = (it, newDate) => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);

    const checkedItem = formData.items.find((fItem) => fItem.check_list_item == it.id)
    checkedItem.last_check_date = moment(newDate).format(dbDateFormat);
    newFormData.items = formData.items.map(item => {
      if (item.id !== checkedItem.check_list_item) {
        return item;
      }
      return {
        ...item,
        ...checkedItem,
      };
    });

    this.setState({
      formData: newFormData
    });
  }

  render() {
    const { formData } = this.state;
    const {
      modalIsOpen,
      closeModal,
      afterOpenModal,
      t,
      vehicle,
      checkLists,
      vehicleCheckListItems
    } = this.props;

    const checkList = this.getCheckList(checkLists);

    return (
      <CBMediaQuery>
        {screenSize => (
          <CBModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={closeModal}
            title='Tarkastettavat kohteet'
            descriptionText='Valitse ajastetut tehtävät ajoneuvolle. Ajoneuvolle luodaan tehtäviä ja lähetetään muistutuksia näiden valintojen perusteella. Tarkastuksia voi hallinnoida tarkemmin asetuksissa.'
            cancelText='Peruuta'
            submitText='Tallenna'
            submitForm={this.submitForm}
            isValid={this.getIsValid()}
            vehicleText={vehicle && vehicle.registration_number}
          >
            {
              checkList && vehicleCheckListItems && (
                <div style={getStyle(screenSize, styles, 'bodyContainer')}>
                  <table>
                    <thead>
                      <tr>
                        <th style={getStyle(screenSize, styles, 'tableHeading')}>
                        </th>
                        <th style={getStyle(screenSize, styles, 'tableHeading')}>
                          {t('checkNameLabel')}
                        </th>
                        <th style={getStyle(screenSize, styles, 'tableHeading')}>
                          {t('checkIntervalLabel')}
                        </th>
                        <th style={getStyle(screenSize, styles, 'tableHeading')}>
                          {t('lastCheckDate')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        checkList && checkList.items.map((item) => (
                          <tr key={item.id}>
                            <td style={getStyle(screenSize, styles, 'tableTd')}>
                              <CBLabelAndCheckbox
                                color='blue'
                                checked={this.getIsItemSelected(item)}
                                onChange={() => { this.itemToggled(item);}}
                                additionalStyles={{ marginBottom: 16 }}
                              />
                            </td>
                            <td style={getStyle(screenSize, styles, 'tableTd')}>
                              <p style={getStyle(screenSize, styles, 'text')}>
                                {item.name}
                              </p>
                            </td>
                            <td style={getStyle(screenSize, styles, 'tableTd')}>
                              <p style={getStyle(screenSize, styles, 'text')}>
                                {item.check_interval_months}
                              </p>
                            </td>
                            <td style={getStyle(screenSize, styles, 'tableTd')}>
                              <div style={getStyle(screenSize, styles, 'datePickerTopContainer')}>
                                <div className='datepicker-container-white-bg'>
                                  <CBDatePickerInput
                                    displayedDate={this.getLastCheckDate(item) ? moment(this.getLastCheckDate(item)) : null}
                                    type='date'
                                    dateFormat={finnishDateFormat}
                                    changeDate={(value) => {
                                      this.setLastCheckDate(item, value);
                                    }}
                                    disabled={!this.getIsItemSelected(item)}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                  {
                    !!formData.items.length && !this.getIsValid() && (
                      <p  style={getStyle(screenSize, styles, 'bottomText')}>Aseta viimeinen tarkastuspäivämäärä kaikille valituille tarkastuksille jatkaaksesi.</p>
                    )
                  }
                </div>
              )
            }
          </CBModal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 32px'
    },
    datePickerTopContainer: {
      maxWidth: 225,
    },
    checkListItemContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    text: {
      color: 'black',
      fontSize: 14,
    },
    bottomText: {
      marginTop: 16,
      color: 'black',
      fontSize: 14,
    },
    tableHeading: {
      fontFamily: 'TitilliumWeb-Regular',
      fontSize: 12,
      paddingLeft: 16,
      paddingBottom: 8,
    },
    tableTd: {
      //border: '1px solid rgba(0, 0, 0, .3)',
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  small: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 16px'
    },
  }
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    selectedOrganizationId: state.organization.selectedOrganization,
    checkLists: state.checkList.checkLists,
    vehicleCheckListItems: state.checkList.vehicleCheckListItems
  };
}

export default connect(
  mapStateToProps,
  {
    fetchCheckLists: _fetchCheckLists,
    fetchVehicleCheckListItems: _fetchVehicleCheckListItems,
    toggleVehicleChecks: _toggleVehicleChecks
  },
)(translate('CheckLists')(SelectCheckListItemsModal));
