import { vehicleCategoryState } from '../initialState';
import { storeInsertItem, storeRemoveItem, storeUpdateObjectInArray } from '../utils/utils';

export default function (state = vehicleCategoryState, action) {
  switch (action.type) {
    case 'FETCH_VEHICLE_CATEGORIES_REQUEST': {
      return {
        ...state,
        categoryStatus: Object.assign({}, state.categoryStatus, {
          loading: true,
          loadingFetch: true,
          error: null,
        }),
      };
    }
    case 'FETCH_VEHICLE_CATEGORIES_SUCCESS': {
      return {
        ...state,
        vehicleCategories: action.payload,
        categoryStatus: Object.assign({}, state.categoryStatus, {
          loading: false,
          loadingFetch: false,
          error: null,
        }),
      };
    }
    case 'FETCH_VEHICLE_CATEGORIES_FAIL': {
      return {
        ...state,
        vehicleCategories: [],
        categoryStatus: Object.assign({}, state.categoryStatus, {
          loading: false,
          loadingFetch: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_VEHICLE_CATEGORY_REQUEST': {
      return {
        ...state,
        categoryStatus: Object.assign({}, state.categoryStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_VEHICLE_CATEGORY_SUCCESS': {
      return {
        ...state,
        vehicleCategories: storeInsertItem(state.vehicleCategories, action.payload),
        categoryStatus: Object.assign({}, state.categoryStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_VEHICLE_CATEGORY_FAIL': {
      return {
        ...state,
        categoryStatus: Object.assign({}, state.categoryStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_VEHICLE_CATEGORY_REQUEST': {
      return {
        ...state,
        categoryStatus: Object.assign({}, state.categoryStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_VEHICLE_CATEGORY_SUCCESS': {
      return {
        ...state,
        vehicleCategories: storeUpdateObjectInArray(state.vehicleCategories, action.payload),
        categoryStatus: Object.assign({}, state.categoryStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_VEHICLE_CATEGORY_FAIL': {
      return {
        ...state,
        categoryStatus: Object.assign({}, state.categoryStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'DELETE_VEHICLE_CATEGORY_REQUEST': {
      return {
        ...state,
        categoryStatus: Object.assign({}, state.categoryStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DELETE_VEHICLE_CATEGORY_SUCCESS': {
      return {
        ...state,
        vehicleCategories: storeRemoveItem(state.vehicleCategories, action.payload.id),
        categoryStatus: Object.assign({}, state.categoryStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DELETE_VEHICLE_CATEGORY_FAIL': {
      return {
        ...state,
        categoryStatus: Object.assign({}, state.categoryStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    default:
      break;
  }
  return state;
}
