import React from 'react';

class CBSeparator extends React.Component {
  render() {
    const { label, showIcon } = this.props;
    return (
      <div style={styles.vehicleInfoPieceContainer}>
        {
          showIcon && (
            <img
              style={styles.buttonIcon}
              alt='remove'
              src='/img/separator.svg'
            />
          )
        }
        <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
          {label}
        </p>
      </div>
    );
  }
}

const styles = {
  vehicleInfoPieceContainer: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },
  buttonIcon: {
    // height: 24,
    width: 40,
    marginLeft: 3,
    transform: 'rotate(90deg)',
  },
  infoLabel: {
    color: '#FFF',
    fontSize: 14,
    fontWeight: 600,
    marginRight: 8,
  },
};

export default CBSeparator;
