import React from 'react';
import { translate } from 'react-i18next';
import CBInputField from '../../shared/form_components/CBInputField';
import CBSubmitButton from '../../shared/form_components/CBSubmitButton';
import CBSpinnerFloating from '../../shared/CBSpinnerFloating';
import {
  getEmailValidator,
  getLengthValidator,
  getNumberValidator,
} from '../../../utils/validators';
import CBHeaderTextWithHelp from '../../shared/CBHeaderTextWithHelp';
import GarageForm from './GarageForm';
import CBButtonWithIconSmall from '../../shared/CBButtonWithIconSmall';
import ConfirmOrganizationModal from '../../OrganizationGarageView/ConfirmOrganizationModal';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';
import { SERVICE_ADMIN } from '../../../utils/utils';
import CBDatePicker from '../../shared/CBDatePicker';
import CBButtonSettings from '../../shared/CBButtonSettings';
import moment from 'moment';

class OrganizationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { formData: Object.assign({}, props.organization), formValid: {}, confirmModalIsOpen: false };
  }

  componentWillReceiveProps(nextProps) {
    const { organization } = this.props;

    //if (organization.id !== nextProps.organization.id) {
    this.setState({ formData: Object.assign({}, nextProps.organization), formValid: {} });
    //}
  }

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
    };
    newFormValid[name] = isValid;

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;

    // If user is trialing, set paid_subscription to true and trial_end to 30 days from now
    // Also trialing customers are considered paid customers. Only care service customers are not paid customers
    // As workshops pays for their usage

    if (changedObject.target === 'is_trialing' && changedObject.value) {
      if (!newFormData.paid_subscription) {
        newFormData.paid_subscription = true;
      }

      if (!newFormData.trial_end) {
        newFormData.trial_end = moment().add(30, 'days');
      }
    }

    if (changedObject.target === 'paid_subscription' && !changedObject.value && newFormData.is_trialing) {
      newFormData.is_trialing = false;
    }

    this.setState({ formData: newFormData });
  };

  updateTrialEndDate = (date) => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData['trial_end'] = date;
    this.setState({ formData: newFormData });
  }

  toggleFormStateBoolean = property => {
    this.setState(prevState => {
      const { formData } = prevState;
      const newFormData = Object.assign({}, formData);
      newFormData[property] = !newFormData[property];
      return { formData: newFormData };
    });
  };

  onGarageChanged = (garage) => {
    const { formData } = this.state;
    const garages = [...formData.garages];
    const index = garages.findIndex((g) => g.id === garage.id);
    if (garage.delete) {
      if (index > -1) {
        garages.splice(index, 1);
      }
    } else {
      garages[index] = garage;
    }
    const newFormData = Object.assign({}, formData);
    newFormData.garages = garages;
    this.setState({ formData: newFormData });
  };

  onGarageRemoved = (garage) => {
    const { formData } = this.state;
    const garages = [...formData.garages];
    const index = garages.findIndex((g) => g.id === garage.id);
    garages[index] = garage;
    const newFormData = Object.assign({}, formData);
    newFormData.garages = garages;
    this.setState({ formData: newFormData });
  };

  showConfirmModal = () => {
    this.setState({
      confirmModalIsOpen: true,
    });
  };

  closeConfirmModal = () => {
    this.setState({
      confirmModalIsOpen: false,
    });
  };

  addGarage = () => {
    const { formData } = this.state;
    const garages = [...formData.garages];
    const newGarages = garages.filter((garage) => {
      return typeof garage.id === 'string' && garage.id.includes('new-');
    });

    garages.push({
      id: `new-${newGarages.length + 1}`,
      name: '',
      vehicle_count: 0,
    });
    const newFormData = Object.assign({}, formData);
    newFormData.garages = garages;
    this.setState({ formData: newFormData });
  }

  submitForm = () => {
    const { formData } = this.state;
    const {
      createOrganization,
      updateOrganization,
      organization,
      newOrganizationCreated,
    } = this.props;
    const newFormData = Object.assign({}, formData);
    newFormData.trial_end = newFormData.trial_end ? moment(newFormData.trial_end).format('YYYY-MM-DD') : null;

    const garages = [...formData.garages];

    const newGarages = garages.map((garage) => {
      const newGarage = Object.assign({}, garage);
      if (typeof newGarage.id === 'string' && newGarage.id.includes('new-')) {
        delete newGarage.id;
      }
      return newGarage;
    });

    newFormData.garages = newGarages;

    if (organization.id) {
      updateOrganization(organization.id, newFormData);
    } else {
      createOrganization(newFormData);
      newOrganizationCreated();
    }
  };

  render() {
    const { t, organizationRequest, organization, user, service } = this.props;
    const { formData, confirmModalIsOpen } = this.state;
    const trialEnd = formData.trial_end ? moment(formData.trial_end) : null;

    return (
      <div style={styles.formContainer}>
        {organizationRequest.organizationStatus.loading && <CBSpinnerFloating />}
        <div>
          <CBInputField
            labelText={t('organizationNameLabel')}
            name='name'
            onChange={this.updateFormState}
            value={formData.name}
            serverErrors={organizationRequest.organizationStatus.error}
            isValidCallback={this.setIsValid}
            validators={[getLengthValidator(3, 255)]}
            labelTextStyle={styles.fieldContainerLeftAlign}
          />
          <CBInputField
            disabled={!user.is_staff}
            labelText={t('businessIdLabel')}
            name='business_id'
            onChange={this.updateFormState}
            value={formData.business_id}
            serverErrors={organizationRequest.organizationStatus.error}
            isValidCallback={this.setIsValid}
            validators={[getLengthValidator(9, 9)]}
            labelTextStyle={styles.fieldContainerLeftAlign}
          />
          {
            user.is_staff && service == SERVICE_ADMIN && (
              <React.Fragment>
                <CBInputField
                  labelText={t('vehicleDiscountPercentLabel')}
                  name='vehicle_discount_percent'
                  onChange={this.updateFormState}
                  type='number'
                  value={formData.vehicle_discount_percent}
                  serverErrors={organizationRequest.organizationStatus.error}
                  isValidCallback={this.setIsValid}
                  validators={[getNumberValidator()]}
                  labelTextStyle={styles.fieldContainerLeftAlign}
                />
                <CBInputField
                  labelText={t('reportDiscountPercentLabel')}
                  name='report_discount_percent'
                  onChange={this.updateFormState}
                  type='number'
                  value={formData.report_discount_percent}
                  serverErrors={organizationRequest.organizationStatus.error}
                  isValidCallback={this.setIsValid}
                  validators={[getNumberValidator()]}
                  labelTextStyle={styles.fieldContainerLeftAlign}
                />
                <CBLabelAndCheckbox
                  checked={formData.paid_subscription}
                  labelText={t('isPaidCustomer')}
                  color='white'
                  onChange={() => this.updateFormState({ target: 'paid_subscription', value: !formData.paid_subscription })}
                />
                <CBLabelAndCheckbox
                  checked={formData.is_trialing}
                  labelText={t('isTrialing')}
                  color='white'
                  onChange={() => {
                    this.updateFormState({ target: 'is_trialing', value: !formData.is_trialing });
                  }}
                />

                {
                  formData.is_trialing && (
                    <div>
                      <p className='font-titillium-web-semi-bold' style={styles.fieldContainerLeftAlign}>
                        {t('trialEnds')}
                      </p>

                      <div style={styles.datepickerContainer}>
                        <CBDatePicker
                          date={trialEnd}
                          changeDate={this.updateTrialEndDate}
                          containerStyle={styles.datePickerInnerContainer}
                        />
                        <div style={styles.clearButtonContainer}>
                          <CBButtonSettings text={t('clear')} onClick={() => { this.updateTrialEndDate(null); }} />
                        </div>
                      </div>
                    </div>
                  )
                }

                <div style={styles.featureContainer}>
                  <p className='font-titillium-web-semi-bold' style={styles.confirmText}>{t('enabledFeatures')}</p>
                  <CBLabelAndCheckbox
                    checked={formData.feature_vehicle_reports_enabled}
                    labelText={t('featureVehicleReports')}
                    color='white'featureVehicleReports
                    onChange={() => {
                      const newVal = !formData.feature_vehicle_reports_enabled;
                      const newFormData = Object.assign({}, formData);
                      newFormData['feature_vehicle_reports_enabled'] = newVal;

                      if (!newVal && formData.feature_vehicle_reports_pdf_enabled) {
                        newFormData['feature_vehicle_reports_pdf_enabled'] = false;
                      }

                      this.setState({ formData: newFormData });
                    }}
                  />
                  <div>
                    <CBLabelAndCheckbox
                      checked={formData.feature_vehicle_reports_pdf_enabled}
                      labelText={t('featurePdfVehicleReports')}
                      color='white'
                      onChange={() => this.updateFormState({ target: 'feature_vehicle_reports_pdf_enabled', value: !formData.feature_vehicle_reports_pdf_enabled })}
                    />
                  </div>

                  <CBLabelAndCheckbox
                    checked={formData.feature_tasks_and_messages_enabled}
                    labelText={t('featureTasksAndMessages')}
                    color='white'
                    onChange={() => this.updateFormState({ target: 'feature_tasks_and_messages_enabled', value: !formData.feature_tasks_and_messages_enabled })}
                  />
                </div>
              </React.Fragment>
            )
          }
          {
            !formData.id && (
              <CBInputField
                labelText={t('mainUserEmailLabel')}
                name='main_user_email'
                onChange={this.updateFormState}
                value={formData.main_user_email}
                serverErrors={organizationRequest.organizationStatus.error}
                isValidCallback={this.setIsValid}
                validators={[getEmailValidator()]}
                labelTextStyle={styles.fieldContainerLeftAlign}
              />
            )
          }
        </div>
        {
          organization.id && organization.is_confirmed && (
            <React.Fragment>
              <div style={styles.confirmationContainer}>
                <p className='font-titillium-web-semi-bold' style={styles.confirmText}>{t('confirmedFromPRH')}</p>
                <img alt='Confirmed' style={styles.confirmIcon} src='/img/round_check_icon_green.svg' />
              </div>
            </React.Fragment>
          )
        }
        {
          organization.id && !organization.is_confirmed && (
            <React.Fragment>
              <div style={styles.confirmationContainer}>
                <p className='font-titillium-web-semi-bold' style={styles.confirmText}>{t('confirmedFromPRH')}</p>
                <img alt='Unconfirmed' style={styles.confirmIcon} src='/img/round_x_icon_red.svg' />
              </div>
              <p style={styles.confirmDescriptionText}>{t('notConfirmedText')}</p>
              <div>
                <CBButtonWithIconSmall
                  icon='/img/icon_checkmark.svg'
                  text={t('confirmNow')}
                  onClick={this.showConfirmModal}
                />
              </div>
            </React.Fragment>
          )
        }
        <div style={styles.garageContainer}>
          <CBHeaderTextWithHelp text={t('organizationGaragesHeading')} />
          <div style={styles.garageListContainer}>
            {
              formData.garages.map(
                (garage) => (
                  <GarageForm
                    key={garage.id}
                    onChange={this.onGarageChanged}
                    isSubForm
                    garage={garage}
                  />
                ),
              )
            }
          </div>
          <div>
            <CBButtonWithIconSmall
              icon='/img/icon_plus.svg'
              text={t('addNewGarage')}
              onClick={this.addGarage}
            />
          </div>
        </div>
        <CBSubmitButton
          disabled={organizationRequest.organizationStatus.loading || !this.getIsValid()}
          onClick={this.submitForm}
        />
        <ConfirmOrganizationModal
          modalIsOpen={confirmModalIsOpen}
          closeModal={this.closeConfirmModal}
          organizationId={organization.id}
        />
      </div>
    );
  }
}

const styles = {
  formContainer: {
    position: 'relative',
  },
  arrowIcon: {
    margin: '0px 8px',
    width: 32,
    height: 32,
    fill: '#ffffff',
  },
  confirmIcon: {
    width: 24,
    height: 24,
  },
  confirmText: {
    fontSize: 14,
  },
  confirmDescriptionText: {
    fontSize: 12,
  },
  confirmationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 16,
  },
  datePickerInnerContainer: {
    // marginRight: 16,
    width: 150,
  },
  datepickerContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    marginTop: 24,
  },
  clearButtonContainer: {
    marginLeft: 8,
    display: 'inline-block',
  },
  fieldContainerLeftAlign: {
    marginBottom: 5,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
  garageContainer: {
    margin: '16px 0 16px 0',
  },
  garageListContainer: {
    margin: '16px 0 16px 0',
  },
  featureContainer: {
    margin: '16px 0 16px 0',
  },
};

export default translate('SettingsView')(OrganizationForm);
