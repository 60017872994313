import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import CBMediaQuery from '../shared/CBMediaQuery';
import SubHeaderBar from '../shared/SubHeaderBar';
import CBButtonLargeGreen from '../shared/CBButtonLargeGreen';
import AnnouncementList from './AnnouncementViewComponents/AnnouncementList';
import { getStyle } from '../../utils/utils';
import { fetchAnnouncements as _fetchAnnouncements } from '../../actions/announcementActions';
import AddAnnouncementModal from './AnnouncementViewComponents/AddAnnouncementModal';

class AnnouncementView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAnnouncementId: null,
      modalIsOpen: false,
      modalMode: 'create',
    };
  }
  componentDidMount = () => {
    const { fetchAnnouncements, selectedWorkshopId } = this.props;
    fetchAnnouncements(selectedWorkshopId);
  };

  componentDidUpdate(prevProps) {
    const { selectedWorkshopId, fetchAnnouncements } = this.props;

    if (selectedWorkshopId != prevProps.selectedWorkshopId) {
      fetchAnnouncements(selectedWorkshopId);
      this.setState({
        modalAnnouncementId: null,
        modalIsOpen: false,
        modalMode: 'create',
      });
    }
  };

  openModal = (modalAnnouncementId = null, mode = 'create') => {
    this.setState({ modalAnnouncementId, modalIsOpen: true, modalMode: mode });
  };

  closeModal = () => this.setState({ modalIsOpen: false });

  render() {
    const { t, announcements } = this.props;
    const { modalIsOpen, modalMode, modalAnnouncementId } = this.state;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'mainContainer')}>
            <div style={getStyle(screenSize, styles, 'subHeaderContainer')}>
              <SubHeaderBar label={t('header')} height={65} />
            </div>
            <div style={getStyle(screenSize, styles, 'addAnnouncementButton')}>
              <CBButtonLargeGreen
                onClick={this.openModal}
                label={t('addAnnouncementButtonLabel')}
              />
            </div>
            <AnnouncementList
              announcements={announcements}
              openModal={this.openModal}
            />
            <AddAnnouncementModal
              announcement={
                modalAnnouncementId &&
                announcements.find(a => a.id === modalAnnouncementId)
              }
              modalIsOpen={modalIsOpen}
              closeModal={this.closeModal}
              mode={modalMode}
            />
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    subHeaderContainer: {
      position: 'fixed',
      top: 50,
      width: '100%',
      left: 0,
      boxShadow: '0px 1px 10px rgba(0,0,0,0.5)',
      paddingLeft: 175,
      zIndex: 2,
    },
    mainContainer: {
      paddingTop: 65,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    addAnnouncementButton: {
      margin: '30px 0',
    },
  },
  medium: {
    subHeaderContainer: {
      paddingLeft: 15,
    },
    addAnnouncementButton: {
      margin: '60px 15px',
    },
  },
  small: {
    subHeaderContainer: {
      paddingLeft: 10,
    },
  },
};

function mapStateToProps(state) {
  return {
    announcements: state.announcement.announcements,
    user: state.authUser.user,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchAnnouncements: _fetchAnnouncements,
  },
)(translate('AnnouncementView')(AnnouncementView));
