import React from 'react';
import { getStyle } from '../../utils/utils';

class CBButtonLink extends React.Component {
  render() {
    const { onClick, text } = this.props;
    return (
      <button className='hover-underline' type='button' onClick={onClick} style={styles.button}>
        { text }
      </button>
    );
  }
}

export default CBButtonLink;

const styles = {
  button: {
    cursor: 'pointer',
    borderColor: 'transparent',
    WebkitBorderRadius: 0,
    MozBorderRadius: 0,
    borderRadius: 0,
    backgroundColor: 'transparent',
    backgroundImage: 'none',
    WebkitBoxShadow: 'none',
    MozBoxShadow: 'none',
    boxShadow: 'none',
    padding: 0,
    border: 0,
    color: '#0095da',
  },
};
