import React, { Component } from 'react';
import { styleMerge, getStyle } from '../../utils/utils';
import CBMediaQuery from './CBMediaQuery';
import CBDatePickerInput from './CBDatePickerInput';
import './datepicker.css';


class CBDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const { value } = this.state;
    const {
      containerStyle,
      date,
      changeDate,
      isClearable,
    } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div
            className='date-picker'
            style={styleMerge(
              getStyle(screenSize, styles, 'box'),
              containerStyle || {},
            )}
          >
            <img
              style={getStyle(screenSize, styles, 'buttonIcon')}
              alt='calendar'
              src='/img/calendar.svg'
            />
            <CBDatePickerInput
              displayedDate={date}
              type='date'
              changeDate={changeDate}
            />
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    box: {
      display: 'flex',
      height: 40,
      opacity: 0.8,
      border: '1px solid white',
      borderRadius: 50,
      alignItems: 'center',
      marginBottom: 0,
      width: '100%',
    },
    buttonIcon: {
      height: 24,
      width: 24,
      marginLeft: 16,
      marginRight: 16,
    },
    input: {
      backgroundColor: 'transparent',
      height: 40,
      fontSize: 14,
      textAlign: 'center',
      width: '100%',
      resize: 'none',
      color: 'white',
      borderStyle: 'none',
    },
  },
  medium: {
    box: {
      maxWidth: '90%',
    },
  },
};

export default CBDatePicker;
