import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getStyle } from '../../../utils/utils';
import {
  fetchInvisibilityPeriods as _fetchInvisibilityPeriods,
  deleteInvisibilityPeriod as _deleteInvisibilityPeriod,
} from '../../../actions/invisibilityPeriodActions';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBButtonWithIconSmall from '../../shared/CBButtonWithIconSmall';
import InvisibilityPeriodModal from './InvisibilityPeriodModal';
import CBPromptModal from '../../shared/CBPromptModal';
import CBButtonLargeGreen from '../../shared/CBButtonLargeGreen';

class WSSettingsInvisibility extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      deleteModalIsOpen: false,
      invisibilityPeriod: null,
    };
  }

  componentWillMount() {
    const { fetchInvisibilityPeriods, selectedWorkshopId } = this.props;
    fetchInvisibilityPeriods(selectedWorkshopId);
  }

  componentDidUpdate(prevProps) {
    const { selectedWorkshopId, fetchInvisibilityPeriods } = this.props;

    if (selectedWorkshopId != prevProps.selectedWorkshopId) {
      this.setState({
        modalIsOpen: false,
        deleteModalIsOpen: false,
        invisibilityPeriod: null,
      });
      fetchInvisibilityPeriods(selectedWorkshopId);
    }
  };

  openCreateModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  }

  openEditModal = (invisibilityPeriod) => {
    this.setState({
      modalIsOpen: true,
      invisibilityPeriod,
    });
  }

  openDeleteModal = (invisibilityPeriod) => {
    this.setState({
      deleteModalIsOpen: true,
      invisibilityPeriod,
    });
  }

  performDelete = (invisibilityPeriodId) => {
    const { user, deleteInvisibilityPeriod, selectedWorkshopId } = this.props;
    deleteInvisibilityPeriod(invisibilityPeriodId, selectedWorkshopId);
    this.setState({ deleteModalIsOpen: false, invisibilityPeriod: null });
  }

  render() {
    const {
      invisibilityPeriods, t,
    } = this.props;
    const { modalIsOpen, deleteModalIsOpen, invisibilityPeriod } = this.state;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <p style={getStyle(screenSize, styles, 'title')}>
              {t('invisibilitySettingsTitle')}
            </p>
            <div style={getStyle(screenSize, styles, 'infoRow')}>
              <p style={getStyle(screenSize, styles, 'infoText')}>
                {t('invisibilityInstructionText')}
              </p>
              <div style={getStyle(screenSize, styles, 'addButton')}>
                <CBButtonLargeGreen
                  label={t('addInvisibilityPeriodButtonLabel')}
                  onClick={this.openCreateModal}
                />
              </div>
            </div>
            {invisibilityPeriods.map((period) => (
              <div key={period.id} style={getStyle(screenSize, styles, 'periodContainer')}>
                <div style={getStyle(screenSize, styles, 'platformNumber')}>
                  <p
                    className='font-titillium-web-semi-bold'
                    style={getStyle(screenSize, styles, 'labelText')}
                  >
                    {t('platformNumberLabel')}
                  </p>
                  <p style={getStyle(screenSize, styles, 'valueText')}>
                    {period.platform_number}
                  </p>
                </div>
                <div style={getStyle(screenSize, styles, 'date')}>
                  <p
                    className='font-titillium-web-semi-bold'
                    style={getStyle(screenSize, styles, 'labelText')}
                  >
                    {t('dateBeginLabel')}
                  </p>
                  <p style={getStyle(screenSize, styles, 'valueText')}>
                    {new Date(period.begin).toLocaleDateString('fi-FI')}
                  </p>
                </div>
                <div style={getStyle(screenSize, styles, 'date')}>
                  <p
                    className='font-titillium-web-semi-bold'
                    style={getStyle(screenSize, styles, 'labelText')}
                  >
                    {t('dateEndLabel')}
                  </p>
                  <p style={getStyle(screenSize, styles, 'valueText')}>
                    {new Date(period.end).toLocaleDateString('fi-FI')}
                  </p>
                </div>
                <CBButtonWithIconSmall icon='/img/edit.svg' text={t('edit')} onClick={() => this.openEditModal(period)} />
                <CBButtonWithIconSmall
                  icon='/img/remove.svg'
                  text={t('remove')}
                  onClick={() => this.openDeleteModal(period)}
                />
              </div>
            ))}
            <InvisibilityPeriodModal
              modalIsOpen={modalIsOpen}
              closeModal={() => this.setState({ modalIsOpen: false, invisibilityPeriod: null })}
              invisibilityPeriod={invisibilityPeriod}
            />
            <CBPromptModal
              modalIsOpen={deleteModalIsOpen}
              closeModal={() => this.setState({ deleteModalIsOpen: false })}
              performAction={() => this.performDelete(invisibilityPeriod.id)}
              title={t('deleteInvisibilityPeriodPromptTitle')}
              text={t('deleteInvisibilityPeriodPromptText')}
              buttonText={t('remove')}
            />
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: 40,
    },
    infoRow: {
      display: 'flex',
      marginBottom: 20,
    },
    title: {
      fontFamily: 'TitilliumWeb-ExtraLight',
      fontSize: 22,
    },
    infoText: {
      flex: 1,
      fontSize: 12,
    },
    addButton: {
      flex: 1,
    },
    periodContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: 8,
    },
    platformNumber: {
      flex: 'auto',
      flexBasis: '20%',
      marginRight: 8,
    },
    date: {
      flex: 'auto',
      flexBasis: '20%',
      marginRight: 8,
    },
    labelText: {
      fontWeight: 400,
    },
    valueText: {
      borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
      marginBottom: 0,
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    invisibilityPeriods: state.invisibilityPeriod.invisibilityPeriods,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchInvisibilityPeriods: _fetchInvisibilityPeriods,
    deleteInvisibilityPeriod: _deleteInvisibilityPeriod,
  },
)(translate('WSSettingsView')(WSSettingsInvisibility));
