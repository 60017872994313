import React, { Component } from 'react';
import { getStyle } from '../../../../utils/utils';
import CBMediaQuery from '../../../shared/CBMediaQuery';
import OptionsListItem from './OptionsListItem';

export default class OptionsList extends Component {
  render() {
    const {
      displayOptions, options, onMouseLeave, type,
    } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <React.Fragment>
            {
              displayOptions && screenSize !== 'small' && (
              <div onMouseLeave={onMouseLeave} style={getStyle(screenSize, styles, 'mouseAreaWrapper')}>
                <div style={getStyle(screenSize, styles, 'container')}>
                  {options.map(item => (
                    <OptionsListItem key={item.title} item={item} />
                  ))}
                </div>
              </div>
              )}
            {
              displayOptions && screenSize === 'small' && (
              <div style={getStyle(screenSize, styles, 'mobileContainer')}>
                {options.map(item => (
                  <OptionsListItem key={item.title} item={item} />
                ))}
              </div>
              )}
          </React.Fragment>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      backgroundColor: 'white',
      width: 150,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 4,
      zIndex: 1,
    },
    mouseAreaWrapper: {
      backgroundColor: 'transparent',
      display: 'flex',
      position: 'relative',
      left: -175,
      top: -100,
      width: 250,
      height: 250,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1,
    },
  },
  small: {
    container: {
      backgroundColor: 'white',
      width: 150,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      top: -200,
      left: -100,
      borderRadius: 4,
      zIndex: 1,
    },
    mobileContainer: {
      backgroundColor: 'white',
      width: 150,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      top: -26,
      left: -150,
      borderRadius: 4,
      zIndex: 1,
    },
  },
};
