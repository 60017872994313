import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import SubHeaderBar from '../shared/SubHeaderBar';
import WSSettingsProfile from './WSSettingsComponents/WSSettingsProfile';
import WSSettingsInvisibility from './WSSettingsComponents/WSSettingsInvisibility';
import WSSettingsMenu from './WSSettingsComponents/WSSettingsMenu';
import CBMediaQuery from '../shared/CBMediaQuery';
import { getStyle } from '../../utils/utils';
import InstructionsView from './WSSettingsComponents/InstructionsView';
import TermsOfServiceView from './WSSettingsComponents/TermsOfServiceView';
import SettingsWorkshopPermissions from '../SettingsView/SettingsViewComponents/SettingsWorkshopPermissions';
import SettingsCareServices from '../SettingsView/SettingsViewComponents/SettingsCareServices';
import { fetchWorkshops as _fetchWorkshops } from '../../actions/workshopActions';
import SettingsCheckLists from '../SettingsView/SettingsViewComponents/SettingsCheckLists';
import UserSettingsProfile from '../SettingsView/SettingsViewComponents/UserSettingsProfile';
import SettingsOrganizationVehicleReminders from '../SettingsView/SettingsViewComponents/SettingsOrganizationVehicleReminders';

class WSSettingsView extends React.Component {
  componentWillMount() {
    const { fetchWorkshops } = this.props;
    fetchWorkshops();
    document.documentElement.classList.add('settings-bg');
  }

  componentWillUnmount() {
    document.documentElement.classList.remove('settings-bg');
  }

  render() {
    const { history, t } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'settingsViewContainer')}>
            <div
              style={getStyle(screenSize, styles, 'settingsViewInnerContainer')}
            >
              <div style={getStyle(screenSize, styles, 'headerContainer')}>
                <SubHeaderBar
                  goBack={history.goBack}
                  label={t('settingsHeading')}
                  rightBlock={<React.Fragment />}
                  height='auto'
                />
              </div>

              <div
                style={getStyle(screenSize, styles, 'menuAndContentContainer')}
              >
                <div style={getStyle(screenSize, styles, 'menuContainer')}>
                  <WSSettingsMenu />
                </div>
                <div style={getStyle(screenSize, styles, 'contentContainer')}>
                  <Route
                    exact
                    path='/workshop-settings/'
                    component={UserSettingsProfile}
                  />
                  <Route
                    exact
                    path='/workshop-settings/profile'
                    component={UserSettingsProfile}
                  />
                  <Route
                    exact
                    path='/workshop-settings/workshop'
                    component={WSSettingsProfile}
                  />
                  <Route
                    exact
                    path='/workshop-settings/invisibility'
                    component={WSSettingsInvisibility}
                  />
                  <Route
                    exact
                    path='/workshop-settings/permissions'
                    component={SettingsWorkshopPermissions}
                  />
                  <Route
                    exact
                    path='/workshop-settings/care_services'
                    component={SettingsCareServices}
                  />
                  <Route
                    exact
                    path='/workshop-settings/check-lists'
                    component={SettingsCheckLists}
                  />
                  <Route
                    exact
                    path='/workshop-settings/org-vehicle-reminders'
                    component={SettingsOrganizationVehicleReminders}
                  />
                  <Route
                    exact
                    path='/workshop-settings/instructions'
                    component={InstructionsView}
                  />
                  <Route
                    exact
                    path='/workshop-settings/terms-and-conditions'
                    component={TermsOfServiceView}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    settingsViewContainer: {
      backgroundColor: '#0095da',
      position: 'absolute',
      left: 0,
      top: 50,
      bottom: 0,
      right: 0,
      paddingLeft: 175,
      paddingRight: 175,
      height: '100%',
      width: '100%',
    },
    headerContainer: {
      paddingLeft: 16,
    },
    settingsViewInnerContainer: {
      backgroundColor: '#0095da',
      position: 'relative',
      height: '100%',
      width: '100%',
      borderTop: '1px solid rgba(255, 255, 255, 0.4)',
    },
    menuAndContentContainer: {
      backgroundColor: '#0095da',
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid rgba(255, 255, 255, 0.4)',
    },
    menuContainer: {
      backgroundColor: '#0095da',
      borderRight: '1px solid rgba(255, 255, 255, 0.4)',
      width: '20%',
    },
    contentContainer: {
      backgroundColor: '#0095da',
      width: '80%',
    },
  },
  medium: {
    settingsViewContainer: {
      backgroundColor: '#0095da',
      position: 'absolute',
      left: 0,
      top: 50,
      bottom: 0,
      right: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
    menuAndContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 20,
    },
    menuContainer: {
      borderRight: 0,
      width: '100%',
      flexDirection: 'column',
    },
    contentContainer: {
      width: '100%',
      paddingLeft: 16,
      paddingRight: 16,
      flexDirection: 'column',
    },
    headerContainer: {
      height: 70,
    },
  },
  small: {
    headerContainer: {
      height: 'auto',
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    workshops: state.workshop.workshops,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchWorkshops: _fetchWorkshops,
  }
)(translate('SettingsView')(WSSettingsView));
