import React from 'react';
import { translate } from 'react-i18next';
import { getStyle, styleMerge } from '../../../utils/utils';
import CBDropzone from '../../shared/CBDropzone';
import CBMediaQuery from '../../shared/CBMediaQuery';


class MessageEditRow extends React.Component {
  state = {
  }

  render() {
    const {
      t,
      removingImages,
      toggleImageRemoveMode,
      editMessage,
      openDeleteMessageModal,
      onImageUpload,
      message,
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div className='font-titillium-web' style={getStyle(screenSize, styles, 'boxContainer')}>
            <div className='font-titillium-web' style={getStyle(screenSize, styles, 'boxLeftContainer')}>

            </div>
            <div className='font-titillium-web' style={getStyle(screenSize, styles, 'boxRightContainer')}>
              <div
                className={removingImages ? '' : 'opacity-and-border-on-hover'}
                style={removingImages
                  ? styleMerge(
                    getStyle(screenSize, styles, 'buttonLeft'),
                    getStyle(screenSize, styles, 'buttonDisabled'),
                  )
                  : getStyle(screenSize, styles, 'buttonLeft')
                }
                onClick={removingImages ? undefined : editMessage}
              >
                <img
                  style={getStyle(screenSize, styles, 'buttonIcon')}
                  alt='edit'
                  src='/img/edit.svg'
                />
                <div style={getStyle(screenSize, styles, 'buttonText')}>
                  {`${t('edit')} `}
                </div>
              </div>
              <div
                className={removingImages ? '' : 'opacity-and-border-on-hover'}
                style={removingImages
                  ? styleMerge(
                    getStyle(screenSize, styles, 'button'),
                    getStyle(screenSize, styles, 'buttonDisabled'),
                  )
                  : getStyle(screenSize, styles, 'button')
                }
                onClick={removingImages ? undefined : openDeleteMessageModal}
              >
                <img
                  style={getStyle(screenSize, styles, 'buttonIcon')}
                  alt='remove'
                  src='/img/remove.svg'
                />
                <div style={getStyle(screenSize, styles, 'buttonText')}>
                  {`${t('remove')} `}
                </div>
              </div>
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    boxContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    boxLeftContainer: {
      display: 'flex',
    },
    boxRightContainer: {
      display: 'flex',
    },
    wideButton: {
      width: 130,
      display: 'flex',
      height: 30,
      border: '1px solid rgba(255, 255, 255, 0.7)',
      borderRadius: 50,
      alignItems: 'center',
      paddingLeft: 5,
      cursor: 'pointer',
    },
    buttonDisabled: {
      opacity: 0.5,
      cursor: 'default',
    },
    buttonIcon: {
      height: 24,
      width: 24,
      marginLeft: 3,
    },
    buttonGreen: {
      backgroundColor: '#00F600',
      border: '0 solid white',
    },
    button: {
      width: 115,
      display: 'flex',
      height: 30,
      border: '1px solid rgba(255, 255, 255, 0.7)',
      borderRadius: 50,
      alignItems: 'center',
      paddingLeft: 5,
      cursor: 'pointer',
    },
    buttonText: {
      color: 'white',
      fontSize: 14,
      textDecoration: 'none solid rgb(255, 255, 255)',
      marginLeft: 10,
    },
    buttonLeft: {
      width: 115,
      display: 'flex',
      height: 30,
      border: '1px solid rgba(255, 255, 255, 0.7)',
      borderRadius: 50,
      alignItems: 'center',
      paddingLeft: 5,
      marginRight: 10,
      cursor: 'pointer',
    },
    buttonRight: {
      width: 130,
      display: 'flex',
      height: 30,
      border: '1px solid rgba(255, 255, 255, 0.7)',
      borderRadius: 50,
      alignItems: 'center',
      paddingLeft: 5,
      marginLeft: 10,
      cursor: 'pointer',
    },
  },
};

export default translate('VehicleDetailsView')(MessageEditRow);
