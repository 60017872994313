import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { ProgressSpinner } from 'primereact/progressspinner';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBButtonSettingsWhite from '../../shared/CBButtonSettingsWhite';
import CBSearchInput from '../../shared/CBSearchInput';
import GarageListItem from './GarageListItem';
import { fetchGarages as _fetchGarages } from '../../../actions/garageActions';
import CBHeaderTextWithHelp from '../../shared/CBHeaderTextWithHelp';

class StaffSettingsGarages extends React.Component {
  state = {
    pageNumber: 1,
    search: '',
    selectedGarage: undefined,
  };

  componentDidMount = () => {
    const { fetchGarages } = this.props;
    const { pageNumber } = this.state;

    fetchGarages(pageNumber);
  };

  selectGarage = (garage) => {
    this.setState({ selectedGarage: Object.assign({}, garage) });
  };

  changeSearchTerms = value => {
    this.setState({
      search: value,
    });
  }

  fetchMore = () => {
    const { fetchGarages } = this.props;
    const { pageNumber, search } = this.state;
    const newPageNumber = pageNumber + 1;

    this.setState({
      pageNumber: newPageNumber,
    });

    fetchGarages(newPageNumber, search);
  }

  fetchWithSearch = () => {
    const { fetchGarages } = this.props;
    const { search } = this.state;
    const newPageNumber = 1;
    this.setState({
      pageNumber: newPageNumber,
    });

    fetchGarages(newPageNumber, search);
  }

  render() {
    const {
      t, garages, loading, totalGarages, garageRequest,
    } = this.props;

    const {
      selectedGarage,
    } = this.state;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'editGarageContainer')}>
              <CBHeaderTextWithHelp text={t('staffEditGarageHeading')} />
            </div>
            <div style={getStyle(screenSize, styles, 'searchGarageContainer')}>
              <CBHeaderTextWithHelp text={t('staffGaragesHeading')} />
              <div>
                <div style={getStyle(screenSize, styles, 'searchContainer')}>
                  <CBSearchInput
                    containerStyle={{ marginLeft: 0 }}
                    changeSearchTerms={this.changeSearchTerms}
                    onBlur={this.fetchWithSearch}
                  />
                </div>
                <div>
                  <table style={getStyle(screenSize, styles, 'table')}>
                    <thead>
                      <tr>
                        {
                          screenSize === 'large' && (
                            <th style={getStyle(screenSize, styles, 'tableHeader')}>Id</th>
                          )
                        }
                        <th style={getStyle(screenSize, styles, 'tableHeader')}>{t('garageTableFirstName')}</th>
                        <th style={getStyle(screenSize, styles, 'tableHeader')}>{t('garageTableLastName')}</th>
                        <th style={getStyle(screenSize, styles, 'tableHeader')}>Email</th>
                        {
                          screenSize === 'large' && (
                            <th style={getStyle(screenSize, styles, 'tableHeader')}>{t('garageTableLastLogin')}</th>
                          )
                        }
                        <th style={getStyle(screenSize, styles, 'tableHeader')} />
                      </tr>
                    </thead>
                    <tbody>
                      {garages.map(garage => (
                        <GarageListItem
                          key={garage.id}
                          garage={garage}
                          onEditClick={this.selectGarage}
                        />
                      ))}
                    </tbody>
                  </table>
                  <div style={getStyle(screenSize, styles, 'loadMoreContainer')}>
                    {
                      loading && (
                        <ProgressSpinner />
                      )
                    }
                    {
                      !loading && garages.length < totalGarages && (
                        <CBButtonSettingsWhite text='Lataa lisää...' onClick={() => { this.fetchMore(); }} />
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      paddingBottom: 50,
    },
    editGarageContainer: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
      width: '40%',
      borderRight: '1px solid rgba(255, 255, 255, 0.4)',
      minHeight: 500,
    },
    searchGarageContainer: {
      width: '60%',
      padding: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
    searchContainer: {
      marginBottom: '16px',
      maxWidth: '400px',
    },
    table: {
      width: '100%',
    },
    tableHeader: {
      fontFamily: 'TitilliumWeb-Light',
      color: '#FFFFFF',
      cursor: 'pointer',
      WebkitGarageSelect: 'none',
      MsGarageSelect: 'none',
      GarageSelect: 'none',
    },
    loadMoreContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 16,
    },
  },
  medium: {
    container: {
      paddingBottom: 25,
    },
    editGarageContainer: {
      paddingLeft: 0,
      paddingRight: 15,
      paddingTop: 15,
    },
    searchGarageContainer: {
      padding: 15,
      paddingLeft: 15,
      paddingRight: 0,
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    editGarageContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      width: '100%',
      borderRight: 0,
      marginBottom: 20,
      minHeight: 0,
    },
    searchGarageContainer: {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: 20,
    },
  },
};

function mapStateToProps(state) {
  return {
    garageRequest: state.garage,
    garages: state.garage.garages,
    totalGarages: state.garage.totalGarages,
    loading: state.garage.garagesStatus.loading,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchGarages: _fetchGarages,
  },
)(translate('SettingsView')(StaffSettingsGarages));
