import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { MultiSelect } from 'primereact/multiselect';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle, SERVICE_FLEET, SERVICE_WORKSHOP, styleMerge } from '../../../utils/utils';
import { fetchGarages as _fetchGarages } from '../../../actions/garageActions';
import {
  fetchTasks as _fetchTasks,
  deleteTask as _deleteTask,
  setTaskStatus as _setTaskStatus,
} from '../../../actions/taskActions';
import CBPromptModal from '../../shared/CBPromptModal';
import TaskListItem from './TaskListItem';
import VehicleListTh from '../../OrganizationGarageView/OrganizationGarageComponents/VehicleListTh';
import TaskFilterBar from './TaskFilterBar';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';
import AddTaskModal from './AddTaskModal';


class TaskListView extends React.Component {
  state = {
    modalIsOpen: false,
    addTaskModalIsOpen: false,
    deleteModalIsOpen: false,
    search: '',
    ordering: 'priority',
    filters: {
      status: 'created'
    },
    fields: [],
    selectedFields: [],
    selectedTasks: [],
  };

  componentWillMount() {
    const {
      fetchGarages,
      t,
      fetchTasks,
      service,
      selectedWorkshopId,
      selectedOrganizationId,
    } = this.props;

    const selectedFields = JSON.parse(localStorage.getItem('selectedTaskFields'));

    const allFields = [
      {
        label: t('headingRegistrationNumber'),
        value: 'vehicle_registration_number',
      },
      {
        label: t('headingPriority'),
        value: 'priority',
      },
      {
        label: t('headingTitle'),
        value: 'title',
      },
      {
        label: t('headingVisibility'),
        value: 'visibility_display',
      },
      {
        label: t('headingStatus'),
        value: 'status',
      },
      {
        label: t('headingCompanyAndGarage'),
        value: 'organization',
      },
      {
        label: t('headingEmployee'),
        value: 'employee',
      },
      {
        label: t('headingOrigin'),
        value: 'origin',
      },
      {
        label: t('headingDueAt'),
        value: 'due_at',
      },
    ];

    this.setState({
      fields: allFields,
    });

    this.setState({
      selectedFields: selectedFields || this.getDefaultFields(),
    });

    fetchGarages();
    this.refetchTasks(null, null);
  }

  getDefaultFields = () => {
    return [
      'vehicle_registration_number',
      'priority',
      'title',
      'visibility_display',
      'status',
      'organization',
      'employee',
      'origin',
      'due_at'
    ];
  }

  setSelectedFields = (newSelectedFields) => {
    this.setState({
      selectedFields: newSelectedFields,
    });
    JSON.stringify(newSelectedFields);
    localStorage.setItem('selectedTaskFields', JSON.stringify(newSelectedFields));
  }

  getGarage = () => {
    const { garages, garageId } = this.props;
    return garages.find(garage => garage.id == garageId);
  }

  getSelectedTasks = () => {
    const { selectedTasks } = this.state;
    const { tasks } = this.props;

    return tasks.filter((msg) => selectedTasks.includes(msg.id));
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
    this.refetchGarageTasks();
  };

  closeUpdateModal = () => {
    this.setState({
      updateModalIsOpen: false,
    });
  };

  openAddTaskModal = () => {
    this.setState({
      addTaskModalIsOpen: true,
    });
  };

  closeAddTaskModal = () => {
    this.setState({
      addTaskModalIsOpen: false,
    });
  };

  closeDeletePromptModal = () => {
    this.setState({
      deleteModalIsOpen: false,
    });
  };

  afterOpenModal = () => {
  };

  selectVehicle = (taskId) => {
    const { selectedTasks } = this.state;

    const newSelectedTasks = selectedTasks.slice();

    newSelectedTasks.push(taskId);
    this.setState({ selectedTasks: newSelectedTasks });
  };

  unselectVehicle = (taskId) => {
    const { selectedTasks } = this.state;

    const newSelectedTasks = selectedTasks.filter((item) => item !== taskId);

    this.setState({ selectedTasks: newSelectedTasks });
  };

  updateTasks = () => {
    this.setState({ updateModalIsOpen: true });
  };

  sendTasks = () => {
    this.setState({ addTaskModalIsOpen: true });
  };

  deleteTasks = () => {
    this.setState({ deleteModalIsOpen: true });
  };

  changeSearchTerms = value => {
    this.setState({
      search: value,
    });
  }

  changeFilterTerms = value => {
    this.setState({
      filters: value
    });
    this.refetchTasks(null, value);
  }

  refetchTasks = (newOrdering = null, newFilters = null) => {
    const { fetchTasks, garageId, service, selectedWorkshopId, selectedOrganizationId } = this.props;
    const { search, ordering, filters } = this.state;

    const params = {
      search,
      ordering: newOrdering || ordering,
      garage_id: garageId
    };

    fetchTasks(
      newFilters ? { ...newFilters, ...params } : { ...filters, ...params },
      service === SERVICE_WORKSHOP && selectedWorkshopId,
      service === SERVICE_FLEET && selectedOrganizationId,
    );
  };

  changeOrdering = (clickedOrdering) => {
    const { ordering } = this.state;
    let newOrdering;
    if (ordering === clickedOrdering) {
      newOrdering = `-${ordering}`;
    } else if (`-${ordering}` === clickedOrdering) {
      newOrdering = 'id';
    } else {
      newOrdering = clickedOrdering;
    }
    this.setState({
      ordering: newOrdering,
    });
    this.refetchTasks(newOrdering);
  };

  getOrderingSymbol = (columnName) => {
    const { ordering } = this.state;
    if (ordering === columnName) {
      return '/img/down_arrow_icon.svg';
    } else if (ordering === `-${columnName}`) {
      return '/img/up_arrow_icon.svg';
    }
    return undefined;
  };

  selectAllTasks = () => {
    const { tasks } = this.props;

    const newSelectedTasks = tasks.map((task) => task.id);
    this.setState({ selectedTasks: newSelectedTasks });
  };

  unselectAllTasks = () => {
    this.setState({ selectedTasks: [] });
  };

  selectAllChanged = () => {
    const { tasks } = this.props;
    const { selectedTasks } = this.state;
    const allChecked = selectedTasks.length === tasks.length;

    if (allChecked) {
      this.unselectAllTasks();
    } else {
      this.selectAllTasks();
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedWorkshopId, selectedOrganizationId } = this.props;

    if (selectedWorkshopId != prevProps.selectedWorkshopId
      || selectedOrganizationId != prevProps.selectedOrganizationId) {
      this.refetchTasks();
    }
  }

  render() {
    const {
      tasks,
      t,
      removeVehicleFromGarage,
      user,
      deleteTask,
      setTaskStatus,
      service,
      selectedWorkshopId,
      careServiceVehicles,
      garageVehicles
    } = this.props;

    const {
      selectedTasks,
      addTaskModalIsOpen,
      deleteModalIsOpen,
      filters
    } = this.state;

    const { modalIsOpen, fields, selectedFields } = this.state;
    const garage = this.getGarage();

    const vehicles = service === SERVICE_WORKSHOP ? careServiceVehicles : garageVehicles;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'topBarsContainer')}>
              <TaskFilterBar
                changeSearchTerms={this.changeSearchTerms}
                changeFilterTerms={this.changeFilterTerms}
                refetchTasks={this.refetchTasks}
                addTask={this.openAddTaskModal}
                filters={filters}
              />
              <hr style={{ opacity: '30%' }} />
            </div>

            <div style={getStyle(screenSize, styles, 'tableContainer')}>
              <table style={getStyle(screenSize, styles, 'table')}>
                <thead>
                  <tr>
                    <th style={styleMerge(getStyle(screenSize, styles, 'tableHeaderFirst'), getStyle(screenSize, styles, 'alignCenter'))}>
                      <CBLabelAndCheckbox
                        checked={selectedTasks.length === tasks.length}
                        additionalStyles={{ justifyContent: 'center' }}
                        onChange={this.selectAllChanged}
                      />
                    </th>

                    <VehicleListTh
                      text={t('headingRegistrationNumber')}
                      fieldName='vehicle_registration_number'
                      orderingSymbol={this.getOrderingSymbol('vehicle_registration_number')}
                      changeOrdering={() => {
                        this.changeOrdering('vehicle_registration_number');
                      }}
                      notSortable
                    />

                    {
                      selectedFields.includes('priority') && (
                        <VehicleListTh
                          text={t('headingPriority')}
                          fieldName='priority'
                          orderingSymbol={this.getOrderingSymbol('priority')}
                          changeOrdering={() => {
                            this.changeOrdering('priority');
                          }}
                        />
                      )
                    }

                    {
                      selectedFields.includes('title') && (
                        <VehicleListTh
                          text={t('headingTitle')}
                          fieldName='title'
                          orderingSymbol={this.getOrderingSymbol('title')}
                          changeOrdering={() => {
                            this.changeOrdering('title');
                          }}
                        />
                      )
                    }

                    {
                      selectedFields.includes('visibility_display') && (
                        <VehicleListTh
                          text={t('headingVisibility')}
                          fieldName='visibility_display'
                          orderingSymbol={this.getOrderingSymbol('visibility_display')}
                          changeOrdering={() => {
                            this.changeOrdering('visibility_display');
                          }}
                          notSortable
                        />
                      )
                    }

                    {
                      selectedFields.includes('status') && (
                        <VehicleListTh
                          text={t('headingStatus')}
                          fieldName='status'
                          orderingSymbol={this.getOrderingSymbol('status')}
                          changeOrdering={() => {
                            this.changeOrdering('status');
                          }}
                          notSortable
                        />
                      )
                    }

                    {
                      selectedFields.includes('organization') && (
                        <VehicleListTh
                          text={t('headingCompanyAndGarage')}
                          fieldName='organization'
                          orderingSymbol={this.getOrderingSymbol('organization__name')}
                          changeOrdering={() => {
                            this.changeOrdering('organization__name');
                          }}
                        />
                      )
                    }

                    {
                      selectedFields.includes('employee') && (
                        <VehicleListTh
                          text={t('headingEmployee')}
                          fieldName='employee'
                          orderingSymbol={this.getOrderingSymbol('employee')}
                          changeOrdering={() => {
                            this.changeOrdering('employee');
                          }}
                          notSortable
                        />
                      )
                    }

                    {
                      selectedFields.includes('origin') && (
                        <VehicleListTh
                          text={t('headingOrigin')}
                          fieldName='origin'
                          orderingSymbol={this.getOrderingSymbol('origin')}
                          changeOrdering={() => {
                            this.changeOrdering('origin');
                          }}
                        />
                      )
                    }

                    {
                      selectedFields.includes('due_at') && (
                        <VehicleListTh
                          text={t('headingDueAt')}
                          fieldName='due_at'
                          orderingSymbol={this.getOrderingSymbol('due_at')}
                          changeOrdering={() => {
                            this.changeOrdering('due_at');
                          }}
                        />
                      )
                    }

                    <th></th>


                    <th style={getStyle(screenSize, styles, 'tableHeaderSettings')}>
                      <MultiSelect
                        value={selectedFields}
                        onChange={(e) => this.setSelectedFields(e.value)}
                        options={fields}
                        className="w-full md:w-20rem"
                        dropdownIcon={undefined}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tasks.map(task => (
                    <TaskListItem
                      selectedFields={selectedFields}
                      task={task}
                      key={task.id}
                      garage={garage}
                      selected={selectedTasks.includes(task.id)}
                      removeVehicleFromGarage={removeVehicleFromGarage}
                      select={this.selectVehicle}
                      unselect={this.unselectVehicle}
                      deleteTask={deleteTask}
                      setTaskStatus={setTaskStatus}
                      service={service}
                      selectedWorkshopId={selectedWorkshopId}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            {addTaskModalIsOpen && (
              <AddTaskModal
                modalIsOpen={addTaskModalIsOpen}
                closeModal={this.closeAddTaskModal}
                vehicles={vehicles}
              />
            )}
            {deleteModalIsOpen && (
              <CBPromptModal
                modalIsOpen={deleteModalIsOpen}
                closeModal={this.closeDeletePromptModal}
                afterOpenModal={() => {}}
                title={t('deleteManyFromGaragePromptTitle')}
                text={`${t('deleteManyFromGaragePromptText')}`}
                buttonText={t('deleteButton')}
                performAction={() => {
                  deleteTask();
                  this.closeDeletePromptModal();
                  this.unselectAllTasks();
                }}
              />
            )}
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      width: '100%',
      padding: '0px 16px 0px 0px',
    },
    filterContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
    searchContainer: {
      maxWidth: 600,
    },
    table: {
      display: 'block',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      tableLayout: 'fixed',
      marginBottom: '24px',
      paddingBottom: 16,
    },
    tableHeader: {
      fontFamily: 'TitilliumWeb-Bold',
      color: '#FFFFFF',
      cursor: 'pointer',
      WebkitUserSelect: 'none',
      MsUserSelect: 'none',
      UserSelect: 'none',
      padding: '8px 8px 8px 8px',
    },
    tableHeaderSettings: {
      cursor: 'pointer',
      WebkitUserSelect: 'none',
      MsUserSelect: 'none',
      UserSelect: 'none',
      //position: 'sticky',
      padding: '8px 8px 8px 8px',
      right: 0,
    },
    tableHeaderFirst: {
      //position: 'sticky',
      left: 0,
    },
    alignCenter: {
      textAlign: 'center',
    },
    topBarsContainer: {
      marginTop: 32,
      paddingLeft: 16,
    },
  },
  medium: {
    table: {
    },
  },
  small: {
    table: {
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    garages: state.garage.garages,
    tasks: state.task.tasks,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    selectedOrganizationId: state.organization.selectedOrganization,
    careServiceVehicles: state.vehicle.careServiceVehicles,
    garageVehicles: state.vehicle.garageVehicles,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchGarages: _fetchGarages,
    fetchTasks: _fetchTasks,
    deleteTask: _deleteTask,
    setTaskStatus: _setTaskStatus,
  },
)(translate('TaskListView')(TaskListView));
