import React from 'react';

class CBHeaderTextWithHelp extends React.Component {
  render() {
    return (
      <div style={styles.headerTextContainer}>
        <p className='font-titillium-web-extra-light' style={styles.headerText}>
          {this.props.text}
        </p>
        <img src='/img/icon_3.svg' alt='Help' style={styles.headingIcon} />
      </div>
    );
  }
}

const styles = {
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: 16,
  },
  headerText: {
    color: '#FFF',
    fontSize: 22,
    margin: 0,
  },
  headingIcon: {
    width: 24,
    height: 24,
  },
};

export default CBHeaderTextWithHelp;
