import React from 'react';
import { translate } from 'react-i18next';
import moment from 'moment';
import VehicleReminderListItem from './VehicleReminderListItem';
import CBButtonWithIcon from '../../../shared/CBButtonWithIcon';
import CBMediaQuery from '../../../shared/CBMediaQuery';
import { getStyle, SERVICE_PERSONAL } from '../../../../utils/utils';
import AddReminderModal from './AddReminderModal';
import { connect } from 'react-redux';

class VehicleDetailsReminders extends React.Component {
  state = {
    modalIsOpen: false,
  };

  addReminder = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  afterOpenModal = () => {};

  render() {
    const { vehicle, t, isSettings, service } = this.props;
    const { modalIsOpen } = this.state;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={isSettings ? getStyle(screenSize, styles, 'containerFullWidth') : getStyle(screenSize, styles, 'container')}>
            {vehicle.can_edit && service === SERVICE_PERSONAL && (
              <React.Fragment>
                {
                  !isSettings && (
                    <div style={getStyle(screenSize, styles, 'buttonContainer')}>
                      <CBButtonWithIcon
                        onClick={this.addReminder}
                        text={t('addReminderButton')}
                        icon='/img/icon_reminder_add.svg'
                      />
                    </div>
                  )
                }

                <div style={getStyle(screenSize, styles, 'remindersContainer')}>
                  {vehicle.reminders
                    .sort(
                      (reminder1, reminder2) =>
                        (moment(reminder1.due_date).isAfter(moment(reminder2.due_date)) ? 1 : -1),
                    )
                    .map(reminder => (
                      <VehicleReminderListItem key={reminder.id} reminder={reminder} />
                    ))}
                </div>
                <AddReminderModal
                  modalIsOpen={modalIsOpen}
                  closeModal={this.closeModal}
                  afterOpenModal={this.afterOpenModal}
                  vehicle={vehicle}
                />
              </React.Fragment>
            )}
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      marginTop: 0,
      marginLeft: 'auto',
      borderLeft: '1px solid rgba(255, 255, 255, 0.4)',
      width: '30%',
      padding: 16,
    },
    containerFullWidth: {
      marginTop: 0,
      marginLeft: 'auto',
      width: '100%',
    },
    remindersContainer: {
      paddingTop: 16,
      height: '100%',
    },
    buttonContainer: {
      width: '100%',
    },
  },
  medium: {
    container: {
      marginTop: 50,
      backgroundImage: 'linear-gradient(0deg, #0095da 3%, #0069ab 100% )',
      backgroundPosition: 'center center',
      width: '100%',
    },
    containerFullWidth: {
      marginTop: 50,
      backgroundImage: 'linear-gradient(0deg, #0095da 3%, #0069ab 100% )',
      backgroundPosition: 'center center',
      width: '100%',
    },
    buttonContainer: {
      width: 200,
    },
  },
};

function mapStateToProps(state) {
  return {
    service: state.app.service
  };
}

export default connect(
  mapStateToProps,
  {},
)(translate('VehicleDetailsView')(VehicleDetailsReminders));
