import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import axios from 'axios';
import UserSettingsProfileInfoForm from './UserSettingsProfileInfoForm';
import {
  updateUserProfile as _updateUserProfile,
  updateUserProfilePicture as _updateUserProfilePicture,
} from '../../../actions/authUserActions';
import { getStyle } from '../../../utils/utils';
import CBHeaderTextWithHelp from '../../shared/CBHeaderTextWithHelp';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBDropzone from '../../shared/CBDropzone';
import AddImageModal from '../../shared/AddImageModal';
import { API_URL } from '../../../config';

class UserSettingsProfile extends React.Component {
  state = {
    token: '',
    addImageModal: false,
    addedImage: null,
  };

  onDrop = acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          addedImage: reader.result,
          addImageModal: true,
        });
      };
    });
  };

  uploadImage = (image) => {
    const { updateUserProfilePicture, user } = this.props;
    updateUserProfilePicture({ image }, user.profile.id);
    this.setState({ addImageModal: false, addedImage: null });
  }

  onRemove = () => {
    const { updateUserProfilePicture, user } = this.props;
    updateUserProfilePicture({ image: null }, user.profile.id);
  }

  hideModal = () => this.setState({ addImageModal: false, addedImage: null })

  fetchApiToken = () => {
    const { t } = this.props;
    axios({
      url: `${API_URL}/uiapi/1/workshop_token/`,
      method: 'GET',
    })
      .then(response => response.data)
      .then(data => {
        this.setState({ token: data.token });
      })
      .catch(() => {
        this.setState({ token: t('facebookHelpText') });
      });
  };

  render() {
    const { user, userRequest, t } = this.props;
    const {
      token, addImageModal, addedImage,
    } = this.state;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'settingsProfileContainer')}>
            <div style={getStyle(screenSize, styles, 'personalInformationContainer')}>
              <CBHeaderTextWithHelp text={t('basicInfoHeading')} />
              <UserSettingsProfileInfoForm isOwnUser user={user} userRequest={userRequest} />
            </div>
            <div style={getStyle(screenSize, styles, 'otherSettingsContainer')}>
            </div>

            <div style={getStyle(screenSize, styles, 'profileImageContainer')}>
              {!!user.profile.image && (
                <React.Fragment>
                  <CBDropzone
                    style={getStyle(screenSize, styles, 'dropZone')}
                    onDropAccepted={this.onDrop}
                  >
                    <img
                      style={getStyle(screenSize, styles, 'profilePicture')}
                      alt='Profile'
                      src={user.profile.image}
                    />
                    {t('changeProfileImageButtonLabel')}
                  </CBDropzone>
                  <button type='button' onClick={this.onRemove} style={getStyle(screenSize, styles, 'removePictureButton')}>
                    {t('removeProfileImageButtonLabel')}
                  </button>
                </React.Fragment>
              )}
              {!user.profile.image && (
                <CBDropzone style={getStyle(screenSize, styles, 'dropZone')} onDropAccepted={this.onDrop}>
                  <div
                    style={getStyle(screenSize, styles, 'profileImageUnset')}
                    src={user.profile.image}
                  >
                    <img
                      src='/img/icon_plus.svg'
                      alt='Add'
                      style={getStyle(screenSize, styles, 'plusIcon')}
                    />
                  </div>
                  {t('addProfileImageButtonLabel')}
                </CBDropzone>
              )}
            </div>
            {addImageModal && (
              <AddImageModal
                handleClose={this.hideModal}
                aspectRatio={1.0}
                image={addedImage}
                onImageSave={(image) => { this.uploadImage(image); }}
              />
            )}
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    settingsProfileContainer: {
      display: 'flex',
      paddingBottom: 50,
    },
    profilePicture: {
      borderRadius: 4,
      marginTop: 8,
      marginBottom: 8,
    },
    tokenText: {
      fontWeight: 'bold',
    },
    personalInformationContainer: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 20,
      width: '33%',
      borderRight: '1px solid rgba(255, 255, 255, 0.4)',
    },
    facebookContainer: {
      borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
      paddingBottom: 20,
      paddingLeft: 40,
      paddingTop: 40,
    },

    otherSettingsContainer: {
      width: '33%',
      paddingRight: 20,
    },
    reminderContainer: {
      borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
      paddingLeft: 40,
      paddingBottom: 20,
      paddingTop: 20,
    },
    removeAccountContainer: {
      paddingLeft: 40,
      paddingTop: 20,
    },
    tokenContainer: {
      borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
      paddingLeft: 40,
      paddingBottom: 20,
      paddingTop: 20,
    },
    profileImageContainer: {
      width: '33%',
      paddingTop: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    profileImageUnset: {
      border: '1px solid #FFFFFF',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 200,
      width: 200,
      marginBottom: 8,
      marginTop: 8,
    },
    plusIcon: {
      width: 45,
      height: 45,
    },
    dropZone: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      color: 'white',
      fontFamily: 'TitilliumWeb-Regular',
      overflow: 'hidden',
      width: '100%'
    },
    fieldContainerLeftAlign: {
      marginBottom: 5,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      width: '100%',
    },
    removePictureButton: {
      backgroundColor: 'transparent',
      border: 0,
      color: 'white',
      fontFamily: 'TitilliumWeb-Regular',
      marginTop: 8,
    },
  },
  medium: {
    personalInformationContainer: {
      paddingLeft: 0,
      paddingRight: 40,
      paddingTop: 20,
      width: '33%',
      borderRight: '1px solid rgba(255, 255, 255, 0.4)',
    },
  },
  small: {
    dropZone: {
      width: '100%',
    },
    settingsProfileContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    personalInformationContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      width: '100%',
      borderRight: 0,
      marginBottom: 20,
    },
    otherSettingsContainer: {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: 20,
    },
    profileImageContainer: {
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 20,
      alignSelf: 'center',
      width: 'unset',
    },
    facebookContainer: {
      paddingLeft: 0,
    },
    reminderContainer: {
      paddingLeft: 0,
    },
    removeAccountContainer: {
      paddingLeft: 0,
    },
    tokenContainer: {
      paddingLeft: 0,
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    userRequest: state.authUser,
  };
}

export default connect(
  mapStateToProps,
  {
    updateUserProfile: _updateUserProfile,
    updateUserProfilePicture: _updateUserProfilePicture,
  },
)(translate('SettingsView')(UserSettingsProfile));
