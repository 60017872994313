import React from 'react';
import { translate } from 'react-i18next';
import CBLabelAndValue from '../../../shared/CBLabelAndValue';
import CBMediaQuery from '../../../shared/CBMediaQuery';
import { getStyle } from '../../../../utils/utils';

class MaintenanceTaskListItem extends React.Component {
  render() {
    const { inspectionPart, t } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div>
            <p
              className='font-titillium-web-extra-light'
              style={getStyle(screenSize, styles, 'sectionHeadingText')}
            >
              {inspectionPart.name}
            </p>
            <div style={getStyle(screenSize, styles, 'infoContainer')}>
              <div style={getStyle(screenSize, styles, 'leftContainer')}>
                <CBLabelAndValue
                  label={t('inspectionTargetLabel')}
                  value={inspectionPart.main_inspection_target}
                />
                <CBLabelAndValue
                  label={t('inspectionComponentLabel')}
                  value={inspectionPart.component}
                />
                <CBLabelAndValue
                  label={t('inspectionLocationLabel')}
                  value={inspectionPart.location}
                />
              </div>
              <div style={getStyle(screenSize, styles, 'rightContainer')}>
                <CBLabelAndValue label={t('inspectionFaultLabel')} value={inspectionPart.fault} />
                <CBLabelAndValue
                  label={t('inspectionSeverityLabel')}
                  value={inspectionPart.severity}
                />
                <CBLabelAndValue
                  label={t('inspectionDescriptionLabel')}
                  value={inspectionPart.description}
                />
              </div>
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    infoContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '75%',
      justifyContent: 'space-between',
    },
    leftContainer: {},
    rightContainer: {},
    sectionHeadingText: {
      fontSize: 24,
    },
  },
  small: {
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
};

export default translate('VehicleDetailsView')(MaintenanceTaskListItem);
