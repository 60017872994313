import React from 'react';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle } from '../../../utils/utils';

class CBButtonFooter extends React.Component {
  render() {
    const { href, text } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'button')}>
            <a style={getStyle(screenSize, styles, 'linkWrapper')} href={href}>
              <p
                className='font-titillium-web-light'
                style={getStyle(screenSize, styles, 'buttonText')}
              >
                <span style={getStyle(screenSize, styles, 'arrowIcon')}>
                  {'> '}
                </span>
                {text}
              </p>
            </a>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

export default CBButtonFooter;

const styles = {
  default: {
    arrowIcon: {
      color: '#0095da',
    },
    linkWrapper: {
      height: 30,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    button: {
      display: 'table',
      minWidth: 200,
      height: 30,
      border: '1px solid rgba(255, 255, 255, .3)',
      borderRadius: 50,
      marginBottom: 16,
      width: '100%',
    },
    buttonText: {
      fontSize: 16,
      color: '#ffffff',
      fontWeight: 300,
      textDecoration: 'none',
      margin: 0,
      paddingLeft: 10,
    },
  },
};
