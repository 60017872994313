import MediaQuery from 'react-responsive';

import React, { Component } from 'react';

export default class CBMediaQuery extends Component {
  render() {
    const { children } = this.props;
    let screenSize = 'large';
    return (
      <MediaQuery minWidth={1300}>
        {matchesLarge => (
          <MediaQuery maxWidth={1300} minWidth={800}>
            {matchesMedium => (
              <MediaQuery maxWidth={799}>
                {matchesSmall => {
                  if (matchesSmall) {
                    screenSize = 'small';
                  } else if (matchesMedium) {
                    screenSize = 'medium';
                  } else if (matchesLarge) {
                    screenSize = 'large';
                  }
                  return children(screenSize);
                }}
              </MediaQuery>
            )}
          </MediaQuery>
        )}
      </MediaQuery>
    );
  }
}
