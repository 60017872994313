import React from 'react';
import { translate } from 'react-i18next';
import VehicleDetailsBasicDescription from './VehicleDetailsBasicDescription';
import VehicleDetailsBasicContent from './VehicleDetailsBasicContent';
import VehicleDetailsSectionHeader from './VehicleDetailsSectionHeader';
import CBMediaQuery from '../../shared/CBMediaQuery';

class VehicleDetailsMainBasic extends React.Component {
  render() {
    const { t } = this.props;
    const vehicle = this.props.vehicle;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div>
            <VehicleDetailsSectionHeader type='basic' text={t('basicInfoHeading')} />
            <div style={styles.mainContentContainer}>
              {screenSize !== 'small' && <VehicleDetailsBasicDescription vehicle={vehicle} />}
              <VehicleDetailsBasicContent vehicle={vehicle} />
            </div>
            {screenSize === 'small' && <VehicleDetailsBasicDescription vehicle={vehicle} />}
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  mainContentContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
};

export default translate('VehicleDetailsView')(VehicleDetailsMainBasic);
