import React from 'react';
import { translate } from 'react-i18next';
import CBButtonFooter from './FooterComponents/CBButtonFooter';
import { getStyle } from '../../utils/utils';
import CBMediaQuery from '../shared/CBMediaQuery';
import CBButtonTransparent from '../shared/CBButtonTransparent';

class Footer extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <React.Fragment>
            <img
              src='/img/horizontal_line.svg'
              alt='Add'
              style={getStyle(screenSize, styles, 'horizontalLine')}
            />
            <div style={getStyle(screenSize, styles, 'footerContainer')}>
              <div style={getStyle(screenSize, styles, 'infoContainer')}>
                <p
                  className='font-titillium-web-bold'
                  style={getStyle(screenSize, styles, 'footerHeader')}
                >
                  www.ovio.fi
                </p>
                <p
                  className='font-titillium-web-regular'
                  style={getStyle(screenSize, styles, 'descriptionText')}
                >
                  {t('description')}
                </p>
                <p
                  className='font-titillium-web-regular'
                  style={getStyle(screenSize, styles, 'copyrightText')}
                >
                  {t('copyrightText')}
                </p>
              </div>
              <div style={getStyle(screenSize, styles, 'helpContainer')}>
                <p
                  className='font-titillium-web-bold'
                  style={getStyle(screenSize, styles, 'footerHeader')}
                >
                  {t('instructionsHeader')}
                </p>
                {/* <CBButtonFooter href='/accounts/terms-and-conditions/' text={t('manualButton')} /> */}
                <CBButtonFooter href='/accounts/terms-and-conditions/' text={t('termsButton')} />
                <CBButtonFooter href='/accounts/dataprivacy/' text={t('dataPrivacyButton')} />
              </div>
              <div style={getStyle(screenSize, styles, 'logoutContainer')}>
                <CBButtonTransparent text={t('logoutButton')} to='/accounts/logout' />
              </div>
            </div>
          </React.Fragment>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    footerContainer: {
      backgrounColor: 'red',
      marginTop: 20,
      display: 'flex',
      flexDirection: 'row',
    },
    descriptionText: {
      fontSize: 10,
      color: '#ffffff',
      textDecoration: 'none solid rgb(255, 255, 255)',
    },
    copyrightText: {
      color: '#FFF',
      paddingTop: 39,
      fontSize: 12,
      textDecoration: 'none solid rgb(255, 255, 255)',
    },
    footerHeader: {
      fontSize: '16px',
      color: '#0095da',
      fontWeight: 700,
    },
    infoContainer: {
      backgrounColor: 'red',
      display: 'flex',
      flexDirection: 'column',
      width: '30%',
    },
    helpContainer: {
      width: '23%',
      paddingLeft: 24,
      paddingRight: 24,
    },
    logoutContainer: {
      paddingTop: 32,
      width: '47%',
    },
    horizontalLine: {
      width: '100%',
      opacity: 0.3,
    },
  },
  medium: {
    footerContainer: {
      marginLeft: 15,
      marginRight: 15,
      marginTop: 15,
      marginBottom: 15,
      flexWrap: 'wrap',
    },
    infoContainer: {
      width: '50%',
      paddingRight: 20,
    },
    helpContainer: {
      width: '50%',
      marginRight: 0,
      paddingRight: 0,
    },
    logoutContainer: {
      width: '100%',
    },
    footerHeader: {
      marginBottom: 16,
    },
    descriptionText: {
      marginBottom: 24,
    },
  },
  small: {
    footerContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: 16,
    },
    infoContainer: {
      width: '100%',
    },
    helpContainer: {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
    },
    logoutContainer: {
      width: '100%',
      paddingTop: 30,
    },
    copyrightText: {
      paddingTop: 15,
    },
    descriptionText: {
      marginBottom: 15,
    },
  },
};

export default translate('Footer')(Footer);
