import { API, GET, POST, PUT, DELETE } from '../middleware/api';
import { API_URL } from '../config';

export function fetchGarages(params) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/garage/`,
      types: [
        'FETCH_GARAGES_REQUEST',
        'FETCH_GARAGES_SUCCESS',
        'FETCH_GARAGES_FAIL',
      ],
      params,
    },
  };
}

export function createGarage(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/garage/`,
      types: [
        'CREATE_GARAGE_REQUEST',
        'CREATE_GARAGE_SUCCESS',
        'CREATE_GARAGE_FAIL',
      ],
      data,
    },
  };
}

export function updateGarage(garageId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/garage/${garageId}/`,
      types: [
        'UPDATE_GARAGE_REQUEST',
        'UPDATE_GARAGE_SUCCESS',
        'UPDATE_GARAGE_FAIL',
      ],
      data,
    },
  };
}

export function fetchGaragePermissions(garageId) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/garage_permission/?garage_id=${garageId}`,
      types: [
        'FETCH_GARAGE_PERMISSIONS_REQUEST',
        'FETCH_GARAGE_PERMISSIONS_SUCCESS',
        'FETCH_GARAGE_PERMISSIONS_FAIL',
      ],
    },
  };
}

export function createGaragePermission(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/garage_permission/`,
      types: [
        'CREATE_GARAGE_PERMISSION_REQUEST',
        'CREATE_GARAGE_PERMISSION_SUCCESS',
        'CREATE_GARAGE_PERMISSION_FAIL',
      ],
      data,
    },
  };
}

export function updateGaragePermission(permissionId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/garage_permission/${permissionId}/`,
      types: [
        'UPDATE_GARAGE_PERMISSION_REQUEST',
        'UPDATE_GARAGE_PERMISSION_SUCCESS',
        'UPDATE_GARAGE_PERMISSION_FAIL',
      ],
      data,
    },
  };
}

export function deleteGaragePermission(permissionId, baseObjId) {
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/garage_permission/${permissionId}/`,
      types: [
        'DELETE_GARAGE_PERMISSION_REQUEST',
        'DELETE_GARAGE_PERMISSION_SUCCESS',
        'DELETE_GARAGE_PERMISSION_FAIL',
      ],
      additionalData: {
        garageId: baseObjId,
        permissionId,
      },
    },
  };
}

export function toggleGarageOrganizationSharing(garageId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/garage/${garageId}/toggle_organization_sharing/`,
      types: [
        'TOGGLE_GARAGE_ORGANIZATION_SHARING_REQUEST',
        'TOGGLE_GARAGE_ORGANIZATION_SHARING_SUCCESS',
        'TOGGLE_GARAGE_ORGANIZATION_SHARING_FAIL',
      ],
      data,
    },
  };
}

export function selectGarage(garage) {
  return {
    type: 'SELECT_GARAGE',
    payload: garage,
  };
}

export function toggleGarageMessageSubscription(garageId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/garage/${garageId}/toggle_message_subscription/`,
      types: [
        'TOGGLE_GARAGE_MESSAGE_SUBSCRIPTION_REQUEST',
        'TOGGLE_GARAGE_MESSAGE_SUBSCRIPTION_SUCCESS',
        'TOGGLE_GARAGE_MESSAGE_SUBSCRIPTION_FAIL',
      ],
      data,
    },
  };
}
