import React, { Component } from 'react';
import { connect } from 'react-redux';
import CBAdvertisement from './CBAdvertisement';

class CBAdvertisementWrapper extends Component {
  render() {
    const { advertisements, location, style } = this.props;

    const advertisementsFilter = advertisements.filter(
      (obj) => obj.locations.includes(location),
    );

    const ads = advertisementsFilter.map((obj, index) => (
      <CBAdvertisement location={location} key={obj.internal_destination_url} index={index} obj={obj} />
    ));

    return <div style={style}>{ads || ''}</div>;
  }
}

const mapStateToProps = (state) => ({
  advertisements: state.advertisement.advertisements,
});

export default connect(mapStateToProps)(CBAdvertisementWrapper);
