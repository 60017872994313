import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { fetchInstructions as _fetchInstructions } from '../../../actions/authUserActions';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import InstructionCollapse from './InstructionCollapse';

class InstructionsView extends React.Component {
  state = {
    chosenInstructions: [0, 1],
  };

  componentDidMount = () => {
    const { fetchInstructions } = this.props;

    fetchInstructions();
  };

  onSelectInstruction = index => {
    const { chosenInstructions } = this.state;
    const updatedInstructions = chosenInstructions;

    if (chosenInstructions.includes(index)) {
      updatedInstructions.splice(chosenInstructions.indexOf(index), 1);
      this.setState({
        chosenInstructions: updatedInstructions,
      });
    } else {
      updatedInstructions.push(index);
      this.setState({
        chosenInstructions: updatedInstructions,
      });
    }
  };

  render() {
    const { instructions } = this.props;
    const { chosenInstructions } = this.state;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'tableOfContentsContainer')}>
              <ul>
                {instructions
                  && instructions.map((instruction, index) => (
                    <div
                      style={getStyle(screenSize, styles, 'listItem')}
                      onClick={() => this.onSelectInstruction(index)}
                      key={instruction.priority}
                    >
                      <img
                        style={getStyle(screenSize, styles, 'arrowIcon')}
                        src='/img/icon_arrow_right.svg'
                        alt='>'
                      />
                      <p
                        style={getStyle(
                          screenSize,
                          styles,
                          chosenInstructions.includes(index) ? 'listTextSelected' : 'listText',
                        )}
                      >
                        {instruction.title}
                      </p>
                    </div>
                  ))}
              </ul>
            </div>
            <div style={getStyle(screenSize, styles, 'instructionContentContainer')}>
              {instructions
                && instructions.map((instruction, index) => (
                  <InstructionCollapse
                    open={chosenInstructions.includes(index)}
                    title={instruction.title}
                    onToggle={() => this.onSelectInstruction(index)}
                    key={instruction.priority}
                  >
                    <p>
                      {instruction.description}
                    </p>
                  </InstructionCollapse>
                ))}
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      paddingBottom: 50,
    },
    tableOfContentsContainer: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 40,
      width: '33%',
      borderRight: '1px solid rgba(255, 255, 255, 0.4)',
      minHeight: 500,
    },
    instructionContentContainer: {
      width: '50%',
      padding: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
    arrowIcon: {
      height: 25,
      width: 25,
    },
    listItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      cursor: 'pointer',
    },
    listText: {
      margin: 0,
      padding: 0,
      fontWeight: 200,
      paddingLeft: 15,
    },
    listTextSelected: {
      margin: 0,
      padding: 0,
      fontWeight: 600,
      paddingLeft: 15,
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    tableOfContentsContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      width: '100%',
      borderRight: 0,
      marginBottom: 20,
      minHeight: 0,
    },
    instructionContentContainer: {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: 20,
    },
  },
};

function mapStateToProps(state) {
  return {
    instructions: state.authUser.instructions,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchInstructions: _fetchInstructions,
  },
)(translate('SettingsView')(InstructionsView));
