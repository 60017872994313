import { cloneDeep } from 'lodash';

import { vehiclePermissionState } from '../initialState';
import { storeUpdateObjectInArray, storeRemoveItem, storeInsertItem } from '../utils/utils';

export default function (state = vehiclePermissionState, action) {
  switch (action.type) {
    case 'UPDATE_VEHICLE_SUCCESS': {
      const oldPermission = state.vehiclePermissions.find(x => x.vehicle.id === action.payload.id);
      if (!oldPermission) {
        return state;
      }
      const newPermission = cloneDeep(oldPermission);
      newPermission.vehicle = action.payload;
      return {
        ...state,
        vehiclePermissions: storeUpdateObjectInArray(
          state.vehiclePermissions,
          newPermission,
        ),
        error: null,
      };
    }
    case 'FETCH_VEHICLE_PERMISSIONS_REQUEST': {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case 'FETCH_VEHICLE_PERMISSIONS_SUCCESS': {
      return {
        ...state,
        vehiclePermissions: action.payload,
        loading: false,
        error: null,
      };
    }
    case 'FETCH_VEHICLE_PERMISSIONS_FAIL': {
      return {
        ...state,
        vehiclePermissions: [],
        loading: false,
        error: action.payload,
      };
    }
    case 'CREATE_VEHICLE_PERMISSION_REQUEST': {
      return {
        ...state,
        errorCreation: null,
      };
    }
    case 'CREATE_VEHICLE_PERMISSION_SUCCESS': {
      const index = state.vehiclePermissions.findIndex(x => x.id == action.payload.parent);
      const oldPermission = state.vehiclePermissions[index];
      const newPermission = Object.assign({}, oldPermission);
      newPermission.children = storeInsertItem(oldPermission.children, action.payload);
      return {
        ...state,
        vehiclePermissions: storeUpdateObjectInArray(state.vehiclePermissions, newPermission),
        errorCreation: null,
      };
    }
    case 'CREATE_VEHICLE_PERMISSION_FAIL': {
      return {
        ...state,
        errorCreation: action.payload,
      };
    }
    case 'UPDATE_VEHICLE_PERMISSION_REQUEST': {
      return {
        ...state,
        error: null,
      };
    }
    case 'UPDATE_VEHICLE_PERMISSION_SUCCESS': {
      if (state.vehiclePermissions.findIndex(x => x.id == action.payload.id) > -1) {
        return {
          ...state,
          vehiclePermissions: storeUpdateObjectInArray(state.vehiclePermissions, action.payload),
          error: null,
        };
      } else {
        const index = state.vehiclePermissions.findIndex(x => x.id == action.payload.parent);
        const oldPermission = state.vehiclePermissions[index];
        const newPermission = Object.assign({}, oldPermission);
        newPermission.children = storeUpdateObjectInArray(oldPermission.children, action.payload);
        return {
          ...state,
          vehiclePermissions: storeUpdateObjectInArray(state.vehiclePermissions, newPermission),
          error: null,
        };
      }
    }
    case 'UPDATE_VEHICLE_PERMISSION_FAIL': {
      return {
        ...state,
        error: action.payload,
      };
    }
    case 'DELETE_VEHICLE_PERMISSION_REQUEST': {
      return {
        ...state,
        error: null,
      };
    }
    case 'DELETE_VEHICLE_PERMISSION_SUCCESS': {
      if (state.vehiclePermissions.findIndex(x => x.id == action.payload.id) > -1) {
        return {
          ...state,
          vehiclePermissions: storeRemoveItem(state.vehiclePermissions, action.payload.id),
          error: null,
        };
      } else {
        const index = state.vehiclePermissions.findIndex(x => x.id == action.payload.parent);
        const oldPermission = state.vehiclePermissions[index];
        const newPermission = Object.assign({}, oldPermission);
        newPermission.children = storeRemoveItem(oldPermission.children, action.payload.id);
        return {
          ...state,
          vehiclePermissions: storeUpdateObjectInArray(state.vehiclePermissions, newPermission),
          error: null,
        };
      }
    }
    case 'DELETE_VEHICLE_PERMISSION_FAIL': {
      return {
        ...state,
        error: action.payload,
      };
    }
    case 'DELETE_VEHICLE_REMINDER_SUCCESS': {
      // Find the permission that is related to the vehicle with this reminder
      // Assuming that there is only one such permission
      let permission;
      const permissionFound = state.vehiclePermissions.some(p => {
        if (!p.vehicle || !p.vehicle.reminders) return false;
        if (p.vehicle.reminders.some(r => r.id === action.payload.id)) {
          permission = p;
          return true;
        }
        return false;
      });
      if (!permissionFound) {
        return { ...state };
      }
      const newPermission = cloneDeep(permission);
      newPermission.vehicle.reminders = storeRemoveItem(
        newPermission.vehicle.reminders, action.payload.id,
      );
      return {
        ...state,
        vehiclePermissions: storeUpdateObjectInArray(state.vehiclePermissions, newPermission),
      };
    }
    case 'REQUEST_VEHICLE_PERMISSION_REQUEST': {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }
    case 'REQUEST_VEHICLE_PERMISSION_SUCCESS': {
      if (action.additionalData.isGarageRequest) {
        return {
          ...state,
          loading: false,
        };
      } else {
        const oldPermission = state.vehiclePermissions.find(
          x => x.id === action.payload.id,
        );
        let newVehiclePermissions;
        if (oldPermission !== undefined) {
          const newPermission = cloneDeep(oldPermission);
          Object.assign(newPermission, action.payload);
          newVehiclePermissions = storeUpdateObjectInArray(
            state.vehiclePermissions, newPermission,
          );
        } else if (oldPermission === undefined) {
          newVehiclePermissions = storeInsertItem(
            state.vehiclePermissions, action.payload,
          );
        }
        return {
          ...state,
          vehiclePermissions: newVehiclePermissions,
          loading: false,
        };
      }
    }
    case 'REQUEST_VEHICLE_PERMISSION_FAIL': {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    case 'CLEAR_VEHICLE_PERMISSION_ERRORS': {
      return {
        ...state,
        error: null,
      };
    }
    default:
      break;
  }
  return state;
}
