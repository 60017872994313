import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import WelcomeText from './HomeViewComponents/WelcomeText';
import WelcomeTextSub from './HomeViewComponents/WelcomeTextSub';
import CBMediaQuery from '../shared/CBMediaQuery';
import CBPromptModal from '../shared/CBPromptModal';

import { FLEET_INVITATION_TYPES, formatDate, getStyle, INVITATION_GARAGE, INVITATION_WORKSHOP, SERVICE_FLEET, SERVICE_WORKSHOP } from '../../utils/utils';
import { acceptInvitation as _acceptInvitation, declineInvitation as _declineInvitation } from '../../actions/authUserActions';


class NoOrganizationView extends React.Component {
  acceptInvitation = (invitation) => {
    const { acceptInvitation } = this.props;
    acceptInvitation(invitation.id);
  };

  declineInvitation = (invitation) => {
    const { declineInvitation } = this.props;
    declineInvitation(invitation.id);
  };

  getInvitationTitle = (invitation) => {
    const { t } = this.props;
    switch(invitation.permission_type) {
      case 'workshop':
        return t('workshopInvitationPromptTitle');
      case 'organization':
        return t('organizationInvitationPromptTitle');
      case 'garage':
        return t('garageInvitationPromptTitle');
      case 'vehicle':
        return t('garageInvitationPromptTitle');
      default:
        return '';
    }
  };

  getInvitationText = (invitation) => {
    const { t } = this.props;
    switch(invitation.permission_type) {
      case 'workshop':
        return t('workshopInvitationPromptText', {
          inviterName: invitation.inviter_name,
          workshopName: invitation.workshop_name
        });
      case 'organization':
        return t('organizationInvitationPromptText', {
          inviterName: invitation.inviter_name,
          organizationName: invitation.organization_name
        });
      case 'garage':
        return t('garageInvitationPromptText', {
          inviterName: invitation.inviter_name,
          garageName: invitation.garage_name
        });
      case 'vehicle':
        return t('garageInvitationPromptText', {
          inviterName: invitation.inviter_name,
          garageName: invitation.garage_name
        });
      default:
        return '';
    }
  };

  render() {
    const { t, user, service, organization, trialIsEnded } = this.props;

    let invitations = [];
    if (service === SERVICE_WORKSHOP) {
      invitations = user.invitations.filter((inv) => inv.permission_type === INVITATION_WORKSHOP);
    } else {
      invitations = user.invitations.filter((inv) => FLEET_INVITATION_TYPES.includes(inv.permission_type));
    }

    const invitation = invitations && invitations.length > 0 && invitations[0];
    return (
      <CBMediaQuery>
        {screenSize => (
          <div>
            <div style={getStyle(screenSize, styles, 'welcomeTextContainer')}>
              <WelcomeText />
            </div>
            <p className="font-titillium-web-extra-light" style={styles.default.headerTextSub}>
              {service === SERVICE_WORKSHOP && t('noWorkshopText')}
              {service === SERVICE_FLEET && !trialIsEnded && t('noOrganizationText')}
              {service === SERVICE_FLEET && trialIsEnded && organization && t('trialEndedText', { organizationName: organization.name, trialEnd: formatDate(organization.trial_end) })}
              <br />
              <a style={{color: 'white'}} href="mailto:info@ovio.fi">info@ovio.fi</a>
            </p>

            {
              invitation && (
                <CBPromptModal
                  modalIsOpen={!!invitation}
                  closeModal={() => this.declineInvitation(invitation)}
                  afterOpenModal={() => { }}
                  title={this.getInvitationTitle(invitation)}
                  text={this.getInvitationText(invitation)}
                  buttonText={t('invitationPromptAccept')}
                  cancelText={t('invitationPromptDecline')}
                  performAction={() => this.acceptInvitation(invitation)}
                />
              )
            }

          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    garageContainer: {
      borderTop: '1px solid rgba(255, 255, 255, .3)',
      borderBottom: '1px solid rgba(255, 255, 255, .3)',
      minWidth: 800,
      paddingTop: 32,
      paddingBottom: 32,
    },
    headerTextSub: {
      color: '#FFF',
      fontSize: 20,
      textAlign: 'center',
      margin: 0,
    },
    garageHeadingContainer: {
      textAlign: 'center',
      marginBottom: 32,
    },
    garageButtonContainer: {
      marginBottom: 16,
    },
    welcomeTextContainer: {
      marginBottom: 16,
      marginTop: 66,
    },
    welcomeTextSubContainer: {
      marginBottom: 75,
    },
    buttonContainer: {
      marginBottom: 110,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 350,
    },
    bodyContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 32,
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    service: state.app.service,
  };
}

export default connect(
  mapStateToProps,
  {
    acceptInvitation: _acceptInvitation,
    declineInvitation: _declineInvitation,
  },
)(translate('HomeView')(NoOrganizationView));
