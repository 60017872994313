import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { fetchTermsOfService as _fetchTermsOfService } from '../../../actions/authUserActions';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';

class TermsOfServiceView extends React.Component {
  componentDidMount = () => {
    const { fetchTermsOfService } = this.props;

    fetchTermsOfService();
  };

  render() {
    const { tos } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'contentContainer')}>
              {tos && tos[0] && (
              <p>
                {tos[0].content}
              </p>
              )}
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      paddingBottom: 50,
    },
    contentContainer: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 40,
      width: '100%',
      borderRight: '1px solid rgba(255, 255, 255, 0.4)',
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    contentContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      width: '100%',
      borderRight: 0,
      marginBottom: 20,
    },
  },
};

function mapStateToProps(state) {
  return {
    tos: state.authUser.tos,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchTermsOfService: _fetchTermsOfService,
  },
)(translate('SettingsView')(TermsOfServiceView));
