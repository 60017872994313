import {
  API, GET, POST, PUT, DELETE,
} from '../middleware/api';
import { API_URL } from '../config';

export function fetchInvisibilityPeriods(workshopId) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/invisibilityperiod/`,
      types: [
        'FETCH_INVISIBILITYPERIODS_REQUEST',
        'FETCH_INVISIBILITYPERIODS_SUCCESS',
        'FETCH_INVISIBILITYPERIODS_FAIL',
      ],
      params: {
        workshop_id: workshopId,
      },
    },
  };
}
export function createInvisibilityPeriod(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/invisibilityperiod/`,
      types: [
        'CREATE_INVISIBILITYPERIOD_REQUEST',
        'CREATE_INVISIBILITYPERIOD_SUCCESS',
        'CREATE_INVISIBILITYPERIOD_FAIL',
      ],
      data,
    },
  };
}
export function updateInvisibilityPeriod(invisibilityPeriodId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/invisibilityperiod/${invisibilityPeriodId}/`,
      types: [
        'UPDATE_INVISIBILITYPERIOD_REQUEST',
        'UPDATE_INVISIBILITYPERIOD_SUCCESS',
        'UPDATE_INVISIBILITYPERIOD_FAIL',
      ],
      data,
    },
  };
}
export function deleteInvisibilityPeriod(invisibilityPeriodId, workshopId) {
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/invisibilityperiod/${invisibilityPeriodId}`,
      types: [
        'DELETE_INVISIBILITYPERIOD_REQUEST',
        'DELETE_INVISIBILITYPERIOD_SUCCESS',
        'DELETE_INVISIBILITYPERIOD_FAIL',
      ],
      params: {
        workshop_id: workshopId,
      },
    },
  };
}
