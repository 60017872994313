import React, { Component } from 'react';
import { getStyle, styleMerge } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';

class VehicleListTh extends Component {
  render() {
    const { fieldName, text, changeOrdering, orderingSymbol, notSortable } = this.props;
    if (changeOrdering) {
      return (
        <CBMediaQuery>
          {(screenSize) => (
            <th
              onClick={() => notSortable ? ()=>{} : changeOrdering(fieldName)}
              style={styleMerge(getStyle(screenSize, styles, 'tableHeader'), notSortable ? {} : getStyle(screenSize, styles, 'clickable'))}
            >
              {text}
              {
                  orderingSymbol && (
                  <img
                    alt=''
                    src={orderingSymbol}
                  />
                  )
              }
            </th>
          )}
        </CBMediaQuery>
      );
    }

    return (
      <CBMediaQuery>
        {(screenSize) => (
          <th style={getStyle(screenSize, styles, 'tableHeader')}>
            {text}
          </th>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    tableHeader: {
      fontFamily: 'TitilliumWeb-Bold',
      color: '#FFFFFF',
      WebkitUserSelect: 'none',
      MsUserSelect: 'none',
      UserSelect: 'none',
      padding: 16,
    },
    clickable: {
      cursor: 'pointer',
    },
  },
};

export default VehicleListTh;
