import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle } from '../../../utils/utils';
import CBLabelAndValue from '../../shared/CBLabelAndValue';
import LicensePlate from '../../shared/LicensePlate';
import CBButtonSettingsWhite from '../../shared/CBButtonSettingsWhite';
import {
  updateVehiclePermission as _updateVehiclePermission,
  createVehiclePermission as _createVehiclePermission,
  deleteVehiclePermission as _deleteVehiclePermission,
} from '../../../actions/vehiclePermissionActions';
import CBPromptModal from '../../shared/CBPromptModal';

class VehiclePermissionView extends React.Component {
  state = {
    userSearch: '',
    deleteModalIsOpen: false,
  };

  openDeletePromptModal = () => {
    this.setState({ deleteModalIsOpen: true });
  }

  closeDeletePromptModal = () => {
    this.setState({ deleteModalIsOpen: false });
  }

  deleteVehiclePermission = () => {
    const { permission, deleteVehiclePermission } = this.props;
    deleteVehiclePermission(permission.id);
  }

  userSearchChanged = changedObject => {
    this.setState({ userSearch: changedObject.value });
  };

  addUser = value => {
    const { permission, createVehiclePermission } = this.props;
    createVehiclePermission({
      email: value,
      vehicle: permission.vehicle.id,
    });
    this.setState({ userSearch: '' });
  };

  handleChangeShareAll = () => {
    const { permission, updateVehiclePermission } = this.props;
    updateVehiclePermission(permission.id, { public_sharing: !permission.public_sharing });
  };

  handleChangeShareUsers = () => {
    const { permission, updateVehiclePermission } = this.props;
    updateVehiclePermission(permission.id, { user_sharing: !permission.user_sharing });
  };

  handleChangeSharePics = () => {
    const { permission, updateVehiclePermission } = this.props;
    updateVehiclePermission(permission.id, { public_images: !permission.public_images });
  };

  handleChangeShareMaintenances = () => {
    const { permission, updateVehiclePermission } = this.props;
    updateVehiclePermission(permission.id, {
      public_maintenances: !permission.public_maintenances,
    });
  };

  handleChangeShareTreatments = () => {
    const { permission, updateVehiclePermission } = this.props;
    updateVehiclePermission(permission.id, { public_treatments: !permission.public_treatments });
  };

  handleChangeShareInspections = () => {
    const { permission, updateVehiclePermission } = this.props;
    updateVehiclePermission(permission.id, { public_inspections: !permission.public_inspections });
  };

  render() {
    const { permission, vehiclePermissionRequest, t } = this.props;
    const { vehicle } = permission;
    const { userSearch, deleteModalIsOpen } = this.state;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'vehicleContainer')}>
              <div style={getStyle(screenSize, styles, 'vehicleImageContainer')}>
                <img
                  style={getStyle(screenSize, styles, 'vehicleImage')}
                  alt='Vehicle'
                  src={vehicle.images[0] ? vehicle.images[0].image : '/img/placeholder.png'}
                />
              </div>
              <p
                className='font-titillium-web-light'
                style={getStyle(screenSize, styles, 'vehicleName')}
              >
                {`${vehicle.manufacturer} ${vehicle.model_name}`}
              </p>
              <div style={getStyle(screenSize, styles, 'licencePlateContainer')}>
                <LicensePlate plateNumber={vehicle.registration_number} size='small' />
              </div>
            </div>
            <div style={getStyle(screenSize, styles, 'permissionDataContainer')}>
              <CBLabelAndValue
                label={t('permissionTypeLabel')}
                value={permission.permission_type_display}
              />
            </div>
            {/* {permission.permission_type === 'owner' && (
              <React.Fragment>
                <div style={getStyle(screenSize, styles, 'shareUserContainer')}>
                  <CBLabelAndSwitch
                    labelText={t('permissionUserSwitch')}
                    size='medium'
                    checked={permission.user_sharing}
                    onChange={this.handleChangeShareUsers}
                  />
                  {permission.children.map(cPermission => (
                    <VehiclePermissionUser key={cPermission.id} permission={cPermission} />
                  ))}

                  <div>
                    <CBInputField
                      labelText=''
                      name='email'
                      additionalErrorFields={['non_field_errors']}
                      onChange={this.userSearchChanged}
                      type='email'
                      value={userSearch}
                      isValidCallback={() => {}}
                      validators={[getEmailValidator()]}
                      serverErrors={vehiclePermissionRequest.errorCreation || undefined}
                      placeholder={` + ${t('addUserPlaceholder')}`}
                      inputStyle={{
                        width: '100%',
                        border: '1px solid rgba(255, 255, 255, 0.4)',
                        fontSize: 14,
                        color: '#ffffff',
                        backgroundColor: 'transparent',
                        margin: 0,
                        padding: 6,
                      }}
                      enterAction={value => {
                        this.addUser(value);
                        this.setState({ userSearch: '' });
                      }}
                    />
                  </div>

                  <p style={getStyle(screenSize, styles, 'smallText')}>
                    {t('permissionShareUserDescription')}
                  </p>
                </div>
                <div style={getStyle(screenSize, styles, 'shareAllContainer')}>
                  <CBLabelAndSwitch
                    labelText={t('permissionAllSwitch')}
                    size='medium'
                    checked={permission.public_sharing}
                    onChange={this.handleChangeShareAll}
                  />
                  <CBLabelAndValue label={t('permissionDataToShowHeading')} value='' />
                  <CBLabelAndSwitch
                    labelText={t('permissionPicturesSwitch')}
                    size='small'
                    checked={permission.public_images}
                    onChange={this.handleChangeSharePics}
                  />
                  <CBLabelAndSwitch
                    labelText={t('permissionMaintenancesSwitch')}
                    size='small'
                    checked={permission.public_maintenances}
                    onChange={this.handleChangeShareMaintenances}
                  />
                  <CBLabelAndSwitch
                    labelText={t('permissionTreatmentsSwitch')}
                    size='small'
                    checked={permission.public_treatments}
                    onChange={this.handleChangeShareTreatments}
                  />
                  <CBLabelAndSwitch
                    labelText={t('permissionInspectionSwitch')}
                    size='small'
                    checked={permission.public_inspections}
                    onChange={this.handleChangeShareInspections}
                  />
                  <p style={getStyle(screenSize, styles, 'smallText')}>
                    {t('permissionShareAllDescription')}
                  </p>
                </div>
              </React.Fragment>
            )} */}
            <div style={getStyle(screenSize, styles, 'removeVehicleContainer')}>
              <CBButtonSettingsWhite text={t('removeVehicleButton')} onClick={this.openDeletePromptModal} />
            </div>
            <CBPromptModal
              modalIsOpen={deleteModalIsOpen}
              closeModal={this.closeDeletePromptModal}
              afterOpenModal={() => {}}
              title={t('deletePermissionPromptTitle')}
              text={t('deletePermissionPromptText')}
              buttonText={t('deleteTreatmentPromptButton')}
              performAction={() => this.deleteVehiclePermission(permission.id)}
            />
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      marginBottom: 32,
      borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
    },
    vehicleImage: {
      width: 200,
      margin: '-15px 0 0 -40px',
    },
    vehicleImageContainer: {
      width: 120,
      height: 120,
      overflow: 'hidden',
    },
    vehicleName: {
      fontSize: 22,
    },
    smallText: {
      fontSize: 12,
    },
    vehicleContainer: {
      marginBottom: 16,
    },
    shareUserContainer: {
      marginBottom: 48,
    },
    shareAllContainer: {
      marginBottom: 32,
    },
    removeVehicleContainer: {
      marginBottom: 32,
      display: 'flex',
      justifyContent: 'center',
    },
    permissionDataContainer: {
      marginBottom: 16,
    },
  },
};

function mapStateToProps(state) {
  return {
    vehiclePermissionRequest: state.vehiclePermission,
  };
}

export default connect(
  mapStateToProps,
  {
    createVehiclePermission: _createVehiclePermission,
    updateVehiclePermission: _updateVehiclePermission,
    deleteVehiclePermission: _deleteVehiclePermission,
  },
)(translate('SettingsView')(VehiclePermissionView));
