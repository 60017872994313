import React, { Component } from 'react';
import { translate } from 'react-i18next';

class CBSettingsButton extends Component {
  onKeyUp = event => {
    const { onClick } = this.props;
    event.preventDefault();
    if (event.keyCode === 13) {
      onClick();
    }
  };

  render() {
    const {
      t, onClick, disabled, text,
    } = this.props;
    return (
      <div
        style={disabled ? styles.containerDisabled : styles.container}
        onClick={disabled ? undefined : onClick}
        role='button'
        tabIndex={0}
        onKeyUp={this.onKeyUp}
        className="opacity-and-border-on-hover"
      >
        <p style={styles.label}>
          {text}
        </p>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid rgba(255,255,255,.3)',
    borderRadius: 50,
    paddingLeft: 10,
    paddingRight: 10,
    height: 40,
    marginRight: 10,
    cursor: 'pointer',
  },
  containerDisabled: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid rgba(255,255,255,.3)',
    borderRadius: 50,
    paddingLeft: 10,
    paddingRight: 10,
    height: 40,
    marginRight: 10,
    cursor: 'unset',
    opacity: 0.5,
  },
  icon: {
    height: 35,
    width: 35,
  },
  label: {
    fontSize: 14,
    margin: 0,
  },
};

export default translate()(CBSettingsButton);
