import {
  API, GET, POST, PUT, PATCH,
} from '../middleware/api';
import { API_URL } from '../config';

export function fetchAuthUser() {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/auth_user/`,
      types: ['FETCH_USER_REQUEST', 'FETCH_USER_SUCCESS', 'FETCH_USER_FAIL'],
    },
  };
}

export function updateUserProfile(profileData, userId) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/user_profile/${userId}/`,
      types: ['UPDATE_PROFILE_REQUEST', 'UPDATE_PROFILE_SUCCESS', 'UPDATE_PROFILE_FAIL'],
      data: profileData,
    },
  };
}

export function updateUserProfilePicture(profileData, userId) {
  return {
    [API]: {
      method: PATCH,
      url: `${API_URL}/uiapi/1/user_profile/${userId}/`,
      types: ['UPDATE_PROFILE_REQUEST', 'UPDATE_PROFILE_SUCCESS', 'UPDATE_PROFILE_FAIL'],
      data: profileData,
    },
  };
}

export function fetchTermsOfService() {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/terms_of_service/`,
      types: ['FETCH_TOS_REQUEST', 'FETCH_TOS_SUCCESS', 'FETCH_TOS_FAIL'],
    },
  };
}

export function fetchInstructions() {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/instruction/`,
      types: [
        'FETCH_INSTRUCTIONS_REQUEST',
        'FETCH_INSTRUCTIONS_SUCCESS',
        'FETCH_INSTRUCTIONS_FAIL',
      ],
    },
  };
}

export function fetchAdminWorkshop(workshop_id) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/workshop/${workshop_id}/`,
      types: [
        'FETCH_ADMIN_WORKSHOP_REQUEST',
        'FETCH_ADMIN_WORKSHOP_SUCCESS',
        'FETCH_ADMIN_WORKSHOP_FAIL',
      ],
    }
  };
}

export function acceptInvitation(invitationId) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/invitation/${invitationId}/accept_invitation/`,
      types: [
        'ACCEPT_INVITATION_REQUEST',
        'ACCEPT_INVITATION_SUCCESS',
        'ACCEPT_INVITATION_FAIL',
      ],
    },
  };
}

export function declineInvitation(invitationId) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/invitation/${invitationId}/reject_invitation/`,
      types: [
        'DECLINE_INVITATION_REQUEST',
        'DECLINE_INVITATION_SUCCESS',
        'DECLINE_INVITATION_FAIL',
      ],
    },
  };
}

export function acceptCareServiceInvitation(invitationId, data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/care_service_invitation/${invitationId}/accept/`,
      types: [
        'ACCEPT_CARE_SERVICE_INVITATION_REQUEST',
        'ACCEPT_CARE_SERVICE_INVITATION_SUCCESS',
        'ACCEPT_CARE_SERVICE_INVITATION_FAIL',
      ],
      data,
    },
  };
}

export function declineCareServiceInvitation(invitationId) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/care_service_invitation/${invitationId}/reject/`,
      types: [
        'DECLINE_CARE_SERVICE_INVITATION_REQUEST',
        'DECLINE_CARE_SERVICE_INVITATION_SUCCESS',
        'DECLINE_CARE_SERVICE_INVITATION_FAIL',
      ],
    },
  };
}

export function clearOwnWorkshop() {
  return {
    type: 'CLEAR_OWN_WORKSHOP',
  };
}