import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { ProgressSpinner } from 'primereact/progressspinner';
import { getStyle, SERVICE_FLEET, SERVICE_WORKSHOP } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBButtonSettingsWhite from '../../shared/CBButtonSettingsWhite';
import CBSearchInput from '../../shared/CBSearchInput';
import CareServiceListItem from './CareServiceListItem';
import {
  fetchCareServices as _fetchCareServices,
  deleteCareService as _deleteCareService,
} from '../../../actions/careServiceActions';
import CareServiceForm from './CareServiceForm';
import CBHeaderTextWithHelp from '../../shared/CBHeaderTextWithHelp';
import CBButtonWithIconSmall from '../../shared/CBButtonWithIconSmall';

class SettingsCareServices extends React.Component {
  state = {
    pageNumber: 1,
    search: '',
    selectedCareService: undefined,
  };

  componentDidMount = () => {
    this.fetchCareServices();
  };

  componentWillReceiveProps(nextProps) {
    const { selectedCareService } = this.state;

    if (nextProps.careServices && selectedCareService) {
      const careService = nextProps.careServices.find(
        (newOrg) => newOrg.id === selectedCareService.id,
      );
      this.setState({
        selectedCareService: careService,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedWorkshopId, selectedOrganizationId } = this.props;

    if (selectedWorkshopId != prevProps.selectedWorkshopId
      || selectedOrganizationId != prevProps.selectedOrganizationId) {
      this.setState({
        pageNumber: 1,
        search: '',
        selectedCareService: undefined,
      });
      this.fetchCareServices();
    }
  };

  addCareService = () => {
    const careService = {
      name: '',
      business_id: '',
      main_user_email: '',
      garages: [],
    };

    this.setState({ selectedCareService: Object.assign({}, careService) });
  };

  selectCareService = (careService) => {
    this.setState({ selectedCareService: Object.assign({}, careService) });
  };

  newCareServiceCreated = () => this.setState({ selectedCareService: undefined });

  changeSearchTerms = value => {
    this.setState({
      search: value,
    });
  }

  fetchCareServices = () => {
    const { fetchCareServices, selectedWorkshopId, selectedOrganizationId, service } = this.props;
    const { search } = this.state;

    let serviceParams = {};

    switch(service) {
      case SERVICE_WORKSHOP:
        serviceParams = {workshop_id: selectedWorkshopId};
        break;
      case SERVICE_FLEET:
        serviceParams = {organization_id: selectedOrganizationId};
        break;
      default:
        break;
    }

    fetchCareServices({ ...serviceParams, search});
  }

  render() {
    const {
      t, careServices, loading, careServiceRequest, deleteCareService, user,
    } = this.props;

    const {
      selectedCareService,
    } = this.state;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'editCareServiceContainer')}>
              {
               false && <CBHeaderTextWithHelp text={t('editCareServiceHeading')} />
              }
              {
                selectedCareService && (
                  <CareServiceForm
                    user={user}
                    careService={selectedCareService}
                    careServiceRequest={careServiceRequest}
                    isOwnCareService={false}
                    deleteCareService={deleteCareService}
                    newCareServiceCreated={this.newCareServiceCreated}
                  />
                )
              }
            </div>
            <div style={getStyle(screenSize, styles, 'searchCareServiceContainer')}>
              <CBHeaderTextWithHelp text={t('careServicesHeading')} />
              <div>
                <div style={getStyle(screenSize, styles, 'searchContainer')}>
                  <CBSearchInput
                    containerStyle={{ marginLeft: 0 }}
                    changeSearchTerms={this.changeSearchTerms}
                    onBlur={this.fetchWithSearch}
                  />
                </div>
                <div>
                  <table style={getStyle(screenSize, styles, 'table')}>
                    <thead>
                      <tr>
                        {
                          screenSize === 'large' && (
                            <th style={getStyle(screenSize, styles, 'tableHeader')}>Id</th>
                          )
                        }
                        <th style={getStyle(screenSize, styles, 'tableHeader')}>{t('careServiceTableOrganization')}</th>
                        <th style={getStyle(screenSize, styles, 'tableHeader')}>{t('careServiceTableWorkshop')}</th>
                        <th style={getStyle(screenSize, styles, 'tableHeader')}>{t('careServiceTableCreatedAt')}</th>

                        <th style={getStyle(screenSize, styles, 'tableHeader')} />
                      </tr>
                    </thead>
                    <tbody>
                      {careServices.map(careService => (
                        <CareServiceListItem
                          key={careService.id}
                          careService={careService}
                          onEditClick={this.selectCareService}
                        />
                      ))}
                    </tbody>
                  </table>
                  <div style={getStyle(screenSize, styles, 'loadMoreContainer')}>
                    {
                      loading && (
                        <ProgressSpinner />
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      paddingBottom: 50,
    },
    editCareServiceContainer: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
      width: '40%',
      borderRight: '1px solid rgba(255, 255, 255, 0.4)',
      minHeight: 500,
    },
    searchCareServiceContainer: {
      width: '60%',
      padding: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
    searchContainer: {
      marginBottom: '16px',
      maxWidth: '400px',
    },
    table: {
      width: '100%',
    },
    tableHeader: {
      fontFamily: 'TitilliumWeb-Light',
      color: '#FFFFFF',
      cursor: 'pointer',
      WebkitCareServiceSelect: 'none',
      MsCareServiceSelect: 'none',
      CareServiceSelect: 'none',
    },
    loadMoreContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 16,
    },
  },
  medium: {
    container: {
      paddingBottom: 25,
    },
    editCareServiceContainer: {
      paddingLeft: 0,
      paddingRight: 15,
      paddingTop: 15,
    },
    searchCareServiceContainer: {
      padding: 15,
      paddingLeft: 15,
      paddingRight: 0,
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    editCareServiceContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      width: '100%',
      borderRight: 0,
      marginBottom: 20,
      minHeight: 0,
    },
    searchCareServiceContainer: {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: 20,
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    careServiceRequest: state.careService,
    careServices: state.careService.careServices,
    loading: state.careService.careServiceListStatus.loading,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    selectedOrganizationId: state.organization.selectedOrganization,
    service: state.app.service
  };
}

export default connect(
  mapStateToProps,
  {
    fetchCareServices: _fetchCareServices,
    deleteCareService: _deleteCareService,
  },
)(translate('SettingsView')(SettingsCareServices));
