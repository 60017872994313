import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle } from '../../../utils/utils';

export default class CBButtonHeader extends Component {
  render() {
    const {
      containerStyle, to, icon, text,
    } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <Link style={containerStyle} to={to}>
            <div style={getStyle(screenSize, styles, 'container')}>
              {screenSize !== 'small' && (
                <p className='font-titillium-web-regular' style={getStyle(screenSize, styles, 'label')}>
                  {text}
                </p>
              )}
              <img src={icon} alt='Icon' style={getStyle(screenSize, styles, 'icon')} />
            </div>
          </Link>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: 10,
      paddingRight: 10,
      height: '100%',
      width: '100%',
    },
    icon: {
      height: 35,
      width: 35,
    },
    label: {
      margin: 0,
      fontSize: 14,
      marginRight: 10,
      textTransform: 'uppercase',
    },
  },
  small: {},
};
