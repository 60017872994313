import React, { Component } from 'react';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../../shared/CBMediaQuery';
import '../../../App.css';
import { getStyle } from '../../../utils/utils';
import { getLengthValidator } from '../../../utils/validators';
import {
  updateInvisibilityPeriod as _updateInvisibilityPeriod,
  createInvisibilityPeriod as _createInvisibilityPeriod,
} from '../../../actions/invisibilityPeriodActions';
import CBButtonSubmit from '../../shared/CBButtonSubmit';
import CBInputField from '../../shared/form_components/CBInputField';
import CBDatePickerInput from '../../shared/CBDatePickerInput';
import CBModal from '../../shared/CBModal';
import { browserIsIE } from '../../../ie-support';

class InvisibilityPeriodModal extends Component {
  constructor(props) {
    super(props);

    const { invisibilityPeriod: ip } = props;

    this.state = {
      formData: {
        platform_number: ip ? ip.platform_number : '',
        begin: ip
          ? moment(ip.begin)
          : moment(),
        end: ip
          ? moment(ip.end)
          : moment(),
      },
      formValid: {
        platform_number: !!ip,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { invisibilityPeriod: ip } = nextProps;
    this.setState({
      formData: {
        platform_number: ip ? ip.platform_number : '',
        begin: ip
          ? moment(ip.begin)
          : moment(),
        end: ip
          ? moment(ip.end)
          : moment(),
      },
      formValid: {
        platform_number: !!ip,
      },
    });
  }

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
      [name]: isValid,
    };

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const {
      closeModal,
      createInvisibilityPeriod,
      updateInvisibilityPeriod,
      invisibilityPeriod,
      user,
      selectedWorkshopId,
    } = this.props;
    const { formData } = this.state;
    const cleanData = {
      platform_number: formData.platform_number,
      begin: moment(formData.begin).format('YYYY-MM-DD'),
      end: moment(formData.end).format('YYYY-MM-DD'),
      workshop: selectedWorkshopId,
    };
    if (invisibilityPeriod != null) {
      updateInvisibilityPeriod(invisibilityPeriod.id, cleanData);
    } else if (invisibilityPeriod === null) {
      createInvisibilityPeriod(cleanData);
    }
    closeModal();
  };

  changeBeginDate = value => {
    this.updateFormState({
      target: 'begin',
      value,
    });
  };

  changeEndDate = value => {
    this.updateFormState({
      target: 'end',
      value,
    });
  };

  render() {
    const { formData } = this.state;
    const {
      modalIsOpen,
      closeModal,
      afterOpenModal,
      invisibilityPeriod,
      t,
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <CBModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={closeModal}
            title={
              invisibilityPeriod === null
                ? t('addInvisibilityPeriodTitle')
                : t('editInvisibilityPeriodTitle')
            }
            cancelText='Peruuta'
            submitText={t('save')}
            submitForm={this.submitForm}
            isValid={this.getIsValid()}
          >
            <div style={getStyle(screenSize, styles, 'container')}>
              <CBInputField
                name='platform_number'
                inputStyle={browserIsIE() ? getStyle(screenSize, styles, 'inputIE') : getStyle(screenSize, styles, 'input')}
                value={formData.platform_number}
                placeholder='12345678944556444T'
                onChange={this.updateFormState}
                validators={[getLengthValidator(1, 100)]}
                isValidCallback={this.setIsValid}
                labelText={t('platformNumberLabel')}
                labelTextStyle={getStyle(screenSize, styles, 'subTitle')}
              />
              <p
                className='font-titillium-web-semi-bold'
                style={getStyle(screenSize, styles, 'subTitle')}
              >
                {t('dateBeginLabel')}
              </p>
              <div className='datepicker-container-white-bg'>
                <CBDatePickerInput
                  displayedDate={formData.begin}
                  type='date'
                  changeDate={this.changeBeginDate}
                />
              </div>
              <p
                className='font-titillium-web-semi-bold'
                style={getStyle(screenSize, styles, 'subTitle')}
              >
                {t('dateEndLabel')}
              </p>
              <div className='datepicker-container-white-bg'>
                <CBDatePickerInput
                  displayedDate={formData.end}
                  type='date'
                  changeDate={this.changeEndDate}
                />
              </div>
            </div>

          </CBModal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      padding: '16px 32px'
    },
    title: {
      fontSize: 32,
      color: '#0095da',
      textAlign: 'center',
    },
    subTitle: {
      fontSize: 14,
      color: 'black',
      textAlign: 'center',
      fontWeight: 600,
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 180,
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
    },
    inputIE: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 180,
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      border: 'none',
      padding: 8,
    },
    datePickerContainer: {
      marginBottom: 10,
    },
    datePickerContainerIE: {
      marginBottom: 10,
      display: 'flex',
      justifyContent: 'center',
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      padding: '16px 16px'
    }
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    updateInvisibilityPeriod: _updateInvisibilityPeriod,
    createInvisibilityPeriod: _createInvisibilityPeriod,
  },
)(translate('WSSettingsView')(InvisibilityPeriodModal));
