import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { styleMerge } from '../../../utils/utils';

class CBSubmitButton extends Component {
  onKeyUp = event => {
    const { onClick } = this.props;
    event.preventDefault();
    if (event.keyCode === 13) {
      onClick();
    }
  };

  getContainerStyle = () => {
    const { disabled, isDeleteButton } = this.props;

    let style = styleMerge(styles.container, disabled ? styles.containerDisabled : {});

    if (isDeleteButton) {
      style = styleMerge(style, styles.containerDelete);
    }

    return style;
  }

  getButtonText = () => {
    const { t, isDeleteButton, text } = this.props;

    if (text) {
      return text;
    }

    return isDeleteButton ? t('deleteButton') : t('saveChangesButton');
  }

  getButtonIcon = () => {
    const { isDeleteButton } = this.props;

    return isDeleteButton ? '/img/icon_trashcan.svg' : '/img/icon_checkmark.svg';
  }

  render() {
    const { t, onClick, disabled } = this.props;
    return (
      <div
        style={this.getContainerStyle()}
        onClick={disabled ? undefined : onClick}
        role='button'
        tabIndex={0}
        onKeyUp={this.onKeyUp}
        className='opacity-and-border-on-hover'
      >
        <img src={this.getButtonIcon()} alt='Submit' style={styles.icon} />
        <p style={styles.label}>
          { this.getButtonText() }
        </p>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid rgba(255,255,255,.3)',
    borderRadius: 50,
    paddingLeft: 10,
    paddingRight: 10,
    height: 40,
    marginRight: 10,
    cursor: 'pointer',
  },
  containerDelete: {
    backgroundColor: '#d04e4e',
  },
  containerDisabled: {
    cursor: 'unset',
    opacity: 0.5,
  },
  icon: {
    height: 35,
    width: 35,
  },
  label: {
    fontSize: 14,
    margin: 0,
    marginLeft: 10,
  },
};

export default translate()(CBSubmitButton);
