import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import InspectionListItem from './ListItems/InspectionListItem';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle, getUrlId } from '../../../utils/utils';
import CBSelect from '../../shared/CBSelect';

class VehicleDetailsInspectionList extends React.Component {
  inspectionSelected = e => {
    const { history, vehicle } = this.props;
    history.push(`/vehicle/${vehicle.id}/inspections/${e.target.value}`);
  };

  render() {
    const { vehicle, location } = this.props;
    const { inspections } = vehicle;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div
            style={getStyle(
              screenSize,
              styles,
              screenSize !== 'small' ? 'listContainer' : 'container',
            )}
          >
            {screenSize !== 'small' && (
              <div>
                {inspections.map(inspection => (
                  <InspectionListItem
                    key={inspection.id}
                    vehicle={vehicle}
                    inspection={inspection}
                  />
                ))}
              </div>
            )}
            {screenSize === 'small' && (
              <div style={getStyle(screenSize, styles, 'selectContainer')}>
                <CBSelect value={getUrlId(location.pathname)} onChange={this.inspectionSelected}>
                  {inspections.map(inspection => (
                    <option key={inspection.id} value={inspection.id}>
                      {`${moment(inspection.date).format('D.M.Y')} | ${
                        inspection.inspection_company_name
                      }`}
                    </option>
                  ))}
                </CBSelect>
              </div>
            )}
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
      width: '25%',
    },
    selectContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 40,
      marginRight: 15,
      marginLeft: 15,
      marginTop: 40,
    },
  },
  small: {
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
      width: '100%',
      maxHeight: 300,
      overflow: 'auto',
    },
  },
};

export default withRouter(VehicleDetailsInspectionList);
