import React from 'react';
import { translate } from 'react-i18next';
import { Button } from 'primereact/button';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBInputField from '../../shared/form_components/CBInputField';
import CBSelectField from '../../shared/form_components/CBSelectField';
import { getEmailValidator } from '../../../utils/validators';
import CBButtonWithIconSmall from '../../shared/CBButtonWithIconSmall';
import CBPromptModal from '../../shared/CBPromptModal';

class PermissionRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: Object.assign({}, props.permission),
      deleteModalIsOpen: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ formData: Object.assign({}, nextProps.permission) });
  }

  getRoleOptions = () => {
    const { t, userRole, canEdit, isGaragePermission } = this.props;

    const options = [
      {
        label: t('roleManager'),
        value: 'manager',
      },
      {
        label: t('roleEmployee'),
        value: 'employee',
      },
    ];

    if (!canEdit || userRole === 'admin') {
      if (isGaragePermission) {
        options.unshift({
          label: `${t('roleManager')} ${t('roleCanAdd')}`,
          value: 'manager_plus',
        });
      }
      options.unshift({
        label: t('roleAdmin'),
        value: 'admin',
      });
    }

    return options;
  };

  descriptionChanged = (valueObj) => {
    const { formData } = this.state;
    const { value } = valueObj;

    const newFormData = Object.assign({}, formData);
    newFormData.description = value;
    this.setState({ formData: newFormData });
  };

  updateRoleDescription = (value) => {
    const { updatePermission, isNew, permission } = this.props;
    const { formData } = this.state;

    if (permission.description === value) return;

    const newFormData = Object.assign({}, formData);
    newFormData.description = value;
    this.setState({ formData: newFormData });

    if (!isNew) {
      updatePermission(formData.id, newFormData);
    }
  };

  updateRole = (option) => {
    const { updatePermission, isNew } = this.props;
    const { formData } = this.state;
    const { value } = option;

    if (formData.role === value) return;

    const newFormData = Object.assign({}, formData);

    if (value === 'manager_plus') {
      newFormData.role = 'manager';
      newFormData.can_add_vehicles = true;
    } else {
      newFormData.role = value;
      newFormData.can_add_vehicles = false;
    }

    this.setState({ formData: newFormData });

    if (!isNew) {
      updatePermission(formData.id, newFormData);
    }
  };

  deletePermission = () => {
    const { deletePermission } = this.props;
    const { formData } = this.state;

    deletePermission(formData.id, formData.vehicle || formData.garage || formData.workshop || formData.organization);
  };

  newPermissionEmailChanged = (email) => {
    const { formData } = this.state;

    const newFormData = Object.assign({}, formData);

    newFormData.user_email = email.value;

    this.setState({
      formData: newFormData,
    });
  };

  createNewPermission = () => {
    const {
      createPermission,
      vehicle,
      garage,
      isWorkshopPermission,
      workshop,
      isOrganizationPermission,
      organization
    } = this.props;
    const { formData } = this.state;

    const { validator } = getEmailValidator();
    if (formData.user_email && validator(formData.user_email)) {

      const data = {
        user_email: formData.user_email,
        role: formData.role,
        description: formData.description,
      };

      if (isWorkshopPermission) {
        data.workshop = workshop.id;
      } else if(isOrganizationPermission) {
        data.organization = organization.id;
      } else {
        data.garage = garage.id;
      }

      if (vehicle) {
        data.vehicle = vehicle.id;
      }

      createPermission(data);
    }
  };

  isValid = () => {
    const { validator } = getEmailValidator();
    const { formData } = this.state;
    return formData.user_email && validator(formData.user_email);
  };

  render() {
    const { formData } = this.state;
    const {
      user,
      description,
      user_email,
      invitation,
      can_add_vehicles = false,
    } = formData;
    let { role } = formData;

    if (can_add_vehicles && role === 'manager') {
      role = 'manager_plus';
    }

    const { isNew, t, canEdit } = this.props;
    const { deleteModalIsOpen } = this.state;
    return (
      <CBMediaQuery>
        {screenSize => (
          <React.Fragment>
            <tr style={getStyle(screenSize, styles, 'row')}>
              <td style={getStyle(screenSize, styles, 'tableTd')}>
                {
                  isNew && (
                    <CBInputField
                      className='white-placeholder-input'
                      value={ user_email }
                      onChange={this.newPermissionEmailChanged}
                      placeholder=' + Lisää uusi käyttöoikeus sähköpostilla'
                      validators={[getEmailValidator()]}
                      noValidationOnChange
                      blurOnEnter
                    />
                  )
                }
                {!isNew && user && user.profile && (`${user.profile.first_name} ${user.profile.last_name} (${user.email})`)}
                {!isNew && !user && invitation && (`${invitation.email} (${t('pending')})`)}
              </td>
              <td style={getStyle(screenSize, styles, 'tableTd')}>
                <CBInputField
                  value={description}
                  onBlur={this.updateRoleDescription}
                  onChange={this.descriptionChanged}
                  noValidationOnChange
                  blurOnEnter
                  disabled={!canEdit}
                />
              </td>
              <td style={getStyle(screenSize, styles, 'tableTd')}>
                <CBSelectField
                  optionStyle={getStyle(screenSize, styles, 'optionStyle')}
                  value={role}
                  options={this.getRoleOptions()}
                  onChange={this.updateRole}
                  disabled={!canEdit}

                />
              </td>
              <td style={getStyle(screenSize, styles, 'tableTdDelete')}>
                {
                  !isNew && canEdit && (
                    <Button
                      icon='pi pi-times'
                      rounded
                      outlined
                      severity='danger'
                      aria-label='Cancel'
                      size='medium'
                      onClick={() => this.setState({deleteModalIsOpen: true})}
                    />
                  )
                }
                {
                  isNew && (
                    <CBButtonWithIconSmall
                      text={t('addPermission')}
                      onClick={this.createNewPermission}
                      icon='/img/icon_plus.svg'
                      disabled={!this.isValid()}
                    />
                  )
                }
              </td>
            </tr>
            <CBPromptModal
              modalIsOpen={deleteModalIsOpen}
              closeModal={() => this.setState({deleteModalIsOpen: false})}
              afterOpenModal={() => {}}
              title={t('deletePermissionTitle')}
              text={t('deletePermissionText')}
              buttonText={t('deletePermissionButton')}
              performAction={() => this.deletePermission()}
            />
          </React.Fragment>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
    },
    tableTd: {
      border: '1px solid rgba(255, 255, 255, .3)',
      color: '#FFFFFF',
      paddingLeft: 16,
      paddingRight: 16,
    },
    tableTdDelete: {
      paddingLeft: 16,
      paddingRight: 16,
    },
    optionStyle: {
      backgroundColor: 'rgb(0, 149, 218)',
    },
    row: {
      marginBottom: 8,
    },
  },
};

export default translate('Permissions')(PermissionRow);
