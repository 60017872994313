import {
  API, GET, POST, PUT, DELETE,
} from '../middleware/api';
import { API_URL } from '../config';

export function fetchTasks(params, workshopId = undefined, organizationId = undefined) {
  const prefix = workshopId ? 'cs_' : '';

  const newParams = {
    ...params,
  };

  if (workshopId) {
    newParams.workshop_id = workshopId;
  }

  if (organizationId) {
    newParams.organization_id = organizationId;
  }

  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/${prefix}task/`,
      types: [
        'FETCH_TASKS_REQUEST',
        'FETCH_TASKS_SUCCESS',
        'FETCH_TASKS_FAIL',
      ],
      params: newParams,
    },
  };
}

export function createTask(data, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/${prefix}task/${suffix}`,
      types: [
        'CREATE_TASK_REQUEST',
        'CREATE_TASK_SUCCESS',
        'CREATE_TASK_FAIL',
      ],
      data,
    },
  };
}

export function updateTask(taskId, data, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/${prefix}task/${taskId}/${suffix}`,
      types: [
        'UPDATE_TASK_REQUEST',
        'UPDATE_TASK_SUCCESS',
        'UPDATE_TASK_FAIL',
      ],
      data,
    },
  };
}

export function deleteTask(taskId, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/${prefix}task/${taskId}/${suffix}`,
      types: [
        'DELETE_TASK_REQUEST',
        'DELETE_TASK_SUCCESS',
        'DELETE_TASK_FAIL',
      ],
    },
  };
}

export function setTaskStatus(taskId, status, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/${prefix}task/${taskId}/set_status/${suffix}`,
      types: [
        'UPDATE_TASK_REQUEST',
        'UPDATE_TASK_SUCCESS',
        'UPDATE_TASK_FAIL',
      ],
      data: {
        status,
      },
    },
  };
}
