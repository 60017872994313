import React, { createRef } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import { Galleria } from 'primereact/galleria';
import {
  createMessageImage as _createMessageImage,
  fetchVehicleMessage as _fetchVehicleMessage,
  deleteMessageImage as _deleteMessageImage,
  deleteVehicleMessage as _deleteVehicleMessage

} from '../../../actions/vehicleActions';
import { getStyle, SERVICE_WORKSHOP } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSpinner from '../../shared/CBSpinner';
import CBLabelAndValue from '../../shared/CBLabelAndValue';
import TextWithLinebreaks from '../../shared/TextWithLinebreaks';
import AddMessageModal from './AddMessageModal';
import MessageResponse from './MessageResponse';
import MessageEditRow from './MessageEditRow';
import AddImageModal from '../../shared/AddImageModal';
import ImageCarouselModal from './ImageCarouselModal';

import CBPromptModal from '../../shared/CBPromptModal';
import AddMessageResponseModal from './AddMessageResponseModal';
import CBButtonSubmit from '../../shared/CBButtonSubmit';
import CBButtonWithIconSmall from '../../shared/CBButtonWithIconSmall';

class VehicleDetailsMessageContent extends React.Component {
  state = {
    imageIndex: 0,
    addImageModal: false,
    addedImage: null,
    removingImages: false,
    imageModalIsOpen: false,
    deleteModalIsOpen: false,
    modalIsOpen: false,
    responseModalIsOpen: false,
  }

  constructor(props) {
    super(props);
    this.gallery = createRef('gallery');
  }

  componentDidMount = () => {
    const {
      match,
      fetchVehicleMessage,
      vehicle,
      service,
      selectedWorkshopId,
    } = this.props;
    if (match) {
      fetchVehicleMessage(match.params.messageId, service === SERVICE_WORKSHOP && selectedWorkshopId);
    }
    else if (vehicle && vehicle.messages.length) {
      fetchVehicleMessage(vehicle.messages[0].id, service === SERVICE_WORKSHOP && selectedWorkshopId);
    }
  };

  componentDidUpdate(prevProps) {
    const {
      match,
      fetchVehicleMessage,
      service,
      selectedWorkshopId,
    } = this.props;

    if (match && match.params.messageId !== prevProps.match.params.messageId) {
      fetchVehicleMessage(match.params.messageId, service === SERVICE_WORKSHOP && selectedWorkshopId);
    }
  }

  onImageUpload = acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          addedImage: reader.result,
          addImageModal: true,
        });
      };
    });
  };

  uploadImage = (image) => {
    const { createMessageImage } = this.props;
    const message = this.getMessage();
    this.setState({ addImageModal: false });
    if (message) {
      createMessageImage({
        message: message.id,
        image: image,
      });
    }
  }

  toggleImageRemoveMode = () => {
    this.setState(prevState => ({
      ...prevState,
      removingImages: !prevState.removingImages,
    }));
  }

  openImageModal = index => {
    this.setState({ imageModalIsOpen: true, imageIndex: index });
  }

  hideImageModal = () => {
    this.setState({ imageModalIsOpen: false });
  }

  removeImage = id => {
    const { deleteMessageImage } = this.props;
    deleteMessageImage(id);
  }

  deleteVehicleMessage = () => {
    const { deleteVehicleMessage, match, vehicle, service, selected, selectedWorkshopId } = this.props;
    this.setState({
      deleteModalIsOpen: false,
    });
    let messageId = null;
    if (match) {
      messageId = match.params.messageId;
    } else if (vehicle && vehicle.messages.length) {
      messageId = vehicle.messages[0].id
    }
    if (service === SERVICE_WORKSHOP) {
      deleteVehicleMessage(messageId, selectedWorkshopId);
    } else {
      deleteVehicleMessage(messageId);
    }
  };

  getMessage = () => {
    const { vehicle, match, searchRequest } = this.props;
    const { messageIDs } = searchRequest;
    let message = match
      ? vehicle.messages.find(x => x.id == match.params.messageId)
      : undefined;
    if (!message && vehicle && vehicle.messages.length) {
      if (messageIDs) {
        if (messageIDs.length) {
          message = vehicle.messages.find(
            x => x.id === messageIDs[0],
          );
        }
      } else {
        message = vehicle.messages[0];
      }
    }
    return message;
  }

  openDeleteMessageModal = () => {
    this.setState({
      deleteModalIsOpen: true,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      deleteModalIsOpen: false,
    });
  };

  afterOpenDeleteModal = () => {
  };

  editMessage = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  openResponseModal = () => {
    this.setState({
      responseModalIsOpen: true,
    });
  };

  closeResponseModal = () => {
    this.setState({
      responseModalIsOpen: false,
    });
  };

  render() {
    const {
      vehicleRequest, t, match, vehicle,
    } = this.props;

    const {
      addImageModal,
      addedImage,
      removingImages,
      imageIndex,
      imageModalIsOpen,
      modalIsOpen,
      deleteModalIsOpen,
      responseModalIsOpen,
    } = this.state;

    let message = match
      ? vehicle.messages.find(x => x.id == match.params.messageId)
      : undefined;

    if (!message && vehicle && vehicle.messages.length) {
      message = vehicle.messages[0]
    }

    if (vehicleRequest.messageStatus.loading) {
      return (
        <CBMediaQuery>
          {screenSize => (
            <div style={getStyle(screenSize, styles, 'messageContentContainer')}>
              <div>
                <CBSpinner />
              </div>
            </div>
          )}
        </CBMediaQuery>
      );
    } else if (vehicleRequest.messageStatus.error) {
      return (
        <CBMediaQuery>
          {screenSize => (
            <div style={getStyle(screenSize, styles, 'messageContentContainer')}>
              <div className='font-titillium-web-extra-light' style={getStyle(screenSize, styles, 'sectionHeadingText')}>
                Viestiä ei löydy.
              </div>
            </div>
          )}
        </CBMediaQuery>
      );
    } else {
      return (
        <CBMediaQuery>
          {screenSize => (
            <div style={getStyle(screenSize, styles, 'messageContentContainer')}>
              {message && (
                <React.Fragment>
                  <div>
                    <div style={getStyle(screenSize, styles, 'responseButtonContainer')}>
                      <CBButtonWithIconSmall
                        icon='/img/icon_announcements.svg'
                        text={t('addNewResponse')}
                        onClick={this.openResponseModal}
                      />
                    </div>
                    <div
                      className='font-titillium-web-extra-light'
                      style={getStyle(screenSize, styles, 'sectionHeading')}
                    >
                      <div style={getStyle(screenSize, styles, 'sectionHeadingText')}>
                        {message.subject}
                      </div>
                      <div style={getStyle(screenSize, styles, 'additionalMessageInfo')}>
                        <div style={getStyle(screenSize, styles, 'creatorContainer')}>
                          {`${t('messageUserLabel')}: ${message.user_display}`}
                        </div>
                        <div>
                          {`${t('messageCreatedLabel')}: ${moment(message.created_at).format('D.M.YYYY HH:mm')}`}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div style={getStyle(screenSize, styles, 'messageTextContainer')}>
                        <p style={getStyle(screenSize, styles, 'treatmentDescriptionText')}>
                          <TextWithLinebreaks text={message.text} />
                        </p>
                      </div>
                      <div style={getStyle(screenSize, styles, 'galleryContainer')}>
                        <ImageCarouselModal
                          show={imageModalIsOpen}
                          handleClose={this.hideImageModal}
                          imageIndex={imageIndex}
                          images={message.images}
                          showNextImage={this.showNextImage}
                          showPreviousImage={this.showPreviousImage}
                          getBackroundImageUrl={this.getBackroundImageUrl}
                        />
                        <div style={getStyle(screenSize, styles, 'imageContainer')}>
                          {
                            message.images.map((image, index) => (
                              <div
                                style={{
                                  ...getStyle(screenSize, styles, 'squareImage'),
                                  backgroundImage: `url(${image.image})`,
                                }}
                                key={image.id}
                                onClick={removingImages
                                  ? () => {
                                    this.removeImage(image.id);
                                    if (message.images.length === 1) {
                                      this.setState({ removingImages: false });
                                    }
                                  }
                                  : () => this.openImageModal(index)}
                              >
                                {removingImages && <img style={getStyle(screenSize, styles, 'imageRemoveIcon')} src='/img/icon_trashcan.svg' alt={t('removeImg')} />}
                              </div>
                            ))
                          }
                        </div>
                      </div>
                      {
                        message.can_edit && (
                          <MessageEditRow
                            onImageUpload={this.onImageUpload }
                            toggleImageRemoveMode={this.toggleImageRemoveMode}
                            removingImages={removingImages}
                            message={message}
                            openDeleteMessageModal={this.openDeleteMessageModal}
                            editMessage={this.editMessage}
                          />
                        )
                      }
                    </div>
                    <div style={getStyle(screenSize, styles, 'responseContainer')}>
                      <div className='font-titillium-web-extra-light' style={getStyle(screenSize, styles, 'responseHeading')}>
                        {t('responseHeading')}
                      </div>
                      {
                        message.responses && message.responses.map((response) => <MessageResponse key={response.id} response={response} message={message} />)
                      }
                    </div>
                  </div>
                  {addImageModal && (
                    <AddImageModal
                      show={addImageModal}
                      handleClose={this.hideAddImageModal}
                      image={addedImage}
                      onImageSave={(image) => { this.uploadImage(image); }}
                    />
                  )}
                  {
                    modalIsOpen && (
                      <AddMessageModal
                        modalIsOpen={modalIsOpen}
                        closeModal={this.closeModal}
                        afterOpenModal={this.afterOpenModal}
                        message={message}
                        vehicle={vehicle}
                      />
                    )
                  }
                  {
                    responseModalIsOpen && (
                      <AddMessageResponseModal
                        modalIsOpen={responseModalIsOpen}
                        closeModal={this.closeResponseModal}
                        message={message}
                      />
                    )
                  }
                  <CBPromptModal
                    modalIsOpen={deleteModalIsOpen}
                    closeModal={this.closeDeleteModal}
                    afterOpenModal={this.afterOpenDeleteModal}
                    title={t('deleteMessagePromptTitle')}
                    text={t('deleteMessagePromptText')}
                    buttonText={t('deleteMessagePromptButton')}
                    performAction={this.deleteVehicleMessage}
                  />
                </React.Fragment>
              )}
            </div>
          )}
        </CBMediaQuery>
      );
    }
  }
}

const styles = {
  default: {
    messageNameText: {
      fontSize: 32,
    },
    imageContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 32,
    },
    squareImage: {
      width: 100,
      height: 100,
      margin: '0 10px 10px 0',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
      cursor: 'pointer',
    },
    imageRemoveIcon: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
      filter: 'drop-shadow(0px 1px 4px rgba(0,0,0,0.5))',
    },
    galleryContainer: {
      marginBottom: 16,
    },
    responseButtonContainer: {
      width: 200,
      marginBottom: 32,
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    messageDescriptionText: {
      fontSize: 14,
    },
    galleryThumbnailsContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    thumbnail: {
      maxWidth: 120,
      cursor: 'pointer',
    },
    thumbnailContainer: {
      marginRight: 8,
    },
    responseContainer: {
      marginTop: 32,
    },
    sectionHeadingText: {
      fontSize: 24,
      color: 'white',
    },
    responseHeading: {
      fontSize: 20,
      color: 'white',
    },
    messageTextContainer: {
      marginBottom: 16,
    },
    sectionHeading: {
      display: 'flex',
      fontSize: 20,
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      color: 'white',
      marginBottom: 16,
    },
    additionalMessageInfo: {
      display: 'flex',
      fontSize: 12,
      color: 'white',
    },
    treatmentDescriptionText: {
      fontSize: 14,
      minWidth: '100%',
      width: 0,
      overflowWrap: 'break-word',
    },
    creatorContainer: {
      marginRight: 16,
    },
    messageContentContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 1)',
      minHeight: 500,
      width: '75%',
      padding: 16,
    },
    topInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginLeft: -16,
      marginRight: -16,
    },
  },
  small: {
    messageContentContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 1)',
      minHeight: 500,
      width: '100%',
      padding: 16,
    },
    topInfoContainer: {
      display: 'flex',
      justifyContent: 'normal',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
    },
  },
};

function mapStateToProps(state) {
  return {
    vehicleRequest: state.vehicle,
    searchRequest: state.search,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchVehicleMessage: _fetchVehicleMessage,
    createMessageImage: _createMessageImage,
    deleteMessageImage: _deleteMessageImage,
    deleteVehicleMessage: _deleteVehicleMessage,
  },
)(translate('VehicleDetailsView')(VehicleDetailsMessageContent));
