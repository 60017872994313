import React, { createRef } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import { Galleria } from 'primereact/galleria';
import {
  createMessageResponseImage as _createMessageResponseImage,
  deleteMessageResponseImage as _deleteMessageResponseImage,
  deleteVehicleMessageResponse as _deleteVehicleMessageResponse,
} from '../../../actions/vehicleActions';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSpinner from '../../shared/CBSpinner';
import CBLabelAndValue from '../../shared/CBLabelAndValue';
import TextWithLinebreaks from '../../shared/TextWithLinebreaks';
import ImageCarouselModal from './ImageCarouselModal';
import MessageEditRow from './MessageEditRow';
import CBPromptModal from '../../shared/CBPromptModal';
import AddMessageResponseModal from './AddMessageResponseModal';
import AddImageModal from '../../shared/AddImageModal';

class MessageResponse extends React.Component {
  state = {
    imageIndex: 0,
    addImageModal: false,
    addedImage: null,
    removingImages: false,
    imageModalIsOpen: false,
    deleteModalIsOpen: false,
    modalIsOpen: false,
  }

  setActiveImage = (index) => {
    this.setState({
      activeImageIndex: index,
    });
  };

  onImageUpload = acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          addedImage: reader.result,
          addImageModal: true,
        });
      };
    });
  };

  uploadImage = (image) => {
    const { createMessageResponseImage, response, message } = this.props;
    this.setState({ addImageModal: false });
    if (response) {
      createMessageResponseImage(message.id, {
        message_response: response.id,
        image: image,
      });
    }
  }

  toggleImageRemoveMode = () => {
    this.setState(prevState => ({
      ...prevState,
      removingImages: !prevState.removingImages,
    }));
  }

  openImageModal = index => {
    this.setState({ imageModalIsOpen: true, imageIndex: index });
  }

  hideImageModal = () => {
    this.setState({ imageModalIsOpen: false });
  }

  removeImage = id => {
    const { deleteMessageResponseImage, message } = this.props;
    deleteMessageResponseImage(message.id, id);
  }

  deleteVehicleMessage = () => {
    const { deleteVehicleMessageResponse, response } = this.props;
    this.setState({
      deleteModalIsOpen: false,
    });
    deleteVehicleMessageResponse(response.id);
  }

  openDeleteMessageModal = () => {
    this.setState({
      deleteModalIsOpen: true,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      deleteModalIsOpen: false,
    });
  };

  afterOpenDeleteModal = () => {
  };

  editMessage = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  render() {
    const {
      t, response, message,
    } = this.props;

    const {
      addImageModal,
      addedImage,
      removingImages,
      imageIndex,
      imageModalIsOpen,
      modalIsOpen,
      deleteModalIsOpen,
    } = this.state;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'messageContentContainer')}>
            {response && (
              <React.Fragment>
                <div>
                  <div
                    className='font-titillium-web-extra-light'
                    style={getStyle(screenSize, styles, 'sectionHeading')}
                  >
                    <div style={getStyle(screenSize, styles, 'additionalMessageInfo')}>
                      <div style={getStyle(screenSize, styles, 'creatorContainer')}>
                        {`${t('messageUserLabel')}: ${response.user_display}`}
                      </div>
                      <div>
                        {`${t('messageCreatedLabel')}: ${moment(response.created_at).format('D.M.YYYY HH:mm')}`}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={getStyle(screenSize, styles, 'messageTextContainer')}>
                      <p style={getStyle(screenSize, styles, 'treatmentDescriptionText')}>
                        <TextWithLinebreaks text={response.text} />
                      </p>
                    </div>
                    <div style={getStyle(screenSize, styles, 'galleryContainer')}>
                      <ImageCarouselModal
                        show={imageModalIsOpen}
                        handleClose={this.hideImageModal}
                        imageIndex={imageIndex}
                        images={response.images}
                        showNextImage={this.showNextImage}
                        showPreviousImage={this.showPreviousImage}
                        getBackroundImageUrl={this.getBackroundImageUrl}
                      />
                      <div style={getStyle(screenSize, styles, 'imageContainer')}>
                        {
                          response.images.map((image, index) => (
                            <div
                              style={{
                                ...getStyle(screenSize, styles, 'squareImage'),
                                backgroundImage: `url(${image.image})`,
                              }}
                              key={image.id}
                              onClick={removingImages
                                ? () => {
                                  this.removeImage(image.id);
                                  if (response.images.length === 1) {
                                    this.setState({ removingImages: false });
                                  }
                                }
                                : () => this.openImageModal(index)}
                            >
                              {removingImages && <img style={getStyle(screenSize, styles, 'imageRemoveIcon')} src='/img/icon_trashcan.svg' alt={t('removeImg')} />}
                            </div>
                          ))
                        }
                      </div>
                    </div>
                    {
                      response.can_edit && (
                        <MessageEditRow
                          onImageUpload={this.onImageUpload }
                          toggleImageRemoveMode={this.toggleImageRemoveMode}
                          removingImages={removingImages}
                          message={response}
                          openDeleteMessageModal={this.openDeleteMessageModal}
                          editMessage={this.editMessage}
                        />
                      )
                    }
                    {addImageModal && (
                      <AddImageModal
                        show={addImageModal}
                        handleClose={this.hideAddImageModal}
                        image={addedImage}
                        onImageSave={(image) => { this.uploadImage(image); }}
                      />
                    )}
                    {
                      modalIsOpen && (
                        <AddMessageResponseModal
                          modalIsOpen={modalIsOpen}
                          closeModal={this.closeModal}
                          response={response}
                          message={message}
                        />
                      )
                    }
                    <CBPromptModal
                      modalIsOpen={deleteModalIsOpen}
                      closeModal={this.closeDeleteModal}
                      afterOpenModal={this.afterOpenDeleteModal}
                      title={t('deleteMessagePromptTitle')}
                      text={t('deleteMessagePromptText')}
                      buttonText={t('deleteMessagePromptButton')}
                      performAction={this.deleteVehicleMessage}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    messageNameText: {
      fontSize: 32,
    },
    messageDescriptionText: {
      fontSize: 14,
    },
    galleryThumbnailsContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    thumbnail: {
      maxWidth: 120,
      cursor: 'pointer',
    },
    thumbnailContainer: {
      marginRight: 8,
    },
    galleryContainer: {
      marginBottom: 16,
    },
    sectionHeadingText: {
      fontSize: 24,
    },
    messageTextContainer: {
      marginBottom: 16,
    },
    sectionHeading: {
      display: 'flex',
      fontSize: 20,
      justifyContent: 'flex-end',
      alignItems: 'center',
      color: 'white',
      marginTop: 16,
      marginBottom: 16,
    },
    additionalMessageInfo: {
      display: 'flex',
      fontSize: 12,
      color: 'white',
    },
    treatmentDescriptionText: {
      fontSize: 14,
      minWidth: '100%',
      width: 0,
      overflowWrap: 'break-word',
    },
    creatorContainer: {
      marginRight: 16,
    },
    messageContentContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 1)',
      borderTop: '1px solid rgba(255, 255, 255, 0.4)',
      marginBottom: 32,
    },
    topInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginLeft: -16,
      marginRight: -16,
    },
    imageContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 32,
    },
    squareImage: {
      width: 100,
      height: 100,
      margin: '0 10px 10px 0',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
      cursor: 'pointer',
    },
    imageRemoveIcon: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
      filter: 'drop-shadow(0px 1px 4px rgba(0,0,0,0.5))',
    },
  },
  small: {
    messageContentContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 1)',
      width: '100%',
    },
    sectionHeading: {
      display: 'flex',
      fontSize: 20,
      justifyContent: 'flex-start',
      alignItems: 'center',
      color: 'white',
      marginTop: 16,
      marginBottom: 16,
    },
    topInfoContainer: {
      display: 'flex',
      justifyContent: 'normal',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
    },
  },
};


function mapStateToProps(state) {
  return {
    vehicleRequest: state.vehicle,
    searchRequest: state.search,
  };
}

export default connect(
  mapStateToProps,
  {
    createMessageResponseImage: _createMessageResponseImage,
    deleteMessageResponseImage: _deleteMessageResponseImage,
    deleteVehicleMessageResponse: _deleteVehicleMessageResponse,
  },
)(translate('VehicleDetailsView')(MessageResponse));
