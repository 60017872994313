import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../../shared/CBMediaQuery';
import '../../../App.css';
import {finnishDateFormat, getStyle, SERVICE_FLEET, SERVICE_WORKSHOP, dbDateFormat } from '../../../utils/utils';
import CBModal from '../../shared/CBModal';
import {
    fetchCheckLists as _fetchCheckLists,
    fetchVehicleCheckListItems as _fetchVehicleCheckListItems,
    toggleVehicleChecks as _toggleVehicleChecks
  } from '../../../actions/checkListActions';
import moment from 'moment';
import { createSaleReportSubscription as _createSaleReportSubscription } from '../../../actions/vehicleActions';
import CBInputField from '../../shared/form_components/CBInputField';
import { getLengthValidator } from '../../../utils/validators';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { toast } from 'react-toastify';



class SaleReportModal extends Component {
  constructor(props) {
    super(props);

    const { vehicle } = props;

    this.state = {
      loading: false
    };
  }

  componentDidMount = () => {
  };

  componentDidUpdate = (prevProps) => {
    const { vehicle } = this.props;

    if (!prevProps.vehicle.sale_report_url && vehicle.sale_report_url) {
      this.setState({
        loading: false
      });
    }
  };

  copyUrlToClipboard = (text) => {
    const { t } = this.props;
    // Modern browsers with Clipboard API
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(function() {
        toast.success(t('linkCopied'));
      }).catch(function(err) {
        console.error('Could not copy text: ', err);
      });
    } else {
      // Fallback for older browsers
      var textarea = document.createElement("textarea");
      textarea.value = text;
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      document.body.appendChild(textarea);
      textarea.select();

      try {
        document.execCommand('copy');
        toast.success(t('linkCopied'));
      } catch (err) {
        console.error('Could not copy text', err);
      }

      document.body.removeChild(textarea);
    }
  }

  fetchSaleReport = () => {
    this.setState({loading: true});

    const { vehicle, createSaleReportSubscription, selectedOrganizationId } = this.props;
    createSaleReportSubscription(vehicle.id, selectedOrganizationId);
  }

  render() {
    const {
      modalIsOpen,
      closeModal,
      afterOpenModal,
      t,
      vehicle,
      createSaleReportSubscription,
      selectedOrganizationId,
      hasFeatureVehicleReportsPdf
    } = this.props;

    const {
      loading
    } = this.state;

    return (
      <CBMediaQuery>
        {screenSize => (
          <CBModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={closeModal}
            title={t('titleSaleReport')}
            descriptionText={hasFeatureVehicleReportsPdf ? t('descriptionSaleReportPdf') : t('descriptionSaleReport')}
            submitText='Sulje'
            submitForm={closeModal}
            isValid={true}
            vehicleText={vehicle && vehicle.registration_number}
            hideCancelButton
          >

            <div style={getStyle(screenSize, styles, 'bodyContainer')}>
            {
                !vehicle.sale_report_url && (
                  <div style={{textAlign: 'center'}}>
                    <div style={getStyle(screenSize, styles, 'reportButtonContainer')}>
                      <Button label='Hae raportti' onClick={this.fetchSaleReport} disabled={loading} />
                    </div>
                    {
                      loading && <ProgressSpinner />
                    }
                  </div>
                )
              }
              {
                vehicle.sale_report_url && (
                  <div>
                    <CBInputField
                      name='sale_report_url'
                      inputStyle={getStyle(screenSize, styles, 'input')}
                      value={vehicle.sale_report_url}
                      placeholder=''
                      onChange={() => {}}
                      validators={[getLengthValidator(1, 40)]}
                      isValidCallback={() => true}
                      labelText={"Linkki myyntiraporttiin"}
                      labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                      disabled={true}
                    />
                    <div style={getStyle(screenSize, styles, 'reportButtonContainer')}>
                      <Button label={hasFeatureVehicleReportsPdf ? t('downloadPdf') : t('openReport')} onClick={() => window.open(vehicle.sale_report_url)} />
                      <Button label={t('copyLink')} onClick={() => {this.copyUrlToClipboard(vehicle.sale_report_url)}} />
                    </div>
                  </div>
                )
              }
            </div>
          </CBModal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 32px'
    },
    reportButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 16,
    },
    text: {
      color: 'black',
      fontSize: 14,
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      width: '100%',
    },
    inputLabelText: {
      color: 'black',
    },
  },
  small: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 16px'
    },
  }
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    selectedOrganizationId: state.organization.selectedOrganization,
  };
}

export default connect(
  mapStateToProps,
  {
    createSaleReportSubscription: _createSaleReportSubscription,
  },
)(translate('SaleReport')(SaleReportModal));
