import { authUserState } from '../initialState';
import { storeRemoveItem } from '../utils/utils';


export default function (state = authUserState, action) {
  switch (action.type) {
    case 'FETCH_USER_REQUEST': {
      return {
        ...state,
        userStatus: Object.assign({}, state.userStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_USER_SUCCESS': {
      if (action.payload.profile.force_password_change) {
        window.location.replace('/accounts/password/change/');
        return {};
      }
      return {
        ...state,
        user: action.payload,
        userStatus: Object.assign({}, state.userStatus, {
          loading: null,
          error: false,
        }),
      };
    }
    case 'FETCH_USER_FAIL': {
      window.location.replace('/accounts/login');
      return {
        ...state,
        user: null,
        userStatus: Object.assign({}, state.userStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_PROFILE_REQUEST': {
      return {
        ...state,
        profileStatus: Object.assign({}, state.profileStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_PROFILE_SUCCESS': {
      const newUser = Object.assign({}, state.user);
      newUser.profile = action.payload;
      return {
        ...state,
        user: newUser,
        profileStatus: Object.assign({}, state.profileStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_PROFILE_FAIL': {
      return {
        ...state,
        profileStatus: Object.assign({}, state.profileStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'FETCH_TOS_REQUEST': {
      return {
        ...state,
        tosStatus: Object.assign({}, state.tosStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_TOS_SUCCESS': {
      return {
        ...state,
        tos: action.payload,
        tosStatus: Object.assign({}, state.tosStatus, {
          loading: null,
          error: false,
        }),
      };
    }
    case 'FETCH_TOS_FAIL': {
      return {
        ...state,
        tos: null,
        tosStatus: Object.assign({}, state.tosStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'FETCH_INSTRUCTIONS_REQUEST': {
      return {
        ...state,
        instructionsStatus: Object.assign({}, state.instructionsStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_INSTRUCTIONS_SUCCESS': {
      return {
        ...state,
        instructions: action.payload,
        instructionsStatus: Object.assign({}, state.instructionsStatus, {
          loading: null,
          error: false,
        }),
      };
    }
    case 'FETCH_INSTRUCTIONS_FAIL': {
      return {
        ...state,
        instructions: null,
        instructionsStatus: Object.assign({}, state.instructionsStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'FETCH_GLOBAL_STATS_REQUEST': {
      return {
        ...state,
        globalStatsStatus: Object.assign({}, state.globalStatsStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_GLOBAL_STATS_SUCCESS': {
      return {
        ...state,
        globalStats: action.payload,
        globalStatsStatus: Object.assign({}, state.globalStatsStatus, {
          loading: null,
          error: false,
        }),
      };
    }
    case 'FETCH_GLOBAL_STATS_FAIL': {
      return {
        ...state,
        globalStats: null,
        globalStatsStatus: Object.assign({}, state.globalStatsStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'FETCH_ADMIN_WORKSHOP_REQUEST': {
      return {
        ...state,
        userStatus: Object.assign({}, state.userStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_ADMIN_WORKSHOP_SUCCESS': {
      return {
        ...state,
        user: {
          ...state.user,
          workshop: action.payload,
        },
        userStatus: Object.assign({}, state.userStatus, {
          loading: null,
          error: false,
        }),
      };
    }
    case 'FETCH_ADMIN_WORKSHOP_FAIL': {
      return {
        ...state,
        user: {
          ...state.user,
          workshop: null,
        },
        userStatus: Object.assign({}, state.userStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CLEAR_OWN_WORKSHOP': {
      return {
        ...state,
        user: {
          ...state.user,
          workshop: null,
        },
      };
    }
    case 'UPDATE_WORKSHOP_SUCCESS': {
      return {
        ...state,
        user: {
          ...state.user,
          workshop: action.payload,
        },
      };
    }
    case 'ACCEPT_INVITATION_REQUEST': {
      return {
        ...state,
        userStatus: Object.assign({}, state.userStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'ACCEPT_INVITATION_SUCCESS': {
      return {
        ...state,
        user: action.payload,
        userStatus: Object.assign({}, state.userStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'ACCEPT_INVITATION_FAIL': {
      return {
        ...state,
        userStatus: Object.assign({}, state.userStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DECLINE_INVITATION_REQUEST': {
      return {
        ...state,
        userStatus: Object.assign({}, state.userStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DECLINE_INVITATION_SUCCESS': {
      const invitationId = action.payload.id;
      const newUser = { ...state.user };
      newUser.invitations = storeRemoveItem(newUser.invitations, invitationId);
      return {
        ...state,
        user: newUser,
        userStatus: Object.assign({}, state.userStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DECLINE_INVITATION_FAIL': {
      return {
        ...state,
        userStatus: Object.assign({}, state.userStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'ACCEPT_CARE_SERVICE_INVITATION_REQUEST': {
      return {
        ...state,
        userStatus: Object.assign({}, state.userStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'ACCEPT_CARE_SERVICE_INVITATION_SUCCESS': {
      const invitationId = action.payload.id;
      const newUser = { ...state.user };
      newUser.care_service_invitations = storeRemoveItem(newUser.care_service_invitations, invitationId);
      return {
        ...state,
        user: newUser,
        userStatus: Object.assign({}, state.userStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'ACCEPT_CARE_SERVICE_INVITATION_FAIL': {
      return {
        ...state,
        userStatus: Object.assign({}, state.userStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DECLINE_CARE_SERVICE_INVITATION_REQUEST': {
      return {
        ...state,
        userStatus: Object.assign({}, state.userStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DECLINE_CARE_SERVICE_INVITATION_SUCCESS': {
      const invitationId = action.payload.id;
      const newUser = { ...state.user };
      newUser.care_service_invitations = storeRemoveItem(newUser.care_service_invitations, invitationId);
      return {
        ...state,
        user: newUser,
        userStatus: Object.assign({}, state.userStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DECLINE_CARE_SERVICE_INVITATION_FAIL': {
      return {
        ...state,
        userStatus: Object.assign({}, state.userStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    default:
      break;
  }
  return state;
}
