import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import CBInputField from '../../shared/form_components/CBInputField';
import { updateUserProfile as _updateUserProfile } from '../../../actions/authUserActions';
import {
  updateUserProfileStaff as _updateUserProfileStaff,
  deleteUser as _deleteUser,
} from '../../../actions/userActions';
import CBSubmitButton from '../../shared/form_components/CBSubmitButton';
import CBSpinnerFloating from '../../shared/CBSpinnerFloating';
import {
  getLengthValidator,
  getNumberValidator,
  getPhoneValidator,
  getEmailValidator,
} from '../../../utils/validators';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';
import CBPromptModal from '../../shared/CBPromptModal';

class UserSettingsProfileInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { deleteModalIsOpen: false, formData: props.user.profile, formValid: {} };
  }

  componentWillReceiveProps(nextProps) {
    const { user } = this.props;

    if (user.id !== nextProps.user.id) {
      this.setState({ formData: Object.assign({}, nextProps.user.profile), formValid: {} });
    }
  }

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
    };
    newFormValid[name] = isValid;

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const { formData } = this.state;
    const {
      updateUserProfile,
      updateUserProfileStaff,
      user,
      isOwnUser,
    } = this.props;
    const newFormData = Object.assign({}, formData);
    delete newFormData.image;

    if (!newFormData.zip_code) {
      newFormData.zip_code = '';
    }

    if (isOwnUser) {
      updateUserProfile(newFormData, user.profile.id);
    } else {
      updateUserProfileStaff(newFormData, user.profile.id);
    }
  };

  deleteUser = () => {
    this.setState({ deleteModalIsOpen: true });
  };

  performDeleteUser= () => {
    const { user, deleteUser } = this.props;
    deleteUser(user.id);
    this.setState({ deleteModalIsOpen: false });
  }

  render() {
    const { userRequest, t, isOwnUser } = this.props;
    const { formData, deleteModalIsOpen } = this.state;
    const request = isOwnUser ? userRequest.profileStatus : userRequest.usersStatus;
    return (
      <div style={styles.formContainer}>
        {request.loading && <CBSpinnerFloating />}
        <CBInputField
          labelText={t('firstNameLabel')}
          name='first_name'
          onChange={this.updateFormState}
          value={formData.first_name}
          serverErrors={request.error}
          isValidCallback={this.setIsValid}
          validators={[getLengthValidator(0, 100)]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('lastNameLabel')}
          name='last_name'
          onChange={this.updateFormState}
          value={formData.last_name}
          serverErrors={request.error}
          isValidCallback={this.setIsValid}
          validators={[getLengthValidator(0, 100)]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('addressLabel')}
          name='street_address'
          onChange={this.updateFormState}
          value={formData.street_address}
          serverErrors={request.error}
          isValidCallback={this.setIsValid}
          validators={[getLengthValidator(0, 100)]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('zipCodeLabel')}
          name='zip_code'
          onChange={this.updateFormState}
          type='number'
          value={formData.zip_code}
          serverErrors={request.error}
          isValidCallback={this.setIsValid}
          validators={[getNumberValidator(), getLengthValidator(0, 10)]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('cityLabel')}
          name='city'
          onChange={this.updateFormState}
          value={formData.city}
          serverErrors={request.error}
          isValidCallback={this.setIsValid}
          validators={[getLengthValidator(0, 100)]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('phoneNumberLabel')}
          name='phone_number'
          onChange={this.updateFormState}
          value={formData.phone_number}
          serverErrors={request.error}
          isValidCallback={this.setIsValid}
          validators={[getPhoneValidator()]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('emailLabel')}
          name='email'
          onChange={this.updateFormState}
          value={formData.email}
          serverErrors={request.error}
          isValidCallback={this.setIsValid}
          validators={[getEmailValidator()]}
          labelTextStyle={styles.fieldContainerLeftAlign}
          disabled
        />
        <div style={styles.buttonContainer}>
          <CBSubmitButton
            disabled={request.loading || !this.getIsValid()}
            onClick={this.submitForm}
          />
        </div>
        <div style={styles.buttonContainer}>
          <CBSubmitButton
            disabled={request.loading}
            onClick={this.deleteUser}
            isDeleteButton
          />
        </div>

        <CBPromptModal
          modalIsOpen={deleteModalIsOpen}
          closeModal={() => this.setState({ deleteModalIsOpen: false })}
          afterOpenModal={() => {}}
          title={t('deleteUserTitle')}
          text={t('deleteUserText')}
          buttonText={t('deleteUserButton')}
          performAction={() => this.performDeleteUser()}
        />
      </div>
    );
  }
}

const styles = {
  buttonContainer: {
    paddingTop: 20,
  },
  formContainer: {
    position: 'relative',
  },
  personalInformationContainer: {},
  fieldContainerLeftAlign: {
    marginBottom: 5,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
};

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
  {
    updateUserProfile: _updateUserProfile,
    updateUserProfileStaff: _updateUserProfileStaff,
    deleteUser: _deleteUser,
  },
)(translate('SettingsView')(UserSettingsProfileInfoForm));
