import React, { Component } from 'react';
import { connect } from 'react-redux';
import { styleMerge, getStyle } from '../../utils/utils';
import CBMediaQuery from './CBMediaQuery';
import {
  searchMaintenances as _searchMaintenances,
  searchTreatments as _searchTreatments,
} from '../../actions/searchActions';
import { fetchWorkshops as _fetchWorkshops } from '../../actions/workshopActions';
import { fetchWSVehicles as _fetchWSVehicles } from '../../actions/vehicleActions';

class CBSearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value:
        (props.type === 'maintenance' || props.type === 'treatment') && props.searchRequest
          ? props.searchRequest.maintenanceFilters.search
          : '',
    };
  }

  handleChange = elem => {
    const {
      onBlur,
      type,
      searchMaintenances,
      searchTreatments,
      fetchWorkshops,
      vehicle,
      searchRequest,
    } = this.props;
    const { value } = elem.target;
    if (type === 'maintenance') {
      searchMaintenances({
        vehicle_id: vehicle.id,
        search: value,
      });
    } else if (type === 'treatment') {
      searchTreatments({
        vehicle_id: vehicle.id,
        search: value,
        treatment_type: searchRequest.treatmentFilters.treatmentType,
      });
    } else if (type === 'workshop') {
      fetchWorkshops({
        search: value,
      });
    } else {
      onBlur();
    }
    this.setState({ value });
  };

  render() {
    const { value } = this.state;
    const {
      containerStyle, placeholder, onBlur, changeSearchTerms, type, searchMaintenances,
      searchTreatments, searchRequest, vehicle,
    } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div
            className='opacity-and-border-on-hover'
            style={styleMerge(getStyle(screenSize, styles, 'box'), containerStyle || {})}
          >
            <img
              onClick={() => {this.input.focus()}}
              style={getStyle(screenSize, styles, 'buttonIcon')}
              alt='search'
              src='/img/magnifier.svg'
            />
            <input
              ref={(ref) => { this.input = ref; }}
              type='text'
              value={value}
              name='search'
              placeholder={placeholder !== undefined ? placeholder : 'Hae'}
              onBlur={this.handleChange}
              onChange={elem => {
                this.setState({ value: elem.target.value });
                if (changeSearchTerms) {
                  changeSearchTerms(elem.target.value);
                }
                if (type === 'treatment') {
                  searchTreatments({
                    vehicle_id: vehicle.id,
                    search: value,
                    treatment_type: searchRequest.treatmentFilters.treatmentType,
                  });
                } else if (type === 'maintenance') {
                  searchMaintenances({
                    vehicle_id: vehicle.id,
                    search: value,
                  });
                }
              }}
              onKeyUp={e => {
                if (e.keyCode === 13) {
                  if (type === 'workshop') {
                    this.handleChange(e);
                  }
                  onBlur();
                }
              }}
              style={getStyle(screenSize, styles, 'searchBox')}
              className='white-placeholder'
              autoComplete='off'
            />
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

CBSearchInput.defaultProps = {
  onBlur: () => null,
};

const styles = {
  default: {
    box: {
      display: 'flex',
      height: 30,
      border: '1px solid rgba(255, 255, 255, 0.6)',
      borderRadius: 50,
      alignItems: 'center',
      marginBottom: 0,
      marginRight: 28,
      marginLeft: 16,
      width: '100%',
    },
    searchBox: {
      color: 'white',
      backgroundColor: 'transparent',
      fontSize: 14,
      borderWidth: 0,
      textDecoration: 'none solid rgb(255, 255, 255)',
      width: '100%',
      height: '100%',
    },
    buttonIcon: {
      height: 24,
      width: 24,
      marginLeft: 16,
    },
  },
  medium: {
    box: {
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
    },
  },
};

function mapStateToProps(state) {
  return {
    searchRequest: state.search,
  };
}

export default connect(
  mapStateToProps,
  {
    searchMaintenances: _searchMaintenances,
    searchTreatments: _searchTreatments,
    fetchWSVehicles: _fetchWSVehicles,
    fetchWorkshops: _fetchWorkshops,
  },
)(CBSearchInput);
