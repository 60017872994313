import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { fetchVehiclePermissions as _fetchVehiclePermissions } from '../../../actions/vehiclePermissionActions';
import CBSpinner from '../../shared/CBSpinner';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle } from '../../../utils/utils';
import VehiclePermissionView from './VehiclePermissionView';

class UserSettingsCars extends React.Component {
  componentDidMount = () => {
    const { fetchVehiclePermissions } = this.props;
    fetchVehiclePermissions();
  };

  render() {
    const { vehiclePermissionRequest, t } = this.props;
    const { vehiclePermissions } = vehiclePermissionRequest;
    if (vehiclePermissionRequest.loading) {
      return <CBSpinner />;
    } else if (vehiclePermissionRequest.error) {
      return (
        <p>
          {vehiclePermissionRequest.error}
        </p>
      );
    } else {
      return (
        <CBMediaQuery>
          {screenSize => (
            <div style={getStyle(screenSize, styles, 'container')}>
              <div style={getStyle(screenSize, styles, 'leftContainer')}>
                {vehiclePermissions.filter((p) => p.permission_type === 'owner').map(permission => (
                  <VehiclePermissionView key={permission.id} permission={permission} />
                ))}
              </div>
              <div style={getStyle(screenSize, styles, 'rightContainer')}>
                {vehiclePermissions.filter((p) => p.permission_type !== 'owner').map(permission => (
                  <VehiclePermissionView key={permission.id} permission={permission} />
                ))}
              </div>
            </div>
          )}
        </CBMediaQuery>
      );
    }
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      paddingBottom: 50,
    },
    leftContainer: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 40,
      width: '50%',
      borderRight: '1px solid rgba(255, 255, 255, 0.4)',
      minHeight: 500,
    },
    rightContainer: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 40,
      width: '50%',
      minHeight: 500,
    },
  },

  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    leftContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      width: '100%',
      borderRight: 0,
      marginBottom: 20,
      minHeight: 0,
    },
    rightContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      width: '100%',
      borderRight: 0,
      marginBottom: 20,
      minHeight: 0,
    },
  },
};

function mapStateToProps(state) {
  return {
    vehiclePermissionRequest: state.vehiclePermission,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchVehiclePermissions: _fetchVehiclePermissions,
  },
)(translate('SettingsView')(UserSettingsCars));
