import { announcementState } from '../initialState';
import { dateCompareFunction } from '../utils/utils';
import {
  storeInsertItem,
  storeRemoveItem,
  storeUpdateObjectInArray,
} from '../utils/utils';

export default function(state = announcementState, action) {
  switch (action.type) {
    // FETCH
    case 'FETCH_ANNOUNCEMENTS_REQUEST': {
      return {
        ...state,
        announcementsStatus: Object.assign({}, state.announcementsStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_ANNOUNCEMENTS_SUCCESS': {
      return {
        ...state,
        announcements: action.payload,
        announcementsStatus: Object.assign({}, state.announcementsStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_ANNOUNCEMENTS_FAIL': {
      return {
        ...state,
        announcements: [],
        announcementsStatus: Object.assign({}, state.announcementsStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    // CREATE
    case 'CREATE_ANNOUNCEMENTS_REQUEST': {
      return {
        ...state,
        announcementsStatus: Object.assign({}, state.announcementsStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_ANNOUNCEMENTS_SUCCESS': {
      return {
        ...state,
        announcements: storeInsertItem(
          state.announcements,
          action.payload,
        ).sort((a, b) => dateCompareFunction(a.date, b.date)),
        announcementsStatus: Object.assign({}, state.announcementsStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_ANNOUNCEMENTS_FAIL': {
      return {
        ...state,
        announcementsStatus: Object.assign({}, state.announcementsStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    // UPDATE
    case 'UPDATE_ANNOUNCEMENTS_REQUEST': {
      return {
        ...state,
        announcementsStatus: Object.assign({}, state.announcementsStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_ANNOUNCEMENTS_SUCCESS': {
      return {
        ...state,
        announcements: storeUpdateObjectInArray(
          state.announcements,
          action.payload,
        ).sort((a, b) => dateCompareFunction(a.date, b.date)),
        announcementsStatus: Object.assign({}, state.announcementsStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_ANNOUNCEMENTS_FAIL': {
      return {
        ...state,
        announcementsStatus: Object.assign({}, state.announcementsStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    // DELETE
    case 'DELETE_ANNOUNCEMENTS_REQUEST': {
      return {
        ...state,
        announcementsStatus: Object.assign({}, state.announcementsStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DELETE_ANNOUNCEMENTS_SUCCESS': {
      return {
        ...state,
        announcements: storeRemoveItem(state.announcements, action.payload.id),
        announcementsStatus: Object.assign({}, state.announcementsStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DELETE_ANNOUNCEMENTS_FAIL': {
      return {
        ...state,
        announcementsStatus: Object.assign({}, state.announcementsStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    default:
      break;
  }
  return state;
}
