import React from 'react';
import { Link } from 'react-router-dom';
import { getStyle } from '../../utils/utils';
import CBMediaQuery from './CBMediaQuery';

class CBButtonLarge extends React.Component {
  render() {
    const {
      to, icon, text, onClick, href, style
    } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div
            className='hover-background-green'
            style={style ? style : getStyle(screenSize, styles, 'largeButton')}
            onClick={onClick}
          >
            {
              href && (
                <a href={href} style={getStyle(screenSize, styles, 'aWrapper')}>
                  {
                    icon && <img alt='Welcome' style={getStyle(screenSize, styles, 'buttonIcon')} src={icon} />
                  }
                  <p style={getStyle(screenSize, styles, 'buttonText')}>
                    {text}
                  </p>
                </a>
              )
            }
            {
              to && (
                <Link style={getStyle(screenSize, styles, 'linkWrapper')} to={to}>
                  {
                    icon && <img alt='Welcome' style={getStyle(screenSize, styles, 'buttonIcon')} src={icon} />
                  }
                  <p style={getStyle(screenSize, styles, 'buttonText')}>
                    {text}
                  </p>
                </Link>
              )
            }
            {
            onClick && (
              <div style={getStyle(screenSize, styles, 'linkWrapper')}>
                {
                  icon && <img alt='Welcome' style={getStyle(screenSize, styles, 'buttonIcon')} src={icon} />
                }
                <p style={getStyle(screenSize, styles, 'buttonText')}>
                  {text}
                </p>
              </div>
            )
          }

          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    buttonIcon: {
      width: 32,
      height: 32,
    },
    buttonText: {
      color: '#FFF',
      textTransform: 'uppercase',
      fontSize: 22,
      marginTop: 8,
    },
    linkWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      'hover:': {
        backgroundColor: '#00f600',
      },
    },
    largeButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 50,
      border: '2px solid #00f600',
      cursor: 'pointer',
      borderRadius: 50,
      ':hover': {
        backgroundColor: '#00f600',
      },
    },
  },
  medium: {
    largeButton: {
      width: 280,
      height: 50,
    },
  },
  small: {
    largeButton: {
      width: 240,
      height: 50,
    },
  },
};

export default CBButtonLarge;
