import React, { Component } from 'react';

class CBCheckbox extends Component {
  render() {
    const { checked, color = 'white' } = this.props;
    let checkedIcon, uncheckedIcon;
    if (color === 'white') {
      checkedIcon = '/img/icon_checked_box.svg';
      uncheckedIcon = '/img/icon_2.svg';
    } else if (color === 'blue') {
      checkedIcon = '/img/icon_checked_box_blue.svg';
      uncheckedIcon = '/img/icon_box_blue.svg';
    } else {
      checkedIcon = '';
      uncheckedIcon = '';
    }
    return (
      <img
        alt=''
        src={checked ? checkedIcon : uncheckedIcon}
        style={styles.icon}
      />
    );
  }
}

const styles = {
  icon: {
    width: 24,
    height: 24,
  },
};

export default CBCheckbox;
