import {
  API, GET, POST, PUT, DELETE,
} from '../middleware/api';
import { API_URL } from '../config';

export function fetchCareServices(params) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/care_service/`,
      types: [
        'FETCH_CARE_SERVICES_REQUEST',
        'FETCH_CARE_SERVICES_SUCCESS',
        'FETCH_CARE_SERVICES_FAIL',
      ],
      params,
    },
  };
}

export function createCareService(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/care_service/invite/`,
      types: [
        'CREATE_CARE_SERVICE_REQUEST',
        'CREATE_CARE_SERVICE_SUCCESS',
        'CREATE_CARE_SERVICE_FAIL',
      ],
      data,
    },
  };
}

export function updateCareService(careServiceId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/care_service/${careServiceId}/`,
      types: [
        'UPDATE_CARE_SERVICE_REQUEST',
        'UPDATE_CARE_SERVICE_SUCCESS',
        'UPDATE_CARE_SERVICE_FAIL',
      ],
      data,
    },
  };
}

export function deleteCareService(careServiceId) {
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/care_service/${careServiceId}/`,
      types: [
        'DELETE_CARE_SERVICE_REQUEST',
        'DELETE_CARE_SERVICE_SUCCESS',
        'DELETE_CARE_SERVICE_FAIL',
      ],
    },
  };
}
