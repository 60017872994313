import i18n from 'i18next';

export const getLengthValidator = (minLength, maxLength) => {
  const validator = value => {
    const checkedValue = typeof value === 'number' ? value.toString() : value;
    return (
      typeof checkedValue === 'string' && value.length >= minLength && value.length <= maxLength
    );
  };
  return {
    validator,
    errorMessage: i18n.t('Validators:notValidLength', { minLength, maxLength }),
  };
};

export const getNumberValidator = () => {
  const validator = value => !isNaN(value);
  return {
    validator,
    errorMessage: i18n.t('Validators:notValidNumber'),
  };
};

export const getEmailValidator = (allowEmpty = true) => {
  const validator = value => {
    if (value === '' && allowEmpty) return true;
    const emailRegEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegEx.test(value);
  };
  return {
    validator,
    errorMessage: i18n.t('Validators:notValidEmail'),
  };
};

export const getRegistrationNumberValidator = () => {
  const validator = value => {
    const regNroRegex = /^[A-ZÅÄÖ]{1,3}(-)?[0-9]{1,5}$/;
    const regNroRegex2 = /^[0-9]{1,5}(-)?[A-ZÅÄÖ]{1,3}$/;
    return regNroRegex.test(value) || regNroRegex2.test(value);
  };
  return {
    validator,
    errorMessage: i18n.t('Validators:notValidRegistrationNumber'),
  };
};

export const getHetuValidator = () => {
  const validator = value => {
    const regNroRegex = /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])([0-9]\d\+|\d\d-|[0-9]\dA)\d{3}[\dA-Z]$/;
    return regNroRegex.test(value);
  };
  return {
    validator,
    errorMessage: i18n.t('Validators:notValidHetu'),
  };
};

export const getPhoneValidator = () => {
  const validator = value => {
    const phoneRegEx = /^(\+?\d{9,15})?$/;
    return phoneRegEx.test(value);
  };
  return {
    validator,
    errorMessage: i18n.t('Validators:notValidPhone'),
  };
};
