import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getStyle, getModalStyle } from '../../utils/utils';
import {
  confirmOrganization as _confirmOrganization,
} from '../../actions/organizationActions';
import CBMediaQuery from '../shared/CBMediaQuery';
import CBButtonSubmit from '../shared/CBButtonSubmit';
import CBButtonGeneric from '../shared/CBButtonGeneric';
import CBInputField from '../shared/form_components/CBInputField';
import { getHetuValidator } from '../../utils/validators';
import CBDatePicker from '../shared/CBDatePicker';
import CBButtonSettings from '../shared/CBButtonSettings';

Modal.setAppElement('#root');

class ConfirmOrganizationModal extends Component {
  state = {
    formData: {
      hetu: '',
    },
    formValid: {
      hetu: false,
    },
  };

  componentDidMount = () => {
    const { organizationId } = this.props;
    const { formData } = this.state;

    this.setState({
      formData: {
        ...formData,
        organizationId,
      },
    });
  };

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
      [name]: isValid,
    };

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const {
      formData,
    } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({
      formData: newFormData,
    });
  };

  onSubmit = () => {
    const { confirmOrganization, closeModal, organizationId, user } = this.props;
    const { formData } = this.state;

    if (!user.has_hetu_hash) {
      window.location.replace('/api/1/authentication/create-authentication-session/')
      return
    }

    const newFormData = Object.assign({}, formData);

    confirmOrganization(organizationId, newFormData);
    closeModal();
  };

  render() {
    const { formData } = this.state;
    const {
      modalIsOpen, closeModal, afterOpenModal, t, user
    } = this.props;

    const hasHetuHash = user.has_hetu_hash;

    return (
      <CBMediaQuery>
        {screenSize => (
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={getModalStyle(screenSize, styles.modal)}
            contentLabel='Description modal'
          >
            <div style={getStyle(screenSize, styles, 'container')}>
              <p style={getStyle(screenSize, styles, 'title')}>
                {t('confirmOrganizationTitle')}
              </p>

              {
                hasHetuHash && (
                  <CBInputField
                    name='hetu'
                    inputStyle={getStyle(screenSize, styles, 'input')}
                    value={formData.hetu}
                    placeholder=''
                    onChange={this.updateFormState}
                    validators={[getHetuValidator()]}
                    isValidCallback={this.setIsValid}
                    labelText={t('hetu')}
                    labelTextStyle={getStyle(screenSize, styles, 'subTitle')}
                  />
                )
              }

              <p style={getStyle(screenSize, styles, 'info')}>
                { hasHetuHash ? t('confirmOrganizationInfoText') : t('confirmOrganizationNoHetuInfoText')}
              </p>

              <div style={getStyle(screenSize, styles, 'buttonContainer')}>
                <div style={getStyle(screenSize, styles, 'cancelButtonContainer')}>
                  <CBButtonSubmit
                    disabled={false}
                    onClick={() => {
                      closeModal();
                    }}
                    text={t('cancel')}
                    style={getStyle(screenSize, styles, 'submitButton')}
                  />
                </div>
                <div style={getStyle(screenSize, styles, 'submitButtonContainer')}>
                  <CBButtonSubmit
                    text={hasHetuHash ? t('confirm') : t('confirmNoHetu')}
                    onClick={this.onSubmit}
                    style={getStyle(screenSize, styles, 'submitButton')}
                    disabled={!this.getIsValid() && hasHetuHash}
                  />
                </div>
              </div>
            </div>
          </Modal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },
    title: {
      fontSize: 32,
      fontFamily: "TitilliumWeb-Light",
      color: '#0095da',
      textAlign: 'center',
    },
    subTitle: {
      fontSize: 14,
      color: 'black',
      textAlign: 'center',
      fontWeight: 600,
    },
    clearButtonContainer: {
      height: 40,
      width: '100%',
      border: '2px solid #0095da',
      color: '#0095da',
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      marginLeft: 20,
    },
    info: {
      width: '100%',
      fontSize: 14,
      color: 'black',
      paddingTop: 16,
      paddingHorizontal: 30,
      textAlign: 'center',
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 400,
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      marginBottom: 30,
    },
    submitButtonContainer: {
      width: 200,
      marginTop: 32,
    },
    cancelButtonContainer: {
      width: 200,
      marginTop: 32,
    },
    buttonContainer: {
      width: 450,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    submitButton: {
      height: 50,
      width: '100%',
      border: '2px solid #0095da',
      color: '#0095da',
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    input: {
      width: '100%',
    },
  },
  modal: {
    default: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height: 'auto',
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    medium: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
      },
      overlay: {
        zIndex: 'auto',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    small: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
  };
}

export default connect(
  mapStateToProps,
  {
    confirmOrganization: _confirmOrganization,
  },
)(translate('ConfirmOrganizationModal')(ConfirmOrganizationModal));
