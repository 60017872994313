import React from 'react';

import CBMediaQuery from '../../shared/CBMediaQuery';

import { formatDate, getStyle } from '../../../utils/utils';

class CareServiceListItem extends React.Component {
  render() {
    const {
      careService, onEditClick,
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <tr>
            {
              screenSize === 'large' && (
                <td style={getStyle(screenSize, styles, 'tableValueText')}>{careService.id}</td>
              )
            }
            <td style={getStyle(screenSize, styles, 'tableValueText')}>{`${careService.organization.name} (${careService.organization.business_id})`}</td>
            <td style={getStyle(screenSize, styles, 'tableValueText')}>{`${careService.workshop.name} (${careService.workshop.business_id})`}</td>
            <td style={getStyle(screenSize, styles, 'tableValueText')}>{formatDate(careService.created_at)}</td>
            <td style={getStyle(screenSize, styles, 'tableValueText')}>
              <img
                alt='Options'
                src='/img/icon_edit.svg'
                style={getStyle(screenSize, styles, 'editButton')}
                onClick={() => onEditClick(careService)}
              />
            </td>
          </tr>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    tableValueText: {
      fontFamily: 'TitilliumWeb-Light',
      fontSize: 18,
      color: '#FFFFFF',
    },
    editButton: {
      cursor: 'pointer',
      width: 20,
      height: 20,
    },
  },
};

export default CareServiceListItem;
