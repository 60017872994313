import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import SubHeaderBar from '../shared/SubHeaderBar';
import CBSearchInput from '../shared/CBSearchInput';
import CBDatePicker from '../shared/CBDatePicker';
import CBMediaQuery from '../shared/CBMediaQuery';
import CBSeparator from '../shared/CBSeparator';

import { getStyle } from '../../utils/utils';
import { fetchWSVehicles as _fetchWSVehicles } from '../../actions/vehicleActions';
import WorkshopVehicleList from '../Workshop/WorkShopVehicleList';
import CBSelectField from '../shared/form_components/CBSelectField';

class HomeWSUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      ordering: '-last_maintenance_date',
    };
  }

  componentWillMount() {
    const {
      fetchWSVehicles,
      selectedWorkshopId,
    } = this.props;

    const { ordering } = this.state;

    const params = {
      ordering,
    };

    if (selectedWorkshopId) {
      fetchWSVehicles(selectedWorkshopId, params);
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedWorkshopId } = this.props;

    if (selectedWorkshopId != prevProps.selectedWorkshopId) {
      this.refetchWSVehicles();
    }
  }

  componentDidMount = () => {
  };

  changeSearchTerms = value => {
    this.setState({
      search: value,
    });
  }

  refetchWSVehicles = params => {
    const {
      fetchWSVehicles,
      selectedWorkshopId,
    } = this.props;

    if (selectedWorkshopId) {
      fetchWSVehicles(selectedWorkshopId, { ...this.state, ...params });
    }
  }

  changeStartDate = (value) => {
    this.setState({
      date_begin: value,
    });
    this.refetchWSVehicles({ date_begin: value });
  }

  changeEndDate = (value) => {
    this.setState({
      date_end: value,
    });
    this.refetchWSVehicles({ date_end: value });
  }

  handleOrderingChanged = data => {
    this.setState({
      ordering: data.value,
    });
    this.refetchWSVehicles({ ordering: data.value });
  }

  render() {
    const {
      history,
      t,
      wsVehicles,
      authUserRequest,
      selectedWorkshopId,
    } = this.props;

    const workshopID = selectedWorkshopId;
    const { date_begin, date_end, ordering } = this.state;

    const orderingOptions = [
      { value: 'last_maintenance_date', label: t('sortDateAscLabel') },
      { value: '-last_maintenance_date', label: t('sortDateDescLabel') },
      { value: 'registration_number', label: t('sortRegNumberAscLabel') },
      { value: '-registration_number', label: t('sortRegNumberDescLabel') },
      { value: 'manufacturer', label: t('sortManufacturerAscLabel') },
      { value: '-manufacturer', label: t('sortManufacturerDescLabel') },
      { value: 'owner', label: t('sortOwnerAscLabel') },
      { value: '-owner', label: t('sortOwnerDescLabel') },
    ];

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <SubHeaderBar
              label=''
              searchBlock={(
                <React.Fragment>
                  <div style={getStyle(screenSize, styles, 'searchInputContainer')}>
                    <CBSearchInput
                      workshopID={workshopID}
                      changeSearchTerms={this.changeSearchTerms}
                      onBlur={this.refetchWSVehicles}
                      containerStyle={{ height: 40, marginLeft: 0 }}
                      placeholder=''
                      type='wsVehicles'
                    />
                  </div>
                  <div style={getStyle(screenSize, styles, 'datePickersContainer')}>
                    <CBSeparator label={t('dateFilterLabel')} showIcon={screenSize !== 'small'} />
                    <div style={getStyle(screenSize, styles, 'datePickerContainer')}>
                      <CBDatePicker
                        date={date_begin}
                        changeDate={this.changeStartDate}
                        placeholder='Alku'
                        isClearable
                      />
                      <img
                        src='/img/icon_arrow_calendar.svg'
                        alt='close'
                        style={getStyle(screenSize, styles, 'arrowIcon')}
                      />
                    </div>
                    <div style={getStyle(screenSize, styles, 'datePickerContainer')}>
                      <CBDatePicker
                        date={date_end}
                        changeDate={this.changeEndDate}
                        placeholder='Loppu'
                        isClearable
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              filterBlock={(
                <React.Fragment>
                  <div style={getStyle(screenSize, styles, 'orderingContainer')}>
                    <CBSelectField
                      name='ordering'
                      optionStyle={getStyle(screenSize, styles, 'option')}
                      value={ordering}
                      options={orderingOptions}
                      placeholder='Valitse'
                      onChange={this.handleOrderingChanged}
                      labelText=''
                    />
                  </div>
                </React.Fragment>
              )}
            />
            <WorkshopVehicleList
              refetchWSVehicles={this.refetchWSVehicles}
              wsVehicles={wsVehicles}
              workshopID={workshopID}
              t={t}
              filters={Object.assign({}, this.state)}
            />
            {screenSize !== 'small' && <React.Fragment></React.Fragment>}
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      marginTop: 16,
    },
    arrowIcon: {
      margin: '0px 16px',
      width: 32,
      height: 32,
      fill: '#ffffff',
    },
    form: {
      backgroundColor: 'transparent',
      borderStyle: 'none',
      color: 'white',
    },
    orderingContainer: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: 200,
    },
    datePickersContainer: {
      color: '#fff !important',
      display: 'flex',
    },
    datePickerContainer: {
      display: 'inline-flex',
    },
    searchInputContainer: {
      width: '33%',
    },
    option: {
      backgroundColor: 'rgba(0, 149, 218, 1)',
    },
  },
  medium: {
    container: {
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 15,
    },
    orderingContainer: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: 200,
    },
    searchInputContainer: {
      width: '33%',
    },
  },
  small: {
    searchInputContainer: {
      width: '100%',
      marginBottom: 8,
    },
  },
};

function mapStateToProps(state) {
  return {
    wsVehicles: state.vehicle.wsVehicles,
    searchRequest: state.search,
    authUserRequest: state.authUser,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchWSVehicles: _fetchWSVehicles,
  },
)(translate('WSHomeView')(HomeWSUser));
