import React, { Component } from 'react';
import Modal from 'react-modal';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../../shared/CBMediaQuery';
import '../../../App.css';
import { getStyle, getModalStyle, SERVICE_FLEET, SERVICE_WORKSHOP } from '../../../utils/utils';
import CBButtonSubmit from '../../shared/CBButtonSubmit';
import CBInputField from '../../shared/form_components/CBInputField';

class RemoteControlModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      selected: null,
    };
  }

  connect = () => {
    const {
      selectWorkshop,
      selectOrganization,
      closeModal,
      service,
    } = this.props;
    const { selected } = this.state;

    if (service === SERVICE_FLEET) {
      selectOrganization(selected);
    } else if (service === SERVICE_WORKSHOP) {
      selectWorkshop(selected);
    }

    this.setState({ search: '', selected: null });
    closeModal();
  };

  selectItem = (item) => {
    this.setState({ selected: item.id, search: item.name });
  };

  render() {
    const { search, selected } = this.state;
    const {
      modalIsOpen,
      closeModal,
      t,
      user,
      service,
    } = this.props;
    const items = service === SERVICE_FLEET ? user.organizations : user.workshops;

    let filteredItems = [];

    if (search !== '') {
      filteredItems = items.filter(item =>
        item.name.toLowerCase().includes(search.toLowerCase()),
      );
    }

    return (
      <CBMediaQuery>
        {screenSize => (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={getModalStyle(screenSize, styles.modal)}
          >
            <div style={getStyle(screenSize, styles, 'container')}>
              <img
                src='/img/icon_close.svg'
                alt='close'
                style={getStyle(screenSize, styles, 'closeIcon')}
                onClick={closeModal}
              />
              <p style={getStyle(screenSize, styles, 'title')}>
                { service === SERVICE_WORKSHOP ? t('remoteControlWsModalTitle') : t('remoteControlOrgModalTitle')}
              </p>
              <React.Fragment>
                <div style={getStyle(screenSize, styles, 'fieldsRow')}>
                  <CBInputField
                    name='search'
                    inputStyle={getStyle(screenSize, styles, 'input')}
                    value={search}
                    placeholder={t('workshopNameInputPlaceholder')}
                    onChange={(e) => {
                      this.setState({ search: e.value, selected: null });
                    }}
                    isValidCallback={() => null}
                    labelText={t('workshopNameInputLabel')}
                    labelTextStyle={getStyle(screenSize, styles, 'subTitle')}
                    autoComplete={false}
                  />
                </div>
                <div style={getStyle(screenSize, styles, 'workshopList')}>
                  {!selected && filteredItems.map(item => (
                    <div
                      key={item.id}
                      style={getStyle(
                        screenSize,
                        styles,
                        'workshopListItem',
                      )}
                      onClick={() => this.selectItem(item)}
                    >
                      {item.name}
                    </div>
                  ))}
                  {!selected
                  && search !== ''
                  && filteredItems.length === 0 && (
                    <div style={getStyle(screenSize, styles, 'errorText')}>
                      {t('noWorkshopsFound')}
                    </div>
                  )}
                </div>
              </React.Fragment>
              <CBButtonSubmit
                disabled={selected === null}
                onClick={this.connect}
                text={t('connectRemoteControlButton')}
              />
            </div>
          </Modal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
    title: {
      fontSize: 32,
      color: '#0095da',
      textAlign: 'center',
    },
    subTitle: {
      fontSize: 14,
      color: 'black',
      textAlign: 'center',
      fontWeight: 600,
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: '100%',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      textAlign: 'center',
    },
    fieldsRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'space-between',
    },
    inputCol: {
      display: 'flex',
      flexDirection: 'column',
    },
    workshopList: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: '30px 0',
      marginBottom: 30,
      borderStyle: 'solid',
      borderColor: 'rgba(102, 102, 102, 0.3)',
      borderWidth: '0 0 1px 0',
      alignItems: 'center',
    },
    workshopListItem: {
      fontFamily: 'TitilliumWeb-SemiBold',
      cursor: 'pointer',
      marginBottom: 5,
    },
    errorText: {
      fontFamily: 'TitilliumWeb-SemiBold',
      color: 'red',
    },
    closeIcon: {
      position: 'absolute',
      top: 8,
      right: 8,
      opacity: 0.2,
      cursor: 'pointer',
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },
    fieldsRow: {
      display: 'flex',
      flexDirection: 'column',
      width: 250,
      justifyContent: 'space-between',
      alignItems: 'space-between',
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 200,
      resize: 'none',
      color: 'black',
      height: 30,
    },
  },
  modal: {
    default: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        zIndex: 100,
      },
    },
    medium: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    small: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '90%',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
  };
}

export default connect(
  mapStateToProps,
  {
  },
)(translate('Header')(RemoteControlModal));
