import React from 'react';
import { Route } from 'react-router';
import { translate } from 'react-i18next';
import VehicleDetailsTaskContent from './VehicleDetailsTaskContent';
import VehicleDetailsTaskList from './VehicleDetailsTaskList';
import VehicleDetailsSectionHeader from './VehicleDetailsSectionHeader';
import CBMediaQuery from '../../shared/CBMediaQuery';

class VehicleDetailsMainTask extends React.Component {
  render() {
    const { t, vehicle } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div>
            <VehicleDetailsSectionHeader type='task' text={t('tasksHeading')} />
            {screenSize === 'small' && <VehicleDetailsTaskList vehicle={vehicle} />}
            <div style={styles.mainContentContainer}>
              {screenSize !== 'small' && <VehicleDetailsTaskList vehicle={vehicle} />}
              <Route
                exact
                path='/vehicle/:vehicleId/tasks/'
                render={() => <VehicleDetailsTaskContent vehicle={vehicle} />}
              />
              <Route
                path='/vehicle/:vehicleId/tasks/:taskId'
                render={props => <VehicleDetailsTaskContent {...props} vehicle={vehicle} />}
              />
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  mainContentContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
};

export default translate('VehicleDetailsView')(VehicleDetailsMainTask);
