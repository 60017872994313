import React, { Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TreatmentListItem from './ListItems/TreatmentListItem';
import CBSelect from '../../shared/CBSelect';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle, getUrlId } from '../../../utils/utils';
import AddTreatmentButton from './ListItems/AddTreatmentButton';
import { searchTreatments as _searchTreatments } from '../../../actions/searchActions';

class VehicleDetailsTreatmentList extends React.Component {
  componentWillMount() {
    const { searchTreatments, searchRequest, vehicle } = this.props;
    searchTreatments({
      vehicle_id: vehicle.id,
      search: searchRequest.treatmentFilters.search,
      treatment_type: searchRequest.treatmentFilters.treatmentType,
    });
  }

  getFilteredTreatments = treatments => {
    const { searchRequest } = this.props;
    const { treatmentIDs } = searchRequest;

    if (!treatmentIDs) {
      return treatments;
    }

    return treatments.filter(treatment =>
      treatmentIDs.includes(parseInt(treatment.id)),
    );
  };

  isSelected = (treatment, idx) => {
    const urlParts = window.location.pathname.split('/');
    const index = urlParts.indexOf('treatments') + 1;

    if (index && urlParts[index]) {
      return treatment.id == urlParts[index];
    }

    return idx == 0;
  };


  treatmentSelected = e => {
    const { history, vehicle } = this.props;
    history.push(`/vehicle/${vehicle.id}/treatments/${e.target.value}`);
  };

  //Do not remove. For unknown reasons the view won't re-render without this.
  static getDerivedStateFromProps(nextProps, prevState) {}

  render() {
    const { vehicle, location, history } = this.props;
    const { treatments } = vehicle;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div
            style={getStyle(
              screenSize,
              styles,
              screenSize !== 'small' ? 'listContainer' : 'container',
            )}
          >
            {screenSize !== 'small' && (
              <div>
                {vehicle.can_edit && <AddTreatmentButton vehicle={vehicle} />}
                {this.getFilteredTreatments(treatments).map((treatment, idx) => (
                  <TreatmentListItem
                    key={treatment.id}
                    vehicle={vehicle}
                    treatment={treatment}
                    history={history}
                    isSelected={this.isSelected(treatment, idx)}
                  />
                ))}
              </div>
            )}
            {screenSize === 'small' && (
              <Fragment>
                <div>
                  {vehicle.can_edit && <AddTreatmentButton vehicle={vehicle} />}
                </div>
                <div style={getStyle(screenSize, styles, 'selectContainer')}>
                  <CBSelect
                    value={getUrlId(location.pathname)}
                    onChange={this.treatmentSelected}
                    >
                    {this.getFilteredTreatments(treatments).map(treatment => (
                      <option key={treatment.id} value={treatment.id}>
                        {`${moment(treatment.date).format('D.M.Y')} | ${
                          treatment.treatment_type_display
                        }`}
                      </option>
                    ))}
                  </CBSelect>
                </div>
              </Fragment>
            )}
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
      width: '25%',
    },
    selectContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 40,
      marginRight: 15,
      marginLeft: 15,
      marginTop: 40,
    },
  },
  small: {
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
      width: '100%',
      maxHeight: 300,
      overflow: 'auto',
    },
  },
};

function mapStateToProps(state) {
  return {
    searchRequest: state.search,
  };
}

export default connect(
  mapStateToProps,
  {
    searchTreatments: _searchTreatments,
  },
)(withRouter(VehicleDetailsTreatmentList));
