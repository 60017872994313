import React from 'react';
import Spinner from 'react-spinkit';

class CBSpinner extends React.Component {
  render() {
    return (
      <div style={styles.spinnerContainer}>
        <Spinner name="ball-beat" />
      </div>
    );
  }
}

const styles = {
  spinnerContainer: {
    textAlign: 'center',
    paddingTop: 100,
  },
};

export default CBSpinner;
