import React, { Component } from 'react';
import { browserIsIE } from '../../../ie-support';

class CBTextAreaField extends Component {
  constructor(props) {
    super(props);
    this.state = { isValid: true, value: props.value, errors: '' };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    if (this.state.value != value) {
      this.setState({
        value
      });
    }
  };

  parseServerErrorMessages = () => {
    const { name, serverErrors } = this.props;
    if (serverErrors !== null && typeof serverErrors === 'object' && serverErrors[name]) {
      let concattedServerErrors = '';
      serverErrors[name].forEach(msgPart => {
        concattedServerErrors += `${msgPart} `;
      });
      return concattedServerErrors;
    }
    return undefined;
  };

  validateInput = elem => {
    const value = elem.target.value;
    const { validators, name, isValidCallback } = this.props;
    if (validators) {
      this.setState({ errors: '' });
      let validationErrors = '';
      let newValueIsValid = true;
      validators.forEach(validator => {
        if (!validator.validator(value)) {
          newValueIsValid = false;
          validationErrors += `${validator.errorMessage} `;
        }
      });
      isValidCallback(name, newValueIsValid);
      this.setState({ isValid: newValueIsValid, errors: validationErrors });
    }
  };

  handleChange = elem => {
    const value = elem.target.value;
    const { isValid } = this.state;
    const { name, type, onChange, validateOnInput } = this.props;
    if (validateOnInput) {
      this.validateInput(elem);
    }
    this.setState({ value });
    if (isValid) {
      onChange({
        target: name,
        value,
      });
    }
  };

  render() {
    const {
      labelStyle,
      labelTextStyle,
      labelText,
      name,
      type,
      step,
      placeholder,
      inputStyle,
      maxLength,
    } = this.props;

    const { value, errors } = this.state;
    return (
      <div style={browserIsIE() ? styles.fieldContainerIE : styles.fieldContainer}>
        <div style={labelStyle || styles.label}>
          <p className='font-titillium-web-semi-bold' style={labelTextStyle || styles.labelText}>
            {labelText}
          </p>
        </div>
        <textarea
          value={value}
          onChange={this.handleChange}
          onBlur={this.validateInput}
          name={name}
          placeholder={placeholder}
          style={inputStyle || styles.input}
          maxLength={maxLength}
        />
        <span style={styles.errorMessage}>
          {this.parseServerErrorMessages() || errors}
        </span>
      </div>
    );
  }
}

const styles = {
  fieldContainer: {
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  fieldContainerIE: {
    marginTop: 60,
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  input: {
    height: 175,
    width: '100%',
    resize: 'none',
    backgroundColor: 'rgb(235, 235, 235)',
    borderRadius: 16,
    fontSize: 14,
    border: 0,
    padding: 8,
  },
  labelStyle: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 20,
  },
  labelTextStyle: {},
  errorMessage: {
    fontSize: 10,
    color: 'red',
  },
};

export default CBTextAreaField;
