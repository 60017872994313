import React from 'react';
import moment from 'moment';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import InfoboxWithIcon from './ListItems/InfoboxWithIcon';
import {
  fetchVehicleTreatment as _fetchVehicleTreatment,
  updateVehicleTreatment as _updateVehicleTreatment,
  deleteVehicleTreatment as _deleteVehicleTreatment,
  createTreatmentImage as _createTreatmentImage,
  deleteTreatmentImage as _deleteTreatmentImage,
} from '../../../actions/vehicleActions';
import CBSpinner from '../../shared/CBSpinner';
import { getStyle, getTreatmentTypeTranslationKey, styleMerge, formatKm } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBTextAreaField from '../../shared/form_components/CBTextAreaField';
import AddTreatmentModal from './AddTreatmentModal';
import CBPromptModal from '../../shared/CBPromptModal';
import TextWithLinebreaks from '../../shared/TextWithLinebreaks';
import ImageCarouselModal from './ImageCarouselModal';
import CBDropzone from '../../shared/CBDropzone';
import AddImageModal from '../../shared/AddImageModal';

class VehicleDetailsTreatmentContent extends React.Component {
  state = {
    modalIsOpen: false,
    deleteModalIsOpen: false,
    imageModalIsOpen: false,
    removingImages: false,
    imageIndex: 0,
    addImageModal: false,
    addedImage: null,
  };

  componentDidMount = () => {
    const {
      match,
      fetchVehicleTreatment,
      vehicle,
      searchRequest,
    } = this.props;
    const { treatmentIDs } = searchRequest;
    if (match) {
      fetchVehicleTreatment(match.params.treatmentId);
    } else if (vehicle && vehicle.treatments.length) {
      if (treatmentIDs) {
        if (treatmentIDs.length) {
          fetchVehicleTreatment(treatmentIDs[0]);
        }
      } else {
        fetchVehicleTreatment(vehicle.treatments[0].id);
      }
    }
  };

  componentDidUpdate(prevProps) {
    const {
      match,
      fetchVehicleTreatment,
      vehicle,
      searchRequest,
    } = this.props;
    const { treatmentIDs } = searchRequest;

    if (match && match.params.treatmentId !== prevProps.match.params.treatmentId) {
      fetchVehicleTreatment(match.params.treatmentId);
    } else if (vehicle
      && vehicle.treatments.length
      && (!prevProps.vehicle.treatments.length
        || vehicle.treatments[0].complete
        !== prevProps.vehicle.treatments[0].complete)) {
      if (treatmentIDs) {
        if (treatmentIDs.length) {
          fetchVehicleTreatment(treatmentIDs[0]);
        }
      } else {
        fetchVehicleTreatment(vehicle.treatments[0].id);
      }
    } else if (vehicle
        && vehicle.treatments.length
        && treatmentIDs
        && treatmentIDs.length
        && prevProps.searchRequest
        && prevProps.searchRequest.treatmentIDs
        && treatmentIDs[0]
        !== prevProps.searchRequest.treatmentIDs[0]) {
      fetchVehicleTreatment(treatmentIDs[0]);
    }
  }

  getTreatment = () => {
    const { vehicle, match } = this.props;
    const { treatmentIDs } = this.props.searchRequest;
    let treatment = match
    ? vehicle.treatments.find(x => x.id == match.params.treatmentId)
    : undefined;
    if (!treatment && vehicle && vehicle.treatments.length) {
      if (treatmentIDs) {
        if (treatmentIDs.length) {
          treatment = vehicle.treatments.find(
            x => x.id === treatmentIDs[0],
          );
        }
      } else {
        treatment = vehicle.treatments[0];
      }
    }
    return treatment;
  }

  deleteVehicleTreatment = () => {
    const { deleteVehicleTreatment, match, vehicle } = this.props;
    this.setState({
      deleteModalIsOpen: false,
    });
    if (match) {
      deleteVehicleTreatment(match.params.treatmentId);
    } else if (vehicle && vehicle.treatments.length) {
      deleteVehicleTreatment(vehicle.treatments[0].id);
    }
  }

  openDeleteTreatmentModal = () => {
    this.setState({
      deleteModalIsOpen: true,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      deleteModalIsOpen: false,
    });
  };

  afterOpenDeleteModal = () => {
  };

  editTreatment = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  hideAddImageModal = () => {
    this.setState({
      addImageModal: false,
    });
  }

  afterOpenModal = () => {
  };

  toggleEdit = () => {
    const { editDescription } = this.state;
    this.setState({ editDescription: !editDescription });
  };

  saveChanges = () => {
    const { description, name, editDescription } = this.state;
    const { match, updateVehicleTreatment, vehicle } = this.props;
    let treatment = match
      ? vehicle.treatments.find(x => x.id === match.params.treatmentId)
      : undefined;

    if (!treatment && vehicle && vehicle.treatments.length) {
      treatment = vehicle.treatments[0];
    }

    this.setState({ editDescription: !editDescription });
    if (match) {
      updateVehicleTreatment(match.params.treatmentId, { ...treatment, description });
    } else if (vehicle && vehicle.treatments.length) {
      updateVehicleTreatment(vehicle.treatments[0].id, { ...treatment, description });
    }
  };

  handleChange = elem => {
    this.setState({
      [elem.target]: elem.value,
    });
  };

  onImageUpload = acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          addedImage: reader.result,
          addImageModal: true,
        });
      };
    });
  };

  uploadImage = (image) => {
    const { createTreatmentImage } = this.props;
    const treatment = this.getTreatment();
    this.setState({ addImageModal: false });
    if (treatment) {
      createTreatmentImage({
        treatment: treatment.id,
        image: image,
      });
    }
  }

  toggleImageRemoveMode = () => {
    this.setState(prevState => ({
      ...prevState,
      removingImages: !prevState.removingImages,
    }));
  }

  removeImage = id => {
    const { deleteTreatmentImage } = this.props;
    deleteTreatmentImage(id);
  }

  openImageModal = index => {
    this.setState({ imageModalIsOpen: true, imageIndex: index });
  }

  hideImageModal = () => {
    this.setState({ imageModalIsOpen: false });
  }

  render() {
    const {
      vehicleRequest, t, match, vehicle, searchRequest,
    } = this.props;
    const { treatmentIDs } = searchRequest;
    const {
      editDescription,
      modalIsOpen,
      deleteModalIsOpen,
      imageModalIsOpen,
      removingImages,
      imageIndex,
      addedImage,
      addImageModal,
    } = this.state;
    const treatment = this.getTreatment();
    let treatmentTranslationKey;
    if (treatment) {
      treatmentTranslationKey = getTreatmentTypeTranslationKey(treatment.treatment_type);
    }
    if (vehicleRequest.treatmentStatus.loading || (treatment && !treatment.complete)) {
      return (
        <div style={styles.default.treatmentContentContainer}>
          <CBSpinner />
        </div>
      );
    } else if (vehicleRequest.treatmentStatus.error) {
      return (
        <p>
          {vehicleRequest.treatmentStatus.error}
        </p>
      );
    } else {
      return (
        <CBMediaQuery>
          {screenSize => (
            <div style={getStyle(screenSize, styles, 'treatmentContentContainer')}>
              {treatment && (
                <React.Fragment>
                  <div style={getStyle(screenSize, styles, 'topInfoContainer')}>
                    <InfoboxWithIcon
                      icon='/img/icon_calendar.svg'
                      text={moment(treatment.date).format('D.M.Y')}
                      labelText={t('dateInfobox')}
                    />
                    <InfoboxWithIcon
                      icon='/img/icon_distance_1.svg'
                      text={treatment.km ? formatKm(treatment.km) : t('notSpecified')}
                      labelText={t('kilometersInfobox')}
                      hasBorders={true}
                    />
                    <InfoboxWithIcon
                      icon='/img/icon_13-1.svg'
                      text={t(`${treatmentTranslationKey}`)}
                      labelText={t('classificationInfobox')}

                    />
                  </div>
                  <div style={getStyle(screenSize, styles, 'bodyContainer')}>
                    <div style={getStyle(screenSize, styles, 'descriptionContainer')}>
                      <div
                        className='font-titillium-web-extra-light'
                        style={getStyle(screenSize, styles, 'sectionHeadingText')}
                      >
                        <div>
                          {t('treatmentInfoHeading')}
                        </div>
                        <div style={getStyle(screenSize, styles, 'additionalTreatmentInfo')}>
                          <div style={getStyle(screenSize, styles, 'creatorContainer')}>
                            {`${t('treatmentUserLabel')}: ${treatment.creator}`}
                          </div>
                          <div>
                            {`${t('treatmentLastModifiedLabel')}: ${moment(treatment.modified_at).format('D.M.YYYY HH:mm')}`}
                          </div>
                        </div>
                      </div>
                      <p
                        style={getStyle(screenSize, styles, 'treatmentNameText')}>
                        {treatment.name}
                      </p>
                      {!editDescription ? (
                        <p style={getStyle(screenSize, styles, 'treatmentDescriptionText')}>
                          <TextWithLinebreaks text={treatment.description} />
                        </p>
                      ) : (
                        <CBTextAreaField
                          value={treatment.description}
                          name='description'
                          onChange={this.handleChange}
                        />
                      )}
                      <div style={getStyle(screenSize, styles, 'imageContainer')}>
                        {treatment.images.map((image, index) => (
                          <div
                            style={{
                              ...getStyle(screenSize, styles, 'squareImage'),
                              backgroundImage: `url(${image.image})`,
                            }}
                            key={image.id}
                            onClick={removingImages
                              ? () => {
                                this.removeImage(image.id);
                                if (treatment.images.length === 1) {
                                  this.setState({ removingImages: false });
                                }
                              }
                              : () => this.openImageModal(index)}
                          >
                            {removingImages && <img style={getStyle(screenSize, styles, 'imageRemoveIcon')} src='/img/icon_trashcan.svg' alt={t('removeImg')} />}
                          </div>
                        ))}
                      </div>
                      {
                        vehicle.can_edit && (
                          <div
                            className='font-titillium-web'
                            style={getStyle(screenSize, styles, 'boxContainer')}
                          >
                            <div
                              className='font-titillium-web'
                              style={getStyle(screenSize, styles, 'boxLeftContainer')}
                            >
                              <CBDropzone
                                className={removingImages ? '' : 'opacity-and-border-on-hover'}
                                style={removingImages ? {
                                  ...getStyle(screenSize, styles, 'wideButton'),
                                  ...getStyle(screenSize, styles, 'buttonDisabled'),
                                } : getStyle(screenSize, styles, 'wideButton')}
                                onDropAccepted={removingImages
                                  ? undefined
                                  : (files) => this.onImageUpload(files)
                                }
                                disabled={removingImages}
                              >
                                <img
                                  style={getStyle(screenSize, styles, 'buttonIcon')}
                                  alt={t('addImg')}
                                  src='/img/icon_plus.svg'
                                />
                                <div style={getStyle(screenSize, styles, 'buttonText')}>
                                  {`${t('addImg')} `}
                                </div>
                              </CBDropzone>
                              {treatment.images.length > 0 && (
                                <div
                                  className={removingImages ? '' : 'opacity-and-border-on-hover'}
                                  style={removingImages
                                    ? styleMerge(
                                      getStyle(screenSize, styles, 'buttonRight'),
                                      getStyle(screenSize, styles, 'buttonGreen'),
                                    )
                                    : getStyle(screenSize, styles, 'buttonRight')
                                  }
                                  onClick={this.toggleImageRemoveMode}
                                >
                                  <img
                                    style={getStyle(screenSize, styles, 'buttonIcon')}
                                    alt='remove'
                                    src={removingImages ? '/img/icon_checkmark.svg' : '/img/remove.svg'}
                                  />
                                  <div style={getStyle(screenSize, styles, 'buttonText')}>
                                    {removingImages ? t('done') : t('removeImg')}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div
                              className='font-titillium-web'
                              style={getStyle(screenSize, styles, 'boxRightContainer')}
                            >
                              <div
                                className={removingImages ? '' : 'opacity-and-border-on-hover'}
                                style={removingImages
                                  ? styleMerge(
                                    getStyle(screenSize, styles, 'buttonLeft'),
                                    getStyle(screenSize, styles, 'buttonDisabled'),
                                  )
                                  : getStyle(screenSize, styles, 'buttonLeft')
                                }
                                onClick={removingImages ? undefined : this.editTreatment}
                              >
                                <img
                                  style={getStyle(screenSize, styles, 'buttonIcon')}
                                  alt='edit'
                                  src='/img/edit.svg'
                                />
                                <div style={getStyle(screenSize, styles, 'buttonText')}>
                                  {`${t('edit')} `}
                                </div>
                              </div>
                              <div
                                className={removingImages ? '' : 'opacity-and-border-on-hover'}
                                style={removingImages
                                  ? styleMerge(
                                    getStyle(screenSize, styles, 'button'),
                                    getStyle(screenSize, styles, 'buttonDisabled'),
                                  )
                                  : getStyle(screenSize, styles, 'button')
                                }
                                onClick={removingImages ? undefined : this.openDeleteTreatmentModal}
                              >
                                <img
                                  style={getStyle(screenSize, styles, 'buttonIcon')}
                                  alt='remove'
                                  src='/img/remove.svg'
                                />
                                <div style={getStyle(screenSize, styles, 'buttonText')}>
                                  {`${t('remove')} `}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                  {
                    modalIsOpen && (
                      <AddTreatmentModal
                        modalIsOpen={modalIsOpen}
                        closeModal={this.closeModal}
                        afterOpenModal={this.afterOpenModal}
                        treatment={treatment}
                        vehicle={vehicle}
                      />
                    )
                  }
                  <CBPromptModal
                    modalIsOpen={deleteModalIsOpen}
                    closeModal={this.closeDeleteModal}
                    afterOpenModal={this.afterOpenDeleteModal}
                    title={t('deleteTreatmentPromptTitle')}
                    text={t('deleteTreatmentPromptText')}
                    buttonText={t('deleteTreatmentPromptButton')}
                    performAction={this.deleteVehicleTreatment}
                  />
                  <ImageCarouselModal
                    show={imageModalIsOpen}
                    handleClose={this.hideImageModal}
                    imageIndex={imageIndex}
                    images={treatment.images}
                    showNextImage={this.showNextImage}
                    showPreviousImage={this.showPreviousImage}
                    getBackroundImageUrl={this.getBackroundImageUrl}
                  />
                  {addImageModal && (
                    <AddImageModal
                      show={addImageModal}
                      handleClose={this.hideAddImageModal}
                      image={addedImage}
                      onImageSave={(image) => { this.uploadImage(image); }}
                    />
                  )}
                </React.Fragment>
              )}
            </div>
          )}
        </CBMediaQuery>
      );
    }
  }
}

const styles = {
  default: {
    boxContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 67,
    },
    boxRightContainer: {
      display: 'flex',
    },
    boxLeftContainer: {
      display: 'flex',
    },
    creatorContainer: {
      marginRight: 16,
    },
    button: {
      width: 115,
      display: 'flex',
      height: 30,
      border: '1px solid rgba(255, 255, 255, 0.7)',
      borderRadius: 50,
      alignItems: 'center',
      paddingLeft: 5,
      cursor: 'pointer',
    },
    wideButton: {
      width: 130,
      display: 'flex',
      height: 30,
      border: '1px solid rgba(255, 255, 255, 0.7)',
      borderRadius: 50,
      alignItems: 'center',
      paddingLeft: 5,
      cursor: 'pointer',
    },
    buttonRight: {
      width: 130,
      display: 'flex',
      height: 30,
      border: '1px solid rgba(255, 255, 255, 0.7)',
      borderRadius: 50,
      alignItems: 'center',
      paddingLeft: 5,
      marginLeft: 10,
      cursor: 'pointer',
    },
    buttonLeft: {
      width: 115,
      display: 'flex',
      height: 30,
      border: '1px solid rgba(255, 255, 255, 0.7)',
      borderRadius: 50,
      alignItems: 'center',
      paddingLeft: 5,
      marginRight: 10,
      cursor: 'pointer',
    },
    buttonGreen: {
      backgroundColor: '#00F600',
      border: '0 solid white',
    },
    buttonText: {
      color: 'white',
      fontSize: 14,
      textDecoration: 'none solid rgb(255, 255, 255)',
      marginLeft: 10,
    },
    buttonIcon: {
      height: 24,
      width: 24,
      marginLeft: 3,
    },
    buttonDisabled: {
      opacity: 0.5,
      cursor: 'default',
    },
    treatmentNameText: {
      fontSize: 32,
    },
    treatmentDescriptionText: {
      fontSize: 14,
      minWidth: '100%',
      width: 0,
      overflowWrap: 'break-word',
    },
    sectionHeadingText: {
      display: 'flex',
      fontSize: 20,
      justifyContent: 'space-between',
      alignItems: 'center',
      color: 'white',
      marginTop: 34,
    },
    descriptionContainer: {},
    additionalTreatmentInfo: {
      display: 'flex',
      fontSize: 12,
      color: 'white',
    },
    treatmentContentContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 1)',
      minHeight: 500,
      width: '75%',
    },
    topInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    bodyContainer: {
      padding: 16,
    },
    imageContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 50,
    },
    squareImage: {
      width: 100,
      height: 100,
      margin: '0 10px 10px 0',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
      cursor: 'pointer',
    },
    imageRemoveIcon: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
      filter: 'drop-shadow(0px 1px 4px rgba(0,0,0,0.5))',
    },
  },
  medium: {
    sectionHeadingText: {
      marginTop: 5,
    },
    // // boxLeftContainer: {
    // //   flexDirection: 'column',
    // // },
    // // boxRightContainer: {
    // //   flexDirection: 'column',
    // // },
  },
  small: {
    buttonRight: {
      marginLeft: 'unset',
      marginTop: 10,
    },
    button: {
      marginTop: 10,
    },
    topInfoContainer: {
      display: 'flex',
      justifyContent: 'normal',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
    },
    treatmentContentContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      backgroundColor: 'transparent',
      minHeight: 500,
      width: '100%',
      padding: 0,
    },
    bodyContainer: {
      backgroundColor: 'rgba(0, 149, 218, 1)',
    },
    boxLeftContainer: {
      flexDirection: 'column',
    },
    boxRightContainer: {
      flexDirection: 'column',
    },
  },
};

function mapStateToProps(state) {
  return {
    vehicleRequest: state.vehicle,
    searchRequest: state.search,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchVehicleTreatment: _fetchVehicleTreatment,
    updateVehicleTreatment: _updateVehicleTreatment,
    deleteVehicleTreatment: _deleteVehicleTreatment,
    createTreatmentImage: _createTreatmentImage,
    deleteTreatmentImage: _deleteTreatmentImage,
  },
)(translate('VehicleDetailsView')(VehicleDetailsTreatmentContent));
