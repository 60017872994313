import React from 'react';
import { translate } from 'react-i18next';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle } from '../../../utils/utils';
import EditVehicleDescriptionModal from './Modal/EditVehicleDescriptionModal';

class VehicleDetailsBasicDescription extends React.Component {
  state = {
    modalIsOpen: false,
  };

  showModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  afterOpenModal = () => {};

  render() {
    const { t, vehicle } = this.props;
    const { modalIsOpen } = this.state;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'mainContentContainer')}>
            <p
              style={getStyle(screenSize, styles, 'descriptionHeaderText')}
              className='font-titillium-web-semi-bold'
            >
              {t('vehicleDescriptionHeading')}
            </p>
            <p
              style={getStyle(screenSize, styles, 'descriptionText')}
              className='font-titillium-web-light'
            >
              {vehicle.description.description}
            </p>
            {vehicle.can_edit && (
              <div onClick={this.showModal}>
                <img
                  src='/img/icon_edit.svg'
                  alt='Edit'
                  style={getStyle(screenSize, styles, 'editIcon')}
                />
              </div>
            )}
            <EditVehicleDescriptionModal
              modalIsOpen={modalIsOpen}
              closeModal={this.closeModal}
              afterOpenModal={this.afterOpenModal}
              description={vehicle.description}
              vehicle={vehicle}
            />
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    mainContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
      width: '25%',
      padding: 30,
    },
    descriptionHeaderText: {
      color: '#FFF',
      fontWeight: 400,
    },
    descriptionText: {
      color: '#FFF',
      whiteSpace: 'pre-line',
    },
    editIcon: {
      height: 30,
      width: 30,
      opacity: 0.7,
      cursor: 'pointer',
    },
  },
  small: {
    mainContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
      width: '100%',
      padding: 30,
    },
  },
};

export default translate('VehicleDetailsView')(VehicleDetailsBasicDescription);
