import React, { Component } from 'react';
import { getStyle } from '../../utils/utils';
import CBMediaQuery from './CBMediaQuery';

class CBButtonLargeGreen extends Component {
  render() {
    const { icon = '/img/add.svg', label, onClick, style } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div
          className='font-titillium-web-regular'
            style={style ? style : getStyle(screenSize, styles, 'container')}
            onClick={onClick}
          >
            <img
              src={icon}
              alt={label}
              style={getStyle(screenSize, styles, 'icon')}
            />
            <p style={getStyle(screenSize, styles, 'label')}>{label}</p>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      width: 290,
      height: 50,
      borderRadius: 25,
      backgroundColor: '#00f600',
      fontFamily: 'Titillium Web',
      fontSize: 18,
      color: '#ffffff',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    label: {
      fontSize: 18,
      color: 'white',
      marginLeft: 10,
      marginBottom: 0,
    },
    icon: {
      height: 32,
      width: 32,
    },
  },
  medium: {
    container: {
      marginBottom: -40,
    },
  },
  small: {
    marginBottom: 0,
  },
};

export default CBButtonLargeGreen;
