import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class OptionsListItem extends Component {
  render() {
    const { item } = this.props;
    const title = (
      <p style={styles.itemText}>
        {item.title}
      </p>
    );
    if (item.to) {
      return (
        <Link className='hover-background-grey' style={styles.container} to={item.to}>
          {title}
        </Link>
      );
    }
    return (
      <div className='hover-background-grey' style={styles.container} onClick={item.action}>
        {title}
      </div>
    );
  }
}

const styles = {
  itemText: {
    color: 'black',
    fontSize: 14,
    marginBottom: 5,
    marginTop: 5,
  },
  container: {
    display: 'flex',
    paddingLeft: 5,
    cursor: 'pointer',
    width: '100%',
  },
};
