import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getStyle, getModalStyle } from '../../../utils/utils';
import {
  createOrganizationVehicleStatus as _createOrganizationVehicleStatus,
} from '../../../actions/vehicleActions';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBButtonSubmit from '../../shared/CBButtonSubmit';
import CBButtonGeneric from '../../shared/CBButtonGeneric';
import CBInputField from '../../shared/form_components/CBInputField';
import { getLengthValidator } from '../../../utils/validators';
import CBDatePicker from '../../shared/CBDatePicker';
import CBButtonSettings from '../../shared/CBButtonSettings';
import moment from 'moment';

Modal.setAppElement('#root');

class EditGarageVehicleModal extends Component {
  state = {
    formData: {
      employee: '',
      business_unit: '',
      next_check_date: undefined,
      next_check_name: '',
    },
  };

  componentDidMount = () => {
    const { garageVehicleStatus, vehicleId } = this.props;
    const { formData } = this.state;

    this.setState({
      formData: {
        ...formData,
        vehicle_id: vehicleId,
      },
    });

    if (garageVehicleStatus) {
      this.setState(state => ({
        ...state,
        formData: {
          ...state.formData,
          employee: garageVehicleStatus.employee,
          business_unit: garageVehicleStatus.business_unit,
          next_check_date: garageVehicleStatus.next_check_date,
          next_check_name: garageVehicleStatus.next_check_name,
          vehicle_id: vehicleId,
        },
      }));
    }
  };

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
      [name]: isValid,
    };

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const {
      formData,
    } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({
      formData: newFormData,
    });
  };

  changeNextCheckDate = (date) => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData.next_check_date = date;
    this.setState({ formData: newFormData });
  }

  onSubmit = () => {
    const { createOrganizationVehicleStatus, closeModal } = this.props;
    const { formData } = this.state;

    const dateObject = {
      next_check_date: formData.next_check_date ? moment(formData.next_check_date).format('YYYY-MM-DD HH:mm:ss') : null,
    };
    const newFormData = Object.assign({}, formData, dateObject);

    createOrganizationVehicleStatus(newFormData);
    closeModal();
  };

  render() {
    const { formData } = this.state;
    const {
      modalIsOpen, closeModal, afterOpenModal, t,
    } = this.props;

    const nextCheckDate = formData.next_check_date
      ? moment(formData.next_check_date)
      : null;

    return (
      <CBMediaQuery>
        {screenSize => (
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={getModalStyle(screenSize, styles.modal)}
            contentLabel='Description modal'
          >
            <div style={getStyle(screenSize, styles, 'container')}>
              <p style={getStyle(screenSize, styles, 'title')}>
                {t('editGarageVehicle')}
              </p>

              <CBInputField
                name='employee'
                inputStyle={getStyle(screenSize, styles, 'input')}
                value={formData.employee}
                placeholder=''
                onChange={this.updateFormState}
                validators={[getLengthValidator(0, 255)]}
                isValidCallback={this.setIsValid}
                labelText={t('employee')}
                labelTextStyle={getStyle(screenSize, styles, 'subTitle')}
              />
              <CBInputField
                name='business_unit'
                inputStyle={getStyle(screenSize, styles, 'input')}
                value={formData.business_unit}
                placeholder=''
                onChange={this.updateFormState}
                validators={[getLengthValidator(0, 255)]}
                isValidCallback={this.setIsValid}
                labelText={t('businessUnit')}
                labelTextStyle={getStyle(screenSize, styles, 'subTitle')}
              />
              <div style={getStyle(screenSize, styles, 'buttonContainer')}>
                <div style={getStyle(screenSize, styles, 'cancelButtonContainer')}>
                  <CBButtonSubmit
                    disabled={false}
                    onClick={() => {
                      closeModal();
                    }}
                    text={t('cancel')}
                    style={getStyle(screenSize, styles, 'submitButton')}
                  />
                </div>
                <div style={getStyle(screenSize, styles, 'submitButtonContainer')}>
                  <CBButtonSubmit
                    text={t('save')}
                    onClick={this.onSubmit}
                    style={getStyle(screenSize, styles, 'submitButton')}
                  />
                </div>
              </div>
            </div>
          </Modal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },
    title: {
      fontSize: 32,
      fontFamily: "TitilliumWeb-Light",
      color: '#0095da',
      textAlign: 'center',
    },
    subTitle: {
      fontSize: 14,
      color: 'black',
      textAlign: 'center',
      fontWeight: 600,
    },
    clearButtonContainer: {
      height: 40,
      width: '100%',
      border: '2px solid #0095da',
      color: '#0095da',
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      marginLeft: 20,
    },
    datepickerContainer: {
      display: 'flex',
      marginBottom: 50,
      marginTop: 24,
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 400,
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      marginBottom: 30,
    },
    info: {
      width: '100%',
      fontSize: 14,
      color: 'black',
      paddingHorizontal: 30,
      textAlign: 'center',
    },
    submitButtonContainer: {
      width: 200,
      marginTop: 32,
    },
    cancelButtonContainer: {
      width: 200,
      marginTop: 32,
    },
    buttonContainer: {
      width: 450,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    submitButton: {
      height: 50,
      width: '100%',
      border: '2px solid #0095da',
      color: '#0095da',
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    input: {
      width: '100%',
    },
  },
  modal: {
    default: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height: 'auto',
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    medium: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
      },
      overlay: {
        zIndex: 'auto',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    small: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
};

function mapStateToProps(state) {
  return {
    vehiclePermissionRequest: state.vehiclePermission,
    user: state.authUser.user,
    vehicle: state.vehicle.vehicle,
  };
}

export default connect(
  mapStateToProps,
  {
    createOrganizationVehicleStatus: _createOrganizationVehicleStatus,
  },
)(translate('EditGarageVehicleModal')(EditGarageVehicleModal));
