import {
  API, GET, PUT, PATCH,
} from '../middleware/api';
import { API_URL } from '../config';

export function fetchGlobalStats() {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/global_stats`,
      types: [
        'FETCH_GLOBAL_STATS_REQUEST',
        'FETCH_GLOBAL_STATS_SUCCESS',
        'FETCH_GLOBAL_STATS_FAIL',
      ],
    }
  };
}