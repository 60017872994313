import React, { Component } from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import fiLocale from 'date-fns/locale/fi';
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

export default class CBDatePickerInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locale: 'fi',
    };
  }

  render() {
    const localeUtilsMap = {
      fi: DateFnsUtils,
    };

    const localeMap = {
      fi: fiLocale,
    };

    const localeFormatMap = {
      fi: 'dd.MM.yyyy',
    };
    const {
      changeDate, displayedDate, type, dateFormat, disabled
    } = this.props;

    const { locale } = this.state;
    if (type === 'date') {
      return (
        <MuiPickersUtilsProvider
          utils={localeUtilsMap[locale]}
          locale={localeMap[locale]}
        >
          <DatePicker
            value={displayedDate}
            onChange={changeDate}
            format={localeFormatMap[locale]}
            disabled={disabled}
            clearable={true}
          />
        </MuiPickersUtilsProvider>
      );
    } else if (type === 'datetime') {
      return (
        <MuiPickersUtilsProvider
          utils={localeUtilsMap[locale]}
          locale={localeMap[locale]}
        >
          <DateTimePicker
            value={displayedDate}
            onChange={changeDate}
            ampm={false}
            format={dateFormat || localeFormatMap[locale]}
            disabled={disabled}
          />
        </MuiPickersUtilsProvider>
      );
    } else {
      const { style, onClick, value } = this.props;
      return (
        <input readOnly style={style} value={value} onClick={onClick} />
      );
    }
  }
}
