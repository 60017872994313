import React, { Component } from 'react';
import moment from 'moment';
import { translate } from 'react-i18next';
import WorkshopNotificationModal from './WorkshopNotificationModal';

class WorkshopNotificationsListItem extends Component {
  state = {
    modalIsOpen: false,
  };

  openModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  afterOpenModal = () => {};

  render() {
    const { announcement, t } = this.props;
    const { modalIsOpen } = this.state;
    return (
      <React.Fragment>
        <div style={styles.announcementContainer} onClick={this.openModal}>
          <div style={styles.header}>
            <p className='font-titillium-web-semi-bold' style={styles.headerText}>
              {announcement.workshop.name}
            </p>
            <p style={styles.subHeaderText}>
              {announcement.workshop.street_address}
            </p>
          </div>
          <div style={styles.body}>
            <p style={styles.descriptionText} className='font-titillium-web-light'>
              {announcement.description}
            </p>
          </div>
          <div style={styles.footer}>
            <p style={styles.footerText}>
              {t('validUntil', { date: moment(announcement.expire_date).format('D.M.Y') })}
            </p>
          </div>
        </div>
        <WorkshopNotificationModal
          modalIsOpen={modalIsOpen}
          closeModal={this.closeModal}
          announcement={announcement}
          afterOpenModal={this.afterOpenModal}
        />
      </React.Fragment>
    );
  }
}

const styles = {
  announcementContainer: {
    backgroundColor: 'rgba(0, 149, 218, 0.5)',
    minHeight: 200,
    flexBasis: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRight: '4px solid #00f600',
    marginBottom: 5,
    paddingTop: 28,
    paddingLeft: 20,
    paddingRight: 20,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerText: {
    fontSize: 18,
  },
  subHeaderText: {
    fontSize: 14,
    opacity: 0.5,
  },
  descriptionText: {
    fontSize: 14,
    textAlign: 'center',
  },
  footerText: {
    opacity: 0.5,
  },
};

export default translate()(WorkshopNotificationsListItem);
