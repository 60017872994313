import { appState } from '../initialState';

export default function (state = appState, action) {
  switch (action.type) {
    case 'SET_APP_SERVICE': {
      return {
        ...state,
        service: action.payload,
      };
    }
    case 'SET_LAST_LIST_URL': {
      return {
        ...state,
        lastListUrl: action.payload,
      };
    }
    default:
      break;
  }
  return state;
}
