import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSearchInput from '../../shared/CBSearchInput';
import {
  createWorkshop as _createWorkshop,
  updateWorkshop as _updateWorkshop,
} from '../../../actions/workshopActions';
import CBHeaderTextWithHelp from '../../shared/CBHeaderTextWithHelp';
import WorkshopListItem from './WorkshopListItem';
import WorkshopForm from './WorkshopForm';
import CBButtonLarge from '../../shared/CBButtonLarge';

class StaffSettingsWorkshops extends React.Component {
  state = {
    selectedWorkshop: null,
  };

  componentDidMount = () => {
  };

  selectWorkshop = (id) => {
    const { workshops } = this.props;
    const { selectedWorkshop } = this.state;
    const workshop = workshops.find((ws) => {
      return id == ws.id
    });
    this.setState({ selectedWorkshop: Object.assign({}, workshop) });
  };

  createNewWorkshop = () => {
    const newWorkshop = {
      business_id: '',
      name: '',
      organization_name: '',
      street_address: '',
      zip_code: '',
      city: '',
      phone_number: '',
      contact_name: '',
      contact_phone_number: '',
    }

    this.setState({ selectedWorkshop: Object.assign({}, newWorkshop) });
  }

  render() {
    const {
      workshops, t, createWorkshop, updateWorkshop, workshopRequest
    } = this.props;
    const { selectedWorkshop } = this.state;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'editWorkshopsContainer')}>
              <div style={getStyle(screenSize, styles, 'addWorkshopContainer')}>
                <CBButtonLarge text={t('addWorkshopButton')} onClick={this.createNewWorkshop} />
              </div>
              <div style={getStyle(screenSize, styles, 'workshopFormContainer')}>
                <CBHeaderTextWithHelp text={t('staffWorkshopsHeading')} />
                {
                  selectedWorkshop && (
                    <WorkshopForm
                      workshop={selectedWorkshop}
                      workshopRequest={workshopRequest}
                      createWorkshop={createWorkshop}
                      updateWorkshop={updateWorkshop}
                    />
                  )
                }
              </div>
            </div>
            <div style={getStyle(screenSize, styles, 'searchWorkshopContainer')}>
              <CBButtonLarge
                style={getStyle(screenSize, styles, 'largeButton')}
                text={t('Footer:logoutButton')}
                href='/accounts/logout/'
              />
              <CBHeaderTextWithHelp text={t('workshopsSearchHeading')} />
              <div style={getStyle(screenSize, styles, 'searchContainer')}>
                <CBSearchInput containerStyle={{ marginLeft: 0 }} type='workshop' />
              </div>
              <div style={getStyle(screenSize, styles, 'resultContainer')}>
                {workshops
                  .map(workshop => (
                    <WorkshopListItem
                      key={workshop.id}
                      buttonAction={this.selectWorkshop}
                      label={workshop.name}
                      editMode={true}
                      id={workshop.id}
                      isFavourite={false}
                      value={`${workshop.street_address}, ${workshop.zip_code} ${workshop.city}`}
                    />
                  ))}
              </div>
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      paddingBottom: 50,
    },
    addWorkshopContainer: {
      marginBottom: 40,
    },
    editWorkshopsContainer: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 40,
      width: '50%',
      borderRight: '1px solid rgba(255, 255, 255, 0.4)',
      minHeight: 500,
    },
    searchWorkshopContainer: {
      width: '50%',
      padding: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
    searchContainer: {
      marginBottom: 16,
    },
    largeButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 50,
      border: '2px solid #00f600',
      cursor: 'pointer',
      borderRadius: 50,
      ':hover': {
        backgroundColor: '#00f600',
      },
      marginTop: 20,
      marginBottom: 45,
    },
  },
  medium: {
    container: {
      paddingBottom: 25,
    },
    addWorkshopContainer: {
      marginBottom: 20,
    },
    editWorkshopsContainer: {
      paddingLeft: 0,
      paddingRight: 15,
      paddingTop: 15,
    },
    searchWorkshopContainer: {
      padding: 15,
      paddingLeft: 15,
      paddingRight: 0,
    },
    largeButton: {
      width: 280,
      height: 50,
      marginTop: 'unset',
      marginBottom: 25,
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    editWorkshopsContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      width: '100%',
      borderRight: 0,
      marginBottom: 20,
      minHeight: 0,
    },
    searchWorkshopContainer: {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: 20,
    },
    largeButton: {
      width: 240,
      height: 50,
    },
  },
};

function mapStateToProps(state) {
  return {
    workshops: state.workshop.workshops,
    workshopRequest: state.workshop,
  };
}

export default connect(
  mapStateToProps,
  {
    createWorkshop: _createWorkshop,
    updateWorkshop: _updateWorkshop,
  },
)(translate('SettingsView')(StaffSettingsWorkshops));
