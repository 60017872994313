import React from 'react';
import { Link } from 'react-router-dom';
import CBMediaQuery from './CBMediaQuery';
import { getStyle, styleMerge } from '../../utils/utils';

class NavbarButton extends React.Component {

  getClassName = () => {
    const {
      alt,
      activeTab,
      disabled,
    } = this.props;

    let className = '';

    if (alt === activeTab) {
      className = 'vehicle-details-nav-item-hover vehicle-details-nav-item-active';
    } else if (!disabled) {
      className = 'vehicle-details-nav-item-hover';
    }

    return className;
  };

  render() {
    const {
      to,
      alt,
      iconSrc,
      buttonText,
      setActiveTab,
      inactive,
      replace,
      disabled,
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <React.Fragment>
            {!inactive ? (
              <Link
                className={this.getClassName()}
                style={styleMerge(getStyle(screenSize, styles, 'navbarButtonContainer'), disabled ? { opacity: 0.4 } : {})}
                to={disabled ? undefined : to}
                onClick={disabled ? undefined : () => setActiveTab(alt)}
                replace={replace}
              >
                <div style={styleMerge(getStyle(screenSize, styles, 'navbarButton'), disabled ? { cursor: 'default' } : {})}>
                  <img
                    alt={alt}
                    style={getStyle(screenSize, styles, 'buttonIcon')}
                    src={iconSrc}
                  />
                  {screenSize === 'large' && (
                    <p
                      className='font-titillium-web-regular'
                      style={getStyle(screenSize, styles, 'buttonText')}
                    >
                      {buttonText}
                    </p>
                  )}
                  {screenSize === 'medium' && (
                    <p
                      className='font-titillium-web-regular'
                      style={getStyle(screenSize, styles, 'buttonText')}
                    >
                      {buttonText}
                    </p>
                  )}
                </div>
              </Link>
            ) : (
              <div style={getStyle(screenSize, styles, 'navbarButtonInactive')}>
                <img
                  alt={alt}
                  style={getStyle(screenSize, styles, 'buttonIcon')}
                  src={iconSrc}
                />
                {screenSize === 'large' && (
                  <p
                    className='font-titillium-web-regular'
                    style={getStyle(screenSize, styles, 'buttonText')}
                  >
                    {buttonText}
                  </p>
                )}
                {screenSize === 'medium' && (
                  <p
                    className='font-titillium-web-regular'
                    style={getStyle(screenSize, styles, 'buttonText')}
                  >
                    {buttonText}
                  </p>
                )}
              </div>
            )}
          </React.Fragment>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    navbarButtonContainer: {
      width: '25%',
      opacity: '.7',
    },
    navbarButton: {
      height: 110,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRight: '1px solid rgba(255,255,255,.3)',
      padding: 16,
      cursor: 'pointer',
    },
    navbarButtonInactive: {
      height: 110,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRight: '1px solid rgba(255,255,255,.3)',
      padding: 16,
      cursor: 'default',
      opacity: 0.4,
      backgroundColor: '#757575',
      width: '25%',
    },
    buttonIcon: {
      display: 'inline-block',
      width: 64,
      height: 64,
    },
    buttonText: {
      marginLeft: 8,
      display: 'inline',
      color: '#FFF',
      fontSize: 18,
      textAlign: 'left',
      // alignSelf: 'center',
      // justifySelf: 'center',
    },
  },
  medium: {
    navbarButton: {
      height: 90,
    },
    navbarButtonInactive: {
      height: 90,
    },
    buttonIcon: {
      display: 'inline-block',
      width: 42,
      height: 42,
    },
    buttonText: {
      paddingTop: 10,
      paddingLeft: 10,
    },
  },
  small: {
    navbarButton: {
      height: 60,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRight: '1px solid rgba(255,255,255,.3)',
      padding: 16,
      cursor: 'pointer',
    },
    buttonIcon: {
      display: 'inline-block',
      width: 32,
      height: 32,
    },
  },
};

export default NavbarButton;
