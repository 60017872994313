import { advertisementState } from '../initialState';
import { storeInsertItem, storeRemoveItem, storeUpdateObjectInArray } from '../utils/utils';

export default function (state = advertisementState, action) {
  switch (action.type) {
    case 'FETCH_ADVERTISEMENTS_REQUEST': {
      return {
        ...state,
        advertisementsStatus: Object.assign({}, state.advertisementsStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_ADVERTISEMENTS_SUCCESS': {
      return {
        ...state,
        advertisements: action.payload,
        advertisementsStatus: Object.assign({}, state.advertisementsStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_ADVERTISEMENTS_FAIL': {
      return {
        ...state,
        advertisements: [],
        advertisementsStatus: Object.assign({}, state.advertisementsStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'CREATE_ADVERTISEMENT_REQUEST': {
      return {
        ...state,
        advertisementsStatus: Object.assign({}, state.advertisementsStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_ADVERTISEMENT_SUCCESS': {
      return {
        ...state,
        advertisements: storeInsertItem(state.advertisements, action.payload),
        advertisementsStatus: Object.assign({}, state.advertisementsStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_ADVERTISEMENT_FAIL': {
      return {
        ...state,
        advertisements: [],
        advertisementsStatus: Object.assign({}, state.advertisementsStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'FETCH_ADVERTISEMENT_REQUEST': {
      return {
        ...state,
        advertisementsStatus: Object.assign({}, state.advertisementsStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_ADVERTISEMENT_SUCCESS': {
      return {
        ...state,
        selectedAdvertisement: action.payload,
        advertisementsStatus: Object.assign({}, state.advertisementsStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_ADVERTISEMENT_FAIL': {
      return {
        ...state,
        selectedAdvertisement: null,
        advertisementsStatus: Object.assign({}, state.advertisementsStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'UPDATE_ADVERTISEMENT_REQUEST': {
      return {
        ...state,
        advertisementsStatus: Object.assign({}, state.advertisementsStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_ADVERTISEMENT_SUCCESS': {
      return {
        ...state,
        selectedAdvertisement: action.payload,
        advertisements: storeUpdateObjectInArray(state.advertisements, action.payload),
        advertisementsStatus: Object.assign({}, state.advertisementsStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_ADVERTISEMENT_FAIL': {
      return {
        ...state,
        advertisementsStatus: Object.assign({}, state.advertisementsStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'DELETE_ADVERTISEMENT_REQUEST': {
      return {
        ...state,
        advertisementsStatus: Object.assign({}, state.advertisementsStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DELETE_ADVERTISEMENT_SUCCESS': {
      const advertisementId = action.additionalData.advertisementId;
      return {
        ...state,
        selectedAdvertisement: null,
        advertisements: storeRemoveItem(state.advertisements, advertisementId),
        advertisementsStatus: Object.assign({}, state.advertisementsStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DELETE_ADVERTISEMENT_FAIL': {
      return {
        ...state,
        advertisementsStatus: Object.assign({}, state.advertisementsStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'UPDATE_ADVERTISEMENT_PICTURE_REQUEST': {
      return {
        ...state,
        advertisementsStatus: Object.assign({}, state.advertisementsStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_ADVERTISEMENT_PICTURE_SUCCESS': {
      return {
        ...state,
        selectedAdvertisement: action.payload,
        advertisements: storeUpdateObjectInArray(state.advertisements, action.payload),
        advertisementsStatus: Object.assign({}, state.advertisementsStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_ADVERTISEMENT_PICTURE_FAIL': {
      return {
        ...state,
        advertisementsStatus: Object.assign({}, state.advertisementsStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    default:
      break;
  }
  return state;
}
