import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { getStyle, SERVICE_FLEET, styleMerge } from '../../../utils/utils';
import CBLabelAndSwitch from '../../shared/CBLabelAndSwitch';
import CBLabelAndValue from '../../shared/CBLabelAndValue';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSubHeading from '../../shared/CBSubHeading';
import CBSelectField from '../../shared/form_components/CBSelectField';
import LicensePlate from '../../shared/LicensePlate';
import PermissionRow from './PermissionRow';
import {
  fetchOrganizations as _fetchOrganizations,
} from '../../../actions/organizationActions';
import {
  fetchGarages as _fetchGarages,
} from '../../../actions/garageActions';
import {
  createOrganizationVehiclePermission as _createOrganizationVehiclePermission,
  deleteOrganizationVehiclePermission as _deleteOrganizationVehiclePermission,
  fetchVehiclesByGarage as _fetchVehiclesByGarage,
  updateOrganizationVehiclePermission as _updateOrganizationVehiclePermission,
  toggleVehicleOrganizationSharing as _toggleVehicleOrganizationSharing,
  toggleVehicleMessageSubscription as _toggleVehicleMessageSubscription,
} from '../../../actions/vehicleActions';
import CBHorizontalLine from '../../shared/CBHorizontalLine';
import CBInputField from '../../shared/form_components/CBInputField';
import { getEmailValidator } from '../../../utils/validators';
import CBButtonWithIconSmall from '../../shared/CBButtonWithIconSmall';

class SettingsOrganizationVehiclePermissions extends React.Component {
  state = {
    selectedOrganization: undefined,
    selectedGarage: undefined,
    selectedVehicle: undefined,
    filteredGarages: [],
    organizations: [],
  };

  componentDidMount() {
    const {
      fetchGarages,
      garages,
      fetchVehiclesByGarage,
    } = this.props;

    fetchGarages();

    if (garages && garages.length) {
      const organizations = this.parseOrganizations(garages);
      const filteredGarages = this.filterGarages(organizations[0].id);
      fetchVehiclesByGarage(filteredGarages[0].id);
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedOrganizationId, garages, fetchVehiclesByGarage } = this.props;

    if (selectedOrganizationId != prevProps.selectedOrganizationId) {
      const organizations = this.parseOrganizations(garages);
      const filteredGarages = this.filterGarages(organizations[0].id, garages);
      filteredGarages.length && fetchVehiclesByGarage(filteredGarages[0].id);
      this.setState({
        selectedOrganization: organizations[0],
        selectedGarage: filteredGarages[0],
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    const { selectedGarage, selectedVehicle } = this.state;
    const { fetchVehiclesByGarage, garages } = this.props;

    this.parseOrganizations(garages);

    if ((!garages || !garages.length) && nextProps.garages && nextProps.garages.length && !selectedGarage) {
      fetchVehiclesByGarage(nextProps.garages[0].id);
      const organizations = this.parseOrganizations(nextProps.garages);
      const filteredGarages = this.filterGarages(organizations[0].id, nextProps.garages);
      this.setState({
        selectedOrganization: organizations[0],
        selectedGarage: filteredGarages[0],
      });
    }

    if (nextProps.garageVehicles && nextProps.garageVehicles.length && selectedVehicle) {
      this.setState({
        selectedVehicle: nextProps.garageVehicles.find((vehicle) => vehicle.id === selectedVehicle.id),
      });
    }
  }

  getDisplayTextForRole = (role) => {
    const { t } = this.props;
    const roleDisplayMap = {
      employee: t('roleEmployee'),
      manager: t('roleManager'),
      admin: t('roleAdmin'),
    };

    return roleDisplayMap[role];
  };

  parseOrganizations = (garages) => {
    const { service, selectedOrganizationId } = this.props;

    const unfilteredOrganizations = garages.map((garage) => {
      return { id: garage.organization_id, name: garage.organization_name };
    });

    const usedIds = [];
    let organizations = unfilteredOrganizations.filter((organization) => {
      if (!usedIds.includes(organization.id)) {
        usedIds.push(organization.id);
        return true;
      } else {
        return false;
      }
    });

    if (service === SERVICE_FLEET && selectedOrganizationId) {
      organizations = organizations.filter((org) => org.id == selectedOrganizationId);
    }

    organizations.sort((a, b) => a.name.localeCompare(b.name));

    this.setState({ organizations });

    return organizations;
  };

  filterGarages = (organizationId, garages) => {
    if (!garages) {
      garages = this.props.garages;
    }

    const filteredGarages = garages.filter((garage) => garage.organization_id === organizationId);

    this.setState({ filteredGarages });

    return filteredGarages;
  };

  getOrganizationOptions = () => {
    const { organizations } = this.state;
    return organizations.map((org) => {
      return {
        label: org.name,
        value: org.id,
      };
    });
  };

  getGarageOptions = () => {
    const { filteredGarages } = this.state;
    return filteredGarages.map((garage) => {
      return {
        label: `${garage.name}`,
        value: garage.id,
      };
    });
  };

  getVehicleOptions = () => {
    const { garageVehicles } = this.props;
    return garageVehicles.map((garageVehicle) => {
      return {
        label: garageVehicle.registration_number,
        value: garageVehicle.id,
      };
    });
  };

  organizationChanged = (selected) => {
    const { fetchVehiclesByGarage } = this.props;
    const { organizations } = this.state;
    const organizationId = Number.parseInt(selected.value);
    const filteredGarages = this.filterGarages(organizationId);

    fetchVehiclesByGarage(filteredGarages[0].id);
    this.setState({
      selectedOrganization: organizations.find((organization) => organizationId === organization.id),
      selectedGarage: filteredGarages[0],
      selectedVehicle: undefined,
    });
  };

  garageChanged = (selected) => {
    const { fetchVehiclesByGarage } = this.props;
    const garageId = Number.parseInt(selected.value);
    fetchVehiclesByGarage(garageId);
    const { filteredGarages } = this.state;
    this.setState({
      selectedGarage: filteredGarages.find((garage) => garageId === garage.id),
      selectedVehicle: undefined,
    });
  };

  vehicleChanged = (selected) => {
    const { garageVehicles } = this.props;
    const vehicleId = Number.parseInt(selected.value);
    this.setState({
      selectedVehicle: garageVehicles.find((garageVehicle) => vehicleId === garageVehicle.id),
    });
  };

  getSelectedOrganization = () => {
    let {
      organizations,
    } = this.state;

    const { garages } = this.props;

    let { selectedOrganization } = this.state;

    if (!organizations && garages && garages.length) {
      organizations = this.parseOrganizations(garages);
    }

    if (!selectedOrganization && organizations.length) {
      [selectedOrganization] = organizations;
    }

    return selectedOrganization;
  };

  getSelectedGarage = () => {
    const {
      filteredGarages,
    } = this.state;
    let { selectedGarage } = this.state;

    if (!selectedGarage && filteredGarages.length) {
      [selectedGarage] = filteredGarages;
    }

    return selectedGarage;
  };

  getSelectedVehicle = () => {
    const {
      garageVehicles,
    } = this.props;
    let { selectedVehicle } = this.state;

    if (!selectedVehicle && garageVehicles.length) {
      [selectedVehicle] = garageVehicles;
    }

    return selectedVehicle;
  };

  canEditPermission = (permission, userRole) => {
    const { user } = this.props;

    if (user.is_staff) {
      return true;
    }

    if (permission.user && user.id === permission.user.id) {
      return false;
    }

    return userRole === 'admin';
  };

  organizationSharingChanged = (value) => {
    const { toggleVehicleOrganizationSharing } = this.props;
    toggleVehicleOrganizationSharing(
      this.getSelectedVehicle().id,
      {
        organization_sharing: value,
        garage_id: this.getSelectedGarage().id,
      },
    );
  };

  messageSubscriptionChanged = (value) => {
    const { toggleVehicleMessageSubscription } = this.props;
    toggleVehicleMessageSubscription(
      this.getSelectedVehicle().id,
      this.getSelectedGarage().id,
      { has_message_subscription: value },
    );
  };

  navigateToGarage = (garage) => {
    const { history } = this.props;
    history.push(`/organization_garage/${garage.id}/`);
  };

  render() {
    const {
      t,
      updateOrganizationVehiclePermission,
      deleteOrganizationVehiclePermission,
      createOrganizationVehiclePermission,
      service,
      selectedOrganizationId
    } = this.props;

    const selectedOrganization = this.getSelectedOrganization();
    const selectedGarage = this.getSelectedGarage();
    const selectedVehicle = this.getSelectedVehicle();

    const isAllowedToAddPermissions = selectedVehicle && ['admin'].includes(selectedVehicle.user_role);

    const permissions = selectedVehicle ? selectedVehicle.permissions : [];

    const imageUrl = selectedVehicle && selectedVehicle.images
      && selectedVehicle.images.length ? selectedVehicle.images[0].image : 'none';
    const imageStyle = {
      backgroundImage: `url("${imageUrl}")`,
    };

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <CBSubHeading>
              {t('organizationVehiclePermissionsHeading')}
            </CBSubHeading>

            <CBSelectField
              containerStyle={getStyle(screenSize, styles, 'selectContainer')}
              inputStyle={getStyle(screenSize, styles, 'selectField')}
              optionStyle={getStyle(screenSize, styles, 'optionStyle')}
              options={this.getOrganizationOptions()}
              placeholder=''
              value={selectedOrganization && selectedOrganization.id}
              onChange={this.organizationChanged}
              labelText={t('labelOrganization')}
              labelTextStyle={{ fontFamily: 'TitilliumWeb-SemiBold' }}
              disabled={service === SERVICE_FLEET && selectedOrganizationId}
            />
            <div style={getStyle(screenSize, styles, 'garageSelectorContainer')}>
              <CBSelectField
                containerStyle={getStyle(screenSize, styles, 'selectContainer')}
                inputStyle={getStyle(screenSize, styles, 'selectField')}
                optionStyle={getStyle(screenSize, styles, 'optionStyle')}
                options={this.getGarageOptions()}
                placeholder=''
                value={selectedGarage && selectedGarage.id}
                onChange={this.garageChanged}
                labelText={t('labelGarage')}
                labelTextStyle={{ fontFamily: 'TitilliumWeb-SemiBold' }}
              />
              {
                selectedGarage && (
                  <CBButtonWithIconSmall text='Siirry autotalliin' onClick={() => this.navigateToGarage(selectedGarage)} />
                )
              }
            </div>
            <CBHorizontalLine />
            {
              selectedGarage && (
                <React.Fragment>
                  <CBSelectField
                    containerStyle={getStyle(screenSize, styles, 'selectContainer')}
                    inputStyle={getStyle(screenSize, styles, 'selectField')}
                    optionStyle={getStyle(screenSize, styles, 'optionStyle')}
                    options={this.getVehicleOptions()}
                    placeholder=''
                    value={selectedVehicle && selectedVehicle.id}
                    onChange={this.vehicleChanged}
                    labelText={t('vehicle')}
                    labelTextStyle={{ fontFamily: 'TitilliumWeb-SemiBold' }}
                  />

                  {
                    selectedVehicle && (
                      <React.Fragment>
                        <div style={getStyle(screenSize, styles, 'vehicleContainer')}>
                          <div style={styleMerge(getStyle(screenSize, styles, 'vehicleImageContainer'), imageStyle)} />
                          <div style={getStyle(screenSize, styles, 'vehicleInfoContainer')}>
                            <LicensePlate size='medium' plateNumber={selectedVehicle && selectedVehicle.registration_number} />
                            <p style={getStyle(screenSize, styles, 'vehicleText')}>
                              {`${selectedVehicle && selectedVehicle.manufacturer} ${selectedVehicle && selectedVehicle.model_name}`}
                            </p>
                          </div>
                        </div>
                        <div style={getStyle(screenSize, styles, 'permissionTypeContainer')}>
                          <CBLabelAndValue
                            label={t('permissionType')}
                            value={this.getDisplayTextForRole(selectedVehicle && selectedVehicle.user_role)}
                          />
                        </div>
                        <div style={getStyle(screenSize, styles, 'switchContainer')}>
                          {
                            selectedVehicle.user_role === 'admin' && (
                              <CBLabelAndSwitch
                                labelText={t('shareVehicleLabel')}
                                size='small'
                                checked={selectedVehicle.organization_sharing}
                                onChange={this.organizationSharingChanged}
                              />
                            )
                          }
                          <CBLabelAndSwitch
                            labelText={t('receiveNotificationsLabel')}
                            size='small'
                            checked={selectedVehicle.user_has_message_subscription}
                            onChange={this.messageSubscriptionChanged}
                          />
                        </div>
                        {
                          selectedVehicle.organization_sharing && (
                            <table>
                              <thead>
                                <tr>
                                  <th style={getStyle(screenSize, styles, 'tableHeading')}>
                                    {t('userTableHeading')}
                                  </th>
                                  <th style={getStyle(screenSize, styles, 'tableHeading')}>
                                    {t('descriptionTableHeading')}
                                  </th>
                                  <th style={getStyle(screenSize, styles, 'tableHeading')}>
                                    {t('permissionTableHeading')}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  permissions && permissions.map((permission) =>
                                    (
                                      <PermissionRow
                                        key={permission.id}
                                        permission={permission}
                                        getDisplayTextForRole={this.getDisplayTextForRole}
                                        deletePermission={deleteOrganizationVehiclePermission}
                                        updatePermission={updateOrganizationVehiclePermission}
                                        canEdit={this.canEditPermission(permission, selectedVehicle.user_role)}
                                        userRole={selectedVehicle.user_role}
                                      />
                                    ))
                                }
                                {
                                  isAllowedToAddPermissions && (
                                    <PermissionRow
                                      isNew
                                      vehicle={selectedVehicle}
                                      garage={selectedGarage}
                                      permission={{
                                        user_email: '',
                                        role: 'employee',
                                        description: '',
                                      }}
                                      getDisplayTextForRole={this.getDisplayTextForRole}
                                      createPermission={createOrganizationVehiclePermission}
                                      userRole={selectedVehicle.user_role}
                                    />
                                  )
                                }

                              </tbody>
                            </table>
                          )
                        }
                      </React.Fragment>
                    )
                  }
                </React.Fragment>
              )
            }
          </div>
        )}
      </CBMediaQuery>
    );
  }
}


const styles = {
  default: {
    container: {
      padding: 32,
      display: 'flex',
      flexDirection: 'column',
    },
    vehicleContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 32,
      marginBottom: 32,
    },
    garageSelectorContainer: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    newPermissionRow: {
      border: '1px solid rgba(255, 255, 255, 0.4)',
    },
    vehicleImageContainer: {
      width: 250,
      height: 167,
      marginBottom: 32,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    },
    vehicleInfoContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    vehicleText: {
      fontSize: 22,
      fontFamily: 'TitilliumWeb-Light',
      color: '#FFFFFF',
      marginLeft: 16,
    },
    permissionTypeContainer: {
      marginTop: 32,
      borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
    },
    selectField: {
      minWidth: 300,
      height: 40,
      border: '1px solid rgb(255 255 255 / 55%)',
      borderRadius: 50,
      backgroundColor: 'transparent',
      color: '#FFF',
      padding: '0px 0px 0px 16px',
    },
    selectContainer: {
      marginTop: 16,
      marginRight: 16,
      minWidth: 300,
    },
    switchContainer: {
      marginTop: 32,
      width: 200,
    },
    tableHeading: {
      fontFamily: 'TitilliumWeb-Regular',
      fontSize: 12,
      color: '#ffffff',
      paddingLeft: 16,
      paddingBottom: 8,
    },
    optionStyle: {
      backgroundColor: 'rgb(0, 149, 218)',
    },
  },
  medium: {
    container: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
    },
  },
};


function mapStateToProps(state) {
  return {
    garages: state.garage.garages,
    garageVehicles: state.vehicle.garageVehicles,
    user: state.authUser.user,
    service: state.app.service,
    selectedOrganizationId: state.organization.selectedOrganization
  };
}

export default connect(
  mapStateToProps,
  {
    fetchGarages: _fetchGarages,
    fetchVehiclesByGarage: _fetchVehiclesByGarage,
    updateOrganizationVehiclePermission: _updateOrganizationVehiclePermission,
    createOrganizationVehiclePermission: _createOrganizationVehiclePermission,
    deleteOrganizationVehiclePermission: _deleteOrganizationVehiclePermission,
    toggleVehicleOrganizationSharing: _toggleVehicleOrganizationSharing,
    toggleVehicleMessageSubscription: _toggleVehicleMessageSubscription,
  },
)(translate('Permissions')(SettingsOrganizationVehiclePermissions));
