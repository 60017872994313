import { API, GET, POST, PUT, DELETE } from '../middleware/api';
import { API_URL } from '../config';

export function fetchAnnouncements(workshopId) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/announcement/`,
      types: [
        'FETCH_ANNOUNCEMENTS_REQUEST',
        'FETCH_ANNOUNCEMENTS_SUCCESS',
        'FETCH_ANNOUNCEMENTS_FAIL',
      ],
      params: {
        workshop_id: workshopId
      },
    },
  };
}
export function createAnnouncement(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/announcement/`,
      types: [
        'CREATE_ANNOUNCEMENTS_REQUEST',
        'CREATE_ANNOUNCEMENTS_SUCCESS',
        'CREATE_ANNOUNCEMENTS_FAIL',
      ],
      data,
    },
  };
}
export function updateAnnouncement(announcementId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/announcement/${announcementId}/`,
      types: [
        'UPDATE_ANNOUNCEMENTS_REQUEST',
        'UPDATE_ANNOUNCEMENTS_SUCCESS',
        'UPDATE_ANNOUNCEMENTS_FAIL',
      ],
      data,
    },
  };
}
export function deleteAnnouncement(announcementId) {
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/announcement/${announcementId}`,
      types: [
        'DELETE_ANNOUNCEMENTS_REQUEST',
        'DELETE_ANNOUNCEMENTS_SUCCESS',
        'DELETE_ANNOUNCEMENTS_FAIL',
      ],
    },
  };
}
