import React, { Component } from 'react';
import { getStyle } from '../../utils/utils'
import AddEditVehicleModal from './AddEditVehicleModal';
import CBMediaQuery from '../shared/CBMediaQuery';

class AddVehicleButton extends Component {
  state = {
    modalIsOpen: false,
  };

  addVehicle = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  afterOpenModal = () => {
  };

  render() {
    const { 
      t,
      workshopID,
    } = this.props;
    const { modalIsOpen } = this.state;

    return (
      <CBMediaQuery>
        {screenSize => (
          <React.Fragment>
            <div
              style={getStyle(screenSize, styles, 'addVehicleButton')}
              onClick={this.addVehicle}
            >
              <img src='/img/add.svg' alt='Add' style={getStyle(screenSize, styles, 'icon')} />
              <p className='font-titillium-web-regular' style={getStyle(screenSize, styles, 'label')}>
                {t('Lisää ajoneuvo')}
              </p>
            </div>
            <AddEditVehicleModal
              modalIsOpen={modalIsOpen}
              closeModal={this.closeModal}
              afterOpenModal={this.afterOpenModal}
              workshopID={workshopID}
            />
          </React.Fragment>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    addVehicleButton: {
      display: 'flex',
      width: 290,
      height: 50,
      border: '2 solid #00f600',
      borderRadius: 100,
      backgroundColor: '#00f600',
      backgroundSize: 'cover',
      fontFamily: 'Titillium Web',
      fontSize: 18,
      color: '#ffffff',
      textDecoration: 'none solid rgb(255, 255, 255)',
      textAlign: 'center',
      alignItems: 'center',
      // justifyContent: 'center',
      paddingLeft: 32,
      cursor: 'pointer',
      // paddingBottom: 38,
    },
    label: {
      fontSize: 18,
      color: 'white',
      marginLeft: 10,
      marginBottom: 0,
    },
    icon: {
      height: 32,
      width: 32,
    },
  },
  medium: {
    addVehicleButton: {
      width: 354,
      height: 50,
      paddingLeft: 63,
    },
  },
};

export default AddVehicleButton;
