import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { ProgressSpinner } from 'primereact/progressspinner';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBButtonSettingsWhite from '../../shared/CBButtonSettingsWhite';
import CBSearchInput from '../../shared/CBSearchInput';
import OrganizationListItem from './OrganizationListItem';
import {
  createOrganization as _createOrganization,
  fetchOrganizations as _fetchOrganizations,
  updateOrganization as _updateOrganization,
} from '../../../actions/organizationActions';
import OrganizationForm from './OrganizationForm';
import CBHeaderTextWithHelp from '../../shared/CBHeaderTextWithHelp';
import CBButtonWithIconSmall from '../../shared/CBButtonWithIconSmall';
import CBSortableTableHeader from '../../shared/CBSortableTableHeader';

class StaffSettingsOrganizations extends React.Component {
  state = {
    pageNumber: 1,
    search: '',
    selectedOrganization: undefined,
    filters: {},
    ordering: 'id',
  };

  componentDidMount = () => {
    const { fetchOrganizations } = this.props;

    fetchOrganizations();
  };

  componentWillReceiveProps(nextProps) {
    const { selectedOrganization } = this.state;

    if (nextProps.organizations && selectedOrganization) {
      const organization = nextProps.organizations.find(
        (newOrg) => newOrg.id === selectedOrganization.id,
      );
      this.setState({
        selectedOrganization: organization,
      });
    }
  }

  addOrganization = () => {
    const organization = {
      name: '',
      business_id: '',
      main_user_email: '',
      garages: [],
    };

    this.setState({ selectedOrganization: Object.assign({}, organization) });
  };

  selectOrganization = (organization) => {
    this.setState({ selectedOrganization: Object.assign({}, organization) });
  };

  newOrganizationCreated = () => this.setState({ selectedOrganization: undefined });

  changeSearchTerms = value => {
    this.setState({
      search: value,
    });
  }

  changeOrdering = (clickedOrdering) => {
    const { ordering, filters } = this.state;
    let newOrdering;
    if (ordering === clickedOrdering) {
      newOrdering = `-${ordering}`;
    } else if (`-${ordering}` === clickedOrdering) {
      newOrdering = 'id';
    } else {
      newOrdering = clickedOrdering;
    }
    this.setState({
      ordering: newOrdering,
    });
    this.fetchWithSearch({ ...filters, ordering: newOrdering });
  }

  fetchWithSearch = (newFilters) => {
    const { fetchOrganizations } = this.props;
    const { search, filters } = this.state;

    fetchOrganizations({ search, ...filters, ...newFilters });
  }

  render() {
    const {
      t, organizations, loading, organizationRequest, updateOrganization, createOrganization, user, service,
    } = this.props;

    const {
      selectedOrganization, ordering
    } = this.state;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'editOrganizationContainer')}>
              <CBHeaderTextWithHelp text={t('editOrganizationHeading')} />
              {
                !selectedOrganization && user.is_staff && (
                  <CBButtonWithIconSmall
                    icon='/img/icon_plus.svg'
                    text={t('addNewOrganization')}
                    onClick={this.addOrganization}
                  />
                )
              }
              {
                selectedOrganization && (
                  <OrganizationForm
                    user={user}
                    organization={selectedOrganization}
                    organizationRequest={organizationRequest}
                    isOwnOrganization={false}
                    updateOrganization={updateOrganization}
                    createOrganization={createOrganization}
                    newOrganizationCreated={this.newOrganizationCreated}
                    service={service}
                  />
                )
              }
            </div>
            <div style={getStyle(screenSize, styles, 'searchOrganizationContainer')}>
              <CBHeaderTextWithHelp text={t('organizationsHeading')} />
              <div>
                <div style={getStyle(screenSize, styles, 'searchContainer')}>
                  <CBSearchInput
                    containerStyle={{ marginLeft: 0 }}
                    changeSearchTerms={this.changeSearchTerms}
                    onBlur={this.fetchWithSearch}
                  />
                </div>
                <div>
                  <table style={getStyle(screenSize, styles, 'table')}>
                    <thead>
                      <tr>
                        {
                          screenSize === 'large' && (
                            <th style={getStyle(screenSize, styles, 'tableHeader')}>Id</th>
                          )
                        }
                        <CBSortableTableHeader
                          onClick={this.changeOrdering}
                          fieldName='name'
                          ordering={ordering}
                          style={getStyle(screenSize, styles, 'tableHeader')}
                          text={t('organizationTableName')} />

                        <CBSortableTableHeader
                          onClick={this.changeOrdering}
                          fieldName='business_id'
                          ordering={ordering}
                          style={getStyle(screenSize, styles, 'tableHeader')}
                          text={t('organizationTableBid')} />

                        <CBSortableTableHeader
                          onClick={this.changeOrdering}
                          fieldName='is_trialing'
                          ordering={ordering}
                          style={getStyle(screenSize, styles, 'tableHeader')}
                          text={t('organizationTableIsTrial')} />

                        <th style={getStyle(screenSize, styles, 'tableHeader')}>{t('organizationTableGarageCount')}</th>

                        <th style={getStyle(screenSize, styles, 'tableHeader')} />
                      </tr>
                    </thead>
                    <tbody>
                      {organizations.map(organization => (
                        <OrganizationListItem
                          key={organization.id}
                          organization={organization}
                          onEditClick={this.selectOrganization}
                        />
                      ))}
                    </tbody>
                  </table>
                  <div style={getStyle(screenSize, styles, 'loadMoreContainer')}>
                    {
                      loading && (
                        <ProgressSpinner />
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      paddingBottom: 50,
    },
    editOrganizationContainer: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
      width: '40%',
      borderRight: '1px solid rgba(255, 255, 255, 0.4)',
      minHeight: 500,
    },
    searchOrganizationContainer: {
      width: '60%',
      padding: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
    searchContainer: {
      marginBottom: '16px',
      maxWidth: '400px',
    },
    table: {
      width: '100%',
    },
    tableHeader: {
      fontFamily: 'TitilliumWeb-Light',
      color: '#FFFFFF',
      cursor: 'pointer',
      WebkitOrganizationSelect: 'none',
      MsOrganizationSelect: 'none',
      OrganizationSelect: 'none',
    },
    loadMoreContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 16,
    },
  },
  medium: {
    container: {
      paddingBottom: 25,
    },
    editOrganizationContainer: {
      paddingLeft: 0,
      paddingRight: 15,
      paddingTop: 15,
    },
    searchOrganizationContainer: {
      padding: 15,
      paddingLeft: 15,
      paddingRight: 0,
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    editOrganizationContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      width: '100%',
      borderRight: 0,
      marginBottom: 20,
      minHeight: 0,
    },
    searchOrganizationContainer: {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: 20,
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    organizationRequest: state.organization,
    organizations: state.organization.organizations,
    loading: state.organization.organizationListStatus.loading,
    service: state.app.service,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchOrganizations: _fetchOrganizations,
    updateOrganization: _updateOrganization,
    createOrganization: _createOrganization,
  },
)(translate('SettingsView')(StaffSettingsOrganizations));
