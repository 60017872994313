import React from 'react';
import { Panel } from 'react-bootstrap';

class InstructionCollapse extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: props.open };
  }

  render() {
    const {
      title, children, open, onToggle,
    } = this.props;
    return (
      <div style={styles.collapseContainer}>
        <button
          type='button'
          className='font-titillium-web'
          style={styles.collapseButton}
          onClick={() => {
            this.setState({ open: !open });
            onToggle();
          }}
        >
          {title}
          <img
            src={open ? '/img/up_arrow_icon.svg' : '/img/down_arrow_icon.svg'}
            alt='Add'
            style={styles.collapseButtonIcon}
          />
        </button>
        <Panel onToggle={() => {}} style={styles.panel} expanded={open}>
          <Panel.Collapse>
            <Panel.Body style={styles.panelBody}>
              {children}
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      </div>
    );
  }
}

const styles = {
  panel: {
    border: 0,
  },
  panelBody: {
    backgroundColor: 'rgb(0, 149, 218)',
    border: '0px',
    padding: 0,
    paddingTop: 16,
    lineHeight: 1,
  },
  collapseButton: {
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    outline: 'inherit',
    textAlign: 'left',
    color: '#FFF',
    fontSize: 22,
    fontWeight: 200,
    width: '100%',
  },
  collapseButtonIcon: {
    float: 'right',
    width: 32,
    height: 32,
    opacity: 0.5,
  },
};

export default InstructionCollapse;
