import React from 'react';
import moment from 'moment';
import { translate } from 'react-i18next';
import { Editor, EditorState, convertFromRaw } from 'draft-js';

import CBMediaQuery from '../../shared/CBMediaQuery';
import CBButtonWithIconSmall from '../../shared/CBButtonWithIconSmall';
import { getStyle } from '../../../utils/utils';

class AnnouncementListItem extends React.Component {
  render() {
    const {
      t,
      announcement,
      onLeftButtonClick,
      onRightButtonClick,
      published = false,
    } = this.props;

    const textColor = published ? 'rgba(255, 255, 255, 0.5)' : 'white';

    const expireDate = moment(announcement.expire_date);
    const publishDate = moment(announcement.date);
    const durationInWeeks = Math.round(
      moment.duration(expireDate.diff(publishDate)).asWeeks(),
    );
    const durationText =
      durationInWeeks > 3
        ? `${Math.round(durationInWeeks / 4)} ${t('monthAbbreviation')}`
        : `${durationInWeeks} ${t('weekAbbreviation')}`;

    const descriptionEditorState = EditorState.createWithContent(
      convertFromRaw(JSON.parse(announcement.description_raw_content)),
    );

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={{
              ...getStyle(screenSize, styles, 'description'),
              color: textColor,
            }}
            >
              <Editor
                editorState={descriptionEditorState}
                readOnly
              />
            </div>
            <div style={getStyle(screenSize, styles, 'dateRow')}>
              <div
                style={{
                  ...getStyle(screenSize, styles, 'expirationText'),
                  color: textColor,
                }}
              >
                {`${t('offerValid')}: ${durationText} (${t('untilDate', {
                  date: expireDate.format('D.M.Y'),
                })})`}
              </div>
              <div
                style={{
                  ...getStyle(screenSize, styles, 'publishDateText'),
                  color: textColor,
                }}
              >
                {`${t('announcementPublishDate')}: ${publishDate.format(
                  'D.M.Y',
                )}`}
              </div>
            </div>
            <div style={getStyle(screenSize, styles, 'buttonRow')}>
              <CBButtonWithIconSmall
                text={published ? t('copy') : t('edit')}
                icon={published ? '/img/copy.svg' : '/img/edit.svg'}
                onClick={onLeftButtonClick}
              />
              <CBButtonWithIconSmall
                text={t('remove')}
                icon='/img/remove.svg'
                onClick={onRightButtonClick}
              />
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      borderStyle: 'solid',
      borderWidth: 0,
      borderTopWidth: 1,
      borderColor: 'rgba(255, 255, 255, 0.3)',
      padding: '30px 20px',
    },
    description: {
      marginBottom: 0,
      zIndex: 0,
    },
    dateRow: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 25,
    },
    expirationText: {
      fontFamily: 'TitilliumWeb-Light',
      fontSize: 24,
    },
    publishDateText: {
      fontFamily: 'TitilliumWeb-Light',
      fontSize: 12,
      color: 'white',
    },
    buttonRow: {
      display: 'flex',
      marginTop: 25,
    },
  },
  medium: {
    container: {
      padding: '20px 20px',
    },
    dateRow: {
      marginTop: 15,
    },
    buttonRow: {
      marginTop: 15,
    },
  },
  small: {},
};

export default translate('AnnouncementView')(AnnouncementListItem);
