import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSearchInput from '../../shared/CBSearchInput';
import {
  fetchFavouriteWorkshops as _fetchFavouriteWorkshops,
  addFavouriteWorkshop as _addFavouriteWorkshop,
  deleteFavouriteWorkshop as _deleteFavouriteWorkshop,
} from '../../../actions/workshopActions';
import CBHeaderTextWithHelp from '../../shared/CBHeaderTextWithHelp';
import WorkshopListItem from './WorkshopListItem';
import CBLabelAndSwitch from '../../shared/CBLabelAndSwitch';

class UserSettingsWorkshops extends React.Component {
  state = {
    workshopAdsEnabled: true,
  };

  componentDidMount = () => {
    const { fetchFavouriteWorkshops } = this.props;
    fetchFavouriteWorkshops();
  };

  handleChange = () => {
    const { workshopAdsEnabled } = this.state;
    this.setState({ workshopAdsEnabled: !workshopAdsEnabled });
  };

  render() {
    const {
      workshops, t, addFavouriteWorkshop, deleteFavouriteWorkshop,
    } = this.props;
    const { workshopAdsEnabled } = this.state;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'favouriteWorkshopContainer')}>
              <div style={getStyle(screenSize, styles, 'favouriteWorkshopSettings')}>
                <CBLabelAndSwitch onChange={this.handleChange} checked={workshopAdsEnabled} labelText={t('workshopAdsSwitchLabel')} />
                <p style={getStyle(screenSize, styles, 'adDescription')}>
                  {t('workshopAdsDescription')}
                </p>
              </div>
              <div style={getStyle(screenSize, styles, 'favouriteWorkshopListContainer')}>
                <CBHeaderTextWithHelp text={t('workshopsMenu')} />
                {workshops.favouriteWorkshops.map(workshop => (
                  <WorkshopListItem
                    key={workshop.id}
                    buttonAction={deleteFavouriteWorkshop}
                    label={workshop.name}
                    id={workshop.id}
                    isFavourite
                    value={`${workshop.street_address}, ${workshop.zip_code} ${workshop.city}`}
                  />
                ))}
              </div>
            </div>
            <div style={getStyle(screenSize, styles, 'searchWorkshopContainer')}>
              <CBHeaderTextWithHelp text={t('workshopsSearchHeading')} />
              <div style={getStyle(screenSize, styles, 'searchContainer')}>
                <CBSearchInput containerStyle={{ marginLeft: 0 }} type='workshop' />
              </div>
              <div style={getStyle(screenSize, styles, 'resultContainer')}>
                {workshops.workshops
                  .filter(ws => {
                    let isFavourite = false;
                    workshops.favouriteWorkshops.forEach(fws => {
                      if (fws.id == ws.id) {
                        isFavourite = true;
                      }
                    });
                    return !isFavourite;
                  })
                  .map(workshop => (
                    <WorkshopListItem
                      key={workshop.id}
                      buttonAction={addFavouriteWorkshop}
                      label={workshop.name}
                      id={workshop.id}
                      isFavourite={false}
                      value={`${workshop.street_address}, ${workshop.zip_code} ${workshop.city}`}
                    />
                  ))}
              </div>
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      paddingBottom: 50,
    },
    favouriteWorkshopContainer: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 40,
      width: '33%',
      borderRight: '1px solid rgba(255, 255, 255, 0.4)',
      minHeight: 500,
    },
    searchWorkshopContainer: {
      width: '50%',
      padding: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
    arrowIcon: {
      height: 25,
      width: 25,
    },
    searchContainer: {
      marginBottom: 16,
    },
    switchContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 16,
    },
    adDescription: {
      fontSize: 12,
    },
    favouriteWorkshopSettings: {
      marginBottom: 80,
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    favouriteWorkshopContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      width: '100%',
      borderRight: 0,
      marginBottom: 20,
      minHeight: 0,
    },
    searchWorkshopContainer: {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: 20,
    },
  },
};

function mapStateToProps(state) {
  return {
    workshops: state.workshop,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchFavouriteWorkshops: _fetchFavouriteWorkshops,
    addFavouriteWorkshop: _addFavouriteWorkshop,
    deleteFavouriteWorkshop: _deleteFavouriteWorkshop,
  },
)(translate('SettingsView')(UserSettingsWorkshops));
