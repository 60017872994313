import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import CBMediaQuery from '../shared/CBMediaQuery';

import { getStyle } from '../../utils/utils';
import { fetchVehicleCategories as _fetchVehicleCategories } from '../../actions/vehicleCategoryActions';
import WorkshopVehicleListItem from '../Workshop/WorkshopVehicleListItem';
import {
  fetchWSVehicles as _fetchWSVehicles,
  fetchWSVehiclesNextPage as _fetchWSVehiclesNextPage,
  clearCurrentVehicle as _clearCurrentVehicle,
} from '../../actions/vehicleActions';
import AddVehicleButton from './AddVehicleButton';

class WorkshopList extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { clearCurrentVehicle } = this.props;
    clearCurrentVehicle();
  }

  render() {
    const {
      wsVehicles,
      workshopID,
      ws,
      wsVehiclePagination,
      t,
      fetchWSVehiclesNextPage,
      filters,
    } = this.props;

    let totalCount = wsVehiclePagination.count

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <AddVehicleButton
              workshopID={workshopID}
              incrementCounter={this.incrementCounter}
              t={t}
            />
            <div style={getStyle(screenSize, styles, 'listContainer')}>
              {wsVehicles && wsVehicles.map(vehicle => <WorkshopVehicleListItem vehicle={vehicle} key={vehicle.id} />)}
            </div>
            <div style={getStyle(screenSize, styles, 'paginationContainer')}>
              <p
                className='font-titillium-web-regular'
                style={getStyle(screenSize, styles, 'pageSize')}
              >
                {wsVehicles.length}
                /
                {totalCount}
              </p>
              {!!wsVehiclePagination.next_page && (
                <div
                  className='hover-background-green'
                  style={getStyle(screenSize, styles, 'paginationButton')}
                  onClick={() => {
                    fetchWSVehiclesNextPage(workshopID, { ...filters, page: wsVehiclePagination.next_page });
                  }}
                >
                  {
                    <p className='font-titillium-web-regular'>
                      {t('Lataa lisää')}
                    </p>
                  }
                </div>
              )
              }
            </div>
            {screenSize !== 'small' && (
              <React.Fragment />
            )}
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      paddingTop: 20,
    },
    listContainer: {
      paddingTop: 20,
      overflow: 'auto',
    },
    paginationButton: {
      width: 300,
      height: 40,
      border: '2px solid #00f600',
      borderRadius: 100,
      backgroundSize: 'cover',
      fontSize: 14,
      color: '#ffffff',
      textDecoration: 'none solid rgb(255, 255, 255)',
      textAlign: 'center',
      paddingTop: 8,
      cursor: 'pointer',
      marginLeft: 16,
    },
    paginationContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
    },
    pageSize: {
      width: 74,
      height: 31,
      fontSize: 20,
      color: '#00f600',
      fontWeight: 300,
      textDecoration: 'none solid rgb(0, 246, 0)',
      paddingVertical: 4,
    },
  },
  medium: {
    container: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 55,
    },
    paginationContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 30,
      marginBottom: 30,
    },
    pageSize: {
      fontWeight: 200,
    },
  },
  small: {},
};

function mapStateToProps(state) {
  return {
    wsVehiclePagination: state.vehicle.wsVehiclePagination,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchWSVehicles: _fetchWSVehicles,
    fetchWSVehiclesNextPage: _fetchWSVehiclesNextPage,
    clearCurrentVehicle: _clearCurrentVehicle,
  },
)(translate('WSHomeView')(WorkshopList));
