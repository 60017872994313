import React from 'react'
import { translate } from "react-i18next";

const WelcomeTextSub = (props) => (
  <div>
    <p className="font-titillium-web-extra-light" style={styles.headerTextSub}>
      {props.t('weKnowCars')}
    </p>
  </div>
)

const styles = {
  headerTextSub: {
    color: '#FFF',
    fontSize: 20,
    textAlign: 'center',
    margin: 0
  }
}

export default translate("HomeView")(WelcomeTextSub)
