import React, { Component } from 'react';
import { getStyle, styleMerge } from '../../utils/utils';

import CBMediaQuery from './CBMediaQuery';

class CBSelectFilters extends Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value, errors: '' };
  }

  handleChange = elem => {
    const value = elem.target.value;
    const { name, onChange } = this.props;
    this.setState({ value });

    const changedFilters = {};
    changedFilters[name] = value
    onChange(changedFilters);
  };

  render() {
    const {
      selectStyle, optionStyle, options, onChange
    } = this.props;
    const { value, placeholder } = this.state;
    return (
      <CBMediaQuery>
        {screenSize => (
          <select
            value={value}
            onChange={this.handleChange}
            style={styleMerge(getStyle(screenSize, styles, 'select'), selectStyle || {})}
            placeholder={placeholder}
          >
            {options.map(option => (
              <option className='font-titillium-web' style={styleMerge(getStyle(screenSize, styles, 'option'), optionStyle || {})} key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    select: {
      backgroundColor: 'transparent',
      display: 'flex',
      height: 40,
      border: '1px solid rgba(255, 255, 255, 0.6)',
      borderRadius: 50,
      alignItems: 'center',
      marginBottom: 0,
      marginRight: 28,
      marginLeft: 16,
      width: '100%',
      color: '#FFFFFF',
      padding: '0 8px'
    },
    option: {
      backgroundColor: '#0095da',
    }
  },
};

export default CBSelectFilters;
