import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getStyle, getModalStyle, TYPE_ORGANIZATION_GARAGE, TYPE_CARE_SERVICE, TYPE_WORKSHOP_ALL } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBButtonSubmit from '../../shared/CBButtonSubmit';
import {
  requestVehiclePermission as _requestVehiclePermission,
} from '../../../actions/vehiclePermissionActions';
import {
  requestGarageVehiclePermission as _requestGarageVehiclePermission,
} from '../../../actions/vehicleActions';


Modal.setAppElement('#root');

const PRICE_PER_UNIT_WORKSHOP = 0.85;
const PRICE_PER_UNIT_FLEET = 2.00;

class UpdateVehiclesModal extends Component {
  getCsrfToken = () => {
    const name = 'csrftoken=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  getDescriptionText = () => {
    const {
      t,
      listType
    } = this.props;
    switch(listType) {
      case TYPE_WORKSHOP_ALL:
        return t('updateWorkshopVehiclesInfo');
      case TYPE_CARE_SERVICE:
        return t('updateWorkshopCareServiceVehiclesInfo');
      case TYPE_ORGANIZATION_GARAGE:
        return t('updateVehiclesInfo');
      default:
        return '';
    }
  };

  render() {
    const {
      modalIsOpen,
      closeModal,
      afterOpenModal,
      t,
      selectedVehicles,
      listType
    } = this.props;

    const isWorkshopUser = listType === TYPE_CARE_SERVICE || listType === TYPE_WORKSHOP_ALL;
    const pricePerUnit = isWorkshopUser ? PRICE_PER_UNIT_WORKSHOP : PRICE_PER_UNIT_FLEET ;
    const totalPrice = ((selectedVehicles.length * pricePerUnit).toFixed(2));
    const totalPriceString = totalPrice.toString().replace('.', ',');
    const registrationNumbers = selectedVehicles.map((vehicle) => vehicle.registration_number);
    const selectedVehicleIds = selectedVehicles.map((vehicle) => vehicle.id);

    return (
      <CBMediaQuery>
        {screenSize => (
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={getModalStyle(screenSize, styles.modal)}
            contentLabel='Update vehicles modal'
          >
            <div style={getStyle(screenSize, styles, 'container')}>
              <p style={getStyle(screenSize, styles, 'title')}>
                {t('updateVehiclesHeading')}
              </p>

              <div style={getStyle(screenSize, styles, 'infoContainer')}>
                <p style={getStyle(screenSize, styles, 'textGarageName')}>{registrationNumbers.join(', ')}</p>
                <p style={getStyle(screenSize, styles, 'info')}>{this.getDescriptionText()}</p>
                <p style={getStyle(screenSize, styles, 'info')}>{isWorkshopUser ? t('updateWorkshopVehiclesPriceInfo') : t('updateVehiclesPriceInfo')}</p>
                <p style={getStyle(screenSize, styles, 'info')}><strong>{`${t('updateVehiclesPriceTotal')} ${totalPriceString}€ (alv 0%)`}</strong></p>
              </div>

              <div style={getStyle(screenSize, styles, 'buttonContainer')}>
                <div style={getStyle(screenSize, styles, 'cancelButtonContainer')}>
                  <CBButtonSubmit
                    disabled={false}
                    onClick={() => {
                      closeModal();
                    }}
                    text={t('cancel')}
                    style={getStyle(screenSize, styles, 'submitButton')}
                  />
                </div>
                <div style={getStyle(screenSize, styles, 'submitButtonContainer')}>
                  {
                    selectedVehicles && selectedVehicles.length &&  (
                      <form action='/api/1/payments/stripe-create-checkout-session/' method='POST'>
                        {/* Add a hidden field with the lookup_key of your Price */}
                        <input type='hidden' name='lookup_key' value={isWorkshopUser ? 'update_workshop_vehicle' : 'update_garage_vehicle'} />
                        <input type='hidden' name='vehicle_ids' value={selectedVehicleIds.join(',')} />
                        <input
                          type='hidden'
                          value={this.getCsrfToken()}
                          name='csrfmiddlewaretoken'
                        />
                        <button
                          className='hover-green-border hover-green-text'
                          id='checkout-button'
                          type='submit'
                        >
                          Siirry maksamaan
                        </button>

                      </form>
                    )
                  }
                </div>
              </div>
            </div>
          </Modal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },
    infoContainer: {
      marginBottom: 32,
      marginTop: 32,
    },
    submitButtonContainer: {
      width: 200,
      marginTop: 32,
    },
    cancelButtonContainer: {
      width: 200,
      marginTop: 32,
    },
    buttonContainer: {
      width: '80%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    textGarageName: {
      fontSize: 18,
      color: '#0095da',
      fontFamily: 'TitilliumWeb-SemiBold',
      textAlign: 'center',
    },
    title: {
      fontSize: 32,
      color: '#0095da',
      textAlign: 'center',
    },
    subTitle: {
      fontSize: 14,
      color: 'black',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    submitButton: {
      height: 50,
      width: '100%',
      border: '2px solid #0095da',
      color: '#0095da',
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 400,
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      marginBottom: 30,
    },
    inputIE: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 400,
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      border: 'none',
      marginBottom: 30,
      padding: 8,
    },
    info: {
      width: '100%',
      fontSize: 14,
      color: 'black',
      paddingTop: 16,
      paddingHorizontal: 30,
      textAlign: 'center',
    },
    error: {
      width: '100%',
      fontSize: 14,
      color: 'red',
      paddingTop: 16,
      paddingHorizontal: 30,
      textAlign: 'center',
    },
  },
  small: {
    input: {
      width: '100%',
    },
  },
  modal: {
    default: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    small: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
};

export default connect(
  null,
  {
    requestVehiclePermission: _requestVehiclePermission,
    requestGarageVehiclePermission: _requestGarageVehiclePermission,
  },
)(translate('GarageView')(UpdateVehiclesModal));
