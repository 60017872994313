import React, { Component } from 'react';
import Modal from 'react-modal';
import { translate } from 'react-i18next';
import CBMediaQuery from './CBMediaQuery';
import { getStyle } from '../../utils/utils';
import CBButtonGeneric from './CBButtonGeneric';

class CBPromptModal extends Component {
  render() {
    const {
      modalIsOpen,
      closeModal,
      afterOpenModal,
      performAction,
      title,
      text,
      buttonText,
      cancelText,
      cancelable = true,
      t,
    } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={styles.modal}
          >
            <div style={getStyle(screenSize, styles, 'container')}>
              <p style={getStyle(screenSize, styles, 'title')}>
                {title}
              </p>
              <p style={getStyle(screenSize, styles, 'text')}>
                {text}
              </p>
              <div style={getStyle(screenSize, styles, 'buttonGroup')}>
                {cancelable && (
                  <div style={getStyle(screenSize, styles, 'buttonContainer')}>
                    <CBButtonGeneric onClick={closeModal} text={cancelText || t('promptCancelButton')} />
                  </div>
                )}
                <div style={getStyle(screenSize, styles, 'buttonContainer')}>
                  <CBButtonGeneric
                    onClick={(e) => {
                      performAction(e);
                    }}
                    text={buttonText}
                  />
                </div>
              </div>
            </div>
          </Modal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      fontSize: 32,
      color: '#0095da',
      textAlign: 'center',
    },
    text: {
      color: '#0095da',
      textAlign: 'center',
      marginBottom: 16,
    },
    buttonContainer: {
      width: 150,
      marginRight: 16,
    },
    buttonGroup: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  modal: {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      height: 'auto',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
  },
};

export default translate()(CBPromptModal);
