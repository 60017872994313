import React from 'react';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle } from '../../../utils/utils';
import { API_URL } from '../../../config';

class ImageCarouselModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { imageIndex: props.imageIndex || 0 };
  }

  componentWillReceiveProps(nextProps) {
    const { imageIndex } = this.state;
    if (imageIndex !== nextProps.imageIndex) {
      this.setState({ imageIndex: nextProps.imageIndex });
    }
  }

  onKeyUpNext = event => {
    event.preventDefault();
    if (event.keyCode === 13) {
      this.showNextImage();
    }
  };

  onKeyUpPrevious = event => {
    event.preventDefault();
    if (event.keyCode === 13) {
      this.showPreviousImage();
    }
  };

  showNextImage = e => {
    e.stopPropagation();
    const { images } = this.props;
    const { imageIndex } = this.state;
    if (imageIndex + 1 <= images.length - 1) {
      this.setState({ imageIndex: imageIndex + 1 });
    }
  };

  showPreviousImage = e => {
    e.stopPropagation();
    const { imageIndex } = this.state;
    if (imageIndex - 1 >= 0) {
      this.setState({ imageIndex: imageIndex - 1 });
    }
  };

  getImageUrl = () => {
    const { images } = this.props;
    const { imageIndex } = this.state;
    const image = images[imageIndex]
      ? images[imageIndex].image
      : '/img/placeholder.png';
    return image;
  };

  render() {
    const { images, handleClose, show } = this.props;

    const { imageIndex } = this.state;

    const showModal = show ? { display: 'flex' } : { display: 'none' };
    return (
      <CBMediaQuery>
        {screenSize => (
          <div
            className='modal'
            style={{ ...getStyle(screenSize, styles, 'modal'), ...showModal }}
            onClick={handleClose}
          >
            <div style={{ ...getStyle(screenSize, styles, 'imageContainer'), backgroundImage: `url(${API_URL}${this.getImageUrl()})` }} />
            <div style={getStyle(screenSize, styles, 'imageChangerContainer')}>
              <div style={getStyle(screenSize, styles, 'imageChangerButtonPrevious')}>
                <div
                  onClick={this.showPreviousImage}
                  role='button'
                  tabIndex={0}
                  onKeyUp={this.onKeyUpPrevious}
                  style={getStyle(screenSize, styles, 'imageChangerButtonImage')}
                >
                  <img alt='Next' src='/img/arrow_black_left.svg' />
                </div>
              </div>
              <div style={getStyle(screenSize, styles, 'fullScreenImageCountWidget')}>
                <img
                  style={getStyle(screenSize, styles, 'imageCountIcon')}
                  alt='Count'
                  src='/img/icon_6-1.svg'
                />
                <p className='font-titillium-web-extra-light'>
                  {`${imageIndex + 1}/${images.length}`}
                </p>
              </div>
              <div style={getStyle(screenSize, styles, 'imageChangerButtonNext')}>
                <div
                  onClick={this.showNextImage}
                  role='button'
                  tabIndex={0}
                  onKeyUp={this.onKeyUpNext}
                  style={getStyle(screenSize, styles, 'imageChangerButtonImage')}
                >
                  <img alt='Next' src='/img/arrow_black_right.svg' />
                </div>
              </div>
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    fullScreenImageCountWidget: {
      marginTop: 16,
      marginLeft: 0,
      alignSelf: 'center',
    },
    imageCountIcon: {
      width: 16,
      height: 16,
    },
    imageContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    imageChangerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    },
    imageChangerButtonNext: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 60,
    },
    imageChangerButtonPrevious: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 60,
    },
    imageChangerButtonImage: {
      width: 50,
      height: 50,
      opacity: 0.4,
      backgroundColor: '#ffffff',
      backgroundSize: 'cover',
      boxShadow: '1px 1px 3px rgba(0,0,0,0.7)',
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 10,
    },
    modal: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.9)',
    },
  },
  medium: {},
  small: {
    vehicleImage: {
      heiht: 'inherit',
      width: '100%',
    },
    optionsContainer: {
      padding: 16,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    imageCountWidget: {
      marginTop: 0,
      marginLeft: 16,
    },
  },
};

export default ImageCarouselModal;
