import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import CBMediaQuery from '../shared/CBMediaQuery';
import moment from 'moment';

import CBHeaderTextWithHelp from '../shared/CBHeaderTextWithHelp';
import { getStyle } from '../../utils/utils';
import CBButtonLarge from '../shared/CBButtonLarge';
import {
  fetchAdvertisements as _fetchAdvertisements,
} from '../../actions/advertisementActions';

class AdvertisementsView extends React.Component {
  componentDidMount () {
    const { fetchAdvertisements } = this.props;

    fetchAdvertisements();
  }

  getLocationTranslation = (location) => {
    const { t } = this.props;

    switch (location) {
      case 'front_page':
        return t('frontPageView');
      case 'garage':
        return t('garageView');
      case 'vehicle_page':
        return t('singleCarView');
      default:
        return '';
    }
  };

  handleSubmit = () => {
    const { history } = this.props;
    history.push('/staff_settings/advertisements/new');
  };

  printTimeLeftUntilExpiry = (date) => {
    const duration = moment.duration(moment(date).diff(moment(Date.now())));
    const days = duration.asDays();
    const hours = duration.asHours();

    const formattedDays = `Voimassa ${Math.round(days)} päivää`;
    const formattedhours = hours >= 2 ? `Voimassa ${Math.round(hours)} tuntia` : 'tunnin';

    if (hours > 0) {
      return days >= 2 ? formattedDays : formattedhours;
    } else {
      return 'Ei voimassa';
    }
  };

  render() {
    const { advertisementsState, t } = this.props;
    const { advertisements } = advertisementsState;

    return (
      <CBMediaQuery>
        {(screenSize) => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'innerContainer')}>
              <div style={getStyle(screenSize, styles, 'menuAndContentContainer')}>
                <div style={getStyle(screenSize, styles, 'contentContainer')}>
                  <CBHeaderTextWithHelp text={t('staffSettingsAdvertisementsHeading')} />
                  <CBButtonLarge
                    onClick={this.handleSubmit}
                    text={t('AddNewAdvertisement')}
                    margin={'1rem 0'}
                  />

                  {advertisements &&
                    advertisements.map((advert, i) => (
                      <div
                        key={advert.id}
                        style={getStyle(screenSize, styles, 'advertContainer')}
                      >
                        <div
                          style={getStyle(
                            screenSize,
                            styles,
                            'advertInnerContainerImage',
                          )}
                        >
                          <img
                            style={getStyle(
                              screenSize,
                              styles,
                              'advertImage',
                            )}
                            src={advert.image_url}
                            alt={advert.destination_name}
                          />
                        </div>

                        <div
                          style={getStyle(
                            screenSize,
                            styles,
                            'advertInnerContainer',
                          )}
                        >
                          <p>
                            <b>Yhteystiedot</b>
                          </p>
                          <p>{advert.destination_name}</p>
                          <p>{advert.customer_name}</p>
                          <p>{advert.customer_contact_name}</p>
                        </div>
                        <div
                          style={getStyle(
                            screenSize,
                            styles,
                            'advertInnerContainer',
                          )}
                        >
                          <p>
                            <b>Käyntikerrat</b>
                          </p>
                          <p>{advert.destination_visit_count}</p>
                        </div>
                        <div
                          style={getStyle(
                            screenSize,
                            styles,
                            'advertInnerContainer',
                          )}
                        >
                          <p>
                            <b>Sijainti</b>
                          </p>
                          <p>
                            {advert.locations.map((location) => this.getLocationTranslation(location)).join(', ')}
                          </p>
                        </div>
                        <div
                          style={getStyle(
                            screenSize,
                            styles,
                            'advertInnerContainer',
                          )}
                        >
                          <p>
                            <b>Voimassaolotiedot</b>
                          </p>
                          <p>
                            {this.printTimeLeftUntilExpiry(advert.expiry_date)}
                          </p>
                          <p>
                            <img
                              src='/img/calendar_success.svg'
                              alt='Edit'
                              style={getStyle(
                                screenSize,
                                styles,
                                'typeIcon',
                              )}
                            />
                            {moment(advert.starting_date).format(
                              'YYYY-MM-DD',
                            )}
                          </p>
                          <p>
                            <img
                              src='/img/calendar_danger.svg'
                              alt='Edit'
                              style={getStyle(
                                screenSize,
                                styles,
                                'typeIcon',
                              )}
                            />
                            {moment(advert.expiry_date).format(
                              'YYYY-MM-DD',
                            )}
                          </p>
                        </div>
                        <div
                          style={getStyle(
                            screenSize,
                            styles,
                            'advertInnerContainerEdit',
                          )}
                        >
                          <Link to={`/staff_settings/advertisements/${advert.id}`}>
                            <img
                              src='/img/icon_edit.svg'
                              alt='Edit'
                              style={getStyle(
                                screenSize,
                                styles,
                                'typeIcon',
                              )}
                            />
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }

};

const styles = {
  default: {
    typeIcon: {
      cursor: 'pointer',
      height: 36,
      width: 36,
      marginRight: 8,
    },
    menuAndContentContainer: {
      backgroundColor: '#0095da',
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid rgba(255, 255, 255, 0.4)',
    },
    contentContainer: {
      padding: '2rem',
      width: '100%',
    },
    advertContainer: {
      fontSize: '16px',
      justifyContent: 'space-between',
      padding: '1rem 0',
      display: 'flex',
      borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
      gap: '2rem',
      marginBottom: '1rem',
    },
    advertInnerContainer: {
      minWidth: '20%',
    },
    advertInnerContainerImage: {
      width: '20%',
    },
    advertInnerContainerEdit: {
      textAlign: 'right',
      width: '5%',
      minWidth: '50px',
    },
    advertImage: {
      width: '100%',
      height: '150px',
      objectFit: 'cover',
    },
  },
  medium: {
    menuAndContentContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    contentContainer: {
      padding: 0,
    },
  },
  small: {
    advertContainer: {
      position: 'relative',
      flexWrap: 'wrap',
      gap: '1rem',
    },
    contentContainer: {
      padding: 0,
    },
    advertInnerContainer: {
      flex: '1 1 49%',
      minWidth: '49%',
      maxWidth: '100%',
    },
    advertInnerContainerImage: {
      flexBasis: '0 0 100%',
      width: '100%',
    },
    advertInnerContainerEdit: {
      position: 'absolute',
      right: 20,
      top: 20,
      zIndex: 2,
      padding: '1rem',
      minWidth: '56px',
      backgroundColor: '#000',
      borderRadius: '50%',
    },
    typeIcon: {
      marginRight: 0,
    },
    advertImage: {
      width: '100%',
      height: '225px',
      objectFit: 'cover',
    },
  },
};

function mapStateToProps(state) {
  return {
    advertisementsState: state.advertisement,
    user: state.authUser.user,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchAdvertisements: _fetchAdvertisements
  },
)(translate('AdvertisementsView')(AdvertisementsView));
