import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { getStyle, SERVICE_FLEET, SERVICE_WORKSHOP } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSubHeading from '../../shared/CBSubHeading';
import {
  fetchOrganizationVehicleReminders as _fetchOrganizationVehicleReminders,
  createOrganizationVehicleReminder as _createOrganizationVehicleReminder,
  updateOrganizationVehicleReminder as _updateOrganizationVehicleReminder,
  deleteOrganizationVehicleReminder as _deleteOrganizationVehicleReminder,
} from '../../../actions/organizationVehicleReminderActions';
import { withRouter } from 'react-router';
import OrganizationVehicleReminderRow from './OrganizationVehicleReminderRow';

const ENABLED_REMINDER_TYPES = ['inspection'];

class SettingsOrganizationVehicleReminders extends React.Component {
  state = {
  };

  componentDidMount() {
    this.fetchReminders();
  }

  componentDidUpdate(prevProps) {
    const { selectedWorkshopId, selectedOrganizationId } = this.props;

    if (selectedWorkshopId != prevProps.selectedWorkshopId
      || selectedOrganizationId != prevProps.selectedOrganizationId) {
      this.fetchReminders();
    }
  }

  fetchReminders = () => {
    const {
      fetchOrganizationVehicleReminders,
      selectedOrganizationId,
      selectedWorkshopId
    } = this.props;

    const params = {};

    if (selectedOrganizationId) {
      params.organization_id = selectedOrganizationId;
    } else if (selectedWorkshopId) {
      params.workshop_id = selectedWorkshopId;
    }

    fetchOrganizationVehicleReminders(params);
  };

  getReminders = () => {
    const { service, selectedWorkshopId, selectedOrganizationId, organizationVehicleReminders } = this.props;

    const reminders = [];

    ENABLED_REMINDER_TYPES.forEach((rType) => {
      let customReminder = null;
      if (service === SERVICE_WORKSHOP && selectedWorkshopId) {
        customReminder = organizationVehicleReminders.find(
          (reminder) => reminder.workshop == selectedWorkshopId && reminder.reminder_type == rType
        );
      }
      else if (service === SERVICE_FLEET && selectedOrganizationId) {
        customReminder = organizationVehicleReminders.find((reminder) => reminder.organization == selectedOrganizationId && reminder.reminder_type == rType);
      }

      if (!customReminder) {
        customReminder = organizationVehicleReminders.find((reminder) => reminder.owner_type == 'default' && reminder.reminder_type == rType);
      }

      if (customReminder) {
        reminders.push(customReminder);
      }
    });

    return reminders;
  };


  render() {
    const {
      t,
      service,
      selectedWorkshopId,
      user,
      createOrganizationVehicleReminder,
      updateOrganizationVehicleReminder,
      deleteOrganizationVehicleReminder,
      selectedOrganizationId,
      organizationVehicleReminders,
    } = this.props;

    const vehicleReminders = this.getReminders();

    const isStaff = user.is_staff;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'sectionContainer')}>
              <CBSubHeading>
                {t('orgVehicleReminderHeading')}
              </CBSubHeading>
            </div>

            <div style={getStyle(screenSize, styles, 'sectionContainer')}>
              <p>
                {t('orgVehicleReminderDescription')}
              </p>
            </div>

            <React.Fragment>
              <table>
                <thead>
                  <tr>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                      {t('checkNameLabel')}
                    </th>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                      {t('reminderNotifyBeforeLabel')}
                    </th>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                      {t('reminderTaskCreationBeforeLabel')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    vehicleReminders.map((reminder) =>
                      (
                        <OrganizationVehicleReminderRow
                          key={reminder.id}
                          reminder={reminder}
                          isStaff
                          createOrganizationVehicleReminder={createOrganizationVehicleReminder}
                          updateOrganizationVehicleReminder={updateOrganizationVehicleReminder}
                          deleteOrganizationVehicleReminder={deleteOrganizationVehicleReminder}
                          service={service}
                          selectedWorkshopId={selectedWorkshopId}
                          selectedOrganizationId={selectedOrganizationId}
                        />
                      ))
                  }
                </tbody>
              </table>
            </React.Fragment>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}


const styles = {
  default: {
    container: {
      padding: 32,
      display: 'flex',
      flexDirection: 'column',
    },
    sectionContainer: {
      marginBottom: 16,
    },
    tableHeading: {
      fontFamily: 'TitilliumWeb-Regular',
      fontSize: 12,
      color: '#ffffff',
      paddingLeft: 8,
      paddingBottom: 8,
    },
    permissionTypeContainer: {
      marginTop: 32,
      borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
    },
    workshopSelectorContainer: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    selectField: {
      minWidth: 300,
      height: 40,
      border: '1px solid rgb(255 255 255 / 55%)',
      borderRadius: 50,
      backgroundColor: 'transparent',
      color: '#FFF',
      padding: '0px 0px 0px 16px',
    },
    selectContainer: {
      marginTop: 16,
      marginRight: 16,
      minWidth: 300,
    },
    optionStyle: {
      backgroundColor: 'rgb(0, 149, 218)',
    },
  },
  medium: {
    container: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    selectedOrganizationId: state.organization.selectedOrganization,
    organizationVehicleReminders: state.organizationVehicleReminder.organizationVehicleReminders,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchOrganizationVehicleReminders: _fetchOrganizationVehicleReminders,
    createOrganizationVehicleReminder: _createOrganizationVehicleReminder,
    updateOrganizationVehicleReminder: _updateOrganizationVehicleReminder,
    deleteOrganizationVehicleReminder: _deleteOrganizationVehicleReminder,
  },
)(withRouter(translate('CheckLists')(SettingsOrganizationVehicleReminders)));






