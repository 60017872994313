import React from 'react';
import { translate } from 'react-i18next';
import CBSpinnerFloating from '../../shared/CBSpinnerFloating';
import CBButtonWithIconSmall from '../../shared/CBButtonWithIconSmall';
import CBLabelAndValue from '../../shared/CBLabelAndValue';
import { formatDate } from '../../../utils/utils';
import CBPromptModal from '../../shared/CBPromptModal';

class CareServiceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { formData: Object.assign({}, props.careService), formValid: {}, confirmModalIsOpen: false };
  }

  componentWillReceiveProps(nextProps) {
    const { careService } = this.props;
    this.setState({ formData: Object.assign({}, nextProps.careService), formValid: {} });
  }

  showDeleteModal = () => {
    this.setState({
      deleteModalIsOpen: true,
    });
  };

  removeCareService = () => {
    const {
      deleteCareService,
      careService,
    } = this.props;

    deleteCareService(careService.id);
  };

  closeDeleteModal = () => {
    this.setState({
      deleteModalIsOpen: false,
    });
  };

  render() {
    const {
      t,
      careServiceRequest,
      careService,
      user,
    } = this.props;
    const { deleteModalIsOpen } = this.state;
    return (
      <div style={styles.formContainer}>
        {careServiceRequest.careServiceStatus.loading && <CBSpinnerFloating />}
        <div>
          <CBLabelAndValue
            label={t('careServiceTableOrganization')}
            value={`${careService.organization.name} (${careService.organization.business_id})`}
          />

          <CBLabelAndValue
            label={t('careServiceTableWorkshop')}
            value={`${careService.workshop.name} (${careService.workshop.business_id})`}
          />

          <CBLabelAndValue
            label={t('careServiceTableCreatedAt')}
            value={formatDate(careService.created_at)}
          />

          <CBLabelAndValue
            label={t('careServiceTerms')}
            value={careService.terms}
          />

          <CBLabelAndValue
            label={t('careServiceInviteMessage')}
            value={careService.invitation ? careService.invitation.text : ''}
          />

          <div style={styles.actionsContainer}>
            <CBButtonWithIconSmall
              icon='/img/icon_trashcan.svg'
              text={t('removeCareService')}
              onClick={this.showDeleteModal}
              disabled={!careService.can_edit}
            />
          </div>
        </div>
        <CBPromptModal
          modalIsOpen={deleteModalIsOpen}
          closeModal={this.closeDeleteModal}
          afterOpenModal={() => {}}
          title={t('deleteCareServiceTitle')}
          text={`${t('deleteCareServiceText')}`}
          buttonText={t('deleteButton')}
          performAction={() => {
            this.removeCareService();
            this.closeDeleteModal();
          }}
        />
      </div>
    );
  }
}

const styles = {
  formContainer: {
    position: 'relative',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  arrowIcon: {
    margin: '0px 8px',
    width: 32,
    height: 32,
    fill: '#ffffff',
  },
  confirmIcon: {
    width: 24,
    height: 24,
  },
  confirmText: {
    fontSize: 14,
  },
  fieldContainerLeftAlign: {
    marginBottom: 5,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
};

export default translate('SettingsView')(CareServiceForm);
