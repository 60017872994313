import React from 'react';
import { translate } from 'react-i18next';
import WSSettingsMenuItem from './WSSettingsMenuItem';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';

class WSSettingsMenu extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'settingsMenuContainer')}>
            <div style={getStyle(screenSize, styles, 'settingsContainer')}>
              <WSSettingsMenuItem
                to='/workshop-settings/profile'
                text={t('userInfoMenu')}
              />
              <WSSettingsMenuItem
                to='/workshop-settings/workshop'
                text={t('basicSettingsTitle')}
              />
              <WSSettingsMenuItem
                to='/workshop-settings/invisibility'
                text={t('invisibilitySettingsTitle')}
              />
              <WSSettingsMenuItem
                to='/workshop-settings/permissions'
                text={t('permissionsSettingsTitle')}
              />
              <WSSettingsMenuItem
                to='/workshop-settings/care_services'
                text={t('careServiceSettingsTitle')}
              />
              <WSSettingsMenuItem
                to='/workshop-settings/check-lists'
                text={t('checkListsSettingsTitle')}
              />
              <WSSettingsMenuItem
                to='/workshop-settings/org-vehicle-reminders'
                text={t('orgVehicleRemindersSettingsTitle')}
              />
            </div>
            <div style={getStyle(screenSize, styles, 'helpContainer')}>
              <WSSettingsMenuItem
                href='/accounts/terms-and-conditions'
                to='foo'
                text={t('termsMenu')}
              />
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    settingsMenuContainer: {},
    helpContainer: {
      borderTop: '1px solid rgba(255, 255, 255, 0.4)',
      borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
    },
  },
};

export default translate('WSSettingsView')(WSSettingsMenu);
