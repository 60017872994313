import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import { getStyle } from '../../utils/utils';
import LicensePlate from '../shared/LicensePlate';
import CBMediaQuery from '../shared/CBMediaQuery';

class WorkshopVehicleListItem extends React.Component {
  render() {
    const { vehicle, t } = this.props;

    let owner = `${t('noOwnerText')}`;
    if (vehicle.unregistered_owner && !!vehicle.unregistered_owner.registered_owner) {
      owner = vehicle.unregistered_owner.registered_owner;
    } else if (!!vehicle.owner) {
      owner = vehicle.owner;
    }
    return (
      <CBMediaQuery>
        {screenSize => (
          <Link to={`/workshop-vehicle/${vehicle.id}/`}>
            <div style={getStyle(screenSize, styles, 'listItemContainer')}>
              <div style={getStyle(screenSize, styles, 'licencePlateContainer')}>
                <LicensePlate
                  plateNumber={vehicle.registration_number}
                />
                <p className='font-titillium-web-light' style={getStyle(screenSize, styles, 'vehicleModelContainer')}>
                  {`${vehicle.manufacturer} ${vehicle.model_name}`}
                </p>
              </div>
              <div>
                <p className='font-titillium-web-extra-light' style={getStyle(screenSize, styles, 'vehicleOwnerContainer')}>
                  {owner}
                </p>
              </div>
              <div style={getStyle(screenSize, styles, 'vehicleModifiedContainer')}>
                <p className='font-titillium-web-extra-light' style={getStyle(screenSize, styles, 'vehicleModifiedContainer')}>
                  {vehicle.last_maintenance_date ? `${moment(vehicle.last_maintenance_date).format('DD.MM.YY')} ` : 'ei huoltoja'}
                </p>
                <img
                  style={getStyle(screenSize, styles, 'buttonIcon')}
                  alt='remove'
                  src='/img/icon_arrow_right.svg'
                />
              </div>
            </div>
          </Link>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    listItemContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'space-between',
      padding: 10,
      paddingLeft: 0,
      borderTop: '1px solid rgba(255,255,255,.3)',
    },
    licencePlateContainer: {
      display: 'flex',
      width: 540,
      fontWeight: 'extra-light',
    },
    vehicleModelContainer: {
      textAlign: 'left',
      fontSize: 20,
      paddingLeft: 22,
      justifySelf: 'center',
      alignSelf: 'center',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    vehicleOwnerContainer: {
      fontSize: 20,
      width: 380,
      textAlign: 'left',
      justifySelf: 'center',
      alignSelf: 'center',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    vehicleModifiedContainer: {
      fontSize: 20,
      width: 170,
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      justifySelf: 'center',
      alignSelf: 'center',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    buttonIcon: {
      height: 24,
      width: 24,
      marginLeft: 3,
      opacity: 0.5,
    },
  },
  medium: {
    vehicleModelContainer: {
      fontSize: 18,
      width: 300,
    },
    vehicleOwnerContainer: {
      fontSize: 18,
      width: 250,
      padding: 0,
      marginLeft: 0,
    },
    vehicleModifiedContainer: {
      fontSize: 18,
      width: 130,
      display: 'flex',
      textAlign: 'right',
    },
    buttonIcon: {
      height: 24,
      width: 24,
      marginLeft: 3,
      opacity: 0.5,
    },
  },
  small: {
    buttonIcon: {
      height: 24,
      width: 24,
      marginLeft: 3,
      opacity: 0.5,
    },
  },
};

export default translate('WSHomeView')(WorkshopVehicleListItem);
