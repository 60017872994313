import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import VehicleDetailsLabelAndValue from '../VehicleDetailsLabelAndValue';

class InspectionListItem extends React.Component {
  render() {
    const { inspection, vehicle } = this.props;

    return (
      <Link to={`/vehicle/${vehicle.id}/inspections/${inspection.id}`}>
        <div style={styles.listItemContainer}>
          <VehicleDetailsLabelAndValue
            label={moment(inspection.date).format('D.M.Y')}
            value={inspection.inspection_company_name}
          />
        </div>
      </Link>
    );
  }
}

const styles = {
  listItemContainer: {
    padding: 10,
    borderBottom: '1px solid rgba(255,255,255,.3)',
    borderLeft: '3px solid #0095da',
  },
};

export default InspectionListItem;
