import React, { Component } from 'react';
import Modal from 'react-modal';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { getStyle, getModalStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { changeVehicleCategory as _changeVehicleCategory } from '../../../actions/vehiclePermissionActions';

Modal.setAppElement('#root');

class ChangeVehicleCategoryModal extends Component {
  changeCategory = (e, categoryId) => {
    e.stopPropagation();
    const { changeVehicleCategory, vehicle } = this.props;
    changeVehicleCategory(vehicle.permission_id, categoryId);
  };

  render() {
    const {
      modalIsOpen, closeModal, afterOpenModal, t, vehicleCategoryRequest,
    } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={getModalStyle(screenSize, styles.modal)}
            contentLabel='Change category'
            shouldCloseOnOverlayClick
          >
            <div style={getStyle(screenSize, styles, 'container')}>
              <p style={getStyle(screenSize, styles, 'title')}>
                {t('changeCategoryHeading')}
              </p>
              <table style={getStyle(screenSize, styles, 'table')}>
                <tbody>
                  {vehicleCategoryRequest.vehicleCategories.map(category => (
                    <tr
                      onClick={e => {
                        this.changeCategory(e, category.id);
                      }}
                      key={category.id}
                      style={getStyle(screenSize, styles, 'categoryRow')}
                      className='hover-background-grey'
                    >
                      <td style={getStyle(screenSize, styles, 'categoryCell')}>
                        <p
                          className='font-titillium-web-semi-bold'
                          style={getStyle(screenSize, styles, 'categoryText')}
                        >
                          {category.name}
                        </p>
                      </td>
                    </tr>
                  ))}
                  <tr
                    onClick={e => {
                      this.changeCategory(e, null);
                    }}
                    key={0}
                    style={getStyle(screenSize, styles, 'categoryRow')}
                    className='hover-background-grey'
                  >
                    <td style={getStyle(screenSize, styles, 'categoryCell')}>
                      <p
                        className='font-titillium-web-semi-bold'
                        style={getStyle(screenSize, styles, 'categoryText')}
                      >
                        {t('noCategoryButton')}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '100%',
    },
    table: {
      width: '100%',
    },
    categoryRow: {
      borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
      cursor: 'pointer',
      padding: 16,
      width: '100%',
    },
    categoryCell: {
      paddingLeft: 8,
      paddingTop: 16,
      paddingBottom: 16,
    },
    categoryText: {
      fontSize: 16,
      color: '#000000',
    },
    title: {
      fontSize: 32,
      color: '#0095da',
      textAlign: 'center',
    },
    subTitle: {
      fontSize: 14,
      color: 'black',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 400,
    },
    info: {
      width: '100%',
      fontSize: 14,
      color: 'black',
      paddingHorizontal: 30,
      textAlign: 'center',
    },
  },
  modal: {
    default: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 370,
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    small: {
      content: {
        width: '90%',
        height: 'unset',
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
};

function mapStateToProps(state) {
  return {
    vehicleCategoryRequest: state.vehicleCategory,
  };
}

export default connect(
  mapStateToProps,
  {
    changeVehicleCategory: _changeVehicleCategory,
  },
)(translate('GarageView')(ChangeVehicleCategoryModal));
