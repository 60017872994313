import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import { fetchVehicleInspection as _fetchVehicleInspection } from '../../../actions/vehicleActions';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSpinner from '../../shared/CBSpinner';
import InspectionPartListItem from './ListItems/InspectionPartListItem';
import CBLabelAndValue from '../../shared/CBLabelAndValue';

class VehicleDetailsInspectionContent extends React.Component {
  componentDidMount = () => {
    const { match, fetchVehicleInspection, vehicle } = this.props;
    if (match) {
      fetchVehicleInspection(match.params.inspectionId);
    }
    else if (vehicle && vehicle.inspections.length) {
      fetchVehicleInspection(vehicle.inspections[0].id);
    }
  };

  componentDidUpdate(prevProps) {
    const { match, fetchVehicleInspection, vehicle } = this.props;

    if (match && match.params.inspectionId !== prevProps.match.params.inspectionId) {
      fetchVehicleInspection(match.params.inspectionId);
    }
    else if (vehicle && vehicle.inspections.length && vehicle.inspections[0].complete !== prevProps.vehicle.inspections[0].complete) {
      fetchVehicleInspection(vehicle.inspections[0].id);
    }
  }

  render() {
    const {
      vehicleRequest, t, match, vehicle,
    } = this.props;
    let inspection = match
      ? vehicle.inspections.find(x => x.id == match.params.inspectionId)
      : undefined;
    if (!inspection && vehicle && vehicle.inspections.length) {
      inspection = vehicle.inspections[0]
    }
    if (vehicleRequest.inspectionStatus.loading || (inspection && !inspection.complete)) {
      return (
        <CBMediaQuery>
          {screenSize => (
            <div style={getStyle(screenSize, styles, 'inspectionContentContainer')}>
              <div style={getStyle(screenSize, styles, 'descriptionContainer')}>
                <CBSpinner />
              </div>
            </div>
          )}
        </CBMediaQuery>
      );
    } else if (vehicleRequest.inspectionStatus.error) {
      return (
        <p>
          {vehicleRequest.inspectionStatus.error}
        </p>
      );
    } else {
      return (
        <CBMediaQuery>
          {screenSize => (
            <div style={getStyle(screenSize, styles, 'inspectionContentContainer')}>
              {inspection && (
                <React.Fragment>



{/*                   <div style={getStyle(screenSize, styles, 'topInfoContainer')}>
                    <InfoboxWithIcon
                      icon='/img/icon_calendar.svg'
                      text={moment(inspection.date).format('D.M.Y')}
                      labelText={t('dateInfobox')}
                    />
                    <InfoboxWithIcon
                      icon='/img/icon_distance_1.svg'
                      text={inspection.km ? formatKm(inspection.km) : t('notSpecified')}
                      labelText={t('kilometersInfobox')}
                      hasBorders={true}
                    />
                    <InfoboxWithIcon
                      icon='/img/icon_13-1.svg'
                      text={inspection.treatment_type_display}
                      labelText={t('classificationInfobox')}
                    />
                  </div> */}



                  <div style={getStyle(screenSize, styles, 'descriptionContainer')}>
                    <p
                      className='font-titillium-web-extra-light'
                      style={getStyle(screenSize, styles, 'sectionHeadingText')}
                    >
                      {t('inspectionInfoHeading')}
                    </p>
                    <CBLabelAndValue
                      label={t('inspectionDateLabel')}
                      value={moment(inspection.date).format('D.M.Y')}
                    />
                    <CBLabelAndValue
                      label={t('inspectionCompanyLabel')}
                      value={inspection.inspection_company_name}
                    />
                    {inspection.parts.map(part => (
                      <InspectionPartListItem
                        key={part.id}
                        inspectionPart={part}
                      />
                    ))}
                  </div>
                </React.Fragment>
              )}
            </div>
          )}
        </CBMediaQuery>
      );
    }
  }
}

const styles = {
  default: {
    inspectionNameText: {
      fontSize: 32,
    },
    inspectionDescriptionText: {
      fontSize: 14,
    },
    sectionHeadingText: {
      fontSize: 24,
    },
    descriptionContainer: {},
    inspectionContentContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 1)',
      minHeight: 500,
      width: '75%',
      padding: 16,
    },
    topInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginLeft: -16,
      marginRight: -16
    },
  },
  small: {
    inspectionContentContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 1)',
      minHeight: 500,
      width: '100%',
      padding: 16,
    },
    topInfoContainer: {
      display: 'flex',
      justifyContent: 'normal',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
    },
  },
};

function mapStateToProps(state) {
  return {
    vehicleRequest: state.vehicle,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchVehicleInspection: _fetchVehicleInspection,
  },
)(translate('VehicleDetailsView')(VehicleDetailsInspectionContent));
