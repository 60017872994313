import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../../../shared/CBMediaQuery';
import { getStyle } from '../../../../utils/utils';
import CBSpinnerFloating from '../../../shared/CBSpinnerFloating';
import {
  deleteVehicleImage as _deleteVehicleImage,
  updateVehicleImage as _updateVehicleImage,
} from '../../../../actions/vehicleActions';
import OptionsButton from '../../../GarageView/GarageViewComponents/OptionsComponents/OptionsButton';
import ImageCarouselModal from '../ImageCarouselModal';
import CBDropzone from '../../../shared/CBDropzone';

class VehicleDetailsTopImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { imageIndex: 0, imageCarouselModal: false };
  }

  componentWillReceiveProps(nextProps) {
    const { vehicle } = this.props;
    const { vehicle: nextVehicle } = nextProps;
    if (!vehicle || !nextVehicle) {
      return;
    }
    // if a new image was added, display it
    if (
      vehicle.id === nextVehicle.id
      && nextVehicle.images.length > vehicle.images.length
    ) {
      this.setState({ imageIndex: nextVehicle.images.length - 1 });
    }
  }

  onKeyUpNext = event => {
    event.preventDefault();
    if (event.keyCode === 13) {
      this.showNextImage();
    }
  };

  onKeyUpPrevious = event => {
    event.preventDefault();
    if (event.keyCode === 13) {
      this.showPreviousImage();
    }
  };

  showModal = () => {
    this.setState({ imageCarouselModal: true });
  };

  hideModal = e => {
    e.stopPropagation();
    this.setState({ imageCarouselModal: false });
  };

  showNextImage = e => {
    e.stopPropagation();
    const { vehicle } = this.props;
    const { imageIndex } = this.state;
    if (imageIndex + 1 <= vehicle.images.length - 1) {
      this.setState({ imageIndex: imageIndex + 1 });
    }
  };

  showPreviousImage = e => {
    e.stopPropagation();
    const { imageIndex } = this.state;
    if (imageIndex - 1 >= 0) {
      this.setState({ imageIndex: imageIndex - 1 });
    }
  };

  getBackroundImageUrl = () => {
    const { vehicle } = this.props;
    const { imageIndex } = this.state;
    const image = vehicle.images[imageIndex]
      ? vehicle.images[imageIndex].image
      : '/img/placeholder.png';
    return `url('${image}')`;
  };

  render() {
    const {
      vehicle,
      onImageUpload,
      isUploadingImage,
      t,
      deleteVehicleImage,
      updateVehicleImage,
    } = this.props;

    const { imageIndex, imageCarouselModal } = this.state;

    const imageOptions = [
      {
        title: t('setDefaultImageButton'),
        action: () => {
          updateVehicleImage(vehicle.images[imageIndex].id, { is_default: true });
          this.setState({ imageIndex: 0 });
        },
      },
    ];

    if (vehicle.images[imageIndex]) {
      imageOptions.push({
        title: t('remove'),
        action: () => {
          deleteVehicleImage(vehicle.images[imageIndex].id);
          this.setState({ imageIndex: 0 });
        },
      });
    }

    return (
      <Fragment>
        <CBMediaQuery>
          {screenSize => (
            <div style={getStyle(screenSize, styles, 'imageCarouselContainer')}>
              {isUploadingImage && <CBSpinnerFloating />}
              <div
                onClick={this.showModal}
                style={{
                  ...getStyle(screenSize, styles, 'imageContainer'),
                  ...{ backgroundImage: this.getBackroundImageUrl() },
                }}
              >
                <div style={getStyle(screenSize, styles, 'imageChangerButtonNext')}>
                  <div
                    onClick={this.showNextImage}
                    role='button'
                    tabIndex={0}
                    onKeyUp={this.onKeyUpNext}
                    style={getStyle(screenSize, styles, 'imageChangerButtonImage')}
                  >
                    <img alt='Next' src='/img/arrow_black_right.svg' />
                  </div>
                </div>
                <div style={getStyle(screenSize, styles, 'imageChangerButtonPrevious')}>
                  <div
                    onClick={this.showPreviousImage}
                    role='button'
                    tabIndex={0}
                    onKeyUp={this.onKeyUpPrevious}
                    style={getStyle(screenSize, styles, 'imageChangerButtonImage')}
                  >
                    <img alt='Next' src='/img/arrow_black_left.svg' />
                  </div>
                </div>
                <div style={getStyle(screenSize, styles, 'optionsContainer')}>
                  <CBDropzone
                    style={getStyle(screenSize, styles, 'dropZone')}
                    onDropAccepted={onImageUpload}
                  >
                    {vehicle.can_edit && (
                      <div style={getStyle(screenSize, styles, 'addImageButton')}>
                        <img
                          className="opacity-and-border-on-hover"
                          style={getStyle(screenSize, styles, 'addImageIcon')}
                          alt='Add'
                          src='/img/car_options-2.svg'
                        />
                      </div>
                    )}
                  </CBDropzone>
                  <div style={getStyle(screenSize, styles, 'optionsRightContainer')}>
                    {vehicle.images.length > 0 && (
                      <React.Fragment>
                        <OptionsButton icon='/img/icon_options.svg' options={imageOptions} />
                        <div style={getStyle(screenSize, styles, 'imageCountWidget')}>
                          <img
                            style={getStyle(screenSize, styles, 'imageCountIcon')}
                            alt='Count'
                            src='/img/icon_6-1.svg'
                          />
                          <p className='font-titillium-web-extra-light'>
                            {`${imageIndex + 1}/${vehicle.images.length}`}
                          </p>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </CBMediaQuery>
        <ImageCarouselModal
          show={imageCarouselModal}
          handleClose={this.hideModal}
          imageIndex={imageIndex}
          images={vehicle.images}
          showNextImage={this.showNextImage}
          showPreviousImage={this.showPreviousImage}
          getBackroundImageUrl={this.getBackroundImageUrl}
        />
      </Fragment>
    );
  }
}

const styles = {
  default: {
    imageCarouselContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      borderRight: '1px solid rgba(255,255,255,.3)',
      minWidth: 520,
    },
    optionsContainer: {
      paddingLeft: 16,
      paddingRight: 16,
      minHeight: 66,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    optionsRightContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    addImageButton: {
      cursor: 'pointer',
    },
    addImageIcon: {
      width: 24,
      height: 24,
      opacity: 0.4,
      border: '1px solid transparent',
    },
    dropZone: {
      width: 24,
      height: 24,
    },
    imageCountWidget: {
      marginLeft: 16,
      marginTop: 16,
    },
    fullScreenImageCountWidget: {
      marginTop: 16,
      marginLeft: 0,
      alignSelf: 'center',
    },
    imageCountIcon: {
      width: 16,
      height: 16,
    },
    imageContainer: {
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    fullScreenImage: {
      width: '40%',
      position: 'absolute',
      left: '50%',
      top: '50%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      transform: 'translate(-50%,-50%)',
    },
    imageChangerContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    fullScreenImageChangerContainer: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      left: '25%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    imageChangerButton: {
      borderBottom: '1px solid rgba(255,255,255,.3)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '50%',
      width: 60,
    },
    imageChangerButtonNext: {
      position: 'absolute',
      height: '100%',
      top: 0,
      right: 0,
      borderBottom: '1px solid rgba(255,255,255,.3)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 60,
    },
    imageChangerButtonPrevious: {
      position: 'absolute',
      height: '100%',
      top: 0,
      left: 0,
      borderBottom: '1px solid rgba(255,255,255,.3)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 60,
    },
    imageChangerButtonImage: {
      width: 50,
      height: 50,
      opacity: 0.4,
      backgroundColor: '#ffffff',
      backgroundSize: 'cover',
      boxShadow: '1px 1px 3px rgba(0,0,0,0.7)',
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modal: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.9)',
    },
    modalMain: {
      position: 'fixed',
      background: 'white',
      width: '80%',
      height: 'auto',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
    displayBlock: {
      display: 'block',
    },
    displayNone: {
      display: 'none',
    },
  },
  small: {
    imageCarouselContainer: {
      height: '100%',
      width: '100%',
      minWidth: 0,
      display: 'flex',
      flexDirection: 'column',
      borderRight: '1px solid rgba(255,255,255,.3)',
    },
    vehicleImage: {
      heiht: 'inherit',
      width: '100%',
    },
    imageCountWidget: {
      marginTop: 0,
      marginLeft: 16,
    },
    imageContainer: {
      minHeight: 400,
    },
  },
};

function mapStateToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  {
    deleteVehicleImage: _deleteVehicleImage,
    updateVehicleImage: _updateVehicleImage,
  },
)(translate('VehicleDetailsView')(VehicleDetailsTopImage));
