import React from 'react';
import { RadioButton } from "primereact/radiobutton"

export default function RadioButtonGroup(props) {
  const {
    name, options, value, setValue, title,
  } = props;

  return (
    <div style={styles.container}>
      {
        title && (
          <p style={styles.titleText}>
            {title}
          </p>
        )
      }
      <div style={styles.buttonGroupContainer}>
        {
          options.map((option) => (
            <div key={option.value} style={styles.buttonContainer}>
              <RadioButton
                id={option.value}
                name={name}
                value={option.value}
                onChange={(e) => setValue(e.value)}
                checked={value === option.value}
              />
              <label style={styles.label} htmlFor={option.value}>{option.label}</label>
            </div>
          ))
        }
      </div>
    </div>
  )
}

const styles = {
  container: {
    padding: '16px 0px 16px 0px',
    textAlign: 'center',
  },
  buttonGroupContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 16,
  },
  label: {
    marginBottom: 0,
    marginLeft: 8,
  },
  titleText: {
    color: 'black',
    fontSize: 14,
    fontFamily: 'TitilliumWeb-SemiBold',
  },
};
