import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import WorkshopNotificationsListItem from './WorkshopNotificationsListItem';
import { fetchFavouriteWorkshops as _fetchFavouriteWorkshops } from '../../actions/workshopActions';
import CBSpinnerFloating from './CBSpinnerFloating';
import CBMediaQuery from './CBMediaQuery';
import { getStyle } from '../../utils/utils';


class WorkshopNotificationsList extends Component {
  componentWillMount() {
    const { fetchFavouriteWorkshops, workshopRequest } = this.props;
    if (!workshopRequest.favouriteWorkshops.length) {
      fetchFavouriteWorkshops();
    }
  }

  getWorkshopAnnouncements = workshops => {
    const announcements = [];
    workshops.forEach(workshop => {
      workshop.recent_announcements.forEach(announcement => {
        announcements.push({
          ...announcement,
          workshop: Object.assign({}, workshop, { recent_announcements: null }),
        });
      });
    });
    return announcements;
  };

  render() {
    const { workshopRequest, type, t } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'header')}>
              <img src='/img/icon_11.svg' alt='Korjaamosi tiedotteet' style={getStyle(screenSize, styles, 'infoIcon')} />
              <p style={getStyle(screenSize, styles, 'headerText')}>
                { t('ownWorkshopAnnouncementHeading') }
              </p>
            </div>
            <div
              style={
                type === 'grid' ? getStyle(screenSize, styles, 'notificationsContainerGrid') : getStyle(screenSize, styles, 'notificationsContainer')
              }
            >
              {(workshopRequest.loading) && (
                <CBSpinnerFloating />
              )}
              {!workshopRequest.loading && workshopRequest.error && (
              <p>
                {workshopRequest.error}
              </p>
              )}
              {!workshopRequest.loading
                && !workshopRequest.error
                && workshopRequest.favouriteWorkshops.length > 0
                && this.getWorkshopAnnouncements(workshopRequest.favouriteWorkshops).map(announcement => (
                  <WorkshopNotificationsListItem key={announcement.id} announcement={announcement} />
                ))}
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    notificationsContainer: {
      display: 'flex',
      flexFlow: 'column',
    },
    notificationsContainerGrid: {
      display: 'flex',
      flexFlow: 'row wrap',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: 900,
      marginBottom: 32,
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: 110,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: 'url("/img/blue_lines-f30477-transparent.png")',
      backgroundPosition: 'center center',
    },
    infoIcon: {
      height: 32,
      width: 32,
    },
    headerText: {
      fontSize: 22,
      fontWeight: 200,
    },
  },
  medium: {
    container: {
      maxWidth: 'none',
    },
  },
};

function mapStateToProps(state) {
  return {
    workshopRequest: state.workshop,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchFavouriteWorkshops: _fetchFavouriteWorkshops,
  },
)(translate()(WorkshopNotificationsList));
