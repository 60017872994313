import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import CBMediaQuery from '../shared/CBMediaQuery';
import { getStyle } from '../../utils/utils';

class MarketingLayoutView extends React.Component {
  componentDidMount = () => {
  };

  render() {
    const {
      history, t
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <p>
              Marketing
            </p>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
};

function mapStateToProps(state) {
  return {
  };
}

export default connect(
  mapStateToProps,
  {
  },
)(translate('MarketingLayoutView')(MarketingLayoutView));
