import React from 'react';
import VehicleDetailsTopInfo from './VehicleDetailsTopInfo';
import VehicleDetailsReminders from '../Reminders/VehicleDetailsReminders';
import VehicleDetailsTopImage from './VehicleDetailsTopImage';
import CBMediaQuery from '../../../shared/CBMediaQuery';
import { getStyle } from '../../../../utils/utils';
import BackButton from '../../../shared/BackButton';
import CBButtonWithIconSmall from '../../../shared/CBButtonWithIconSmall';

class VehicleDetailsTop extends React.Component {
  render() {
    const {
      vehicle,
      onImageUpload,
      isUploadingImage,
      user,
      navigateBack,
      showNavigateBack,
    } = this.props;
    if (vehicle) {
      return (
        <React.Fragment>
          <CBMediaQuery>
            {screenSize => (
              <React.Fragment>
                {
                  showNavigateBack && (
                    <div style={getStyle(screenSize, styles, 'settingsBar')}>
                      <CBButtonWithIconSmall
                        text='Takaisin'
                        icon='/img/icon_arrow_left_2.svg'
                        onClick={() => navigateBack()}
                      />
                    </div>
                  )
                }
                <div style={getStyle(screenSize, styles, 'topContainer')}>
                  <VehicleDetailsTopImage
                    isUploadingImage={isUploadingImage}
                    onImageUpload={onImageUpload}
                    vehicle={vehicle}
                  />
                  <VehicleDetailsTopInfo
                    screenSize={screenSize}
                    vehicle={vehicle}
                    user={user}
                  />
                  {screenSize === 'large' && (
                    <VehicleDetailsReminders vehicle={vehicle} />
                  )}
                </div>
              </React.Fragment>
            )}
          </CBMediaQuery>
        </React.Fragment>
      );
    } else {
      return (
        <div style={styles.topContainer}>
          <div style={styles.imageContainer} />
          <div style={styles.infoContainer} />
          <div style={styles.reminderContainer} />
        </div>
      );
    }
  }
}

const styles = {
  default: {
    smallInfoText: {
      color: '#FFF',
      fontSize: 12,
    },
    mainLabel: {
      fontSize: 32,
      color: '#ffffff',
      fontWeight: 300,
    },
    subLabel: {
      fontSize: 20,
      color: '#ffffff',
      fontWeight: 200,
    },
    topContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: 'auto',
      backgroundImage: 'linear-gradient(0deg, #0095da 3%, #0069ab 100% )',
      backgroundPosition: 'center center',
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    infoTopContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    settingsBar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: 50,
    },
  },
  medium: {
    settingsBar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: 50,
      paddingLeft: 8,
    },
  },
  small: {
    topContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundImage: 'linear-gradient(0deg, #0095da 3%, #0069ab 100% )',
      backgroundPosition: 'center center',
      height: 'auto',
    },
  },
};

export default VehicleDetailsTop;
