import React from 'react';

class WorkshopListItem extends React.Component {
  getIconUrl = () => {
    const {
      editMode, isFavourite
    } = this.props;
    if (editMode) {
      return '/img/icon_edit.svg';
    }
    else {
      return isFavourite ? '/img/round_x_icon.svg' : '/img/round_plus_icon.svg';
    }
  }

  render() {
    const {
      label, value, isFavourite, id, buttonAction,
    } = this.props;
    return (
      <div className={id} style={styles.workshopContainer}>
        <div style={styles.workshopTextContainer}>
          <p className='font-titillium-web-semi-bold' style={styles.infoLabel}>
            {label}
          </p>
          <p className='font-titillium-web-light' style={styles.infoValue}>
            {value}
          </p>
        </div>
        <div style={styles.workshopControlsContainer}>
          <img
            alt='Icon'
            style={styles.icon}
            onClick={() => buttonAction(id)}
            src={this.getIconUrl()}
          />
        </div>
      </div>
    );
  }
}

const styles = {
  workshopContainer: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  infoLabel: {
    color: '#FFF',
    fontSize: 14,
    fontWeight: 600,
  },
  infoValue: {
    color: '#FFF',
    fontSize: 14,
    fontWeight: 200,
  },
  workshopControlsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    cursor: 'pointer',
    width: 30,
    height: 30,
  },
};

export default WorkshopListItem;
