import React, { Component } from 'react';
import Modal from 'react-modal';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../shared/CBMediaQuery';
import { getStyle, getModalStyle, SERVICE_WORKSHOP } from '../../utils/utils';
import CBButtonSubmit from '../shared/CBButtonSubmit';
import CBInputField from '../shared/form_components/CBInputField';
import {
  fetchWSVehicle as _fetchWSVehicle,
  createWSVehicle as _createWSVehicle,
  updateWSVehicle as _updateWSVehicle,
} from '../../actions/vehicleActions';
import { getLengthValidator, getEmailValidator, getPhoneValidator } from '../../utils/validators';
import { browserIsIE } from '../../ie-support';

class AddEditVehicleModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
      },
      formValid: {
        registration_number: false,
      },
    };
  }

  getCsrfToken = () => {
    const name = 'csrftoken=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  componentDidMount = () => {
    const { service, selectedWorkshopId, vehicle, wsVehicle } = this.props;
    let initialFormData;
    let initialFormValid;
    if (vehicle) {
      initialFormData = {
        registration_number: vehicle.registration_number,
      };
      initialFormValid = {
        registration_number: !!initialFormData.registration_number,
      };
    } else {
      initialFormData = {};
      initialFormValid = {
        registration_number: false,
      };
    }
    this.setState({
      formData: {
        workshop_id: selectedWorkshopId,
        ...initialFormData,
      },
      formValid: initialFormValid,
    });
  };

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
      [name]: isValid,
    };

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const { target, value } = changedObject;
    let newValue = value;
    if (target === 'registration_number') {
      newValue = newValue.toUpperCase();
      const regex = /[^A-Z-ÅÄÖ0-9]/g;
      newValue = newValue.replace(regex, '');
    }
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[target] = newValue;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const { formData } = this.state;
    const {
      closeModal,
      createWSVehicle,
      updateWSVehicle,
      vehicle,
      selectedWorkshopId,
    } = this.props;
    const newFormData = Object.assign({}, formData, { workshop_id: selectedWorkshopId });

    if (vehicle) {
      updateWSVehicle(newFormData, vehicle.id);
    } else {
      createWSVehicle(newFormData);
    }

    closeModal();
  };


  render() {
    const { formData } = this.state;
    const {
      modalIsOpen, closeModal, afterOpenModal, t, vehicle, selectedWorkshopId
    } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={getModalStyle(screenSize, styles.modal)}
            contentLabel='Example Modal'
          >
            <div style={browserIsIE() ? getStyle(screenSize, styles, 'containerIE') : getStyle(screenSize, styles, 'container')}>
              <img
                src='/img/icon_close.svg'
                alt='close'
                style={getStyle(screenSize, styles, 'closeIcon')}
                onClick={closeModal}
              />
              <p style={getStyle(screenSize, styles, 'title')}>
                {!vehicle ? t('addVehicleInfoHeading') : t('editVehicleInfoHeading')}
              </p>
              <CBInputField
                name='registration_number'
                inputStyle={browserIsIE() ? getStyle(screenSize, styles, 'inputIE') : getStyle(screenSize, styles, 'input')}
                value={formData.registration_number}
                placeholder='ABC-123'
                onChange={this.updateFormState}
                validators={[getLengthValidator(4, 7)]}
                isValidCallback={this.setIsValid}
                labelText={t('addCarModalRegNumberHeading')}
                labelTextStyle={getStyle(screenSize, styles, 'subTitle')}
                disabled={!!vehicle}
              />
              <span style={getStyle(screenSize, styles, 'divider')} />
              <p style={getStyle(screenSize, styles, 'info')}>
                Ajoneuvon lisääminen maksaa 0,85€ (alv 0%). Ajoneuvon lisäämisen yhteydessä tehdään aina myös Traficom haku.
              </p>

              <span style={getStyle(screenSize, styles, 'spacer')} />
              <div style={getStyle(screenSize, styles, 'buttonContainer')}>
                <div style={getStyle(screenSize, styles, 'cancelButtonContainer')}>
                  <CBButtonSubmit
                    disabled={false}
                    onClick={() => {
                      closeModal();
                    }}
                    text={'Peruuta'}
                    style={getStyle(screenSize, styles, 'submitButton')}
                  />
                </div>
                <div style={getStyle(screenSize, styles, 'submitButtonContainer')}>
                  <form action='/api/1/payments/stripe-create-checkout-session/' method='POST'>
                    {/* Add a hidden field with the lookup_key of your Price */}
                    <input type='hidden' name='lookup_key' value={'add_workshop_vehicle'} />
                    <input type='hidden' name='registration_number' value={formData.registration_number} />
                    <input type='hidden' name='workshop_id' value={selectedWorkshopId} />
                    <input
                      type='hidden'
                      value={this.getCsrfToken()}
                      name='csrfmiddlewaretoken'
                    />
                    <button
                      className='hover-green-border hover-green-text'
                      id='checkout-button'
                      type='submit'
                      disabled={!this.getIsValid()}
                    >
                      Siirry maksamaan
                    </button>

                  </form>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    closeIcon: {
      position: 'absolute',
      top: 8,
      right: 8,
      opacity: 0.2,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },
    containerIE: {
      display: 'unset',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },
    submitButtonContainer: {
      width: 200,
      marginTop: 32,
    },
    cancelButtonContainer: {
      width: 200,
      marginTop: 32,
    },
    buttonContainer: {
      width: '80%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    info: {
      width: '100%',
      fontSize: 14,
      color: 'black',
      paddingTop: 16,
      paddingHorizontal: 30,
      textAlign: 'center',
    },
    submitButton: {
      height: 50,
      width: '100%',
      border: '2px solid #0095da',
      color: '#0095da',
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },

    title: {
      fontSize: 32,
      color: '#0095da',
      textAlign: 'center',
    },
    subTitle: {
      fontSize: 14,
      color: 'black',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    icon: {
      width: 540,
      height: 1,
      opacity: .3,
      // marginTop: 25,
      marginBottom: 14,
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: '90%',
      border: '0',
      borderRadius: 100,
      height: 40,
      outlineStyle: 'none',
    },
    inputIE: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: '90%',
      border: '0',
      borderRadius: 100,
      outlineStyle: 'none',
      padding: 8,
    },
    info: {
      width: '100%',
      fontSize: 14,
      color: 'black',
      paddingHorizontal: 30,
      textAlign: 'center',
    },
    divider: {
      height: 1,
      width: '90%',
      borderBottom: '1px solid #666',
      opacity: 0.3,
      marginTop: 15,
      marginBottom: 15,
    },
    spacer: {
      marginBottom: 30,
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 200,
      resize: 'none',
      color: 'black',
      height: 30,
    },
  },
  modal: {
    default: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        maxHeight: '95vh',
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    small: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
};

function mapStateToProps(state) {
  return {
    vehiclePermissionRequest: state.vehiclePermission,
    user: state.authUser.user,
    wsVehicle: state.vehicle.wsVehicle,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    service: state.app.service,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchWSVehicle: _fetchWSVehicle,
    createWSVehicle: _createWSVehicle,
    updateWSVehicle: _updateWSVehicle,
  },
)(translate('WSHomeView')(AddEditVehicleModal));
