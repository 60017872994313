import {
  API, GET, POST, PUT, DELETE,
} from '../middleware/api';
import { API_URL } from '../config';

export function fetchMessages(params, workshopId = undefined, organizationId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const newParams = {
    ...params,
  };

  if (workshopId) {
    newParams.workshop_id = workshopId;
  }

  if (organizationId) {
    newParams.organization_id = organizationId;
  }

  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/${prefix}vehicle_message/`,
      types: [
        'FETCH_MESSAGES_REQUEST',
        'FETCH_MESSAGES_SUCCESS',
        'FETCH_MESSAGES_FAIL',
      ],
      params: newParams,
    },
  };
}

export function createMessage(data, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  const urlExtenstion = data.vehicle_ids && data.vehicle_ids.length ? 'create_batch/' : '';

  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/${prefix}vehicle_message/${urlExtenstion}${suffix}`,
      types: [
        'CREATE_MESSAGE_REQUEST',
        'CREATE_MESSAGE_SUCCESS',
        'CREATE_MESSAGE_FAIL',
      ],
      data,
    },
  };
}

export function createMessageResponse(data, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';

  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/${prefix}vehicle_message_response/${suffix}`,
      types: [
        'CREATE_MESSAGE_RESPONSE_REQUEST',
        'CREATE_MESSAGE_RESPONSE_SUCCESS',
        'CREATE_MESSAGE_RESPONSE_FAIL',
      ],
      data,
    },
  };
}

export function updateMessage(messageId, data, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/${prefix}vehicle_message/${messageId}/${suffix}`,
      types: [
        'UPDATE_MESSAGE_REQUEST',
        'UPDATE_MESSAGE_SUCCESS',
        'UPDATE_MESSAGE_FAIL',
      ],
      data,
    },
  };
}

export function deleteMessage(messageId, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/${prefix}vehicle_message/${messageId}/${suffix}`,
      types: [
        'DELETE_MESSAGE_REQUEST',
        'DELETE_MESSAGE_SUCCESS',
        'DELETE_MESSAGE_FAIL',
      ],
    },
  };
}
