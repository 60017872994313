import { workshopState } from '../initialState';
import { storeInsertItem, storeRemoveItem, storeUpdateObjectInArray } from '../utils/utils';

export default function (state = workshopState, action) {
  switch (action.type) {
    case 'FETCH_WORKSHOPS_REQUEST': {
      return {
        ...state,
        workshopListStatus: Object.assign({}, state.workshopListStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_WORKSHOPS_SUCCESS': {
      return {
        ...state,
        workshops: action.payload,
        workshopListStatus: Object.assign({}, state.workshopListStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_WORKSHOPS_FAIL': {
      return {
        ...state,
        workshopListStatus: Object.assign({}, state.workshopListStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'FETCH_FAVOURITE_WORKSHOPS_REQUEST': {
      return {
        ...state,
        favouriteStatus: Object.assign({}, state.favouriteStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_FAVOURITE_WORKSHOPS_SUCCESS': {
      return {
        ...state,
        favouriteWorkshops: action.payload,
        favouriteStatus: Object.assign({}, state.favouriteStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_FAVOURITE_WORKSHOPS_FAIL': {
      return {
        ...state,
        favouriteStatus: Object.assign({}, state.favouriteStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'ADD_FAVOURITE_WORKSHOP_REQUEST': {
      return {
        ...state,
        favouriteStatus: Object.assign({}, state.favouriteStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'ADD_FAVOURITE_WORKSHOP_SUCCESS': {
      return {
        ...state,
        favouriteWorkshops: storeInsertItem(state.favouriteWorkshops, action.payload),
        favouriteStatus: Object.assign({}, state.favouriteStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'ADD_FAVOURITE_WORKSHOP_FAIL': {
      return {
        ...state,
        favouriteStatus: Object.assign({}, state.favouriteStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'DELETE_FAVOURITE_WORKSHOP_REQUEST': {
      return {
        ...state,
        favouriteStatus: Object.assign({}, state.favouriteStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DELETE_FAVOURITE_WORKSHOP_SUCCESS': {
      return {
        ...state,
        favouriteWorkshops: storeRemoveItem(state.favouriteWorkshops, action.payload.id),
        favouriteStatus: Object.assign({}, state.favouriteStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DELETE_FAVOURITE_WORKSHOP_FAIL': {
      return {
        ...state,
        favouriteStatus: Object.assign({}, state.favouriteStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_WORKSHOP_REQUEST': {
      return {
        ...state,
        workshopStatus: Object.assign({}, state.workshopStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_WORKSHOP_SUCCESS': {
      return {
        ...state,
        workshops: storeInsertItem(state.workshops, action.payload),
        workshopStatus: Object.assign({}, state.workshopStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_WORKSHOP_FAIL': {
      return {
        ...state,
        workshopStatus: Object.assign({}, state.workshopStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_WORKSHOP_REQUEST': {
      return {
        ...state,
        workshopStatus: Object.assign({}, state.workshopStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_WORKSHOP_SUCCESS': {
      return {
        ...state,
        workshops: storeUpdateObjectInArray(state.workshops, action.payload),
        workshopStatus: Object.assign({}, state.workshopStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_WORKSHOP_FAIL': {
      return {
        ...state,
        workshopStatus: Object.assign({}, state.workshopStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_SMS_PACKAGE_REQUEST': {
      return {
        ...state,
        smsPackageStatus: Object.assign({}, state.smsPackageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_SMS_PACKAGE_SUCCESS': {
      return {
        ...state,
        smsAvailable: action.payload.available,
        smsMax: action.payload.max,
        smsPackageStatus: Object.assign({}, state.smsPackageStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_SMS_PACKAGE_FAIL': {
      return {
        ...state,
        smsPackageStatus: Object.assign({}, state.smsPackageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'FETCH_SMS_COUNTS_REQUEST': {
      return {
        ...state,
        smsCountsStatus: Object.assign({}, state.smsCountsStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_SMS_COUNTS_SUCCESS': {
      return {
        ...state,
        smsAvailable: action.payload.available,
        smsMax: action.payload.max,
        smsCountsStatus: Object.assign({}, state.smsCountsStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_SMS_COUNTS_FAIL': {
      return {
        ...state,
        smsCountsStatus: Object.assign({}, state.smsCountsStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_WORKSHOP_PERMISSION_REQUEST': {
      return {
        ...state,
        workshopStatus: Object.assign({}, state.workshopStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_WORKSHOP_PERMISSION_SUCCESS': {
      const workshopId = action.payload.workshop;
      const workshop = state.workshops.find((x) => x.id === workshopId);
      const newWorkshop = { ...workshop };
      newWorkshop.permissions = storeUpdateObjectInArray(newWorkshop.permissions, action.payload);
      return {
        ...state,
        workshops: storeUpdateObjectInArray(state.workshops, newWorkshop),
        workshopStatus: Object.assign({}, state.workshopStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_WORKSHOP_PERMISSION_FAIL': {
      return {
        ...state,
        workshopStatus: Object.assign({}, state.workshopStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'DELETE_WORKSHOP_PERMISSION_REQUEST': {
      return {
        ...state,
        workshopStatus: Object.assign({}, state.workshopStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DELETE_WORKSHOP_PERMISSION_SUCCESS': {
      const workshopId = action.additionalData.workshopId;
      const workshop = state.workshops.find((x) => x.id === workshopId);
      const newWorkshop = { ...workshop };

      newWorkshop.permissions = storeRemoveItem(newWorkshop.permissions, action.additionalData.permissionId);
      return {
        ...state,
        workshops: storeUpdateObjectInArray(state.workshops, newWorkshop),
        workshopStatus: Object.assign({}, state.workshopStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DELETE_WORKSHOP_PERMISSION_FAIL': {
      return {
        ...state,
        workshopStatus: Object.assign({}, state.workshopStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_WORKSHOP_PERMISSION_REQUEST': {
      return {
        ...state,
        workshopStatus: Object.assign({}, state.workshopStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_WORKSHOP_PERMISSION_SUCCESS': {
      const workshopId = action.payload.workshop;
      const workshop = state.workshops.find((x) => x.id === workshopId);
      const newWorkshop = { ...workshop };
      newWorkshop.permissions = storeInsertItem(newWorkshop.permissions, action.payload);
      return {
        ...state,
        workshops: storeUpdateObjectInArray(state.workshops, newWorkshop),
        workshopStatus: Object.assign({}, state.workshopStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_WORKSHOP_PERMISSION_FAIL': {
      return {
        ...state,
        workshopStatus: Object.assign({}, state.workshopStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'TOGGLE_WORKSHOP_MESSAGE_SUBSCRIPTION_REQUEST': {
      return {
        ...state,
        workshopStatus: Object.assign({}, state.workshopStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'TOGGLE_WORKSHOP_MESSAGE_SUBSCRIPTION_SUCCESS': {
      return {
        ...state,
        workshops: storeUpdateObjectInArray(state.workshops, action.payload),
        workshopStatus: Object.assign({}, state.workshopStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'TOGGLE_WORKSHOP_MESSAGE_SUBSCRIPTION_FAIL': {
      return {
        ...state,
        workshopStatus: Object.assign({}, state.workshopStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'SELECT_WORKSHOP': {
      return {
        ...state,
        selectedWorkshop: action.payload,
      };
    }
    default:
      break;
  }
  return state;
}
