import React from 'react';

class CBButtonSettingsWhite extends React.Component {
  render() {
    const { onClick, text } = this.props;
    return (
      <div
        className='hover-green-border hover-green-text'
        style={
          styles.largeButton
        }
        onClick={onClick}
      >
        <p style={styles.buttonText}>
          {text}
        </p>
      </div>
    );
  }
}

export default CBButtonSettingsWhite;

const styles = {
  buttonText: {
    fontSize: 14,
    fontWeight: 700,
    textDecoration: 'none solid rgb(0, 246, 0)',
    color: '#FFFFFF',
    padding: 16,
    marginBottom: 0,
  },
  largeButton: {
    height: 50,
    width: '50%',
    border: '2px solid #FFFFFF',
    color: '#FFFFFF',
    borderRadius: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
};
