import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../../shared/CBMediaQuery';
import '../../../App.css';

import { getStyle } from '../../../utils/utils';
import CBInputField from '../../shared/form_components/CBInputField';
import CBTextAreaField from '../../shared/form_components/CBTextAreaField';

import {
  createCareService as _createCareService,
} from '../../../actions/careServiceActions';
import {
  getEmailValidator,
  getLengthValidator,
} from '../../../utils/validators';
import CBModal from '../../shared/CBModal';

import {
  acceptCareServiceInvitation as _acceptCareServiceInvitation,
  declineCareServiceInvitation as _declineCareServiceInvitation,
} from '../../../actions/authUserActions';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';

class CareServiceResponseModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        organization_name: props.invitation.organization_name || '',
        terms: '',
      },
      formValid: {
      },
      acceptTerms: false
    };
  }

  componentDidMount = () => {
    const { formData } = this.state;
    const newState = {
      formData: {
        ...formData,
      },
    };

    this.setState(newState);
  };

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
      [name]: isValid,
    };

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const { formData } = this.state;
    const {
      closeModal,
      acceptCareServiceInvitation,
      invitation,
    } = this.props;

    acceptCareServiceInvitation(invitation.id, formData);

    closeModal();
  };

  closeModal = () => {
    const {
      closeModal,
      declineCareServiceInvitation,
      invitation,
    } = this.props;

    declineCareServiceInvitation(invitation.id);

    closeModal();
  };

  getDescriptionText = () => {
    const {
      t,
      invitation,
    } = this.props;

    let description = t('invitationResponseDescription', {
      organization_name: invitation.organization_name,
      workshop_name: invitation.workshop_name,
      workshop_business_id: invitation.workshop_business_id,
      business_id: invitation.business_id,

    });

    if (!invitation.organization) {
      description += '\n\n';
      description += t('invitationResponseDescriptionOrgNew', {
        business_id: invitation.business_id,
      });
    }

    return description;
  }

  render() {
    const { formData, acceptTerms } = this.state;
    const {
      modalIsOpen,
      afterOpenModal,
      t,
      invitation,
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <CBModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={this.closeModal}
            title='Liity huolenpitopalveluun'
            descriptionText={this.getDescriptionText()}
            cancelText='Hylkää'
            submitText='Hyväksy'
            submitForm={this.submitForm}
            isValid={this.getIsValid() && acceptTerms}
          >
            <div style={getStyle(screenSize, styles, 'bodyContainer')}>
              {
                !invitation.organization && (
                  <CBInputField
                    name='organization_name'
                    inputStyle={getStyle(screenSize, styles, 'input')}
                    value={formData.organization_name}
                    placeholder=''
                    onChange={this.updateFormState}
                    validators={[getLengthValidator(1, 40)]}
                    isValidCallback={this.setIsValid}
                    labelText={t('invitationOrganizationNameLabel')}
                    labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                  />
                )
              }

              <CBTextAreaField
                name='terms'
                value={formData.text}
                onChange={this.updateFormState}
                labelText={t('invitationTermsLabel')}
                labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                validators={[getLengthValidator(1, 5000)]}
                isValidCallback={this.setIsValid}
                placeholder={t('invitationTermsPlaceholder')}
                validateOnInput
              />

              <CBLabelAndCheckbox
                color='blue'
                checked={acceptTerms}
                labelText={t('acceptCareServiceTerms', { business_id: invitation.business_id })}
                onChange={() => this.setState({acceptTerms: !acceptTerms})}
                additionalStyles={{ marginBottom: 16 }}
              />
            </div>
          </CBModal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 32px'
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      width: '100%',
    },
    inputLabelText: {
      color: 'black',
    },
  },
  small: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 16px'
    }
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
  };
}

export default connect(
  mapStateToProps,
  {
    acceptCareServiceInvitation: _acceptCareServiceInvitation,
    declineCareServiceInvitation: _declineCareServiceInvitation,
  },
)(translate('CareService')(CareServiceResponseModal));
