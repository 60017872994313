import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { getStyle } from '../../../../utils/utils';
import CBMediaQuery from '../../../shared/CBMediaQuery';
import AddTreatmentModal from '../AddTreatmentModal';
import CBButtonLargeGreen from '../../../shared/CBButtonLargeGreen';

class AddTreatmentButton extends Component {
  state = {
    modalIsOpen: false,
  };

  addTreatment = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  afterOpenModal = () => {};

  render() {
    const { t, vehicle } = this.props;
    const { modalIsOpen } = this.state;

    return (
      <CBMediaQuery>
        {screenSize => (
          <React.Fragment>
            {screenSize !== 'small' && (
            <div
              style={getStyle(screenSize, styles, 'container')}
              onClick={this.addTreatment}
            >
              <img src='/img/icon_15.svg' alt='Add' style={getStyle(screenSize, styles, 'icon')} />
              <p style={getStyle(screenSize, styles, 'label')}>
                {t('addTreatmentLabel')}
              </p>
            </div>
            )}
            {screenSize === 'small' && (
              <CBButtonLargeGreen
                style={getStyle(screenSize, styles, 'addTreatmentButton')}
                onClick={this.addTreatment}
                label={t('addTreatmentLabel')}
              />
            )}
            {
              modalIsOpen && (
                <AddTreatmentModal
                  vehicle={vehicle}
                  modalIsOpen={modalIsOpen}
                  closeModal={this.closeModal}
                  afterOpenModal={this.afterOpenModal}
                />
              )
            }
          </React.Fragment>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      height: 52,
      alignItems: 'center',
      backgroundColor: 'rgba(0, 246, 0, 0.3)',
      border: 'solid 2px rgba(0, 246, 0, 1)',
      cursor: 'pointer',
    },
    label: {
      fontSize: 14,
      color: '#00f600',
      marginLeft: 10,
      marginBottom: 0,
    },
    icon: {
      height: 32,
      width: 32,
    },
  },
  small: {
    addTreatmentButton: {
      display: 'flex',
      height: 50,
      borderRadius: 25,
      marginLeft: 16,
      marginRight: 16,
      backgroundColor: 'rgb(0, 246, 0)',
      fontFamily: 'Titillium Web',
      fontSize: 18,
      color: 'rgb(255, 255, 255)',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      marginTop: 50,
    },
  },
};

export default translate('VehicleDetailsView')(AddTreatmentButton);
