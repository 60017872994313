import React from 'react';
import CBMediaQuery from '../../../shared/CBMediaQuery';
import { getStyle } from '../../../../utils/utils';

class InfoboxWithIcon extends React.Component {
  render() {
    const {
      text, labelText, icon, hasBorders,
    } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div
            style={getStyle(
              screenSize,
              styles,
              hasBorders ? 'infoboxContainerWithBorders' : 'infoboxContainer',
            )}
          >
            <img alt='Icon' style={getStyle(screenSize, styles, 'infoboxIcon')} src={icon} />
            <div style={getStyle(screenSize, styles, 'infoboxTextContainer')}>
              <p
                className='font-titillium-web-semi-bold'
                style={getStyle(screenSize, styles, 'infoText')}
              >
                {text}
              </p>
              <p
                className='font-titillium-web-extra-light'
                style={getStyle(screenSize, styles, 'labelText')}
              >
                {labelText}
              </p>
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    infoboxContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      padding: 16,
      borderBottom: '1px solid rgba(255, 255, 255, .4)',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
    },
    infoboxTextContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    infoboxContainerWithBorders: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      padding: 16,
      borderRight: '1px solid rgba(255, 255, 255, .4)',
      borderLeft: '1px solid rgba(255, 255, 255, .4)',
      borderBottom: '1px solid rgba(255, 255, 255, .4)',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
    },
    infoboxIcon: {
      width: 64,
      height: 64,
      marginBottom: 16,
    },
    infoText: {
      fontSize: 24,
      color: '#FFF',
      textAlign: 'center',
    },
    labelText: {
      fontSize: 16,
      color: '#FFF',
      textAlign: 'center',
    },
  },
  medium: {
    infoboxContainer: {
      width: '33%',
    },
    infoboxContainerWithBorders: {
      width: '33%',
    },
    infoText: {
      fontSize: 20,
      textAlign: 'center',
    },
    labelText: {
      fontSize: 14,
      marginBottom: 30,
      textAlign: 'center',
    },
    infoboxIcon: {
      marginBottom: 15,
      marginTop: 41,
    },
  },
  small: {
    infoboxContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'normal',
      flexDirection: 'row',
      width: '100%',
      padding: 10,
      borderBottom: '1px solid rgba(255, 255, 255, .4)',
    },
    infoboxContainerWithBorders: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'normal',
      flexDirection: 'row',
      width: '100%',
      padding: 10,
      borderRight: '1px solid rgba(255, 255, 255, .4)',
      borderLeft: '1px solid rgba(255, 255, 255, .4)',
      borderBottom: '1px solid rgba(255, 255, 255, .4)',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
      marginTop: 0.2,
    },
    infoboxTextContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'normal',
      flexDirection: 'column',
      marginLeft: 10,
    },
    infoText: {
      fontSize: 18,
      color: '#FFF',
      textAlign: 'left',
      marginBottom: 0,
    },
    labelText: {
      fontSize: 14,
      color: '#FFF',
      marginBottom: 0,
      textAlign: 'left',
    },
    infoboxIcon: {
      width: 32,
      height: 32,
      marginBottom: 'auto',
      marginTop: 'auto',
    },
  },
};

export default InfoboxWithIcon;
