import React from 'react';
import { translate } from 'react-i18next';
import { Button } from 'primereact/button';
import { getStyle, SERVICE_ADMIN, SERVICE_FLEET, SERVICE_WORKSHOP, storeUpdateObjectInArray } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBInputField from '../../shared/form_components/CBInputField';
import CBSelectField from '../../shared/form_components/CBSelectField';
import { getEmailValidator } from '../../../utils/validators';
import CBButtonWithIconSmall from '../../shared/CBButtonWithIconSmall';
import CBPromptModal from '../../shared/CBPromptModal';

class OrganizationVehicleReminderRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: Object.assign({}, props.reminder),
    };
  }

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  updateItem = (option) => {
    const {
      service,
      selectedOrganizationId,
      selectedWorkshopId,
      createOrganizationVehicleReminder,
      updateOrganizationVehicleReminder,
      reminder,
    } = this.props;

    const { formData } = this.state;

    const isDefault = reminder.owner_type === 'default';
    const isWorkshopOrFleet = service === SERVICE_FLEET || service === SERVICE_WORKSHOP;

    if (isDefault && isWorkshopOrFleet) {
      const data = { ...formData };

      switch (service) {
        case SERVICE_FLEET:
          data.organization = selectedOrganizationId;
          data.owner_type = 'organization';
          break;
        case SERVICE_WORKSHOP:
          data.workshop = selectedWorkshopId;
          data.owner_type = 'workshop';
          break;
        default:
          break;
      }

      createOrganizationVehicleReminder(data);
    } else {
      const data = { ...formData };
      const params = {};
      if (selectedWorkshopId) {
        params.workshop_id = selectedWorkshopId;
      } else if (selectedOrganizationId) {
        params.organization_id = selectedOrganizationId;
      }

      updateOrganizationVehicleReminder(formData.id, data, params);
    }
  };

  isChanged = () => {
    const { reminder } = this.props;
    const { formData } = this.state;
    return reminder.days_before_task_creation != formData.days_before_task_creation
      || reminder.days_before_notification != formData.days_before_notification;
  };

  isValid = () => {
    const { formData } = this.state;
    return formData.days_before_task_creation != null && formData.days_before_notification != null;
  };

  getReminderLabel = (reminderType) => {
    const { t } = this.props;

    switch (reminderType) {
      case 'inspection':
        return t('labelInspection');
      default:
        return '';
    }
  };

  render() {
    const { formData } = this.state;

    const { isNew, t, isStaff, checkList, service } = this.props;

    const canEdit = !checkList || checkList.can_edit;

    return (
      <CBMediaQuery>
        {screenSize => (
          <React.Fragment>
            <tr style={getStyle(screenSize, styles, 'row')}>
              <td style={getStyle(screenSize, styles, 'tableTd')}>
                <p>
                  {this.getReminderLabel(formData.reminder_type)}
                </p>
              </td>
              <td style={getStyle(screenSize, styles, 'tableTdSmall')}>
                <CBInputField
                  name='days_before_task_creation'
                  value={formData.days_before_task_creation}
                  onChange={this.updateFormState}
                  noValidationOnChange
                  blurOnEnter
                  type='number'
                  disabled={!canEdit}
                />
              </td>
              <td style={getStyle(screenSize, styles, 'tableTdSmall')}>
                <CBInputField
                  name='days_before_notification'
                  value={formData.days_before_notification}
                  onChange={this.updateFormState}
                  noValidationOnChange
                  blurOnEnter
                  type='number'
                  disabled={!canEdit}
                />
              </td>
              <td style={getStyle(screenSize, styles, 'tableTdDelete')}>
                <div style={getStyle(screenSize, styles, 'actionsContainer')}>
                  {
                    !isNew && (
                      <CBButtonWithIconSmall
                        text={t('updateCheckListItem')}
                        onClick={this.updateItem}
                        icon='/img/icon_save.svg'
                        disabled={!this.isValid() || !this.isChanged()}
                      />
                    )
                  }
                </div>
              </td>
            </tr>
          </React.Fragment>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
    },
    actionsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    tableTd: {
      border: '1px solid rgba(255, 255, 255, .3)',
      color: '#FFFFFF',
      paddingLeft: 8,
      paddingRight: 8,
    },
    tableTdSmall: {
      border: '1px solid rgba(255, 255, 255, .3)',
      color: '#FFFFFF',
      paddingLeft: 8,
      paddingRight: 8,
    },
    tableTdDelete: {
      paddingLeft: 8,
      paddingRight: 8,
    },
    optionStyle: {
      backgroundColor: 'rgb(0, 149, 218)',
    },
    row: {
      marginBottom: 8,
    },
  },
};

export default translate('CheckLists')(OrganizationVehicleReminderRow);
