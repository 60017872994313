import { taskState } from '../initialState';
import { storeInsertItem, storeRemoveItem, storeUpdateObjectInArray } from '../utils/utils';

export default function (state = taskState, action) {
  switch (action.type) {
    case 'FETCH_TASKS_REQUEST': {
      return {
        ...state,
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: true,
          loadingFetch: true,
          error: null,
        }),
      };
    }
    case 'FETCH_TASKS_SUCCESS': {
      return {
        ...state,
        tasks: action.payload,
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_TASKS_FAIL': {
      return {
        ...state,
        tasks: [],
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_TASK_REQUEST': {
      return {
        ...state,
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_TASK_SUCCESS': {
      return {
        ...state,
        tasks: storeInsertItem(state.tasks, action.payload),
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_TASK_FAIL': {
      return {
        ...state,
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_TASK_REQUEST': {
      return {
        ...state,
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_TASK_SUCCESS': {
      return {
        ...state,
        tasks: storeUpdateObjectInArray(state.tasks, action.payload),
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_TASK_FAIL': {
      return {
        ...state,
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'DELETE_TASK_REQUEST': {
      return {
        ...state,
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DELETE_TASK_SUCCESS': {
      return {
        ...state,
        tasks: storeRemoveItem(state.tasks, action.payload.id),
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DELETE_TASK_FAIL': {
      return {
        ...state,
        taskStatus: Object.assign({}, state.taskStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    default:
      break;
  }
  return state;
}
