import React, { Component } from 'react';
import { getStyle, styleMerge } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';

class VehicleListTd extends Component {
  render() {
    const {
      additionalStyles,
      textPrimary,
      textSecondary,
      onClick,
      additionalStylesPrimaryText,
      icon,
    } = this.props;

    return (
      <CBMediaQuery>
        {(screenSize) => (
          <td onClick={onClick} style={styleMerge(getStyle(screenSize, styles, 'tableValueTd'), additionalStyles || {})}>
            {
              icon && (
                <div style={getStyle(screenSize, styles, 'iconContainer')}>
                  <img src={icon} />
                </div>
              )
            }
            {
              textPrimary && (
                <div style={styleMerge(getStyle(screenSize, styles, 'textPrimary'), additionalStylesPrimaryText || {})}>
                  {textPrimary}
                </div>
              )
            }
            {
              textSecondary && (
                <div style={getStyle(screenSize, styles, 'textSecondary')}>
                  {textSecondary}&nbsp;
                </div>
              )
            }
          </td>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    tableValueTd: {
      color: '#FFFFFF',
      padding: 16,
      cursor: 'pointer',
      borderBottom: '1px solid rgba(255, 255, 255, .3)',
    },
    textPrimary: {
      fontFamily: 'TitilliumWeb-SemiBold',
      fontSize: 16,

    },
    textSecondary: {
      fontFamily: 'TitilliumWeb-Regular',
      fontSize: 14,
    },
  },
};

export default VehicleListTd;
