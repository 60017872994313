import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import VehicleDetailsLabelAndValue from '../VehicleDetailsLabelAndValue';
import { translate } from 'react-i18next';
import { formatDateTime, styleMerge } from '../../../../utils/utils';

class DetailsTaskListItem extends React.Component {
  getPriorityStyle = (task) => {
    let color = '';
    switch (task.priority) {
      case 'low':
        color = '#4EBFEF';
        break;
      case 'medium':
        color = '#E6CA38';
        break;
      case 'high':
        color = '#FF3B3B';
        break;
      default:
        return {};
    }

    return {
      borderRight: `8px solid ${color}`,
    };
  };

  getContainerStyle = () => {
    const { task, isSelected } = this.props;

    let style = styleMerge(styles.listItemContainer, this.getPriorityStyle(task))

    if (isSelected) {
      style = styleMerge(style, styles.selected);
    }

    return style;

  };

  render() {
    const { task, vehicle } = this.props;

    return (
      <Link to={`/vehicle/${vehicle.id}/tasks/${task.id}`}>
        <div style={this.getContainerStyle()}>
          <div style={styleMerge(styles.innerContainer, task.status === 'done' ? styles.isDone : {})}>
            <VehicleDetailsLabelAndValue
              label={moment(task.created_at).format('D.M.Y')}
              value={task.title}
            />
            <VehicleDetailsLabelAndValue
              alignRight
              label={task.vehicle_registration_number}
              value={task.done_at ? `Tehty ${formatDateTime(task.done_at)}` : ''}
            />
          </div>
        </div>
      </Link>
    );
  }
}

const styles = {
  listItemContainer: {
    padding: 10,
    borderBottom: '1px solid rgba(255,255,255,.3)',
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selected: {
    borderLeft: '4px solid #27bbff',
  },
  isDone: {
    opacity: '60%',
  },
};

export default translate('TaskListView')(DetailsTaskListItem);
