import React from 'react'
import { translate } from "react-i18next";

const WelcomeText = (props) => (
  <div>
    <p className="font-titillium-web-extra-light" style={styles.headerText}>
        {props.t('welcomeToCarbookHeader')}
    </p>
  </div>
)

const styles = {
  headerText: {
    color: '#FFF',
    fontSize: 60,
    textAlign: 'center',
    margin: 0
  }
}

export default translate("HomeView")(WelcomeText)
