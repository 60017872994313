import React from 'react';
import { translate } from 'react-i18next';
import { Button } from 'primereact/button';
import { getStyle, SERVICE_ADMIN, SERVICE_FLEET, SERVICE_WORKSHOP, storeUpdateObjectInArray } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBInputField from '../../shared/form_components/CBInputField';
import CBSelectField from '../../shared/form_components/CBSelectField';
import { getEmailValidator } from '../../../utils/validators';
import CBButtonWithIconSmall from '../../shared/CBButtonWithIconSmall';
import CBPromptModal from '../../shared/CBPromptModal';

class CheckListItemRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: Object.assign({}, props.item),
      deleteModalIsOpen: false,
    };
  }

  resetRow = () => {
    this.setState({
      formData: {
        'name': '',
        'description': '',
        'check_interval_months': null,
        'days_before_task_creation': null,
        'days_before_notification': null,
      }
    });
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  updateItem = (option) => {
    const {
      updateCheckListItem,
      checkList,
      service,
      selectedOrganizationId,
      selectedWorkshopId,
      createCheckList
    } = this.props;

    const { formData } = this.state;

    const isDefaultCheckList = checkList.list_type == 'default';
    const isWorkshopOrFleet = service === SERVICE_FLEET || service === SERVICE_WORKSHOP;

    if (isDefaultCheckList && isWorkshopOrFleet) {
      const newItems = storeUpdateObjectInArray([...checkList.items], formData);

      const data = {
        items: newItems.map((item) => {
          return {
            ...item,
            parent: item.id
          }
        }),
      };

      switch(service) {
        case SERVICE_FLEET:
          data.organization = selectedOrganizationId;
          data.list_type = 'organization';
          break;
        case SERVICE_WORKSHOP:
          data.workshop = selectedWorkshopId;
          data.list_type = 'workshop';
          break;
        default:
          break;
      }

      createCheckList(data);
    } else {
      const data = {...formData, check_list: checkList.id};
      const params = {};
      if (selectedWorkshopId) {
        params.workshop_id = selectedWorkshopId;
      } else if(selectedOrganizationId) {
        params.organization_id = selectedOrganizationId;
      }

      updateCheckListItem(formData.id, data, params);
    }
  };

  deleteItem = () => {
    const {
      deleteCheckListItem,
      checkList,
      selectedOrganizationId,
      selectedWorkshopId,
    } = this.props;
    const { formData } = this.state;

    const params = {};
    if (selectedWorkshopId) {
      params.workshop_id = selectedWorkshopId;
    } else if(selectedOrganizationId) {
      params.organization_id = selectedOrganizationId;
    }

    deleteCheckListItem(formData.id, checkList.id, params);
  };

  isChanged = () => {
    const { item } = this.props;
    const { formData } = this.state;
    return item.name != formData.name
      || item.description != formData.description
      || item.check_interval_months != formData.check_interval_months
      || item.days_before_task_creation != formData.days_before_task_creation
      || item.days_before_notification != formData.days_before_notification
  };

  createItem = () => {
    const {
      createCheckListItem,
      createCheckList,
      checkList,
      service,
      selectedWorkshopId,
      selectedOrganizationId,
    } = this.props;

    const { formData } = this.state;

    if (checkList) {
      const data = {...formData};
      data.check_list = checkList.id;
      createCheckListItem(data);
    } else {
      const data = {
        items: [formData],
      };

      switch(service) {
        case SERVICE_FLEET:
          data.organization = selectedOrganizationId;
          data.list_type = 'organization';
          break;
        case SERVICE_WORKSHOP:
          data.workshop = selectedWorkshopId;
          data.list_type = 'workshop';
          break;
        default:
          data.list_type = 'default';
      }

      createCheckList(data);
    }
    this.resetRow();
  };

  isValid = () => {
    const { formData } = this.state;
    return formData.name && formData.check_interval_months
      && formData.days_before_task_creation != null && formData.days_before_notification != null;
  };

  render() {
    const { formData, deleteModalIsOpen } = this.state;

    const { isNew, t, isStaff, checkList, service } = this.props;

    const canEdit = !checkList || checkList.can_edit;

    return (
      <CBMediaQuery>
        {screenSize => (
          <React.Fragment>
            <tr style={getStyle(screenSize, styles, 'row')}>
              <td style={getStyle(screenSize, styles, 'tableTd')}>
                <CBInputField
                  name='name'
                  className='white-placeholder-input'
                  value={ formData.name }
                  onChange={this.updateFormState}
                  placeholder=' + Uusi tarkastus'
                  noValidationOnChange
                  blurOnEnter
                  disabled={!(service === SERVICE_ADMIN && isStaff)}
                />
              </td>
              <td style={getStyle(screenSize, styles, 'tableTd')}>
                <CBInputField
                  name='description'
                  value={formData.description}
                  onChange={this.updateFormState}
                  noValidationOnChange
                  blurOnEnter
                  disabled={!(service === SERVICE_ADMIN && isStaff)}
                />
              </td>
              <td style={getStyle(screenSize, styles, 'tableTdSmall')}>
                <CBInputField
                  name='check_interval_months'
                  value={formData.check_interval_months}
                  onChange={this.updateFormState}
                  noValidationOnChange
                  blurOnEnter
                  type='number'
                  disabled={!(service === SERVICE_ADMIN && isStaff)}
                />
              </td>
              <td style={getStyle(screenSize, styles, 'tableTdSmall')}>
                <CBInputField
                  name='days_before_task_creation'
                  value={formData.days_before_task_creation}
                  onChange={this.updateFormState}
                  noValidationOnChange
                  blurOnEnter
                  type='number'
                  disabled={!canEdit}
                />
              </td>
              <td style={getStyle(screenSize, styles, 'tableTdSmall')}>
                <CBInputField
                  name='days_before_notification'
                  value={formData.days_before_notification}
                  onChange={this.updateFormState}
                  noValidationOnChange
                  blurOnEnter
                  type='number'
                  disabled={!canEdit}
                />
              </td>
              <td style={getStyle(screenSize, styles, 'tableTdDelete')}>
                <div style={getStyle(screenSize, styles, 'actionsContainer')}>
                  {
                    isNew && (
                      <CBButtonWithIconSmall
                        text={t('addCheckListItem')}
                        onClick={this.createItem}
                        icon='/img/icon_plus.svg'
                        disabled={!this.isValid()}
                      />
                    )
                  }
                  {
                    !isNew && (
                      <CBButtonWithIconSmall
                        text={t('updateCheckListItem')}
                        onClick={this.updateItem}
                        icon='/img/icon_save.svg'
                        disabled={!this.isValid() || !this.isChanged()}
                      />
                    )
                  }
                  {
                    !isNew && service == SERVICE_ADMIN && isStaff && (
                      <Button
                        icon='pi pi-times'
                        rounded
                        outlined
                        severity='danger'
                        aria-label='Cancel'
                        size='medium'
                        onClick={() => this.setState({ deleteModalIsOpen: true })}
                      />
                    )
                  }
                </div>
              </td>
            </tr>
            <CBPromptModal
              modalIsOpen={deleteModalIsOpen}
              closeModal={() => this.setState({deleteModalIsOpen: false})}
              afterOpenModal={() => {}}
              title={t('deleteCheckListItemTitle')}
              text={t('deleteCheckListItemText')}
              buttonText={t('deleteCheckListItemButton')}
              performAction={() => this.deleteItem()}
            />
          </React.Fragment>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
    },
    actionsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    tableTd: {
      border: '1px solid rgba(255, 255, 255, .3)',
      color: '#FFFFFF',
      paddingLeft: 8,
      paddingRight: 8,
    },
    tableTdSmall: {
      border: '1px solid rgba(255, 255, 255, .3)',
      color: '#FFFFFF',
      paddingLeft: 8,
      paddingRight: 8,
    },
    tableTdDelete: {
      paddingLeft: 8,
      paddingRight: 8,
    },
    optionStyle: {
      backgroundColor: 'rgb(0, 149, 218)',
    },
    row: {
      marginBottom: 8,
    },
  },
};

export default translate('CheckLists')(CheckListItemRow);
