import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { ListBox } from 'primereact/listbox';
import { getStyle, getModalStyle } from '../../../utils/utils';
import {
  attachVehicle as _attachVehicle,
  detachVehicle as _detachVehicle,
} from '../../../actions/vehicleActions';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBButtonSubmit from '../../shared/CBButtonSubmit';


Modal.setAppElement('#root');

class AttachVehicleModal extends Component {
  state = {
    selectedParent: null,
  };

  componentDidMount = () => {
    const {
      vehicle,
      t,
      garageVehicles,
    } = this.props;

    let selectedParent = { id: null, display_label: t('noAttachment') };

    if (vehicle.parent) {
      selectedParent = garageVehicles.find((gv) => gv.id == vehicle.parent.id);
    }

    this.setState(
      {
        selectedParent,
      },
    );
  };

  getOptions = () => {
    const {
      garageVehicles,
      t,
      vehicle,
    } = this.props;

    const childrenIds = vehicle.children.map((child) => child.id);

    const vehicles = garageVehicles.filter((gVehicle) => {
      return gVehicle.id != vehicle.id && !childrenIds.includes(gVehicle.id) && gVehicle.garage_id == vehicle.garage_id;
    });

    return [
      { id: null, display_label: t('noAttachment') },
      ...vehicles,
    ];
  };

  setSelectedParent = (selectedParent) => {
    this.setState({ selectedParent });
  };

  onSubmit = () => {
    const { selectedParent } = this.state;
    const {
      attachVehicle,
      detachVehicle,
      closeModal,
      vehicle,
    } = this.props;

    if (selectedParent && selectedParent.id) {
      attachVehicle(vehicle.id, vehicle.garage_id, { parent_vehicle_id: selectedParent.id });
    } else {
      detachVehicle(vehicle, vehicle.garage_id, {});
    }
    closeModal();
  };

  render() {
    const { selectedParent } = this.state;
    const {
      modalIsOpen, closeModal, afterOpenModal, t,
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={getModalStyle(screenSize, styles.modal)}
            contentLabel='Description modal'
          >
            <div style={getStyle(screenSize, styles, 'container')}>
              <p style={getStyle(screenSize, styles, 'title')}>
                {t('attachGarageVehicle')}
              </p>

              <ListBox
                filter
                value={selectedParent}
                onChange={(e) => this.setSelectedParent(e.value)}
                options={this.getOptions()}
                optionLabel='display_label'
                className='w-full md:w-14rem'
              />

              <div style={getStyle(screenSize, styles, 'buttonContainer')}>
                <div style={getStyle(screenSize, styles, 'cancelButtonContainer')}>
                  <CBButtonSubmit
                    disabled={false}
                    onClick={() => {
                      closeModal();
                    }}
                    text={t('cancel')}
                    style={getStyle(screenSize, styles, 'submitButton')}
                  />
                </div>
                <div style={getStyle(screenSize, styles, 'submitButtonContainer')}>
                  <CBButtonSubmit
                    text={t('save')}
                    onClick={this.onSubmit}
                    style={getStyle(screenSize, styles, 'submitButton')}
                  />
                </div>
              </div>
            </div>
          </Modal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },
    title: {
      fontSize: 32,
      fontFamily: "TitilliumWeb-Light",
      color: '#0095da',
      textAlign: 'center',
    },
    subTitle: {
      fontSize: 14,
      color: 'black',
      textAlign: 'center',
      fontWeight: 600,
    },
    clearButtonContainer: {
      height: 40,
      width: '100%',
      border: '2px solid #0095da',
      color: '#0095da',
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      marginLeft: 20,
    },
    datepickerContainer: {
      display: 'flex',
      marginBottom: 50,
      marginTop: 24,
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 400,
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      marginBottom: 30,
    },
    info: {
      width: '100%',
      fontSize: 14,
      color: 'black',
      paddingHorizontal: 30,
      textAlign: 'center',
    },
    submitButtonContainer: {
      width: 200,
      marginTop: 32,
    },
    cancelButtonContainer: {
      width: 200,
      marginTop: 32,
    },
    buttonContainer: {
      width: 450,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    submitButton: {
      height: 50,
      width: '100%',
      border: '2px solid #0095da',
      color: '#0095da',
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    input: {
      width: '100%',
    },
  },
  modal: {
    default: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height: 'auto',
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    medium: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
      },
      overlay: {
        zIndex: 'auto',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    small: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
};

function mapStateToProps(state) {
  return {
    vehiclePermissionRequest: state.vehiclePermission,
    user: state.authUser.user,
    garageVehicles: state.vehicle.garageVehicles,
  };
}

export default connect(
  mapStateToProps,
  {
    attachVehicle: _attachVehicle,
    detachVehicle: _detachVehicle,
  },
)(translate('AttachVehicleModal')(AttachVehicleModal));
