import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getStyle, getModalStyle } from '../../../../utils/utils';
import {
  updateVehicle as _updateVehicle,
} from '../../../../actions/vehicleActions';
import CBMediaQuery from '../../../shared/CBMediaQuery';
import CBButtonSubmit from '../../../shared/CBButtonSubmit';
import CBInputField from '../../../shared/form_components/CBInputField';
import { getLengthValidator } from '../../../../utils/validators';

Modal.setAppElement('#root');

class EditVehicleTopInfoModal extends Component {
  state = {
    formData: {
      year_model: '',
      owner_description: '',
    },
    formValid: {
      year_model: false,
      owner_description: false,
    },
  };

  componentDidMount = () => {
    const { vehicle } = this.props;
    if (vehicle.year_model) {
      this.setState(state => ({
        ...state,
        formData: {
          ...state.formData,
          year_model: vehicle.year_model,
        },
        formValid: {
          ...state.formValid,
          year_model: true,
        }
      }));
    }
    if (vehicle.owner_description) {
      this.setState(state => ({
        ...state,
        formData: {
          ...state.formData,
          owner_description: vehicle.owner_description,
        },
        formValid: {
          ...state.formValid,
          owner_description: true,
        }
      }));
    }
  };

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
      [name]: isValid,
    };

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const {
      formData
    } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({
      formData: newFormData
    });
  };

  onSubmit = () => {
    const {
      vehicle,
      updateVehicle,
    } = this.props;
    const { formData } = this.state;


    if (vehicle) {
      updateVehicle(vehicle.id, formData);
    }
  };

  render() {
    const { formData } = this.state;
    const {
      modalIsOpen, closeModal, afterOpenModal, t,
    } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={getModalStyle(screenSize, styles.modal)}
            contentLabel='Description modal'
          >
            <div style={getStyle(screenSize, styles, 'container')}>
              <p style={getStyle(screenSize, styles, 'title')}>
                {t('vehicleTopInfoModalHeading')}
              </p>

              <CBInputField
                name='year_model'
                inputStyle={getStyle(screenSize, styles, 'input')}
                value={formData.year_model}
                type='number'
                placeholder={t('yearModelPlaceholder')}
                onChange={this.updateFormState}
                validators={[getLengthValidator(0, 4)]}
                isValidCallback={this.setIsValid}
                labelText={t('yearModelLabel')}
                labelTextStyle={getStyle(screenSize, styles, 'subTitle')}
              />
              <CBInputField
                name='owner_description'
                inputStyle={getStyle(screenSize, styles, 'input')}
                value={formData.owner_description}
                placeholder={t('vehicleOwnerDescriptionPlaceholder')}
                onChange={this.updateFormState}
                validators={[getLengthValidator(0, 21)]}
                isValidCallback={this.setIsValid}
                labelText={t('vehicleOwnerDescriptionLabel')}
                labelTextStyle={getStyle(screenSize, styles, 'subTitle')}
              />
              <CBButtonSubmit
                text={t('save')}
                disabled={!this.getIsValid()}
                onClick={this.onSubmit}
              />
            </div>
          </Modal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },
    title: {
      fontSize: 32,
      fontFamily: "TitilliumWeb-Light",
      color: '#0095da',
      textAlign: 'center',
    },
    subTitle: {
      fontSize: 14,
      color: 'black',
      textAlign: 'center',
      fontWeight: 600,
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 400,
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      marginBottom: 30,
    },
    info: {
      width: '100%',
      fontSize: 14,
      color: 'black',
      paddingHorizontal: 30,
      textAlign: 'center',
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    input: {
      width: '100%',
    },
  },
  modal: {
    default: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 'auto',
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    medium: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
      },
      overlay: {
        zIndex: 'auto',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    small: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
};

function mapStateToProps(state) {
  return {
    vehiclePermissionRequest: state.vehiclePermission,
    user: state.authUser.user,
    vehicle: state.vehicle.vehicle,
  };
}

export default connect(
  mapStateToProps,
  {
    updateVehicle: _updateVehicle,
  },
)(translate('VehicleDetailsView')(EditVehicleTopInfoModal));
