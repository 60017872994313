import React from 'react';

const TextWithLinebreaks = (props) => {
  const { text } = props;
  return (
    <React.Fragment>
      {text.split('\n').map((line, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={i}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default TextWithLinebreaks;
