import React, { Component } from 'react';
import { translate } from 'react-i18next';
import CBMediaQuery from './CBMediaQuery';
import { getStyle, styleMerge } from '../../utils/utils';

class CBButtonAddCar extends Component {
  render() {
    const { t, onClick, text, disabled } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={styleMerge(getStyle(screenSize, styles, 'container'), disabled ? { opacity: 0.6, cursor: 'auto' } : {})} onClick={!disabled ? onClick : () => {}}>
            <img src='/img/icon_plus.svg' alt='Add' style={getStyle(screenSize, styles, 'plusIcon')} />
            <img
              src='/img/icon_car_basic_info.svg'
              alt='Car'
              style={getStyle(screenSize, styles, 'carIcon')}
            />
            {screenSize !== 'small' && (
              <p style={getStyle(screenSize, styles, 'label')}>
                {text || t('addCarButton')}
              </p>
            )}
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid rgba(255,255,255,.3)',
      borderRadius: 50,
      paddingLeft: 10,
      paddingRight: 10,
      height: 40,
      marginRight: 10,
      cursor: 'pointer',
    },
    carIcon: {
      height: 35,
      width: 35,
    },
    plusIcon: {
      height: 24,
      width: 24,
    },
    label: {
      fontSize: 14,
      margin: 0,
      marginLeft: 10,
    },
  },
  small: {},
};

export default translate('GarageView')(CBButtonAddCar);
