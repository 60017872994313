import { searchState } from '../initialState';

export default function (state = searchState, action) {
  switch (action.type) {
    case 'SEARCH_VEHICLE_MAINTENANCES_REQUEST': {
      return {
        ...state,
        maintenanceFilters: {
          search: action.additionalData.search,
        },
        searchStatus: Object.assign({}, state.searchStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'SEARCH_VEHICLE_MAINTENANCES_SUCCESS': {
      return {
        ...state,
        maintenanceIDs: action.payload,
        searchStatus: Object.assign({}, state.searchStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'SEARCH_VEHICLE_MAINTENANCES_FAIL': {
      return {
        ...state,
        maintenanceIDs: [],
        searchStatus: Object.assign({}, state.searchStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'SEARCH_VEHICLE_TREATMENTS_REQUEST': {
      return {
        ...state,
        treatmentFilters: {
          search: action.additionalData.search,
          treatmentType: action.additionalData.treatment_type,
        },
        searchStatus: Object.assign({}, state.searchStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'SEARCH_VEHICLE_TREATMENTS_SUCCESS': {
      return {
        ...state,
        treatmentIDs: action.payload,
        searchStatus: Object.assign({}, state.searchStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'SEARCH_VEHICLE_TREATMENTS_FAIL': {
      return {
        ...state,
        treatmentIDs: [],
        searchStatus: Object.assign({}, state.searchStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    default:
      break;
  }
  return state;
}
