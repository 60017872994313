import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../../shared/CBMediaQuery';
import '../../../App.css';

import { getStyle } from '../../../utils/utils';
import CBInputField from '../../shared/form_components/CBInputField';

import {
  setManualKm as _setManualKm,
} from '../../../actions/vehicleActions';
import CBModal from '../../shared/CBModal';

import {
  acceptCareServiceInvitation as _acceptCareServiceInvitation,
  declineCareServiceInvitation as _declineCareServiceInvitation,
} from '../../../actions/authUserActions';
import CBDatePickerInput from '../../shared/CBDatePickerInput';
import { finnishDateFormat } from '../../../utils/utils';
import moment from 'moment';

class ManualKmModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        manual_km: props.vehicle.manual_km || null,
        manual_km_date: props.vehicle.manual_km_date || null,
      },
      formValid: {
      },
    };
  }

  componentDidMount = () => {
    const { formData } = this.state;
    const newState = {
      formData: {
        ...formData,
      },
    };

    this.setState(newState);
  };

  getIsValid = () => {
    const { formData } = this.state;
    const { manual_km, manual_km_date } = formData;

    return (manual_km && manual_km_date) || (!manual_km && !manual_km_date);
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const { formData } = this.state;
    const {
      closeModal,
      setManualKm,
      vehicle,
      selectedOrganizationId
    } = this.props;

    const data = {
      ...formData,
      organization_id: selectedOrganizationId
    }

    setManualKm(vehicle.id, data);

    closeModal();
  };

  closeModal = () => {
    const {
      closeModal,
    } = this.props;

    closeModal();
  };

  render() {
    const { formData } = this.state;
    const {
      modalIsOpen,
      afterOpenModal,
      t,
      vehicle,
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <CBModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={this.closeModal}
            title={t('manualKmModalTitle')}
            descriptionText={t('manualKmModalDescription')}
            cancelText={t('cancel')}
            submitText={t('save')}
            vehicleText={vehicle.registration_number}
            submitForm={this.submitForm}
            isValid={this.getIsValid()}
          >
            <div style={getStyle(screenSize, styles, 'bodyContainer')}>

              <CBInputField
                name='manual_km'
                inputStyle={getStyle(screenSize, styles, 'input')}
                value={formData.manual_km}
                placeholder=''
                type='number'
                onChange={this.updateFormState}
                isValidCallback={this.setIsValid}
                labelText={t('manualKmLabel')}
                labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
              />

              <div style={getStyle(screenSize, styles, 'inputContainer')}>
                <p
                  className='font-titillium-web-semi-bold'
                  style={getStyle(screenSize, styles, 'dateInputLabel')}
                >
                  {t('manualKmDateLabel')}
                </p>
                <div className='datepicker-container-white-bg'>
                  <CBDatePickerInput
                    displayedDate={formData.manual_km_date ? moment(formData.manual_km_date) : null}
                    type='date'
                    dateFormat={finnishDateFormat}
                    changeDate={(value) => {
                      this.updateFormState({ target: 'manual_km_date', value: value ? moment(value).format('YYYY-MM-DD') : null});
                    }}
                  />
                </div>
              </div>


            </div>
          </CBModal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 32px'
    },
    datepickerContainer: {
      display: 'flex',
    },
    dateInputLabel: {
      color: 'black',
      textAlign: 'center'
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      width: '100%',
    },
    inputLabelText: {
      color: 'black',
    },
  },
  small: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 16px'
    }
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    selectedOrganizationId: state.organization.selectedOrganization,
  };
}

export default connect(
  mapStateToProps,
  {
    setManualKm: _setManualKm,
  },
)(translate('ManualKmModal')(ManualKmModal));
