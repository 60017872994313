import { checkListState } from '../initialState';
import { storeInsertItem, storeRemoveItem, storeUpdateObjectInArray } from '../utils/utils';

export default function (state = checkListState, action) {
  switch (action.type) {
    case 'FETCH_CHECK_LISTS_REQUEST': {
      return {
        ...state,
        checkListStatus: Object.assign({}, state.checkListStatus, {
          loading: true,
          loadingFetch: true,
          error: null,
        }),
      };
    }
    case 'FETCH_CHECK_LISTS_SUCCESS': {
      return {
        ...state,
        checkLists: action.payload,
        checkListStatus: Object.assign({}, state.checkListStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_CHECK_LISTS_FAIL': {
      return {
        ...state,
        checkLists: [],
        checkListStatus: Object.assign({}, state.checkListStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_CHECK_LIST_REQUEST': {
      return {
        ...state,
        checkListStatus: Object.assign({}, state.checkListStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_CHECK_LIST_SUCCESS': {
      return {
        ...state,
        checkLists: storeInsertItem(state.checkLists, action.payload),
        checkListStatus: Object.assign({}, state.checkListStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_CHECK_LIST_FAIL': {
      return {
        ...state,
        checkListStatus: Object.assign({}, state.checkListStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_CHECK_LIST_REQUEST': {
      return {
        ...state,
        checkListStatus: Object.assign({}, state.checkListStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_CHECK_LIST_SUCCESS': {
      return {
        ...state,
        checkLists: storeUpdateObjectInArray(state.checkLists, action.payload),
        checkListStatus: Object.assign({}, state.checkListStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_CHECK_LIST_FAIL': {
      return {
        ...state,
        checkListStatus: Object.assign({}, state.checkListStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'DELETE_CHECK_LIST_REQUEST': {
      return {
        ...state,
        checkListStatus: Object.assign({}, state.checkListStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DELETE_CHECK_LIST_SUCCESS': {
      return {
        ...state,
        checkLists: storeRemoveItem(state.checkLists, action.payload.id),
        checkListStatus: Object.assign({}, state.checkListStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DELETE_CHECK_LIST_FAIL': {
      return {
        ...state,
        checkListStatus: Object.assign({}, state.checkListStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_CHECK_LIST_ITEM_REQUEST': {
      return {
        ...state,
        checkListStatus: Object.assign({}, state.checkListStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_CHECK_LIST_ITEM_SUCCESS': {
      const checkListId = action.payload.check_list;
      const checkList = state.checkLists.find((x) => x.id === checkListId);
      const newCheckList = { ...checkList };
      newCheckList.items = storeInsertItem(newCheckList.items, action.payload);
      return {
        ...state,
        checkLists: storeUpdateObjectInArray(state.checkLists, newCheckList),
        checkListStatus: Object.assign({}, state.checkListStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_CHECK_LIST_ITEM_FAIL': {
      return {
        ...state,
        checkListStatus: Object.assign({}, state.checkListStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_CHECK_LIST_ITEM_REQUEST': {
      return {
        ...state,
        checkListStatus: Object.assign({}, state.checkListStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_CHECK_LIST_ITEM_SUCCESS': {
      const checkListId = action.payload.check_list;
      const checkList = state.checkLists.find((x) => x.id === checkListId);
      const newCheckList = { ...checkList };
      newCheckList.items = storeUpdateObjectInArray(newCheckList.items, action.payload);
      return {
        ...state,
        checkLists: storeUpdateObjectInArray(state.checkLists, newCheckList),
        checkListStatus: Object.assign({}, state.checkListStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_CHECK_LIST_ITEM_FAIL': {
      return {
        ...state,
        checkListStatus: Object.assign({}, state.checkListStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'DELETE_CHECK_LIST_ITEM_REQUEST': {
      return {
        ...state,
        checkListStatus: Object.assign({}, state.checkListStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DELETE_CHECK_LIST_ITEM_SUCCESS': {
      const checkListId = action.additionalData.checkListId;
      const checkList = state.checkLists.find((x) => x.id === checkListId);
      const newCheckList = { ...checkList };

      newCheckList.items = storeRemoveItem(newCheckList.items, action.additionalData.itemId);
      return {
        ...state,
        checkLists: storeUpdateObjectInArray(state.checkLists, newCheckList),
        checkListStatus: Object.assign({}, state.checkListStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DELETE_CHECK_LIST_ITEM_FAIL': {
      return {
        ...state,
        checkListStatus: Object.assign({}, state.checkListStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'FETCH_VEHICLE_CHECK_LIST_ITEM_REQUEST': {
      return {
        ...state,
        vehicleCheckListItems: [],
        vehicleCheckListItemStatus: Object.assign({}, state.vehicleCheckListItemStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_VEHICLE_CHECK_LIST_ITEM_SUCCESS': {
      return {
        ...state,
        vehicleCheckListItems: action.payload,
        vehicleCheckListItemStatus: Object.assign({}, state.vehicleCheckListItemStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_VEHICLE_CHECK_LIST_ITEM_FAIL': {
      return {
        ...state,
        vehicleCheckListItems: [],
        vehicleCheckListItemStatus: Object.assign({}, state.vehicleCheckListItemStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    default:
      break;
  }
  return state;
}
