import React, { Component } from 'react';
import { Editor, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import EditorStyleButton from './EditorStyleButton';

const INLINE_STYLES = [
  { icon: '/img/icon-bold.svg', activeIcon: '/img/icon-bold-white.svg', style: 'BOLD' },
  { icon: '/img/icon-italic.svg', activeIcon: '/img/icon-italic-white.svg', style: 'ITALIC' },
  { icon: '/img/icon-underline.svg', activeIcon: '/img/icon-underline-white.svg', style: 'UNDERLINE' },
];

class EditorWithBold extends Component {
  toggleInlineStyle = inlineStyle => {
    const { onChange, editorState } = this.props;
    onChange(
      RichUtils.toggleInlineStyle(
        editorState,
        inlineStyle,
      ),
    );
  }

  handleKeyCommand = (command, editorState) => {
    const { onChange } = this.props;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  render() {
    const {
      controlRowStyle = {},
      contentStyle = {},
      containerStyle = {},
      editorState,
      onChange,
    } = this.props;
    const currentStyle = editorState.getCurrentInlineStyle();
    return (
      <CBMediaQuery>
        {screenSize => (
          <div
            style={{
              ...getStyle(screenSize, styles, 'container'),
              ...containerStyle,
            }}
          >
            <div
              style={{
                ...getStyle(screenSize, styles, 'controlRow'),
                ...controlRowStyle,
              }}
            >
              {INLINE_STYLES.map(type => (
                <EditorStyleButton
                  key={type.style}
                  active={currentStyle.has(type.style)}
                  icon={type.icon}
                  activeIcon={type.activeIcon}
                  onToggle={this.toggleInlineStyle}
                  style={type.style}
                />
              ))}
            </div>
            <div
              style={{
                ...getStyle(screenSize, styles, 'editor'),
                ...contentStyle,
              }}
              onClick={() => this.editor.focus()}
            >
              <Editor
                ref={ref => { this.editor = ref; }}
                editorState={editorState}
                handleKeyCommand={this.handleKeyCommand}
                onChange={onChange}
              />
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      align: 'center',
      width: '100%',
      backgroundColor: '#EBEBEB',
      borderRadius: 2,
    },
    controlRow: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: '#BABABA',
      borderRadius: 2,
    },
    controlButton: {
      color: 'white',
      fontFamily: 'TitilliumWeb-Regular',
      fontSize: 16,
      padding: 10,
      cursor: 'pointer',
    },
    editor: {
      backgroundColor: '#EBEBEB',
      color: 'black',
      fontFamily: 'TitilliumWeb-Regular',
      fontSize: 14,
      padding: 20,
      borderBottomLeftRadius: 2,
      borderBottomRightRadius: 2,
    },
  },
};

export default EditorWithBold;
