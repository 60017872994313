import React from 'react';
import { translate } from 'react-i18next';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBButtonTransparent from '../../shared/CBButtonTransparent';

const VehicleDetailsMaintenanceReportButton = props => {
  const { t, match } = props;
  const { vehicleId } = match.params;
  return (
    <CBMediaQuery>
      {screenSize => (
        <div style={getStyle(screenSize, styles, 'container')}>
          <CBButtonTransparent
            text={t('showReport')}
            textStyle={getStyle(screenSize, styles, 'text')}
            imgSrc='/img/icon_download.svg'
            to={`/vehicle_report/${vehicleId}`}
          />
        </div>
      )}
    </CBMediaQuery>
  );
};

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: 110,
      padding: '0 50px',
    },
    text: {
      fontFamily: 'TitilliumWeb-Regular',
      fontSize: 18,
      color: 'white',
    },
  },
  medium: {},
};

export default translate('VehicleDetailsView')(
  VehicleDetailsMaintenanceReportButton,
);
