import React, { Component } from 'react';
import CBMediaQuery from './CBMediaQuery';
import { getStyle } from '../../utils/utils';
import {connect} from 'react-redux';

class CBProfilePictureSmall extends Component {
  render() {
    const { user } = this.props;
    if (!user || !user.profile || !user.profile.image) {
      return false;
    }
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <img
              src={user.profile.image}
              alt={'Profile'}
              style={getStyle(screenSize, styles, 'picture')}
            />
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%'
    },
    picture: {
      height: 50,
      width: 50,
    }
  },
  small: {},
};


function mapStateToProps(state) {
  return {
    user: state.authUser.user,
  };
}

export default connect(
  mapStateToProps
)(CBProfilePictureSmall);