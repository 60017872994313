import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { fetchVehiclePermissions as _fetchVehiclePermissions } from '../../actions/vehiclePermissionActions';
import VehicleGridView from './GarageViewComponents/VehicleGridView';
import WorkshopNotificationsList from '../shared/WorkshopNotificationsList';
import CBAdvertisementWrapper from '../shared/CBAdvertisementWrapper';
import CBMediaQuery from '../shared/CBMediaQuery';
import { getStyle } from '../../utils/utils';
import { fetchVehicleCategories as _fetchVehicleCategories } from '../../actions/vehicleCategoryActions';
import { selectGarage as _selectGarage } from '../../actions/garageActions';

class GarageLayoutView extends React.Component {
  componentDidMount = () => {
    const {
      fetchVehiclePermissions,
      fetchVehicleCategories,
      selectGarage,
    } = this.props;
    fetchVehiclePermissions();
    fetchVehicleCategories();
    selectGarage(undefined);
  };

  render() {
    const { vehicleCategoryRequest, history, t } = this.props;

    return (
      <CBMediaQuery>
        {(screenSize) => (
          <div style={getStyle(screenSize, styles, 'garageContainer')}>
            <VehicleGridView
              vehicleCategories={vehicleCategoryRequest.vehicleCategories}
              goBack={history.goBack}
            />
            {screenSize !== 'small' && (
              <React.Fragment>
                <div
                  style={getStyle(
                    screenSize,
                    styles,
                    'workshopNotificationsContainer'
                  )}
                >
                  <WorkshopNotificationsList />
                  <div
                    style={getStyle(screenSize, styles, 'advertisementSection')}
                  >
                    {screenSize === 'large' && (
                      <p style={getStyle(screenSize, styles, 'adsText')}>
                        {t('adsHeading')}
                      </p>
                    )}

                    <CBAdvertisementWrapper
                      style={getStyle(
                        screenSize,
                        styles,
                        'advertisementContainer'
                      )}
                      location="garage"
                      header="Mainoksia"
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
            {screenSize === 'small' && (
              <>
                <div style={getStyle(screenSize, styles, 'advertisementSection')}>
                  <p style={getStyle(screenSize, styles, 'adsText')}>
                    {t('adsHeading')}
                  </p>
                </div>
                <CBAdvertisementWrapper
                  style={getStyle(screenSize, styles, 'advertisementContainer')}
                  location="garage"
                  header="Mainoksia"
                />
              </>
            )}
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    garageContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    workshopNotificationsContainer: {
      paddingTop: 110,
      width: '35%',
      borderLeft: '1px solid rgba(255, 255, 255, 0.3)',
    },
    advertisementSection: {
      marginLeft: 16,
      marginTop: 16,
    },
    advertisementContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    adsText: {
      fontSize: 12,
      color: '#ffffff',
      fontWeight: 300,
      textDecoration: 'none solid rgb(255, 255, 255)',
      opacity: 0.6,
    },
  },
  medium: {
    garageContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    advertisementContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    workshopNotificationsContainer: {
      marginTop: 110,
      width: '100%',
      marginLeft: 0,
    },
  },
  small: {
    advertisementContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      height: 'auto',
      borderTop: '1px solid rgba(255, 255, 255, .3)',
      width: 'auto',
      paddingTop: 20,
    },

    adsText: {
      marginTop: 16,
    },
  },
};

function mapStateToProps(state) {
  return {
    vehicleCategoryRequest: state.vehicleCategory,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchVehiclePermissions: _fetchVehiclePermissions,
    fetchVehicleCategories: _fetchVehicleCategories,
    selectGarage: _selectGarage,
  },
)(translate('GarageView')(GarageLayoutView));
