import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import WelcomeText from './HomeViewComponents/WelcomeText';
import WelcomeTextSub from './HomeViewComponents/WelcomeTextSub';
import CBButtonLarge from '../shared/CBButtonLarge';
import CBAdvertisementWrapper from '../shared/CBAdvertisementWrapper';
import WorkshopNotificationsList from '../shared/WorkshopNotificationsList';
import CBMediaQuery from '../shared/CBMediaQuery';
import CBButtonGeneric from '../shared/CBButtonGeneric';
import { getCsrfToken, getStyle } from '../../utils/utils';
import CBSubHeading from '../shared/CBSubHeading';
import CBPromptModal from '../shared/CBPromptModal';
import { use } from 'i18next/dist/commonjs';

class HomeEndUser extends React.Component {
  garageButtonTextStyle = {
    fontFamily: 'TitilliumWeb-Light',
    fontSize: 20,
    color: '#FFF',
    padding: 16,
    marginBottom: 0,
  }

  componentWillMount() {
  }

  render() {
    const { t, user } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div>
            {
              !user.has_hetu_hash && !user.profile.has_duplicate_hetu && (
                <div style={getStyle(screenSize, styles, 'noHetuContainer')}>
                  <p style={getStyle(screenSize, styles, 'noHetuText')}>
                    {t('noHetuSet')}
                    <a
                      style={getStyle(screenSize, styles, 'noHetuLink')}
                      className='hover-underline'
                      href='/api/1/authentication/create-authentication-session/'
                    >
                      {t('here') }
                    </a>
                  </p>
                </div>
              )
            }

            {
              !user.has_hetu_hash && user.profile.has_duplicate_hetu && (
                <div style={getStyle(screenSize, styles, 'noHetuContainer')}>
                  <p style={getStyle(screenSize, styles, 'noHetuText')}>
                    {t('duplicateHetu')}
                  </p>
                </div>
              )
            }

            <div style={getStyle(screenSize, styles, 'welcomeTextContainer')}>
              <WelcomeText />
            </div>
            <div style={getStyle(screenSize, styles, 'welcomeTextSubContainer')}>
              <WelcomeTextSub />
            </div>
            <div style={getStyle(screenSize, styles, 'bodyContainer')}>
              {
                user.has_hetu_hash && (
                  <div style={getStyle(screenSize, styles, 'buttonContainer')}>
                    <CBButtonLarge
                      to='/garage'
                      icon='/img/icon_carage_c.svg'
                      text={t('navigateToGarageButton')}
                    />
                  </div>
                )
              }

              <WorkshopNotificationsList type={screenSize !== 'small' ? 'grid' : 'list'} />
              <CBAdvertisementWrapper
                style={getStyle(screenSize, styles, 'advertisementContainer')}
                location='front_page'
              />
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    garageContainer: {
      borderTop: '1px solid rgba(255, 255, 255, .3)',
      borderBottom: '1px solid rgba(255, 255, 255, .3)',
      minWidth: 800,
      paddingTop: 32,
      paddingBottom: 32,
    },
    garageHeadingContainer: {
      textAlign: 'center',
      marginBottom: 32,
    },
    garageButtonContainer: {
      marginBottom: 16,
    },
    welcomeTextContainer: {
      marginBottom: 16,
      marginTop: 66,
    },
    welcomeTextSubContainer: {
      marginBottom: 75,
    },
    buttonContainer: {
      marginBottom: 110,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 350,
    },
    bodyContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 32,
    },
    announcementContainer: {},
    advertisementContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      height: 300,
      borderTop: '1px solid rgba(255, 255, 255, .3)',
      width: 1400,
      paddingTop: 20,
    },
    noHetuContainer: {
      backgroundColor: '#e74c3c',
      width: '100%',
      padding: 16,
      height: 60,
    },
    noHetuText: {
      color: '#FFF',
    },
    noHetuLink: {
      color: '#FFF',
    },
  },
  medium: {
    garageContainer: {
      borderTop: '1px solid rgba(255, 255, 255, .3)',
      borderBottom: '1px solid rgba(255, 255, 255, .3)',
      minWidth: 400,
      paddingTop: 32,
      paddingBottom: 32,
    },
    advertisementContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignContent: 'space-between',
      height: 618,
      borderTop: '1px solid rgba(255, 255, 255, .3)',
      width: 720,
      paddingTop: 20,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  small: {
    noHetuContainer: {
      backgroundColor: '#e74c3c',
      width: '100%',
      padding: 16,
      height: 100,
    },
    garageContainer: {
      borderTop: '1px solid rgba(255, 255, 255, .3)',
      borderBottom: '1px solid rgba(255, 255, 255, .3)',
      minWidth: 0,
      paddingTop: 32,
      paddingBottom: 32,
    },
    advertisementContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      height: 'auto',
      borderTop: '1px solid rgba(255, 255, 255, .3)',
      width: 'auto',
      paddingTop: 20,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: 'unset',
    },
  },
};

function mapStateToProps(state) {
  return {
    garages: state.garage.garages,
    user: state.authUser.user,
  };
}

export default connect(
  mapStateToProps,
  {
  },
)(translate('HomeView')(HomeEndUser));
