import React, { Component } from 'react';
import { Editor } from 'draft-js';
import 'draft-js/dist/Draft.css';

class EditorBlue extends Component {
  render() {
    return (
      <div style={styles.contentStyle}>
        <Editor {...this.props} />
      </div>
    );
  }
}

const styles = {
  contentStyle: {
    width: '100%',
    backgroundColor: '#0095DA',
    color: 'white',
    fontFamily: 'TitilliumWeb-Regular',
    fontSize: 14,
    padding: 20,
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
  },
};

export default EditorBlue;
