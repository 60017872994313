import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import CBMediaQuery from '../shared/CBMediaQuery';
import { getStyle, TYPE_CARE_SERVICE } from '../../utils/utils';
import VehicleListView from '../OrganizationGarageView/OrganizationGarageComponents/VehicleListView';
import { fetchVehiclesForCareService as _fetchVehiclesForCareService } from '../../actions/vehicleActions';
import InviteToCareServiceModal from './components/InviteToCareServiceModal';

class CareServiceLayoutView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invitationModalIsOpen: false,
    };
  }

  componentDidMount = () => {
    const { fetchVehiclesForCareService, selectedWorkshopId } = this.props;
    fetchVehiclesForCareService(selectedWorkshopId, {ordering: 'registration_number'});
  };

  render() {
    const {
      careServiceVehicles, selectedWorkshopId, fetchVehiclesForCareService,
    } = this.props;

    const { invitationModalIsOpen } = this.state;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <VehicleListView
              inviteToCareService={() => this.setState({ invitationModalIsOpen: true })}
              vehicles={careServiceVehicles}
              fetchVehicles={(params) => { fetchVehiclesForCareService(selectedWorkshopId, params); }}
              listType={TYPE_CARE_SERVICE}
            />
            <InviteToCareServiceModal
              modalIsOpen={invitationModalIsOpen}
              selectedWorkshopId={selectedWorkshopId}
              closeModal={() => this.setState({ invitationModalIsOpen: false })}
            />
          </div>

        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
};

function mapStateToProps(state) {
  return {
    selectedWorkshopId: state.workshop.selectedWorkshop,
    careServiceVehicles: state.vehicle.careServiceVehicles,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchVehiclesForCareService: _fetchVehiclesForCareService,
  },
)(translate('CareServiceLayoutView')(CareServiceLayoutView));
