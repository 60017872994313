import React, { Component } from 'react';
import Modal from 'react-modal';
import moment from 'moment';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { WORKSHOP_NOTIFICATION_MAX_LENGTH } from '../../../config';
import CBMediaQuery from '../../shared/CBMediaQuery';
import '../../../App.css';
import { getStyle, getModalStyle } from '../../../utils/utils';
import CBButtonSubmit from '../../shared/CBButtonSubmit';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';
import EditorWithBold from './EditorWithBold';
import EditorBlue from './EditorBlue';
import BuySMSModal from '../../VehicleDetailsView/VehicleDetailsComponents/BuySMSModal';
import {
  createAnnouncement as _createAnnouncement,
  updateAnnouncement as _updateAnnouncement,
} from '../../../actions/announcementActions';
import {
  fetchWorkshops as _fetchWorkshops,
  fetchSMSCounts as _fetchSMSCounts,
} from '../../../actions/workshopActions';
import CBDatePickerInput from '../../shared/CBDatePickerInput';

class AddAnnouncementModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        date: moment().format('YYYY-MM-DD'),
        durationWeeks: 2,
        visible_in_ovio: true,
        send_email: false,
        send_sms: false,
      },
      formValid: {},
      descriptionEditorState: EditorState.createEmpty(),
      notificationEditorState: EditorState.createEmpty(),
      buySMSModalIsOpen: false,
    };
  }

  componentWillMount() {
    const { fetchWorkshops, fetchSMSCounts, user, selectedWorkshopId } = this.props;
    fetchWorkshops();
    fetchSMSCounts(selectedWorkshopId);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.announcement) {
      return;
    }

    const {
      date,
      expire_date,
      description_raw_content,
      visible_in_ovio,
      send_sms,
      send_email,
    } = nextProps.announcement;

    const durationWeeks = Math.round(
      moment.duration(moment(expire_date).diff(moment(date))).asWeeks(),
    );
    const descriptionEditorState = EditorState.createWithContent(
      convertFromRaw(JSON.parse(description_raw_content)),
    );
    const notificationEditorState = this.createNotificationEditorState(
      descriptionEditorState,
    );

    this.setState({
      formData: {
        date,
        durationWeeks,
        visible_in_ovio,
        send_sms,
        send_email,
      },
      descriptionEditorState,
      notificationEditorState,
    });
  }

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
      [name]: isValid,
    };

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  toggleBoolean = target => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [target]: !prevState.formData[target],
      },
    }));
  };

  changeDuration = value => {
    if (![2, 4, 8].includes(value)) {
      console.error('Invalid duration value');
      return;
    }
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        durationWeeks: value,
      },
    }));
  };

  submitForm = () => {
    const { formData, descriptionEditorState } = this.state;
    const {
      date,
      visible_in_ovio,
      send_email,
      send_sms,
      durationWeeks,
    } = formData;
    const {
      createAnnouncement,
      updateAnnouncement,
      closeModal,
      mode,
      announcement,
      smsAvailable,
      user,
      selectedWorkshopId,
    } = this.props;

    if (send_sms && this.getRequiredSMSCount() > smsAvailable) {
      this.openBuySMSModal();
      return;
    }

    const expire_date = moment(date)
      .add(durationWeeks, 'weeks')
      .format('YYYY-MM-DD');
    const content = descriptionEditorState.getCurrentContent();
    const description = content.getPlainText();
    const description_raw_content = JSON.stringify(convertToRaw(content));

    const cleanFormData = {
      workshop: selectedWorkshopId,
      date,
      visible_in_ovio,
      send_email,
      send_sms,
      expire_date,
      description,
      description_raw_content,
    };
    if (mode === 'update') {
      updateAnnouncement(announcement.id, cleanFormData);
    } else if (mode === 'create' || mode === 'copy') {
      createAnnouncement(cleanFormData);
    }
    this.setState({
      formData: {
        date: moment().format('YYYY-MM-DD'),
        durationWeeks: 2,
        visible_in_ovio: true,
        send_email: false,
        send_sms: false,
      },
      formValid: {},
      displayedDate: moment(),
      descriptionEditorState: EditorState.createEmpty(),
      notificationEditorState: EditorState.createEmpty(),
    });
    closeModal();
  };

  changeDate = value => {
    const { formData } = this.state;
    this.setState({
      formData: { ...formData, date: moment(value).format('YYYY-MM-DD') },
      displayedDate: value,
    });
  };

  createNotificationEditorState = descriptionEditorState => {
    const rawContent = convertToRaw(descriptionEditorState.getCurrentContent());
    let text = rawContent.blocks[0].text;
    if (text.length > WORKSHOP_NOTIFICATION_MAX_LENGTH) {
      rawContent.blocks[0].text = `${text.slice(
        0,
        WORKSHOP_NOTIFICATION_MAX_LENGTH,
      )}...`;
    }
    return EditorState.createWithContent(convertFromRaw(rawContent));
  };

  descriptionEditorOnChange = descriptionEditorState => {
    const notificationEditorState = this.createNotificationEditorState(
      descriptionEditorState,
    );
    this.setState({
      notificationEditorState,
      descriptionEditorState,
    });
  };

  openBuySMSModal = () => {
    this.setState({ buySMSModalIsOpen: true });
  };

  closeBuySMSModal = () => {
    this.setState({ buySMSModalIsOpen: false });
  };

  getRequiredSMSCount = () => {
    const { descriptionEditorState } = this.state;
    const { user, selectedWorkshopId } = this.props;
    const workshop = user.workshops.find((ws) => ws.id == selectedWorkshopId);
    const descriptionLength = descriptionEditorState
      .getCurrentContent()
      .getPlainText().length;
    const smsPerAnnouncement =
      descriptionLength < 161 ? 1 : Math.ceil(descriptionLength / 153);
    return (
      workshop &&
      smsPerAnnouncement * workshop.announcement_receiver_count
    );
  };

  render() {
    const {
      formData,
      descriptionEditorState,
      notificationEditorState,
      buySMSModalIsOpen,
      displayedDate,
    } = this.state;
    const {
      modalIsOpen,
      closeModal,
      afterOpenModal,
      t,
      mode = 'create',
      smsMax,
      smsAvailable,
    } = this.props;

    const requiredSMSCount = this.getRequiredSMSCount();

    return (
      <CBMediaQuery>
        {screenSize => (
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={getModalStyle(screenSize, styles.modal)}
          >
            <div style={getStyle(screenSize, styles, 'container')}>
              <img
                src='/img/icon_close.svg'
                alt='close'
                style={getStyle(screenSize, styles, 'closeIcon')}
                onClick={closeModal}
              />
              <p style={getStyle(screenSize, styles, 'title')}>
                {mode === 'update'
                  ? t('updateAnnouncementTitle')
                  : t('addAnnouncementTitle')}
              </p>
              <div style={getStyle(screenSize, styles, 'fieldTitle')}>
                {t('publishDateInputTitle')}
              </div>
              <CBDatePickerInput
                displayedDate={displayedDate}
                type='date'
                changeDate={this.changeDate}
              />
              <div style={getStyle(screenSize, styles, 'fieldTitle')}>
                {t('durationInputTitle')}
              </div>
              <div style={getStyle(screenSize, styles, 'checkboxRow')}>
                <div style={getStyle(screenSize, styles, 'checkbox')}>
                  <CBLabelAndCheckbox
                    checked={formData.durationWeeks === 2}
                    labelText={`2 ${t('weekAbbreviation')}`}
                    color='blue'
                    onChange={() => this.changeDuration(2)}
                  />
                </div>
                <div style={getStyle(screenSize, styles, 'checkbox')}>
                  <CBLabelAndCheckbox
                    checked={formData.durationWeeks === 4}
                    labelText={`1 ${t('monthAbbreviation')}`}
                    color='blue'
                    onChange={() => this.changeDuration(4)}
                  />
                </div>
                <div style={getStyle(screenSize, styles, 'checkbox')}>
                  <CBLabelAndCheckbox
                    checked={formData.durationWeeks === 8}
                    labelText={`2 ${t('monthAbbreviation')}`}
                    color='blue'
                    onChange={() => this.changeDuration(8)}
                  />
                </div>
              </div>
              <div style={getStyle(screenSize, styles, 'fieldTitle')}>
                {t('messageTypeInputTitle')}
              </div>
              <div style={getStyle(screenSize, styles, 'checkboxRow')}>
                <div style={getStyle(screenSize, styles, 'checkbox')}>
                  <CBLabelAndCheckbox
                    checked={formData.visible_in_ovio}
                    labelText={t('messageInOvioCheckboxLabel')}
                    color='blue'
                    onChange={() => this.toggleBoolean('visible_in_ovio')}
                  />
                </div>
                <div style={getStyle(screenSize, styles, 'checkbox')}>
                  <CBLabelAndCheckbox
                    checked={formData.send_email}
                    labelText={t('emailCheckboxLabel')}
                    color='blue'
                    onChange={() => this.toggleBoolean('send_email')}
                  />
                </div>
                <div style={getStyle(screenSize, styles, 'checkbox')}>
                  <CBLabelAndCheckbox
                    checked={formData.send_sms}
                    labelText={`${t('smsCheckboxLabel')} (${t(
                      'available',
                    )} ${smsAvailable}/${smsMax}, ${t(
                      'requiredCountForAnnouncement',
                    )} ${requiredSMSCount})`}
                    color='blue'
                    onChange={() => this.toggleBoolean('send_sms')}
                  />
                </div>
              </div>
              <div style={getStyle(screenSize, styles, 'fieldTitle')}>
                {t('descriptionInputTitle')}
              </div>
              <EditorWithBold
                editorState={descriptionEditorState}
                onChange={this.descriptionEditorOnChange}
              />
              <div style={getStyle(screenSize, styles, 'blueFieldTitle')}>
                {t('notificationInputTitle')}
              </div>
              <EditorBlue
                readOnly
                editorState={notificationEditorState}
                onChange={() => null}
              />
              <div style={getStyle(screenSize, styles, 'saveButtonContainer')}>
                <CBButtonSubmit
                  disabled={!this.getIsValid()}
                  onClick={this.submitForm}
                  text={t('saveButtonLabel')}
                />
              </div>
            </div>
            <BuySMSModal
              modalIsOpen={buySMSModalIsOpen}
              closeModal={this.closeBuySMSModal}
            />
          </Modal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    closeIcon: {
      position: 'absolute',
      top: 8,
      right: 8,
      opacity: 0.2,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      padding: 10,
    },
    title: {
      fontSize: 32,
      color: '#0095da',
      textAlign: 'center',
    },
    fieldTitle: {
      fontSize: 14,
      color: 'black',
      textAlign: 'center',
      fontFamily: 'TitilliumWeb-SemiBold',
      marginBottom: 5,
    },
    blueFieldTitle: {
      width: '100%',
      fontSize: 14,
      color: 'white',
      textAlign: 'center',
      fontFamily: 'TitilliumWeb-SemiBold',
      padding: 15,
      marginTop: 10,
      background: '#0095da',
      borderTopLeftRadius: 2,
      borderTopRightRadius: 2,
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      marginBottom: 30,
      width: 180,
    },
    info: {
      width: '100%',
      fontSize: 14,
      color: 'black',
      paddingHorizontal: 30,
      textAlign: 'center',
    },
    checkboxRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignContent: 'center',
      justifyContent: 'center',
      marginBottom: 10,
    },
    checkbox: {
      margin: '0 15px',
    },
    descriptionInput: {
      width: '80%',
      height: 250,
      resize: 'none',
    },
    saveButtonContainer: {
      display: 'flex',
      marginTop: 20,
      width: '100%',
      justifyContent: 'center',
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      width: 200,
      resize: 'none',
      color: 'black',
      height: 30,
    },
    descriptionInput: {
      width: '90%',
      height: 150,
      resize: 'none',
    },
  },
  modal: {
    default: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        maxHeight: '95vh',
      },
      overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    medium: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    small: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '90%',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    announcements: state.announcements,
    smsAvailable: state.workshop.smsAvailable,
    smsMax: state.workshop.smsMax,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchWorkshops: _fetchWorkshops,
    fetchSMSCounts: _fetchSMSCounts,
    createAnnouncement: _createAnnouncement,
    updateAnnouncement: _updateAnnouncement,
  },
)(translate('AnnouncementView')(AddAnnouncementModal));
