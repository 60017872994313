import {
  API, GET, POST, PUT, DELETE,
} from '../middleware/api';
import { API_URL } from '../config';

export function fetchInvoiceReports(params) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/invoice_report/`,
      types: [
        'FETCH_INVOICE_REPORTS_REQUEST',
        'FETCH_INVOICE_REPORTS_SUCCESS',
        'FETCH_INVOICE_REPORTS_FAIL',
      ],
      params,
    },
  };
}

export function fetchInvoiceReport(id) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/invoice_report/${id}/`,
      types: [
        'FETCH_INVOICE_REPORT_REQUEST',
        'FETCH_INVOICE_REPORT_SUCCESS',
        'FETCH_INVOICE_REPORT_FAIL',
      ],
    },
  };
}
