import React, { Component } from 'react';
import Modal from 'react-modal';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../../shared/CBMediaQuery';
import '../../../App.css';
import { getStyle, getModalStyle, finnishDateFormat, SERVICE_PERSONAL, SERVICE_FLEET } from '../../../utils/utils';
import CBButtonSubmit from '../../shared/CBButtonSubmit';
import CBInputField from '../../shared/form_components/CBInputField';
import CBTextAreaField from '../../shared/form_components/CBTextAreaField';
import CBSelectField from '../../shared/form_components/CBSelectField';
import {
  createVehicleTreatment as _createVehicleTreatment,
  updateVehicleTreatment as _updateVehicleTreatment,
} from '../../../actions/vehicleActions';
import {
  getNumberValidator,
  getLengthValidator,
} from '../../../utils/validators';
import CBDatePickerInput from '../../shared/CBDatePickerInput';
import CBModal from '../../shared/CBModal';


class AddTreatmentModal extends Component {
  constructor(props) {
    super(props);

    const { treatment, service } = props;

    const defaultTreatmentType = service === SERVICE_PERSONAL ? 'treatment' : 'workshop_maintenance';

    this.state = {
      formData: {
        treatment_type: treatment ? treatment.treatment_type : defaultTreatmentType,
        km: treatment ? treatment.km : null,
        name: treatment ? treatment.name : '',
        user: treatment ? treatment.user : '',
        description: treatment ? treatment.description : '',
        date: treatment
          ? moment(treatment.date).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
        vehicle: '',
        workshop: null,
      },
      formValid: {
        name: !!treatment,
        description: !!treatment,
      },
    };
  }

  componentDidMount = () => {
    const { user, vehicle, treatment, service } = this.props;
    const { formData } = this.state;

    const defaultTreatmentType = service === SERVICE_PERSONAL ? 'treatment' : 'workshop_maintenance';

    this.setState({
      formData: {
        ...formData,
        user: treatment
          ? treatment.name
          : `${user.profile.first_name} ${user.profile.last_name}`,
        vehicle: vehicle.id,
        treatment_type: treatment ? treatment.treatment_type : defaultTreatmentType,
        workshop: null,
      },
    });
  };

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
      [name]: isValid,
    };

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const { formData } = this.state;
    const {
      createVehicleTreatment,
      updateVehicleTreatment,
      treatment,
      closeModal,
      vehicle,
      user,
    } = this.props;
    if (treatment) {
      updateVehicleTreatment(treatment.id, formData);
    } else {
      createVehicleTreatment(formData);
    }
    closeModal();
  };

  render() {
    const { formData } = this.state;
    const {
      modalIsOpen,
      closeModal,
      afterOpenModal,
      t,
      treatment,
      user,
      vehicle,
      service
    } = this.props;

    const treatmentTypes = (service === SERVICE_PERSONAL || service === SERVICE_FLEET)
      ? [
          { value: 'treatment', label: t('treatmentTypeTreatment') },
          { value: 'maintenance', label: t('treatmentTypeMaintenance') },
          { value: 'repair', label: t('treatmentTypeRepair') },
          { value: 'wash', label: t('treatmentTypeWash') },
        ]
      : [
          {
            value: 'workshop_maintenance',
            label: t('treatmentTypeWorkshopMaintenance'),
          },
        ];
    return (
      <CBMediaQuery>
        {screenSize => (
          <CBModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={closeModal}
            title={treatment ? t('updateTreatmentLabel') : t('addTreatmentLabel')}
            descriptionText=''
            cancelText='Peruuta'
            submitText='Lähetä'
            submitForm={this.submitForm}
            isValid={this.getIsValid()}
            vehicleText={vehicle.registration_number}
          >
            <div style={getStyle(screenSize, styles, 'bodyContainer')}>
              <div style={getStyle(screenSize, styles, 'inputContainer')}>
                <CBSelectField
                  name='treatment_type'
                  inputStyle={getStyle(screenSize, styles, 'input')}
                  value={formData.treatment_type}
                  options={treatmentTypes}
                  placeholder='Valitse'
                  onChange={this.updateFormState}
                  labelText={t('addTreatmentTypeLabel')}
                  labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                />
              </div>
              <div style={getStyle(screenSize, styles, 'inputContainer')}>
                <CBInputField
                  name='km'
                  inputStyle={getStyle(screenSize, styles, 'input')}
                  value={parseInt(formData.km)}
                  type='number'
                  min='0'
                  max='99999999'
                  maxlength='8'
                  placeholder='Mittarilukema'
                  onChange={this.updateFormState}
                  validators={[
                    getNumberValidator(),
                    getLengthValidator(0, 8)
                  ]}
                  isValidCallback={this.setIsValid}
                  labelText={t('addTreatmentKmLabel')}
                  labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                />
              </div>
              <div style={getStyle(screenSize, styles, 'inputContainer')}>
                <p
                  className='font-titillium-web-semi-bold'
                  style={getStyle(screenSize, styles, 'dateInputLabel')}
                >
                  {t('addTreatmentDateLabel')}
                </p>
                <div className='datepicker-container-white-bg'>
                  <CBDatePickerInput
                    displayedDate={formData.date ? moment(formData.date) : null}
                    type='date'
                    dateFormat={finnishDateFormat}
                    changeDate={(value) => {
                      this.updateFormState({ target: 'date', value: moment(value).format('YYYY-MM-DD') });
                    }}
                  />
                </div>
              </div>
              <div style={getStyle(screenSize, styles, 'inputContainer')}>
                <CBInputField
                  name='name'
                  inputStyle={getStyle(screenSize, styles, 'input')}
                  value={formData.name}
                  placeholder={t('treatmentTitlePlaceholder')}
                  onChange={this.updateFormState}
                  validators={[getLengthValidator(1, 40)]}
                  isValidCallback={this.setIsValid}
                  labelText={t('addTreatmentNameLabel')}
                  labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                />
              </div>
              <div style={getStyle(screenSize, styles, 'inputContainer')}>
                <CBTextAreaField
                  name='description'
                  value={formData.description}
                  onChange={this.updateFormState}
                  labelText={t('addTreatmentDescriptionLabel')}
                  labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                  validators={[getLengthValidator(1, 5000)]}
                  isValidCallback={this.setIsValid}
                  validateOnInput
                />
              </div>
            </div>
          </CBModal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 32px'
    },
    inputContainer: {
      marginBottom: 16,
    },
    imagesContainer: {
      marginTop: 16,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    dropZoneContainer: {
      marginTop: 16,
    },
    dropZoneText: {
      color: 'rgb(102, 102, 102)',
      fontSize: 24,
    },
    datepickerContainer: {
      display: 'flex',
    },
    dateInputLabel: {
      color: 'black',
      textAlign: 'center'
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      width: '100%',
    },
    inputLabelText: {
      color: 'black',
    },
  },
  small: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 16px'
    },
  }
};

function mapStateToProps(state) {
  return {
    vehiclePermissionRequest: state.vehiclePermission,
    user: state.authUser.user,
    vehicle: state.vehicle.vehicle,
    service: state.app.service,
  };
}

export default connect(
  mapStateToProps,
  {
    createVehicleTreatment: _createVehicleTreatment,
    updateVehicleTreatment: _updateVehicleTreatment,
  },
)(translate('VehicleDetailsView')(AddTreatmentModal));
