import React from 'react';
import { translate } from 'react-i18next';
import { getStyle } from '../../../../utils/utils';
import VehicleDetailsNavbarButton from '../../../shared/NavbarButton';
import CBMediaQuery from '../../../shared/CBMediaQuery';

class WSVehicleDetailsNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'Maintenance',
    };
  }

  render() {
    const { t, vehicle, history } = this.props;
    const { activeTab } = this.state;

    const pathParts = history.location.pathname.split('/');
    let maintenancesLinkReplace = false;
    if (
      pathParts.length > 4
      && pathParts[3] === 'maintenances'
      && (
        !vehicle.maintenances[0]
        || parseInt(pathParts[4], 10) === vehicle.maintenances[0].id
      )
    ) {
      // If current route is the first maintenance in the list or there are no maintenances
      // use 'replace' instead of 'push' when navigating to avoid duplicate routes on the stack
      maintenancesLinkReplace = true;
    }
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'navbarContainer')}>
            <div style={getStyle(screenSize, styles, 'buttonGroup')}>
              <VehicleDetailsNavbarButton
                to={`/workshop-vehicle/${vehicle.id}/basic/`}
                iconSrc='/img/icon_car_basic_info.svg'
                alt='Basic'
                buttonText={t('basicInfoHeading')}
                setActiveTab={tabName => this.setState({ activeTab: tabName })}
                activeTab={activeTab}
                inactive
              />
              {vehicle.maintenances && (
                <VehicleDetailsNavbarButton
                  to={`/workshop-vehicle/${vehicle.id}/maintenances/`}
                  iconSrc='/img/icon_car_service.svg'
                  alt='Maintenance'
                  buttonText={t('maintenanceHistoryHeading')}
                  setActiveTab={tabName =>
                    this.setState({ activeTab: tabName })
                  }
                  activeTab={activeTab}
                  replace={maintenancesLinkReplace}
                />
              )}
              {vehicle.treatments && (
                <VehicleDetailsNavbarButton
                  to={`/workshop-vehicle/${vehicle.id}/treatments/`}
                  iconSrc='/img/icon_car_maintenance.svg'
                  alt='Treatment'
                  buttonText={t('treatmentHistoryHeading')}
                  setActiveTab={tabName =>
                    this.setState({ activeTab: tabName })
                  }
                  activeTab={activeTab}
                  inactive
                />
              )}
              {vehicle.inspections && (
                <VehicleDetailsNavbarButton
                  to={`/workshop-vehicle/${vehicle.id}/inspections/`}
                  iconSrc='/img/icon_car_checkup.svg'
                  alt='Inspection'
                  buttonText={t('inspectionsHeading')}
                  setActiveTab={tabName =>
                    this.setState({ activeTab: tabName })
                  }
                  activeTab={activeTab}
                  inactive
                />
              )}
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    navbarContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
    },
    buttonGroup: {
      width: '70%',
      display: 'flex',
      justifyContent: 'flex-start',
    },
    navbarButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRight: '1px solid #FFF',
      padding: 16,
      cursor: 'pointer',
    },
    buttonIcon: {
      display: 'inline-block',
      width: 64,
      height: 64,
    },
    buttonText: {
      display: 'inline',
      color: '#FFF',
      fontSize: 18,
    },
  },
  medium: {
    buttonGroup: {
      width: '100%',
      justifyContent: 'center',
    },
  },
  small: {
    buttonGroup: {
      width: '100%',
    },
  },
};

export default translate('VehicleDetailsView')(WSVehicleDetailsNavbar);
