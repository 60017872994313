import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { ProgressSpinner } from 'primereact/progressspinner';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBButtonSettingsWhite from '../../shared/CBButtonSettingsWhite';
import CBSearchInput from '../../shared/CBSearchInput';
import UserListItem from './UserListItem';
import { fetchUsers as _fetchUsers } from '../../../actions/userActions';
import UserSettingsProfileInfoForm from './UserSettingsProfileInfoForm';
import CBHeaderTextWithHelp from '../../shared/CBHeaderTextWithHelp';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';
import CBSortableTableHeader from '../../shared/CBSortableTableHeader';

class StaffSettingsUsers extends React.Component {
  state = {
    pageNumber: 1,
    search: '',
    filters: {},
    selectedUser: undefined,
    ordering: 'id',
  };

  componentDidMount = () => {
    const { fetchUsers } = this.props;
    const { pageNumber } = this.state;

    fetchUsers(pageNumber);
  };

  selectUser = (user) => {
    this.setState({ selectedUser: Object.assign({}, user) });
  };

  changeSearchTerms = value => {
    this.setState({
      search: value,
    });
  }

  fetchMore = () => {
    const { fetchUsers } = this.props;
    const { pageNumber, search, filters, ordering } = this.state;
    const newPageNumber = pageNumber + 1;

    this.setState({
      pageNumber: newPageNumber,
    });

    fetchUsers(newPageNumber, search, { ...filters, ordering });
  }

  fetchWithSearch = (newFilters) => {
    const { fetchUsers } = this.props;
    const { search, filters } = this.state;
    const newPageNumber = 1;
    this.setState({
      pageNumber: newPageNumber,
    });

    fetchUsers(newPageNumber, search, newFilters || filters);
  }

  updateOrganizationUserFilter = () => {
    const { filters } = this.state;

    const newFilters = { ...filters };

    if (!('is_organization_user' in newFilters)) {
      newFilters.is_organization_user = 1;
    } else {
      delete newFilters.is_organization_user;
    }

    this.setState({
      filters: newFilters,
    });

    this.fetchWithSearch(newFilters);
  }

  changeOrdering = (clickedOrdering) => {
    const { ordering, filters } = this.state;
    let newOrdering;
    if (ordering === clickedOrdering) {
      newOrdering = `-${ordering}`;
    } else if (`-${ordering}` === clickedOrdering) {
      newOrdering = 'id';
    } else {
      newOrdering = clickedOrdering;
    }
    this.setState({
      ordering: newOrdering,
    });
    this.fetchWithSearch({ ...filters, ordering: newOrdering });
  }

  render() {
    const {
      t, users, loading, totalUsers, userRequest,
    } = this.props;

    const {
      selectedUser,
      filters,
      ordering,
    } = this.state;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'editUserContainer')}>
              <CBHeaderTextWithHelp text={t('staffEditUserHeading')} />
              {
                selectedUser && (
                  <UserSettingsProfileInfoForm
                    user={selectedUser}
                    userRequest={userRequest}
                    isOwnUser={false}
                  />
                )
              }
            </div>
            <div style={getStyle(screenSize, styles, 'searchUserContainer')}>
              <CBHeaderTextWithHelp text={t('staffUsersHeading')} />
              <div>
                <div style={getStyle(screenSize, styles, 'searchContainer')}>
                  <CBSearchInput
                    containerStyle={{ marginLeft: 0 }}
                    changeSearchTerms={this.changeSearchTerms}
                    onBlur={this.fetchWithSearch}
                  />
                </div>
                <div style={getStyle(screenSize, styles, 'checkBoxContainer')}>
                  <CBLabelAndCheckbox
                    checked={filters.is_organization_user}
                    labelText={t('showOnlyOrganizationUsers')}
                    color='white'
                    onChange={() => this.updateOrganizationUserFilter()}
                  />
                </div>
                <div>
                  <table style={getStyle(screenSize, styles, 'table')}>
                    <thead>
                      <tr>
                        {
                          screenSize === 'large' && (
                            <CBSortableTableHeader
                              onClick={this.changeOrdering}
                              fieldName='id'
                              text='Id'
                              style={getStyle(screenSize, styles, 'tableHeader')}
                              ordering={ordering}
                            />
                          )
                        }
                        <CBSortableTableHeader
                          onClick={this.changeOrdering}
                          fieldName='profile__first_name'
                          text={t('userTableFirstName')}
                          style={getStyle(screenSize, styles, 'tableHeader')}
                          ordering={ordering}
                        />

                        <CBSortableTableHeader
                          onClick={this.changeOrdering}
                          fieldName='profile__last_name'
                          text={t('userTableLastName')}
                          style={getStyle(screenSize, styles, 'tableHeader')}
                          ordering={ordering}
                        />

                        <CBSortableTableHeader
                          onClick={this.changeOrdering}
                          fieldName='email'
                          text='Email'
                          style={getStyle(screenSize, styles, 'tableHeader')}
                          ordering={ordering}
                        />

                        {
                          screenSize === 'large' && (
                            <CBSortableTableHeader
                              onClick={this.changeOrdering}
                              fieldName='last_login'
                              text={t('userTableLastLogin')}
                              style={getStyle(screenSize, styles, 'tableHeader')}
                              ordering={ordering}
                            />
                          )
                        }
                        <th style={getStyle(screenSize, styles, 'tableHeader')} />
                      </tr>
                    </thead>
                    <tbody>
                      {users.map(user => (
                        <UserListItem
                          key={user.id}
                          user={user}
                          onEditClick={this.selectUser}
                        />
                      ))}
                    </tbody>
                  </table>
                  <div style={getStyle(screenSize, styles, 'loadMoreContainer')}>
                    {
                      loading && (
                        <ProgressSpinner />
                      )
                    }
                    {
                      !loading && users.length < totalUsers && (
                        <CBButtonSettingsWhite text='Lataa lisää...' onClick={() => { this.fetchMore(); }} />
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      paddingBottom: 50,
    },
    editUserContainer: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
      width: '40%',
      borderRight: '1px solid rgba(255, 255, 255, 0.4)',
      minHeight: 500,
    },
    searchUserContainer: {
      width: '60%',
      padding: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
    searchContainer: {
      marginBottom: '16px',
      maxWidth: '400px',
    },
    checkBoxContainer: {
      marginBottom: '16px',
    },
    table: {
      width: '100%',
    },
    tableHeader: {
      fontFamily: 'TitilliumWeb-Light',
      color: '#FFFFFF',
      cursor: 'pointer',
      WebkitUserSelect: 'none',
      MsUserSelect: 'none',
      UserSelect: 'none',
    },
    loadMoreContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 16,
    },
  },
  medium: {
    container: {
      paddingBottom: 25,
    },
    editUserContainer: {
      paddingLeft: 0,
      paddingRight: 15,
      paddingTop: 15,
    },
    searchUserContainer: {
      padding: 15,
      paddingLeft: 15,
      paddingRight: 0,
    },
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    editUserContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      width: '100%',
      borderRight: 0,
      marginBottom: 20,
      minHeight: 0,
    },
    searchUserContainer: {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: 20,
    },
  },
};

function mapStateToProps(state) {
  return {
    userRequest: state.user,
    users: state.user.users,
    totalUsers: state.user.totalUsers,
    loading: state.user.usersStatus.loading,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchUsers: _fetchUsers,
  },
)(translate('SettingsView')(StaffSettingsUsers));
