import {
  API, GET, POST, PUT, DELETE,
} from '../middleware/api';
import { API_URL } from '../config';

export function fetchOrganizationVehicleReminders(params) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/organization_vehicle_reminder/`,
      types: [
        'FETCH_ORGANIZATION_VEHICLE_REMINDER_REQUEST',
        'FETCH_ORGANIZATION_VEHICLE_REMINDER_SUCCESS',
        'FETCH_ORGANIZATION_VEHICLE_REMINDER_FAIL',
      ],
      params,
    },
  };
}

export function createOrganizationVehicleReminder(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/organization_vehicle_reminder/`,
      types: [
        'CREATE_ORGANIZATION_VEHICLE_REMINDER_REQUEST',
        'CREATE_ORGANIZATION_VEHICLE_REMINDER_SUCCESS',
        'CREATE_ORGANIZATION_VEHICLE_REMINDER_FAIL',
      ],
      data,
    },
  };
}

export function updateOrganizationVehicleReminder(organizationVehicleReminderId, data, params={}) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/organization_vehicle_reminder/${organizationVehicleReminderId}/`,
      types: [
        'UPDATE_ORGANIZATION_VEHICLE_REMINDER_REQUEST',
        'UPDATE_ORGANIZATION_VEHICLE_REMINDER_SUCCESS',
        'UPDATE_ORGANIZATION_VEHICLE_REMINDER_FAIL',
      ],
      data,
      params,
    },
  };
}

export function deleteOrganizationVehicleReminder(organizationVehicleReminderId, params={}) {
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/organization_vehicle_reminder/${organizationVehicleReminderId}/`,
      types: [
        'DELETE_ORGANIZATION_VEHICLE_REMINDER_REQUEST',
        'DELETE_ORGANIZATION_VEHICLE_REMINDER_SUCCESS',
        'DELETE_ORGANIZATION_VEHICLE_REMINDER_FAIL',
      ],
      additionalData: {
        itemId: organizationVehicleReminderId,
      },
      params
    },
  };
}
