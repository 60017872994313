import React, { Component } from 'react';
import { getStyle, styleMerge } from '../../../../utils/utils';
import CBMediaQuery from '../../../shared/CBMediaQuery';

export default class EditButton extends Component {
  render() {
    const { onClick } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div
            onClick={onClick}
            style={styleMerge(
              getStyle(screenSize, styles, 'container'),
              onClick ? undefined : getStyle(screenSize, styles, 'disabled'),
            )}
          >
            <img src='/img/edit.svg' alt='edit' />
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      border: '1px solid white',
      borderRadius: 50,
      height: 50,
      width: 50,
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    disabled: {
      opacity: 0.4,
      cursor: 'auto',
    },
  },
};
