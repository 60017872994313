import React from 'react';
import CBMediaQuery from './shared/CBMediaQuery';
import { getStyle } from '../utils/utils';

class MainLayoutView extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div
            className='main-content-container'
            style={getStyle(screenSize, styles, 'mainContentContainer')}
          >
            {children}
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    mainContentContainer: {
      paddingLeft: 175,
      paddingRight: 175,
      marginTop: 50,
    },
  },
  medium: {
    mainContentContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: 50,
    },
  },
};

export default MainLayoutView;
