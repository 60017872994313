import React from 'react';
import { getStyle, styleMerge } from '../../utils/utils';
import CBCheckbox from './form_components/CBCheckbox';
import CBMediaQuery from './CBMediaQuery';

class CBLabelAndCheckbox extends React.Component {
  render() {
    const {
      additionalStyles,
      labelText,
      checked,
      onChange,
      color = 'white',
    } = this.props;
    let textColor = 'white';
    if (color === 'blue') {
      textColor = 'black';
    }
    return (
      <CBMediaQuery>
        {screenSize => (
          <div
            onClick={onChange}
            style={styleMerge(
              getStyle(screenSize, styles, 'checkboxContainer'),
              additionalStyles || {},
            )}
          >
            <CBCheckbox checked={checked} color={color} />
            {
              !!labelText && (
                <p
                  style={{
                    ...getStyle(screenSize, styles, 'checkboxLabel'),
                    color: textColor,
                    fontFamily: color === 'blue' ? 'TitilliumWeb-Bold' : null,
                  }}
                >
                  {labelText}
                </p>
              )
            }

          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    checkboxContainer: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
    },
    checkboxLabel: {
      marginLeft: 8,
      marginBottom: 0,
    },
  },
};

export default CBLabelAndCheckbox;
