import i18n from 'i18next';
import transFi from './translations/fi';
import transEn from './translations/en';

i18n.init({
  resources: {
    en: transEn,
    fi: transFi,
  },
  fallbackLng: 'en',
  debug: false,
  lng: 'fi',

  ns: ['default', 'Footer', 'HomeView'],
  defaultNS: 'default',
  keySeparator: false,

  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },

  react: {
    wait: true,
  },
});

export default i18n;
