import React from 'react';
import { translate } from 'react-i18next';
import CBMediaQuery from './CBMediaQuery';
import { getStyle } from '../../utils/utils';
import CBSwitch from './form_components/CBSwitch';

class CBLabelAndSwitch extends React.Component {
  render() {
    const {
      labelText, size, checked, onChange,
    } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'switchContainer')}>
            <p
              className={size === 'small' ? 'font-titillium-web-regular' : 'font-titillium-web-semi-bold'}
              style={size === 'small' ? getStyle(screenSize, styles, 'switchLabelSmall') : getStyle(screenSize, styles, 'switchLabel')}
            >
              {labelText}
            </p>
            <div>
              <CBSwitch onChange={onChange} checked={checked} size={size} />
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    switchContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 16,
    },
    switchLabel: {
    },
    switchLabelSmall: {
    },
  },
};


export default (translate('SettingsView')(CBLabelAndSwitch));
