import React from 'react';
import { getStyle, styleMerge } from '../../utils/utils';
import CBButtonGarageAction from './CBButtonGarageAction';
import CBButtonSubmit from './CBButtonSubmit';

export default function ImagePreview(props) {
  const {
    onDelete, onClick, src,
  } = props;

  const imageStyle = {
    backgroundImage: `url("${src}")`,
  };

  return (
    <div style={styles.container}>
      <div onClick={onClick} style={styleMerge(styles.imageContainer, imageStyle)}>
        <div style={{ width: '100%', height: '100%' }} />
      </div>
      <CBButtonSubmit
        disabled={false}
        onClick={onDelete}
        text='Poista kuva'
        textStyle={styles.cancelButtonText}
        style={styles.cancelButton}
      />

    </div>
  )
}

const styles = {
  container: {
    width: 150,
    display: 'flex',
    flexDirection: 'column',
    marginRight: 8,
    marginBottom: 16,
  },
  imageContainer: {
    height: 150,
    width: 150,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    cursor: 'pointer',
  },
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
  },
  cancelButtonText: {
    fontSize: 14,
    fontWeight: 700,
    textDecoration: 'none solid rgb(0, 246, 0)',
    color: 'black',
    padding: 8,
    marginBottom: 0,
  },
  cancelButton: {
    marginTop: 8,
    width: '100%',
    border: '1px solid #00000050',
    color: '#000000',
    borderRadius: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
};
