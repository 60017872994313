import { messageState } from '../initialState';
import { storeInsertItems, storeInsertItem, storeRemoveItem, storeUpdateObjectInArray } from '../utils/utils';

export default function (state = messageState, action) {
  switch (action.type) {
    case 'FETCH_MESSAGES_REQUEST': {
      return {
        ...state,
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: true,
          loadingFetch: true,
          error: null,
        }),
      };
    }
    case 'FETCH_MESSAGES_SUCCESS': {
      return {
        ...state,
        messages: action.payload,
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_MESSAGES_FAIL': {
      return {
        ...state,
        messages: [],
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_MESSAGE_REQUEST': {
      return {
        ...state,
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_MESSAGE_SUCCESS': {
      const messages = Array.isArray(action.payload)
        ? storeInsertItems(state.messages, action.payload)
        : storeInsertItem(state.messages, action.payload);
      return {
        ...state,
        messages: messages,
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_MESSAGE_FAIL': {
      return {
        ...state,
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_MESSAGE_RESPONSE_REQUEST': {
      return {
        ...state,
        messageResponseStatus: Object.assign({}, state.messageResponseStatus, {
          loading: true,
          error: null,
        }),
      };
    }

    case 'CREATE_MESSAGE_RESPONSE_SUCCESS': {
      const messageId = action.payload.message;
      const message = state.messages.find(t => t.id == messageId);
      const newMessage = Object.assign({}, message);
      newMessage.responses.push(action.payload);
      return {
        ...state,
        messages: storeUpdateObjectInArray(state.messages, newMessage),
        messageResponseStatus: Object.assign({}, state.messageResponseStatus, {
          loading: false,
          error: null,
        }),
      };
    }

    case 'CREATE_MESSAGE_RESPONSE_FAIL': {
      return {
        ...state,
        messageResponseStatus: Object.assign({}, state.messageResponseStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }

    case 'UPDATE_MESSAGE_REQUEST': {
      return {
        ...state,
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_MESSAGE_SUCCESS': {
      return {
        ...state,
        messages: storeUpdateObjectInArray(state.messages, action.payload),
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_MESSAGE_FAIL': {
      return {
        ...state,
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'DELETE_MESSAGE_REQUEST': {
      return {
        ...state,
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DELETE_MESSAGE_SUCCESS': {
      return {
        ...state,
        messages: storeRemoveItem(state.messages, action.payload.id),
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DELETE_MESSAGE_FAIL': {
      return {
        ...state,
        messageStatus: Object.assign({}, state.messageStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    default:
      break;
  }
  return state;
}
