import React from 'react';
import moment from 'moment';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import OptionsButton from '../../../GarageView/GarageViewComponents/OptionsComponents/OptionsButton';
import { deleteVehicleReminder as _deleteVehicleReminder } from '../../../../actions/vehicleActions';

class VehicleReminderListItem extends React.Component {
  render() {
    const { reminder, t, deleteVehicleReminder } = this.props;
    const reminderOptions = [
      {
        title: t('remove'),
        action: () => {
          deleteVehicleReminder(reminder.id);
        },
      },
    ];
    return (
      <div style={styles.reminderContainer}>
        <div>
          <p>
            {reminder.name}
          </p>
        </div>
        <div style={styles.detailsContainer}>
          <img src='/img/icon_reminder.svg' alt='Reminder' style={styles.icon} />
          <p style={styles.dueDateText}>
            {moment(reminder.due_date).format('D.M.Y HH:mm')}
          </p>
          <OptionsButton icon='/img/icon_options.svg' options={reminderOptions} />
        </div>
      </div>
    );
  }
}

const styles = {
  reminderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid rgba(255, 255, 255, 0.4)',
    height: 40,
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  dueDateText: {
    marginLeft: 10,
    marginRight: 10,
  },
  icon: {
    width: 24,
    height: 24,
  },
};

function mapStateToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  {
    deleteVehicleReminder: _deleteVehicleReminder,
  },
)(translate('VehicleDetailsView')(VehicleReminderListItem));
