import React from 'react';
import moment from 'moment';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchVehicleMaintenance as _fetchVehicleMaintenance,
  updateVehicleMaintenance as _updateVehicleMaintenance,
  deleteVehicleMaintenance as _deleteVehicleMaintenance,
  createMaintenanceImage as _createMaintenanceImage,
  deleteMaintenanceImage as _deleteMaintenanceImage,
} from '../../../actions/vehicleActions';
import InfoboxWithIcon from './ListItems/InfoboxWithIcon';
import MaintenanceTaskListItem from './ListItems/MaintenanceTaskListItem';
import MaintenancePartListItem from './ListItems/MaintenancePartListItem';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle, styleMerge, formatKm } from '../../../utils/utils';
import CBSpinner from '../../shared/CBSpinner';
import TextWithLineBreaks from '../../shared/TextWithLinebreaks';
import AddMaintenanceModal from './AddMaintenanceModal';
import CBPromptModal from '../../shared/CBPromptModal';
import ImageCarouselModal from './ImageCarouselModal';
import CBDropzone from '../../shared/CBDropzone';
import AddImageModal from '../../shared/AddImageModal';

class VehicleDetailsMaintenanceContent extends React.Component {
  state = {
    modalIsOpen: false,
    deleteModalIsOpen: false,
    imageModalIsOpen: false,
    removingImages: false,
    imageIndex: 0,
  };

  componentDidMount = () => {
    const {
      match, fetchVehicleMaintenance, vehicle, searchRequest,
    } = this.props;
    const { maintenanceIDs } = searchRequest;
    if (match) {
      fetchVehicleMaintenance(match.params.maintenanceId);
    } else if (vehicle && vehicle.maintenances.length) {
      if (maintenanceIDs) {
        if (maintenanceIDs.length) {
          fetchVehicleMaintenance(maintenanceIDs[0]);
        }
      } else {
        fetchVehicleMaintenance(vehicle.maintenances[0].id);
      }
    }
  };

  componentDidUpdate(prevProps) {
    const {
      match, fetchVehicleMaintenance, vehicle, searchRequest,
    } = this.props;
    const { maintenanceIDs } = searchRequest;

    if (match && match.params.maintenanceId !== prevProps.match.params.maintenanceId) {
      fetchVehicleMaintenance(match.params.maintenanceId);
    } else if (
      vehicle
      && vehicle.maintenances.length
      && vehicle.maintenances[0].complete !== prevProps.vehicle.maintenances[0].complete
    ) {
      if (maintenanceIDs) {
        if (maintenanceIDs.length) {
          fetchVehicleMaintenance(maintenanceIDs[0]);
        }
      } else {
        fetchVehicleMaintenance(vehicle.maintenances[0].id);
      }
    } else if (
      vehicle
      && vehicle.maintenances.length
      && maintenanceIDs
      && maintenanceIDs.length
      && prevProps.searchRequest
      && prevProps.searchRequest.maintenanceIDs
      && maintenanceIDs[0] !== prevProps.searchRequest.maintenanceIDs[0]) {
      fetchVehicleMaintenance(maintenanceIDs[0]);
    }
  }

  deleteVehicleMaintenance = () => {
    const { deleteVehicleMaintenance, match, vehicle } = this.props;
    this.setState({
      deleteModalIsOpen: false,
    });
    if (match) {
      deleteVehicleMaintenance(match.params.maintenanceId);
    } else if (vehicle && vehicle.maintenances.length) {
      deleteVehicleMaintenance(vehicle.maintenances[0].id);
    }
  }

  openDeleteMaintenanceModal = () => {
    this.setState({
      deleteModalIsOpen: true,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      deleteModalIsOpen: false,
    });
  };

  editMaintenance = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  hideAddImageModal = () => {
    this.setState({
      addImageModal: false,
    });
  }

  onImageUpload = acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          addedImage: reader.result,
          addImageModal: true,
        });
      };
    });
  };

  uploadImage = (image, maintenanceId) => {
    const { createMaintenanceImage } = this.props;
    this.setState({ addImageModal: false });
    if (maintenanceId) {
      createMaintenanceImage({
        maintenance: maintenanceId,
        image: image
      });
    }
  }

  toggleImageRemoveMode = () => {
    this.setState(prevState => ({
      ...prevState,
      removingImages: !prevState.removingImages,
    }));
  }

  removeImage = id => {
    const { deleteMaintenanceImage } = this.props;
    deleteMaintenanceImage(id);
  }

  openImageModal = index => {
    this.setState({ imageModalIsOpen: true, imageIndex: index });
  }

  hideImageModal = () => {
    this.setState({ imageModalIsOpen: false });
  }

  render() {
    const {
      vehicleRequest, t, match, vehicle, searchRequest, user, selectedWorkshopId,
    } = this.props;
    const { maintenanceIDs } = searchRequest;
    const {
      modalIsOpen,
      deleteModalIsOpen,
      imageModalIsOpen,
      removingImages,
      imageIndex,
      addImageModal,
      addedImage,
    } = this.state;
    let maintenance = match
      ? vehicle.maintenances.find(
        x => x.id === parseInt(match.params.maintenanceId, 10),
      )
      : undefined;
    if (!maintenance && vehicle && vehicle.maintenances.length) {
      if (maintenanceIDs) {
        if (maintenanceIDs.length) {
          maintenance = vehicle.maintenances.find(
            x => x.id === parseInt(maintenanceIDs[0], 10),
          );
        }
      }
    }
    if (!maintenance) {
      return (
        <CBMediaQuery>
          {screenSize => (
            <div
              style={getStyle(screenSize, styles, 'maintenanceContentContainer')}
            />
          )}
        </CBMediaQuery>
      );
    }
    const editable = (
      vehicle.can_edit
      && user.is_workshop_user
      && maintenance
      && selectedWorkshopId === maintenance.workshop.id
      && maintenance.source === 'carbook');
    if (vehicleRequest.maintenanceStatus.loading || (maintenance && !maintenance.complete)) {
      return (
        <CBMediaQuery>
          {screenSize => (
            <div
              style={getStyle(screenSize, styles, 'maintenanceContentContainer')}
            >
              <CBSpinner />
            </div>
          )}
        </CBMediaQuery>
      );
    } else {
      let sourceCapitalized = '';
      if (maintenance) {
        switch (maintenance.source) {
          case 'autofutur':
          case 'solteq':
          case 'automaster':
            sourceCapitalized = maintenance.source.charAt(0).toUpperCase()
              + maintenance.source.slice(1);
            break;
          case 'carbook':
            sourceCapitalized = 'CarBook';
            break;
          default:
        }
      }
      return (
        <CBMediaQuery>
          {screenSize => (
            <div
              style={getStyle(screenSize, styles, 'maintenanceContentContainer')}
            >
              {maintenance && (
                <React.Fragment>
                  <div style={getStyle(screenSize, styles, 'topInfoContainer')}>
                    <InfoboxWithIcon
                      icon='/img/icon_calendar.svg'
                      text={moment(maintenance.pickup_date).format('D.M.Y')}
                      labelText={t('dateInfobox')}
                      border='right'
                    />
                    <InfoboxWithIcon
                      icon='/img/icon_distance_1.svg'
                      text={`${formatKm(maintenance.km)}`}
                      labelText={t('kilometersInfobox')}
                      hasBorders
                    />
                    <InfoboxWithIcon
                      icon='/img/icon_13-1.svg'
                      text={maintenance.workshop.name}
                      labelText={t('workshopInfobox')}
                      border='left'
                      style={{ default: { infoboxContainer: { borderLeft: '1px solid rgba(255, 255, 255, .4)' } } }}
                    />
                  </div>
                  <div style={getStyle(screenSize, styles, 'bodyContainer')}>
                    <div
                      style={getStyle(screenSize, styles, 'tasksAndPartsContainer')}
                    >
                      <div
                        style={getStyle(screenSize, styles, 'taskContainer')}
                      >
                        <div style={getStyle(screenSize, styles, 'paddedContent')}>
                          <p
                            className='font-titillium-web-extra-light'
                            style={getStyle(screenSize, styles, 'sectionHeadingText')}
                          >
                            {t('maintenanceInfoHeading')}
                          </p>
                        </div>
                        {maintenance.tasks.map(task => (
                          <MaintenanceTaskListItem key={task.id} task={task} />
                        ))}
                      </div>
                      {maintenance.parts.length > 0 && (
                        <div
                          style={getStyle(screenSize, styles, 'partContainer')}
                        >
                          <div style={getStyle(screenSize, styles, 'paddedContent')}>
                            <p
                              className='font-titillium-web-extra-light'
                              style={getStyle(screenSize, styles, 'sectionHeadingText')}
                            >
                              {t('partsHeading')}
                            </p>
                          </div>
                            {maintenance.parts.map(part => (
                              <MaintenancePartListItem key={part.id} part={part} />
                            ))}
                        </div>
                      )}
                    </div>
                    <div style={getStyle(screenSize, styles, 'additionalInfoContainer')}>
                      <p
                        className='font-titillium-web-extra-light'
                        style={getStyle(screenSize, styles, 'sectionHeadingText')}
                      >
                        {t('maintenanceAdditionalInfoHeading')}
                      </p>
                      {maintenance.notes.map(note => (
                        <React.Fragment key={note.id}>
                          <p key={`desc${note.id}`}><TextWithLineBreaks text={note.description} /></p>
                          <p key={`custdesc${note.id}`}>
                            <TextWithLineBreaks text={note.description_customer} />
                          </p>
                        </React.Fragment>
                      ))}
                    </div>
                    {/* Hide because of GDPR */}
                    {/* {maintenance.foreman_name !== '' && (
                      <div style={getStyle(screenSize, styles, 'additionalInfoContainer')}>
                        <p
                          className='font-titillium-web-extra-light'
                          style={getStyle(screenSize, styles, 'sectionHeadingText')}
                        >
                          Työnjohtaja
                        </p>
                        <p>{maintenance.foreman_name}</p>
                      </div>
                    )} */}
                    <div style={getStyle(screenSize, styles, 'workshopAddressContainer')}>
                      <p
                        className='font-titillium-web-extra-light'
                        style={getStyle(screenSize, styles, 'sectionHeadingText')}
                      >
                        {t('workshopAddressHeading')}
                      </p>
                      <p>
                        {`${maintenance.workshop.street_address}, ${
                          maintenance.workshop.zip_code
                        } ${maintenance.workshop.city}`}
                      </p>
                    </div>
                  </div>
                  {maintenance.images.length > 0 && (
                    <div style={getStyle(screenSize, styles, 'imageContainer')}>
                      {maintenance.images.map((image, index) => (
                        <div
                          style={{
                            ...getStyle(screenSize, styles, 'squareImage'),
                            backgroundImage: `url(${image.image})`,
                          }}
                          key={image.id}
                          onClick={
                            removingImages
                              ? () => {
                                this.removeImage(image.id);
                                if (maintenance.images.length === 1) {
                                  this.setState({ removingImages: false });
                                }
                              }
                              : () => this.openImageModal(index)
                          }
                        >
                          {removingImages && <img style={getStyle(screenSize, styles, 'imageRemoveIcon')} src='/img/icon_trashcan.svg' alt={t('removeImg')} />}
                        </div>
                      ))}
                      <ImageCarouselModal
                        show={imageModalIsOpen}
                        handleClose={this.hideImageModal}
                        imageIndex={imageIndex}
                        images={maintenance.images}
                        showNextImage={this.showNextImage}
                        showPreviousImage={this.showPreviousImage}
                        getBackroundImageUrl={this.getBackroundImageUrl}
                      />
                    </div>
                  )}
                  <div style={getStyle(screenSize, styles, 'source')}>
                    {t('maintenanceSourceLabel')}
                    {': '}
                    {sourceCapitalized}
                  </div>
                  {
                    !maintenance.is_vin_confirmed && (
                      <div style={getStyle(screenSize, styles, 'notVinConfirmedContainer')}>
                        <img style={getStyle(screenSize, styles, 'notVinConfirmedImg')} src='/img/icon_attention_red.svg' />
                        <p style={getStyle(screenSize, styles, 'notVinConfirmedText')}>{t('notVinConfirmed')}</p>
                      </div>
                    )
                  }

                  {
                    editable && (
                      <div
                        className='font-titillium-web'
                        style={getStyle(screenSize, styles, 'boxContainer')}
                      >
                        <div
                          className='font-titillium-web'
                          style={getStyle(screenSize, styles, 'boxLeftContainer')}
                        >
                          <CBDropzone
                            className={removingImages ? '' : 'opacity-and-border-on-hover'}
                            style={removingImages ? {
                              ...getStyle(screenSize, styles, 'wideButton'),
                              ...getStyle(screenSize, styles, 'buttonDisabled'),
                            } : getStyle(screenSize, styles, 'wideButton')}
                            onDropAccepted={removingImages
                              ? undefined
                              : (files) => this.onImageUpload(files, maintenance.id)
                            }
                            disabled={removingImages}
                          >
                            <img
                              style={getStyle(screenSize, styles, 'buttonIcon')}
                              alt={t('addImg')}
                              src='/img/icon_plus.svg'
                            />
                            <div style={getStyle(screenSize, styles, 'buttonText')}>
                              {`${t('addImg')} `}
                            </div>
                          </CBDropzone>
                          {maintenance.images.length > 0 && (
                            <div
                              className={removingImages ? '' : 'opacity-and-border-on-hover'}
                              style={removingImages
                                ? styleMerge(
                                  getStyle(screenSize, styles, 'buttonRight'),
                                  getStyle(screenSize, styles, 'buttonGreen'),
                                )
                                : getStyle(screenSize, styles, 'buttonRight')
                              }
                              onClick={this.toggleImageRemoveMode}
                            >
                              <img
                                style={getStyle(screenSize, styles, 'buttonIcon')}
                                alt='remove'
                                src={removingImages ? '/img/icon_checkmark.svg' : '/img/remove.svg'}
                              />
                              <div style={getStyle(screenSize, styles, 'buttonText')}>
                                {removingImages ? t('done') : t('removeImg')}
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          className='font-titillium-web'
                          style={getStyle(screenSize, styles, 'boxRightContainer')}
                        >
                          <div
                            className={removingImages ? '' : 'opacity-and-border-on-hover'}
                            style={removingImages ? {
                              ...getStyle(screenSize, styles, 'buttonLeft'),
                              ...getStyle(screenSize, styles, 'buttonDisabled'),
                            } : getStyle(screenSize, styles, 'buttonLeft')}
                            onClick={removingImages ? undefined : this.editMaintenance}
                          >
                            <img
                              style={getStyle(screenSize, styles, 'buttonIcon')}
                              alt='edit'
                              src='/img/edit.svg'
                            />
                            <div style={getStyle(screenSize, styles, 'buttonText')}>
                              {`${t('edit')} `}
                            </div>
                          </div>
                          <div
                            className={removingImages ? '' : 'opacity-and-border-on-hover'}
                            style={removingImages ? {
                              ...getStyle(screenSize, styles, 'button'),
                              ...getStyle(screenSize, styles, 'buttonDisabled'),
                            } : getStyle(screenSize, styles, 'button')}
                            onClick={removingImages ? undefined : this.openDeleteMaintenanceModal}
                          >
                            <img
                              style={getStyle(screenSize, styles, 'buttonIcon')}
                              alt='remove'
                              src='/img/remove.svg'
                            />
                            <div style={getStyle(screenSize, styles, 'buttonText')}>
                              {`${t('remove')} `}
                            </div>
                          </div>
                        </div>
                        <AddMaintenanceModal
                          modalIsOpen={modalIsOpen}
                          closeModal={this.closeModal}
                          maintenance={maintenance}
                        />
                        <CBPromptModal
                          modalIsOpen={deleteModalIsOpen}
                          closeModal={this.closeDeleteModal}
                          title={t('deleteMaintenancePromptTitle')}
                          text={t('deleteMaintenancePromptText')}
                          buttonText={t('deleteMaintenancePromptButton')}
                          performAction={this.deleteVehicleMaintenance}
                        />
                      </div>
                    )
                  }
                  {addImageModal && (
                    <AddImageModal
                      show={addImageModal}
                      handleClose={this.hideAddImageModal}
                      image={addedImage}
                      onImageSave={(image) => { this.uploadImage(image, maintenance.id); }}
                    />
                  )}
                </React.Fragment>
              )}
            </div>
          )}
        </CBMediaQuery>
      );
    }
  }
}

const styles = {
  default: {
    sectionHeadingText: {
      fontSize: 24,
    },
    paddedContent: {
      paddingLeft: 16,
      paddingTop: 16,
    },
    additionalInfoContainer: {
      padding: 16,
    },
    workshopAddressContainer: {
      padding: 16,
    },
    notVinConfirmedContainer: {
      padding: 16,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    notVinConfirmedImg: {
      height: 35,
      width: 35,
    },
    notVinConfirmedText: {
      margin: '0 0 0 8px',
      fontWeight: 'bold',
    },
    maintenanceContentContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 1)',
      minHeight: 500,
      width: '75%',
    },
    topInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    tasksAndPartsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    taskContainer: {
      width: '50%',
      borderRight: '1px solid rgba(255, 255, 255, .4)',
    },
    partContainer: {
      width: '50%',
    },
    boxContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 67,
      padding: 16,
    },
    boxRightContainer: {
      display: 'flex',
    },
    boxLeftContainer: {
      display: 'flex',
    },
    button: {
      width: 115,
      display: 'flex',
      height: 30,
      border: '1px solid rgba(255, 255, 255, 0.7)',
      borderRadius: 50,
      alignItems: 'center',
      paddingLeft: 5,
      cursor: 'pointer',
    },
    wideButton: {
      width: 130,
      display: 'flex',
      height: 30,
      border: '1px solid rgba(255, 255, 255, 0.7)',
      borderRadius: 50,
      alignItems: 'center',
      paddingLeft: 5,
      cursor: 'pointer',
    },
    buttonRight: {
      width: 130,
      display: 'flex',
      height: 30,
      border: '1px solid rgba(255, 255, 255, 0.7)',
      borderRadius: 50,
      alignItems: 'center',
      paddingLeft: 5,
      marginLeft: 10,
      cursor: 'pointer',
    },
    buttonLeft: {
      width: 115,
      display: 'flex',
      height: 30,
      border: '1px solid rgba(255, 255, 255, 0.7)',
      borderRadius: 50,
      alignItems: 'center',
      paddingLeft: 5,
      marginRight: 10,
      cursor: 'pointer',
    },
    buttonGreen: {
      backgroundColor: '#00F600',
      border: '0 solid white',
    },
    buttonText: {
      color: 'white',
      fontSize: 14,
      textDecoration: 'none solid rgb(255, 255, 255)',
      marginLeft: 10,
    },
    buttonIcon: {
      height: 24,
      width: 24,
      marginLeft: 3,
    },
    imageContainer: {
      padding: 16,
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 50,
    },
    squareImage: {
      width: 100,
      height: 100,
      margin: '0 10px 10px 0',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
      cursor: 'pointer',
    },
    imageRemoveIcon: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
      filter: 'drop-shadow(0px 1px 4px rgba(0,0,0,0.5))',
    },
    source: {
      fontFamily: 'TitilliumWeb-Regular',
      padding: 16,
      color: 'white',
    },
    buttonDisabled: {
      opacity: 0.5,
      cursor: 'default',
    },
  },
  medium: {
    topInfoContainer: {
      width: '100%',
    },
  },
  small: {
    topInfoContainer: {
      display: 'flex',
      justifyContent: 'normal',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
      marginLeft: 0,
      marginRight: 0,
    },
    maintenanceContentContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      backgroundColor: 'transparent',
      minHeight: 500,
      width: '100%',
      padding: 0,
    },
    bodyContainer: {
      backgroundColor: 'rgba(0, 149, 218, 1)',
      padding: 0,
    },
    tasksAndPartsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    taskContainer: {
      width: '100%',
      padding: 0,
      borderRight: 0,
    },
    partContainer: {
      width: '100%',
      padding: 0,
    },
    source: {
      backgroundColor: 'rgba(0, 149, 218, 1)',
      padding: 16,
    },
    notVinConfirmedContainer: {
      backgroundColor: 'rgba(0, 149, 218, 1)',
      padding: 16,
    }
  },
};
function mapStateToProps(state) {
  return {
    vehicleRequest: state.vehicle,
    searchRequest: state.search,
    user: state.authUser.user,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchVehicleMaintenance: _fetchVehicleMaintenance,
    updateVehicleMaintenance: _updateVehicleMaintenance,
    deleteVehicleMaintenance: _deleteVehicleMaintenance,
    createMaintenanceImage: _createMaintenanceImage,
    deleteMaintenanceImage: _deleteMaintenanceImage,
  },
)(translate('VehicleDetailsView')(VehicleDetailsMaintenanceContent));
