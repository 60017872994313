import React, { createRef } from 'react';
import 'cropperjs/dist/cropper.css';
import Cropper from 'react-cropper';
import { translate } from 'react-i18next';
import CBMediaQuery from './CBMediaQuery';
import CBButtonWithIcon from './CBButtonWithIcon';
import { getStyle } from '../../utils/utils';
import { MAX_UPLOAD_FILE_SIZE } from '../../config';

const compressCanvas = (canvas) => {
  // convert the image contained in the canvas into a data URL, so that it's final
  // size is less than MAX_UPLOAD_FILE_SIZE by repeatedly lowering the quality
  let quality = 0.92;
  let dataURL;
  do {
    dataURL = canvas.toDataURL('image/jpeg', quality);
    quality -= 0.1;
  } while (dataURL.length > MAX_UPLOAD_FILE_SIZE && quality > 0);
  return dataURL;
};

class AddImageModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: props.image,
    };
    this.cropper = createRef('cropper');
  }

  componentWillReceiveProps(nextProps) {
    const { image } = this.state;
    if (image !== nextProps.image) {
      this.setState({ image: nextProps.image });
    }
  }

  render() {
    const {
      t, onImageSave, handleClose, aspectRatio,
    } = this.props;
    const { image } = this.state;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div
            className='modal'
            style={{ ...getStyle(screenSize, styles, 'modal') }}
          >
            <div>
              <p
                className='font-titillium-web-light'
                style={getStyle(screenSize, styles, 'headerContainer')}
              >
                {t('cropImage')}
              </p>
            </div>
            <div
              style={{ ...getStyle(screenSize, styles, 'contentContainer') }}
            >
              <Cropper
                ref={this.cropper}
                src={image}
                autoCropArea={1.0}
                aspectRatio={aspectRatio}
                style={{ ...getStyle(screenSize, styles, 'cropperContainer') }}
                guides={false}
                background={false}
              />
            </div>
            <div style={{ ...getStyle(screenSize, styles, 'activityContainer') }}>
              <CBButtonWithIcon
                icon='/img/icon_close_white.svg'
                text={t('cancel')}
                onClick={handleClose}
              />
              <div style={{ ...getStyle(screenSize, styles, 'rotateButtonContainer') }}>
                <button
                  type='button'
                  onClick={() => { this.cropper.current.rotate(-90); }}
                  style={getStyle(screenSize, styles, 'button')}
                >
                  <img
                    src='/img/icon_rotate_left.svg'
                    alt='turn-left'
                    style={{ ...getStyle(screenSize, styles, 'icon') }}
                  />
                </button>
                <button
                  type='button'
                  onClick={() => { this.cropper.current.rotate(90); }}
                  style={getStyle(screenSize, styles, 'button')}
                >
                  <img
                    src='/img/icon_rotate_left.svg'
                    alt='turn-right'
                    style={{ ...getStyle(screenSize, styles, 'mirrorIcon') }}
                  />
                </button>
              </div>
              <CBButtonWithIcon
                icon='/img/icon_checkmark.svg'
                text={t('accept')}
                onClick={() => {
                  const dataURL = compressCanvas(this.cropper.current.getCroppedCanvas());
                  onImageSave(dataURL);
                }}
              />
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

AddImageModal.defaultProps = {
  aspectRatio: NaN,
};

const styles = {
  default: {
    activityContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '80%',
      zIndex: 100,
      marginTop: 10,
      justifyContent: 'space-between',
    },
    button: {
      background: 'transparent',
      margin: 0,
      padding: 0,
      border: 'none',
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '80%',
    },
    cropperContainer: {
      width: '100%',
      height: 600,
    },
    headerContainer: {
      alignSelf: 'center',
      fontSize: 32,
      color: 'white',
      marginBottom: 20,
    },
    icon: {
      width: 48,
      height: 48,
      marginRight: 20,
    },
    mirrorIcon: {
      width: 48,
      height: 48,
      transform: 'scaleX(-1)',
    },
    modal: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.9)',
      alignItems: 'center',
      justifyContent: 'center',
    },
    rotateButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  medium: {
    activityContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '95%',
      zIndex: 100,
      marginTop: 10,
      justifyContent: 'space-between',
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '95%',
    },
    cropperContainer: {
      width: '100%',
      height: 300,
    },
    icon: {
      marginRight: 4,
    }
  },
  small: {},
};

export default translate('VehicleDetailsView')(AddImageModal);
