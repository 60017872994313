import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import CBMediaQuery from '../shared/CBMediaQuery';
import { SERVICE_FLEET, getStyle } from '../../utils/utils';
import TaskListView from './components/TaskListView';

class TasksLayoutView extends React.Component {
  componentDidMount = () => {
  };

  render() {
    const {
      history, t, hasFeatureTasksAndMessages, service
    } = this.props;

    if (!hasFeatureTasksAndMessages && service === SERVICE_FLEET) {
      return (
        <div style={styles.default.welcomeTextContainer}>
        <p className="font-titillium-web-extra-light" style={styles.default.headerTextSub}>
          Et ole oikeutettu käyttämään viestejä ja tehtäviä. Ota yhteyttä ylläpitoon.
        </p>
      </div>
      );
    }

    return (
      <CBMediaQuery>
        {screenSize => (
          <TaskListView />
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    headerTextSub: {
      color: '#FFF',
      fontSize: 20,
      textAlign: 'center',
      margin: 0
    },
    welcomeTextContainer: {
      marginBottom: 16,
      marginTop: 32,
    },
  },
};

function mapStateToProps(state) {
  return {
    service: state.app.service
  };
}

export default connect(
  mapStateToProps,
  {
  },
)(translate('TasksLayoutView')(TasksLayoutView));
