import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import CBMediaQuery from '../shared/CBMediaQuery';

import { getStyle } from '../../utils/utils';
import CBButtonLarge from '../shared/CBButtonLarge';
import WelcomeText from './HomeViewComponents/WelcomeText';
import WelcomeTextSub from './HomeViewComponents/WelcomeTextSub';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';


class HomeAdminUser extends React.Component {
  render() {
    const { history } = this.props;


    return (
      <CBMediaQuery>
        {screenSize => (
          <div>
            <div style={getStyle(screenSize, styles, 'welcomeTextContainer')}>
              <WelcomeText />
            </div>
            <div style={getStyle(screenSize, styles, 'welcomeTextSubContainer')}>
              <WelcomeTextSub />
            </div>
            <div style={getStyle(screenSize, styles, 'bodyContainer')}>
            <div style={getStyle(screenSize, styles, 'buttonContainer')}>
                <CBButtonLarge text="Yksityisportaaliin" onClick={() => { window.location.href = 'https://my.ovio.fi'; }} />
              </div>
              <div style={getStyle(screenSize, styles, 'buttonContainer')}>
                <CBButtonLarge text="Korjaamoportaaliin" onClick={() => { window.location.href = 'https://service.ovio.fi'; }} />
              </div>
              <div style={getStyle(screenSize, styles, 'buttonContainer')}>
                <CBButtonLarge text="Yritysportaaliin" onClick={() => { window.location.href = 'https://fleet.ovio.fi'; }} />
              </div>
              <div style={getStyle(screenSize, styles, 'buttonContainer')}>
                <CBButtonLarge text="Myyntiraportti demo" onClick={() => { history.push(`/sale-report`); }} />
              </div>
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    welcomeTextContainer: {
      marginBottom: 16,
      marginTop: 66,
    },
    welcomeTextSubContainer: {
      marginBottom: 75,
    },
    bodyContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 32,
    },
    buttonContainer: {
      marginBottom: 16,
      minWidth: 280,
    },
  },
};

function mapStateToProps(state) {
  return {
  };
}

export default connect(
  mapStateToProps,
  {
  },
)(translate('HomeAdminUser')(withRouter(HomeAdminUser)));
