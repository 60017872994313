import React from 'react';
import { translate } from 'react-i18next';
import { formatDateTime, getStyle, styleMerge } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';

class MessageCard extends React.Component {
  render() {
    const { t, message, isLastResponse } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={styleMerge(getStyle(screenSize, styles, 'container'), isLastResponse ? getStyle(screenSize, styles, 'lastResponse') : {})}>
            <div style={getStyle(screenSize, styles, 'topInfoContainer')}>
              <p style={getStyle(screenSize, styles, 'topInfoText')}>
                {formatDateTime(message.created_at)}
              </p>
              <p style={getStyle(screenSize, styles, 'topInfoText')}>
                {message.user_display}
              </p>
            </div>
            <p style={getStyle(screenSize, styles, 'title')}>
              {!!message.subject && message.subject}
            </p>
            <p style={getStyle(screenSize, styles, 'text')}>
              {message.text}
            </p>

          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      backgroundColor: '#FFFFFF',
      border: '1px solid rgba(0, 0, 0, 0.2)',
      borderRadius: 8,
      padding: 8,
      maxWidth: 400,
      minWidth: 200,
      marginBottom: 16,
    },
    lastResponse: {
      backgroundColor: '#dbeaf1',
      border: '1px solid #0095da',
    },
    topInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    topInfoText: {
      color: 'black',
      fontFamily: 'TitilliumWeb-SemiBold',
      fontSize: 12,
    },
    text: {
      color: 'black',
      fontSize: 16,
    },
    title: {
      fontSize: 18,
      color: '#0095da',
      fontFamily: 'TitilliumWeb-SemiBold',
    },
  },
};

export default translate('VehicleDetailsView')(MessageCard);
