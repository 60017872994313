import { API, GET, PATCH, POST, PUT, DELETE } from '../middleware/api';
import { API_URL } from '../config';

export function fetchWorkshops(params) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/workshop/`,
      types: [
        'FETCH_WORKSHOPS_REQUEST',
        'FETCH_WORKSHOPS_SUCCESS',
        'FETCH_WORKSHOPS_FAIL',
      ],
      params,
    },
  };
}

export function createWorkshop(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/workshop/`,
      types: [
        'CREATE_WORKSHOP_REQUEST',
        'CREATE_WORKSHOP_SUCCESS',
        'CREATE_WORKSHOP_FAIL',
      ],
      data,
    },
  };
}

export function updateWorkshop(workshopId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/workshop/${workshopId}/`,
      types: [
        'UPDATE_WORKSHOP_REQUEST',
        'UPDATE_WORKSHOP_SUCCESS',
        'UPDATE_WORKSHOP_FAIL',
      ],
      data,
    },
  };
}

export function fetchFavouriteWorkshops() {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/favourite_workshop/`,
      types: [
        'FETCH_FAVOURITE_WORKSHOPS_REQUEST',
        'FETCH_FAVOURITE_WORKSHOPS_SUCCESS',
        'FETCH_FAVOURITE_WORKSHOPS_FAIL',
      ],
    },
  };
}

export function addFavouriteWorkshop(workshopId) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/favourite_workshop/`,
      types: [
        'ADD_FAVOURITE_WORKSHOP_REQUEST',
        'ADD_FAVOURITE_WORKSHOP_SUCCESS',
        'ADD_FAVOURITE_WORKSHOP_FAIL',
      ],
      data: {
        workshop: workshopId,
      },
    },
  };
}

export function deleteFavouriteWorkshop(workshopId) {
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/favourite_workshop/${workshopId}/`,
      types: [
        'DELETE_FAVOURITE_WORKSHOP_REQUEST',
        'DELETE_FAVOURITE_WORKSHOP_SUCCESS',
        'DELETE_FAVOURITE_WORKSHOP_FAIL',
      ],
    },
  };
}

export function createSMSPackage(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/sms_package/`,
      types: [
        'CREATE_SMS_PACKAGE_REQUEST',
        'CREATE_SMS_PACKAGE_SUCCESS',
        'CREATE_SMS_PACKAGE_FAIL',
      ],
      data,
    },
  };
}

export function fetchSMSCounts(workshopId) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/sms_counts/`,
      types: [
        'FETCH_SMS_COUNTS_REQUEST',
        'FETCH_SMS_COUNTS_SUCCESS',
        'FETCH_SMS_COUNTS_FAIL',
      ],
      params: {
        workshop_id: workshopId,
      },
    },
  };
}

export function updateWorkshopInfo(data, workshopId) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/workshop/${workshopId}/`,
      types: [
        'UPDATE_WORKSHOP_REQUEST',
        'UPDATE_WORKSHOP_SUCCESS',
        'UPDATE_WORKSHOP_FAIL',
      ],
      data,
    },
  };
}

export function patchWorkshopInfo(data, workshopId) {
  return {
    [API]: {
      method: PATCH,
      url: `${API_URL}/uiapi/1/workshop/${workshopId}/`,
      types: [
        'UPDATE_WORKSHOP_REQUEST',
        'UPDATE_WORKSHOP_SUCCESS',
        'UPDATE_WORKSHOP_FAIL',
      ],
      data,
    },
  };
}

export function fetchWorkshopPermissions(workshopId) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/workshop_permission/?workshop_id=${workshopId}`,
      types: [
        'FETCH_WORKSHOP_PERMISSIONS_REQUEST',
        'FETCH_WORKSHOP_PERMISSIONS_SUCCESS',
        'FETCH_WORKSHOP_PERMISSIONS_FAIL',
      ],
    },
  };
}

export function createWorkshopPermission(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/workshop_permission/`,
      types: [
        'CREATE_WORKSHOP_PERMISSION_REQUEST',
        'CREATE_WORKSHOP_PERMISSION_SUCCESS',
        'CREATE_WORKSHOP_PERMISSION_FAIL',
      ],
      data,
    },
  };
}

export function updateWorkshopPermission(permissionId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/workshop_permission/${permissionId}/`,
      types: [
        'UPDATE_WORKSHOP_PERMISSION_REQUEST',
        'UPDATE_WORKSHOP_PERMISSION_SUCCESS',
        'UPDATE_WORKSHOP_PERMISSION_FAIL',
      ],
      data,
    },
  };
}

export function deleteWorkshopPermission(permissionId, baseObjId) {
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/workshop_permission/${permissionId}/`,
      types: [
        'DELETE_WORKSHOP_PERMISSION_REQUEST',
        'DELETE_WORKSHOP_PERMISSION_SUCCESS',
        'DELETE_WORKSHOP_PERMISSION_FAIL',
      ],
      additionalData: {
        workshopId: baseObjId,
        permissionId,
      },
    },
  };
}

export function toggleWorkshopMessageSubscription(workshopId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/workshop/${workshopId}/toggle_message_subscription/`,
      types: [
        'TOGGLE_WORKSHOP_MESSAGE_SUBSCRIPTION_REQUEST',
        'TOGGLE_WORKSHOP_MESSAGE_SUBSCRIPTION_SUCCESS',
        'TOGGLE_WORKSHOP_MESSAGE_SUBSCRIPTION_FAIL',
      ],
      data,
    },
  };
}

export function selectWorkshop(workshopId) {
  return {
    type: 'SELECT_WORKSHOP',
    payload: workshopId,
  };
}
