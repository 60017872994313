import React, { Component } from 'react';
import { browserIsIE } from '../../../ie-support';

class CBInputField extends Component {
  constructor(props) {
    super(props);
    this.state = { isValid: true, errors: '' };
  }

  onEnterUp = e => {
    const { enterAction, blurOnEnter } = this.props;
    if (e.keyCode === 13 && enterAction) {
      const { isValid, value } = this.state;
      if (isValid && value != '' && enterAction) enterAction(value);
      this.setState({ value: '' });
    }
    if (e.keyCode === 13 && blurOnEnter) {
      e.target.blur();
    }
  };

  parseServerErrorMessages = () => {
    const { name, serverErrors, additionalErrorFields } = this.props;
    if (serverErrors !== null && typeof serverErrors === 'object') {
      let concattedServerErrors = '';
      if (serverErrors[name]) {
        serverErrors[name].forEach(msgPart => {
          concattedServerErrors += `${msgPart} `;
        });
        return concattedServerErrors;
      }

      if (additionalErrorFields) {
        additionalErrorFields.forEach(errorField => {
          serverErrors[errorField].forEach(msgPart => {
            concattedServerErrors += `${msgPart} `;
          });
        });
        return concattedServerErrors;
      }
    }
    return undefined;
  };

  validateInput = elem => {
    const value = elem.target.value;
    const { validators, name, isValidCallback, onBlur } = this.props;
    if (validators) {
      this.setState({ errors: '' });
      let validationErrors = '';
      let newValueIsValid = true;
      validators.forEach(validator => {
        if (!validator.validator(value)) {
          newValueIsValid = false;
          validationErrors += `${validator.errorMessage} `;
        }
      });
      if (isValidCallback) {
        isValidCallback(name, newValueIsValid);
      }
      this.setState({ isValid: newValueIsValid, errors: validationErrors });
      if (newValueIsValid && onBlur) {
        onBlur(value);
      }
      return;
    }
    if (onBlur) {
      onBlur(value);
    }
  };

  handleChange = elem => {
    const { noValidationOnChange } = this.props;
    if (!noValidationOnChange) {
      this.validateInput(elem);
    }
    const value = elem.target.value;
    const { name, type, onChange } = this.props;
    onChange({
      target: name,
      value: type === 'number' ? parseFloat(value, 0) : value,
    });
  };

  render() {
    const {
      className,
      labelStyle,
      labelTextStyle,
      labelText,
      name,
      type,
      step,
      min,
      max,
      maxlength,
      placeholder,
      inputStyle,
      autoComplete = false,
      value,
      disabled = false,
    } = this.props;

    const { errors } = this.state;
    return (
      <div style={styles.fieldContainer}>
        <p
          className='font-titillium-web-semi-bold'
          style={labelTextStyle || styles.labelText}
        >
          {labelText}
        </p>

        <input
          value={typeof value != "object" ? value : ""}
          onChange={this.handleChange}
          onBlur={this.validateInput}
          className={className || undefined}
          name={name}
          type={type || 'text'}
          step={step}
          min={min || '0'}
          max={max}
          maxLength={maxlength}
          placeholder={placeholder}
          style={inputStyle ? inputStyle : (browserIsIE() ? styles.inputIE : styles.input)}
          onKeyUp={this.onEnterUp}
          autoComplete={autoComplete ? 'on' : 'off'}
          disabled={disabled}
        />
        <span style={styles.errorMessage}>
          {this.parseServerErrorMessages() || errors}
        </span>
      </div>
    );
  }
}

const styles = {
  fieldContainer: {
    marginBottom: 4,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  input: {
    width: '100%',
    border: 0,
    borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
    fontSize: 14,
    color: '#ffffff',
    backgroundColor: 'transparent',
    margin: 0,
  },
  inputIE: {
    width: '100%',
    border: 0,
    borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
    fontSize: 14,
    color: '#ffffff',
    backgroundColor: 'transparent',
    marginTop: 16,
    marginBottom: 16,
  },
  labelTextStyle: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 20,
  },
  errorMessage: {
    fontSize: 10,
    color: 'red',
  },
};

export default CBInputField;
