import React from 'react';
import { Link } from 'react-router-dom';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';

class WSSettingsMenuItem extends React.Component {
  render() {
    const { href, to, text } = this.props;
    return (
      <CBMediaQuery>{screenSize => (
          <div style={getStyle(screenSize, styles, 'settingsLinkContainer')}>
            {!href &&
              (<Link style={getStyle(screenSize, styles, 'linkWrapper')} to={to}>
                <p style={getStyle(screenSize, styles, 'linkText')}>{text}</p>
              </Link>)
            }
            {!!href &&
              (<a style={getStyle(screenSize, styles, 'linkWrapper')} href={href}>
                <p style={getStyle(screenSize, styles, 'linkText')}>{text}</p>
              </a>)
            }
          </div>
        )}
      </CBMediaQuery>
        
      
    );
  }
}

const styles = {
  default: {
    settingsLinkContainer: {
      paddingLeft: 16,
      paddingTop: 8,
    },
  },
  medium: {
    paddingTop: 20,
  },
  small: {},
};

export default WSSettingsMenuItem;
