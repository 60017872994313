import { API, GET, POST, PUT, DELETE } from '../middleware/api';
import { API_URL } from '../config';

export function fetchAdvertisements() {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/media/`,
      types: [
        'FETCH_ADVERTISEMENTS_REQUEST',
        'FETCH_ADVERTISEMENTS_SUCCESS',
        'FETCH_ADVERTISEMENTS_FAIL',
      ],
    },
  };
}
export function fetchAdvertisement(advertId) {
  const url = `${API_URL}/uiapi/1/media/${advertId}/`;
  return {
    [API]: {
      method: GET,
      url,
      types: [
        'FETCH_ADVERTISEMENT_REQUEST',
        'FETCH_ADVERTISEMENT_SUCCESS',
        'FETCH_ADVERTISEMENT_FAIL',
      ],
    },
  };
}

export function createAdvertisement(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/media/`,
      types: [
        'CREATE_ADVERTISEMENT_REQUEST',
        'CREATE_ADVERTISEMENT_SUCCESS',
        'CREATE_ADVERTISEMENT_FAIL',
      ],
      data,
    },
  };
}

export function updateAdvertisement(data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/media/${data.id}/`,
      types: [
        'UPDATE_ADVERTISEMENT_REQUEST',
        'UPDATE_ADVERTISEMENT_SUCCESS',
        'UPDATE_ADVERTISEMENT_FAIL',
      ],
      data,
    },
  };
}

export function updateAdvertisementPicture(advertPictureData, advertId) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/user_profile/${advertId}/`,
      types: [
        'UPDATE_ADVERTISEMENT_PICTURE_REQUEST',
        'UPDATE_ADVERTISEMENT_PICTURE_SUCCESS',
        'UPDATE_ADVERTISEMENT_PICTURE_FAIL',
      ],
      data: advertPictureData,
    },
  };
}

export function deleteAdvertisement(advertId) {
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/media/${advertId}`,
      types: [
        'DELETE_ADVERTISEMENT_REQUEST',
        'DELETE_ADVERTISEMENT_SUCCESS',
        'DELETE_ADVERTISEMENT_FAIL',
      ],
      additionalData: {
        advertisementId: advertId,
      }
    },
  };
}
