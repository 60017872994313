import React, { Component } from 'react';
import Switch from 'react-switch';

class CBSwitch extends Component {
  render() {
    const { onChange, checked, size } = this.props;
    return (
      <Switch
        onChange={onChange}
        checked={checked}
        onColor='#FFF'
        offColor='#FFF'
        onHandleColor='#0095da'
        offHandleColor='#e1e1e1'
        width={size === 'small' ? 50 : 70}
        height={size === 'small' ? 20 : 30}
      />
    );
  }
}

export default CBSwitch;
