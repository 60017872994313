import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import CBMediaQuery from '../../shared/CBMediaQuery';
import AnnouncementListItem from './AnnouncementListItem';
import { getStyle } from '../../../utils/utils';
import { deleteAnnouncement as _deleteAnnouncement } from '../../../actions/announcementActions';

class AnnouncementList extends React.Component {
  render() {
    const { t, announcements, openModal } = this.props;
    const today = moment(new Date());
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            {announcements.map(announcement => {
              const date = moment(announcement.date);
              const published = date.isSameOrBefore(today);
              return (
                <AnnouncementListItem
                  key={announcement.id}
                  announcement={announcement}
                  published={published}
                  onRightButtonClick={() =>
                    this.props.deleteAnnouncement(announcement.id)
                  }
                  onLeftButtonClick={() =>
                    openModal(announcement.id, published ? 'copy' : 'update')
                  }
                />
              );
            })}
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {},
  },
  medium: {},
  small: {},
};

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
  {
    deleteAnnouncement: _deleteAnnouncement,
  },
)(translate('AnnouncementView')(AnnouncementList));
