export function setAppService(service) {
  return {
    type: 'SET_APP_SERVICE',
    payload: service,
  };
}

export function setLastListUrl(listUrl) {
  return {
    type: 'SET_LAST_LIST_URL',
    payload: listUrl,
  };
}
