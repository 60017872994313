import React, { Component } from 'react';
import { translate } from 'react-i18next';
import EditButton from './EditButton';
import { getStyle } from '../../../../utils/utils';
import CBMediaQuery from '../../../shared/CBMediaQuery';
import AddEditVehicleModal from '../../../Workshop/AddEditVehicleModal';

class WSHeaderData extends Component {
  state = {
    modalIsOpen: false,
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  openModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  afterOpenModal = () => {};

  render() {
    const { modalIsOpen } = this.state;
    const { vehicle, t, user } = this.props;

    let owner = `${t('noOwnerInfoLabel')}`;
    if (vehicle.unregistered_owner && !!vehicle.unregistered_owner.registered_owner) {
      owner = vehicle.unregistered_owner.registered_owner;
    } else if (!!vehicle.owner) {
      owner = vehicle.owner;
    }

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'dataContainer')}>
              <p className='font-titillium-web-extra-light' style={getStyle(screenSize, styles, 'primaryText')}>
                {owner}
              </p>
              <p className='font-titillium-web-extra-light' style={getStyle(screenSize, styles, 'primaryText')}>
                {vehicle.unregistered_owner && vehicle.unregistered_owner.phone_number}
              </p>
            </div>
            <div style={getStyle(screenSize, styles, 'editContainer')}>
              <EditButton onClick={vehicle.has_registered_owner ? undefined : this.openModal} />
              <AddEditVehicleModal
                vehicle={vehicle}
                modalIsOpen={modalIsOpen}
                closeModal={this.closeModal}
                afterOpenModal={this.afterOpenModal}
              />
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}
const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    dataContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    primaryText: {
      fontSize: 20,
      lineHeight: 0.9,
      fontWeight: 200,
      marginRight: 16,
    },
  },
  medium: {
    container: {
      width: '100%',
    },
    dataContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      alignItems: 'flex-start',
      justifyContent: 'right',
      marginTop: 0,
    },
    editContainer: {
      transform: 'translate(0px, -20px)',
    },
  },
  small: {
    dataContainer: {
      flexDirection: 'column',
    },
  },
};

export default translate('VehicleDetailsView')(WSHeaderData);