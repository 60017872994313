import React from 'react';

class CBHorizontalLine extends React.Component {
  render() {
    return (
      <div style={styles.container} />
    );
  }
}

const styles = {
  container: {
    width: '100%',
    borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
    marginTop: 32,
  },
};

export default CBHorizontalLine;
