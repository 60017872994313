import { careServiceState } from '../initialState';
import { storeInsertItem, storeRemoveItem, storeUpdateObjectInArray } from '../utils/utils';

export default function (state = careServiceState, action) {
  switch (action.type) {
    case 'FETCH_CARE_SERVICES_REQUEST': {
      return {
        ...state,
        careServiceListStatus: Object.assign({}, state.careServiceStatus, {
          loading: true,
          loadingFetch: true,
          error: null,
        }),
      };
    }
    case 'FETCH_CARE_SERVICES_SUCCESS': {
      return {
        ...state,
        careServices: action.payload,
        careServiceListStatus: Object.assign({}, state.careServiceStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_CARE_SERVICES_FAIL': {
      return {
        ...state,
        careServices: [],
        careServiceListStatus: Object.assign({}, state.careServiceStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'CREATE_CARE_SERVICE_REQUEST': {
      return {
        ...state,
        careServiceStatus: Object.assign({}, state.careServiceStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_CARE_SERVICE_SUCCESS': {
      return {
        ...state,
        careServices: storeInsertItem(state.careServices, action.payload),
        careServiceStatus: Object.assign({}, state.careServiceStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_CARE_SERVICE_FAIL': {
      return {
        ...state,
        careServiceStatus: Object.assign({}, state.careServiceStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_CARE_SERVICE_REQUEST': {
      return {
        ...state,
        careServiceStatus: Object.assign({}, state.careServiceStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_CARE_SERVICE_SUCCESS': {
      return {
        ...state,
        careServices: storeUpdateObjectInArray(state.careServices, action.payload),
        careServiceStatus: Object.assign({}, state.careServiceStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_CARE_SERVICE_FAIL': {
      return {
        ...state,
        careServiceStatus: Object.assign({}, state.careServiceStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'DELETE_CARE_SERVICE_REQUEST': {
      return {
        ...state,
        careServiceStatus: Object.assign({}, state.careServiceStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DELETE_CARE_SERVICE_SUCCESS': {
      return {
        ...state,
        careServices: storeRemoveItem(state.careServices, action.payload.id),
        careServiceStatus: Object.assign({}, state.careServiceStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DELETE_CARE_SERVICE_FAIL': {
      return {
        ...state,
        careServiceStatus: Object.assign({}, state.careServiceStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    default:
      break;
  }
  return state;
}
