import axios from 'axios';
import { toast } from 'react-toastify';
import i18n from '../i18n';
const dotenv = require('dotenv').config();

export const GET = 'get';
export const PUT = 'put';
export const POST = 'post';
export const DELETE = 'delete';
export const PATCH = 'patch';

export const API = 'Call API';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

export default (store) => (next) => (action) => {
  const callAPI = action[API];

  if (!callAPI) {
    return next(action);
  }

  // Using url
  if (!callAPI.url) {
    throw new Error('Missing URL');
  }

  // Using method
  if (
    !callAPI.method ||
    (callAPI.method !== GET &&
      callAPI.method !== POST &&
      callAPI.method !== PUT &&
      callAPI.method !== PATCH &&
      callAPI.method !== DELETE)
  ) {
    throw new Error('Missing HTTP method');
  }

  // Using three types: request, success and fail
  if (!callAPI.types || callAPI.types.length !== 3) {
    throw new Error('Invalid action types');
  }

  // Data merge
  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[API];
    return finalAction;
  };

  const [requestType, successType, failType] = callAPI.types;

  // Dispatch action
  const requestAction = actionWith({
    type: requestType,
    additionalData: callAPI.additionalData,
  });
  next(requestAction);
  // The actual HTTP request
  return axios({
    url: callAPI.url,
    method: callAPI.method,
    data: callAPI.data,
    params: callAPI.params,
    // withCredentials: true,
    // Add headers only in production
    // Depending on .env variable either add token of service.ovio or my.ovio user
    headers: {
      Authorization:
        process.env.REACT_APP_IS_PRODUCTION == 'false' &&
        (process.env.REACT_APP_IS_WORKSHOP_USER == 'true'
          ? `Token ${process.env.REACT_APP_SERVICE_TOKEN}`
          : `Token ${process.env.REACT_APP_USER_TOKEN}`),
    },
  })
    .then((response) => response)
    .then((response) => {
      if (response.config.method != 'get') {
        toast.success('Tiedot tallennettiin onnistuneesti!');
      }
      const data = response.data;
      next(
        actionWith({
          type: successType,
          payload: data,
          additionalData: callAPI.additionalData,
        })
      );
    })
    .catch((error) => {
      if (!error.response) {
        // Not an error with the HTTP request
        throw error;
      }
      // REQUEST_VEHICLE_PERMISSION errors are shown in a separate modal
      if (
        !(
          requestType === 'REQUEST_VEHICLE_PERMISSION_REQUEST' &&
          typeof error.response.data === 'object' &&
          error.response.data.error_code
        )
      ) {
        if (typeof error.response.data === 'object' && error.response.data.error_code) {
          const keys = [
            `errors:${error.response.data.error_code}`,
            `traficomErrors:${error.response.data.error_code}`,
          ];
          toast.error(i18n.t(keys));
        } else if (error.response.data.non_field_errors) {
          toast.error(error.response.data.non_field_errors[0]);
        } else {
          toast.error(
            'Tietojen hakeminen/tallentaminen epäonnistui. Yritä myöhemmin uudelleen.'
          );
        }
      }
      next(
        actionWith({
          type: failType,
          payload: error.response.data,
          additionalData: callAPI.additionalData,
        })
      );
    });
};
