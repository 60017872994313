import React from 'react';
import Spinner from 'react-spinkit';

class CBSpinnerFloating extends React.Component {
  render() {
    const { color } = this.props;
    return (
      <div style={styles.spinnerContainer}>
        <Spinner name='ball-beat' color={color} />
      </div>
    );
  }
}

CBSpinnerFloating.defaultProps = {
  color: undefined,
};

const styles = {
  spinnerContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
  },
};

export default CBSpinnerFloating;
