import React from 'react';
import { translate } from 'react-i18next';
import moment from 'moment';
import axios from 'axios';
import CBInputField from '../../shared/form_components/CBInputField';
import CBSubmitButton from '../../shared/form_components/CBSubmitButton';
import CBButtonSettings from '../../shared/CBButtonSettings';
import CBSpinnerFloating from '../../shared/CBSpinnerFloating';
import {
  getLengthValidator,
  getNumberValidator,
  getPhoneValidator,
  getEmailValidator,
} from '../../../utils/validators';
import CBDatePicker from '../../shared/CBDatePicker';
import { API_URL } from '../../../config';
import CBLabelAndValue from '../../shared/CBLabelAndValue';

class GarageForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { formData: Object.assign({}, props.garage), formValid: {} };
  }

  componentWillReceiveProps(nextProps) {
    const { garage } = this.props;

    if (garage.id !== nextProps.garage.id) {
      this.setState({ formData: Object.assign({}, nextProps.garage), formValid: {} });
    }
  }

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
    };
    newFormValid[name] = isValid;

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const { isSubForm, onChange } = this.props;
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });

    if (isSubForm && onChange) {
      onChange({ ...newFormData });
    }
  };

  toggleFormStateBoolean = property => {
    const { isSubForm, onChange } = this.props;
    this.setState(prevState => {
      const { formData } = prevState;
      const newFormData = Object.assign({}, formData);
      newFormData[property] = !newFormData[property];
      if (isSubForm && onChange) {
        onChange({ ...newFormData });
      }
      return { formData: newFormData };
    });
  };

  removeGarage = () => {
    const { onChange } = this.props;
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData.delete = true;
    onChange({ ...newFormData });
  };

  submitForm = () => {
    const { formData } = this.state;
    const { createGarage, updateGarage, garage } = this.props;
    const newFormData = Object.assign({}, formData);

    if (garage.id) {
      updateGarage(garage.id, newFormData);
    } else {
      createGarage(newFormData);
    }
  };

  render() {
    const { t, garageRequest, isSubForm } = this.props;
    const { formData } = this.state;
    return (
      <div style={styles.formContainer}>
        {garageRequest && garageRequest.garageStatus.loading && <CBSpinnerFloating />}
        <div>

          <img
            src='/img/icon_close.svg'
            alt='close'
            style={styles.closeIcon}
            onClick={this.removeGarage}
            className='opacity-on-hover'
          />
          <CBInputField
            labelText={t('nameLabel')}
            name='name'
            onChange={this.updateFormState}
            value={formData.name}
            serverErrors={garageRequest && garageRequest.garageStatus.error}
            isValidCallback={this.setIsValid}
            validators={[getLengthValidator(3, 255)]}
            labelTextStyle={styles.fieldContainerLeftAlign}
          />
          <CBLabelAndValue label={t('vehicleCountLabel')} value={formData.vehicle_count} />
        </div>

        {
            !isSubForm && (
              <CBSubmitButton
                disabled={garageRequest.garageStatus.loading || !this.getIsValid()}
                onClick={this.submitForm}
              />
            )
        }

      </div>
    );
  }
}

const styles = {
  closeIcon: {
    position: 'absolute',
    top: 8,
    right: 8,
    opacity: 0.2,
    height: 20,
    widhth: 20,
    cursor: 'pointer',
  },
  formContainer: {
    position: 'relative',
    marginBottom: 32,
  },
  arrowIcon: {
    margin: '0px 8px',
    width: 32,
    height: 32,
    fill: '#ffffff',
  },
  datepickerContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    marginTop: 24,
  },
  fieldContainerLeftAlign: {
    marginBottom: 5,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
};

export default translate('SettingsView')(GarageForm);
