import {
  API, GET, POST, PUT, DELETE,
} from '../middleware/api';
import { API_URL } from '../config';

export function fetchVehicleCategories() {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/vehicle_category/`,
      types: [
        'FETCH_VEHICLE_CATEGORIES_REQUEST',
        'FETCH_VEHICLE_CATEGORIES_SUCCESS',
        'FETCH_VEHICLE_CATEGORIES_FAIL',
      ],
    },
  };
}

export function createVehicleCategory(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/vehicle_category/`,
      types: [
        'CREATE_VEHICLE_CATEGORY_REQUEST',
        'CREATE_VEHICLE_CATEGORY_SUCCESS',
        'CREATE_VEHICLE_CATEGORY_FAIL',
      ],
      data,
    },
  };
}

export function updateVehicleCategory(categoryId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/vehicle_category/${categoryId}/`,
      types: [
        'UPDATE_VEHICLE_CATEGORY_REQUEST',
        'UPDATE_VEHICLE_CATEGORY_SUCCESS',
        'UPDATE_VEHICLE_CATEGORY_FAIL',
      ],
      data,
    },
  };
}

export function deleteVehicleCategory(categoryId) {
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/vehicle_category/${categoryId}/`,
      types: [
        'DELETE_VEHICLE_CATEGORY_REQUEST',
        'DELETE_VEHICLE_CATEGORY_SUCCESS',
        'DELETE_VEHICLE_CATEGORY_FAIL',
      ],
    },
  };
}
