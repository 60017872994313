import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle, formatDate, formatPrice } from '../../../utils/utils';
import CBHeaderTextWithHelp from '../../shared/CBHeaderTextWithHelp';
import {
  fetchInvoiceReports as _fetchInvoiceReports,
  createInvoiceReport as _createInvoiceReport,
  updateInvoiceReport as _updateInvoiceReport,
  deleteInvoiceReport as _deleteInvoiceReport,
} from '../../../actions/invoiceReportActions';
import _ from 'lodash';
import { Link } from 'react-router-dom/cjs/react-router-dom';

class StaffSettingsPricing extends React.Component {
  componentDidMount () {
    const { fetchInvoiceReports } = this.props;
    fetchInvoiceReports();
  }

  render() {
    const {
      t,
      invoiceReports,
    } = this.props;

    const sortedInvoiceReports = _.reverse(_.sortBy(invoiceReports, 'valid_to_date'));

    return (
      <CBMediaQuery>
        {(screenSize) => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <CBHeaderTextWithHelp text={t('staffSettingsInvoiceReportsHeading')} />
            <div style={getStyle(screenSize, styles, 'sectionContainer')}>
              <p>
                {t('staffSettingsInvoiceReportsDescription')}
              </p>
            </div>
            <React.Fragment>
              <table style={getStyle(screenSize, styles, 'table')}>
                <thead>
                  <tr>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                      {t('invoiceReportBusinessIdLabel')}
                    </th>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                      {t('invoiceReportBusinessNameLabel')}
                    </th>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                      {t('invoiceReportBusinessTypeLabel')}
                    </th>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                      {t('invoiceReportBeginDateLabel')}
                    </th>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                      {t('invoiceReportEndDateLabel')}
                    </th>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                      {t('invoiceReportVehicleCountLabel')}
                    </th>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                      {t('invoiceReportPriceLabel')}
                    </th>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    sortedInvoiceReports.map((invoiceReport) => (
                      <tr key={invoiceReport.id}>
                        <td style={getStyle(screenSize, styles, 'tableTd')}>
                          {invoiceReport.business_id}
                        </td>
                        <td style={getStyle(screenSize, styles, 'tableTd')}>
                          {invoiceReport.business_name}
                        </td>
                        <td style={getStyle(screenSize, styles, 'tableTd')}>
                          {t(invoiceReport.organization_type)}
                        </td>
                        <td style={getStyle(screenSize, styles, 'tableTd')}>
                          {formatDate(invoiceReport.begin_date)}
                        </td>
                        <td style={getStyle(screenSize, styles, 'tableTd')}>
                          {formatDate(invoiceReport.end_date)}
                        </td>
                        <td style={getStyle(screenSize, styles, 'tableTd')}>
                          {invoiceReport.vehicles.length}
                        </td>
                        <td style={getStyle(screenSize, styles, 'tableTd')}>
                          {formatPrice(invoiceReport.total_price)}
                        </td>
                        <td style={getStyle(screenSize, styles, 'tableTd')}>
                          <a
                            className='hover-underline'
                            style={getStyle(screenSize, styles, 'link')}
                            href={`/reports/invoice_report/${invoiceReport.id}`}
                          >
                            {t('invoiceReportLinkLabel')}
                          </a>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </React.Fragment>
          </div>
        )}
      </CBMediaQuery>
    );
  }

};

const styles = {
  default: {
    container: {
      padding: 16,
    },
    tableHeading: {
      fontFamily: 'TitilliumWeb-Regular',
      fontSize: 12,
      color: '#ffffff',
      paddingLeft: 8,
      paddingBottom: 8,
    },
    link: {
      color: '#FFFFFF',
    },
    tableTd: {
      border: '1px solid rgba(255, 255, 255, .3)',
      color: '#FFFFFF',
      paddingLeft: 8,
      paddingRight: 8,
    },
    table: {
      tableLayout: 'auto',
      width: '100%',
    },
    sectionContainer: {
      marginBottom: 16,
    },
  },
  medium: {
    container: {
      padding: 0,
    },
  },
  small: {
    container: {
      padding: 0,
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    invoiceReports: state.invoiceReport.invoiceReports,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchInvoiceReports: _fetchInvoiceReports,
  },
)(translate('SettingsView')(StaffSettingsPricing));
