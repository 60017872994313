import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CBMediaQuery from './CBMediaQuery';
import { getStyle } from '../../utils/utils';
import { history } from '../../index';

export default class BackButton extends Component {
  render() {
    const { to, navigateBack } = this.props;

    return (
      <CBMediaQuery>
        {screenSize =>
          (to ? (
            <Link to={to}>
              <div className='opacity-and-border-on-hover' style={getStyle(screenSize, styles, 'container')}>
                <img
                  src='/img/icon_arrow.svg'
                  alt='Back'
                  style={getStyle(screenSize, styles, 'icon')}
                />
              </div>
            </Link>
          ) : (
            <div
              className='pointer'
              onClick={navigateBack}
              style={getStyle(screenSize, styles, 'container')}
            >
              <img
                src='/img/icon_arrow.svg'
                alt='Back'
                style={getStyle(screenSize, styles, 'icon')}
              />
            </div>
          ))
        }
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      width: 60,
      height: 40,
      opacity: 0.3,
      border: '1px solid #ffffff',
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      'hover:': {
        borderColor: 'white',
      },
    },
    icon: {
      width: 32,
      height: 32,
    },
  },
  small: {
    container: {
      height: 40,
      width: 40,
    },
  },
};
