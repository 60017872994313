import { organizationVehicleReminderState } from '../initialState';
import { storeInsertItem, storeRemoveItem, storeUpdateObjectInArray } from '../utils/utils';

export default function (state = organizationVehicleReminderState, action) {
  switch (action.type) {
    case 'CREATE_ORGANIZATION_VEHICLE_REMINDER_REQUEST': {
      return {
        ...state,
        organizationVehicleReminderStatus: Object.assign({}, state.organizationVehicleReminderStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'CREATE_ORGANIZATION_VEHICLE_REMINDER_SUCCESS': {
      return {
        ...state,
        organizationVehicleReminders: storeInsertItem(state.organizationVehicleReminders, action.payload),
        organizationVehicleReminderStatus: Object.assign({}, state.organizationVehicleReminderStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'CREATE_ORGANIZATION_VEHICLE_REMINDER_FAIL': {
      return {
        ...state,
        organizationVehicleReminderStatus: Object.assign({}, state.organizationVehicleReminderStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'UPDATE_ORGANIZATION_VEHICLE_REMINDER_REQUEST': {
      return {
        ...state,
        organizationVehicleReminderStatus: Object.assign({}, state.organizationVehicleReminderStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'UPDATE_ORGANIZATION_VEHICLE_REMINDER_SUCCESS': {
      return {
        ...state,
        organizationVehicleReminders: storeUpdateObjectInArray(state.organizationVehicleReminders, action.payload),
        organizationVehicleReminderStatus: Object.assign({}, state.organizationVehicleReminderStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'UPDATE_ORGANIZATION_VEHICLE_REMINDER_FAIL': {
      return {
        ...state,
        organizationVehicleReminderStatus: Object.assign({}, state.organizationVehicleReminderStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'DELETE_ORGANIZATION_VEHICLE_REMINDER_REQUEST': {
      return {
        ...state,
        organizationVehicleReminderStatus: Object.assign({}, state.organizationVehicleReminderStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'DELETE_ORGANIZATION_VEHICLE_REMINDER_SUCCESS': {
      return {
        ...state,
        organizationVehicleReminders: storeRemoveItem(state.organizationVehicleReminders, action.additionalData.itemId),
        organizationVehicleReminderStatus: Object.assign({}, state.organizationVehicleReminderStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'DELETE_ORGANIZATION_VEHICLE_REMINDER_FAIL': {
      return {
        ...state,
        organizationVehicleReminderStatus: Object.assign({}, state.organizationVehicleReminderStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    case 'FETCH_ORGANIZATION_VEHICLE_REMINDER_REQUEST': {
      return {
        ...state,
        organizationVehicleReminders: [],
        organizationVehicleReminderStatus: Object.assign({}, state.organizationVehicleReminderStatus, {
          loading: true,
          error: null,
        }),
      };
    }
    case 'FETCH_ORGANIZATION_VEHICLE_REMINDER_SUCCESS': {
      return {
        ...state,
        organizationVehicleReminders: action.payload,
        organizationVehicleReminderStatus: Object.assign({}, state.organizationVehicleReminderStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_ORGANIZATION_VEHICLE_REMINDER_FAIL': {
      return {
        ...state,
        organizationVehicleReminders: [],
        organizationVehicleReminderStatus: Object.assign({}, state.organizationVehicleReminderStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    default:
      break;
  }
  return state;
}
